import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, withStyles, Typography } from '@material-ui/core'
import moment from 'moment'
import * as STOCKS from '../../../redux/actions/stocks'
import * as CONSTANTS from '../../../utils/constants'
import { formatNumber } from '../../../utils/helpers'

const styles = theme => ({
    detailsWrapper: {
        display: 'flex',
        flexDirection: 'row'
    },
    details: {
        flex: 1,
        padding: '36px 25px 0px 24px',
    },
    detailText: {
        padding: 0,
        fontWeight: 500
    },
    priceTitle: {
        color: 'black'
    }
})

class FinancingTabDetails extends Component {

    state = {
        page: 0,
        rowsPerPage: 2,
        renderPage: false,
        currentStock: null,
        financingCostModal: false
    }

    changeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value })
    }

    changePage = (event, page) => {
        this.setState({ page })
    }

    getStockHandler = () => {
        this.props.getStockById(this.props.fullStock.id).then(stock => {
            this.setState({ currentStock: stock, renderPage: true })
        })
    }

    componentDidMount() {
        this.getStockHandler()
    }

    cancelCostModal = () => this.setState({ financingCostModal: false }, this.getStockHandler)

    onSaveHandler = () => this.setState({ financingCostModal: false }, this.getStockHandler)

    render() {
        const { classes } = this.props
        let filedsToDisplay = [
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.REMAINEDVALUE, name: this.props.language.fCost.remainedValue },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.FINANCEDVALUE, name: this.props.language.fCost.financedValue },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.CONTRACTDATE, name: this.props.language.fCost.contractDate },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.CONTRACT_NUMBER, name: this.props.language.fCost.contractNumber },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.INITIALCONTRIBUTION, name: this.props.language.fCost.initial },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.ANALYSISTAX1, name: this.props.language.fCost.a1 },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.ANALYSISTAX2, name: this.props.language.fCost.a2 },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.INTERESTRESTITUTION, name: this.props.language.fCost.interest },
        ]

        if (this.state.renderPage) {
            return (
                <>
                    <div className={classes.detailsWrapper}>
                        <div className={classes.details}>
                            {filedsToDisplay.map(field => {
                                if (field.field === CONSTANTS.FINANCING_COST_DETAILS_FIELDS.CONTRACTDATE) {
                                    return (
                                        <Typography key={field.field} className={classes.detailText} component="p">{field.name}: {!this.state.currentStock.financingCost[field.field] ? <span>-</span> : moment(this.state.currentStock.financingCost[field.field]).format(CONSTANTS.DEFAULT_DATE_FORMAT)}</Typography>
                                    )
                                }
                                if (field.field === CONSTANTS.FINANCING_COST_DETAILS_FIELDS.CONTRACT_NUMBER) {
                                    return (
                                        <Typography key={field.field} className={classes.detailText} component="p">{field.name}: {this.state.currentStock.financingCost[field.field] || '-'}</Typography>
                                    )
                                }
                                return (
                                    <Typography key={field.field} className={classes.detailText} component="p">{field.name}: {formatNumber(this.state.currentStock.financingCost[field.field]) || 0}</Typography>
                                )
                            })}
                            <div>
                                <h3 className={classes.priceTitle}>{this.props.language.fCost.financingPrice}: {formatNumber(this.state.currentStock.financingCost.total)}</h3>
                            </div>
                            <Button onClick={() => this.setState({ financingCostModal: true })}>{this.props.language.editButton}</Button>
                        </div>
                    </div>
                </>)
        }
        else return null
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails,
})

const mapDispatchToProps = dispatch => ({
    getStockById: stockId => dispatch(STOCKS.getById(stockId))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FinancingTabDetails))