import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField, List, ListItem, ListItemText, InputAdornment, withStyles } from '@material-ui/core'
import { ArrowDownward } from '@material-ui/icons'

const styles = () => ({
    listContainer: {
        position: 'relative',
        display: 'block',
        height: '45px',
        marginTop: '8px',
        marginBottom: '8'
    },
    list: {
        width: '100%',
        backgroundColor: '#ffffff',
        position: 'absolute',
        zIndex: '100',
        boxSizing: 'border-box',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        margin: '0px',
        marginTop: '25px'
    },
    listItem: {
        padding: '10px'
    },
    textField: {
        display: 'block',
        height: 12
    },
    labelRoot: {
        marginTop: -6
    },
    labelFocused: {}
})

class AutoCompleteInput extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: '',
            showList: false,
            showNoOptions: false,
            emptyValue: false,
            options: props.options || []
        }

        this.props.getOptions()
            .then(options => this.setState({ options }))
    }

    handleChange = value => {
        if (value.length) {
            this.props.getOptions(value).then(values => {
                if (values.length) {
                    this.setState({ showList: true, value: value, showNoOptions: false, emptyValue: false, options: values })
                } else {
                    this.setState({ showList: true, value: value, showNoOptions: true, emptyValue: false, options: [] })
                }
            })
            this.props.onChange({
                target: {
                    brandName: value,
                    name: this.props.name,
                    value: value
                }
            })
        } else {
            this.setState({ showList: false, value: '', showNoOptions: false, emptyValue: true }, this.handleFocus)
        }
    }

    handleFocus = () => {
        //search for empty string ( match all )
        this.props.getOptions(this.state.value).then(values => {
            this.setState({
                options: values,
                showNoOptions: values.length ? false : true,
                showList: true
            })
        })
    }

    handleBlur = () => {
        this.setState({
            showList: false,
            showNoOptions: false
        })
    }

    handleItemClick = (event, option) => {
        this.setState({
            value: option.label,
            showNoOptions: false,
            showList: false
        })
        this.props.onChange({
            target: {
                brandName: option.label,
                name: this.props.name,
                value: option.value
            }
        })

        event.preventDefault()
        event.stopPropagation()
    }

    generateValue() {
        if (this.props.value >= 0 && !this.state.value.length > 0 && !this.state.emptyValue) {
            let findIndex = this.state.options.findIndex(option =>option.value === this.props.value)
        
            if (findIndex > -1) {
                return this.state.options[findIndex].label
            }
            return ''
        } else
            return this.state.value
    }

    render() {
        const { classes, getOptions, ...restOfProps } = this.props
        const listItems = this.state.options.map((option, index) =>
            <ListItem key={index} divider={true} button className={classes.listItem} onMouseDown={event => this.handleItemClick(event, option)}>
                <ListItemText
                    primary={option.label}
                />
            </ListItem>)
        return (
            <div className={classes.listContainer}>
                <TextField
                    {...restOfProps}
                    label={this.props.label}
                    className={classes.textField}
                    name={this.props.name}
                    value={this.generateValue()}
                    onChange={event => this.handleChange(event.target.value)}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    autoComplete="off"
                    margin="normal"
                    variant="outlined"
                    fullWidth={true}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment onClick={this.handleFocus}>
                                <ArrowDownward />
                            </InputAdornment>
                        )
                    }}
                />
                {this.state.showList && <List dense={true} className={classes.list}>
                    {listItems}
                    {this.state.showNoOptions && this.props.noOptions}
                </List>}
            </div>
        )
    }
}

AutoCompleteInput.propTypes = {
    options: PropTypes.array.isRequired,
    noOptions: PropTypes.object
}

export default withStyles(styles)(AutoCompleteInput)