import React, { Component } from 'react'
import { TextField, Button, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { Lock, LockOpen as Opened } from '@material-ui/icons'
import sha256 from 'sha256'

import { validations } from '../../utils/validationUtils'
import * as NOTIFICATION from '../../utils/notification'
import * as LOGIN from '../../redux/actions/login'

class Login extends Component {
    state = {
        isLoading: false,
        username: '',
        password: '',
        usernameTouched: false,
        passwordTouched: false,
        canSubmit: false
    }

    componentDidMount() {
        this.toggleSubmitButton()
    }


    login = () => this.props.login(this.state.username, sha256(this.state.password))
        .then(() => this.props.onLogin())
        .catch(err => {
            if (!err.response)
                NOTIFICATION.error(this.props.languageReducer.serverDown)
            else
                NOTIFICATION.error(this.props.languageReducer.invalidCredentials)
            this.setState({ isLoading: false })
        })

    onChange(event) {
        this.setState({ [event.target.name]: event.target.value, [`${event.target.name}Touched`]: true }, this.toggleSubmitButton)
    }

    toggleSubmitButton = () => this.setState({
        canSubmit: validations.notEmpty(this.state.username)
            && validations.notEmpty(this.state.password)
    })

    render() {
        let { loginForm } = this.props.languageReducer

        return (
            <div className='loginContainer'>
                <div className='login'>
                    {this.state.canSubmit === false ? <Lock fontSize='large' disabled />
                        : <Opened fontSize='large' color='primary' />}
                    <TextField
                        className='loginInput'
                        label={loginForm.username}
                        onChange={event => this.onChange(event)}
                        error={this.state.usernameTouched ? validations.notEmpty(this.state.username) ? false : true : false}
                        name='username'
                    />
                    <TextField
                        className='loginInput'
                        label={loginForm.password}
                        type='password'
                        error={this.state.passwordTouched ? validations.notEmpty(this.state.password) ? false : true : false}
                        onChange={event => this.onChange(event)}
                        onKeyPress={event => event.key === 'Enter' ? this.login() : null}
                        name='password'
                    />
                    <Button
                        disabled={!this.state.canSubmit}
                        className='loginButton'
                        variant='contained'
                        onClick={() => this.login()} color='primary'>
                        {this.state.isLoading ? <CircularProgress style={{ color: '#ffffff' }} size={24} /> : loginForm.login}
                    </Button>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    login: (username, password) => dispatch(LOGIN.login(username, password)),
    isLogged: () => dispatch(LOGIN.isLogged())
})

const mapStateToProps = reducers => ({
    languageReducer: reducers.languageReducer.i18n.login
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)