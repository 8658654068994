import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { withStyles, CircularProgress } from '@material-ui/core'

import SimpleModal from '../../common/SimpleModal'
import InputGenerator from '../../common/subComponents/InputGenerator'

import * as CONSTANTS from '../../../utils/constants'
import * as NOTIFICATIONS from '../../../utils/notification'
import * as LEASINGS from '../../../redux/actions/leasing'

const styles = theme => ({
    modalContainer: {
        paddingTop: 18,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        color: 'black'
    },
    equalFlex: {
        flex: 1
    },
    inputPadding: {
        paddingRight: 10,
        paddingTop: 2
    },
    spinnerStyle: {
        color: 'white',
        height: '25px !important',
        width: '25px !important'
    }
})

class LeasingModal extends Component {

    currentDate = moment().format(CONSTANTS.INPUT_TYPE_DATE_FORMAT)

    initialFields = [
        {
            value: '', type: 'dropdownSelector', utils: 'Leasing', name: 'leasingId',
            options: [],
        }
    ]

    state = {
        openModal: false,
        renderPage: false,
        modalFields: this.initialFields,
    }

    InputWrapper = ({ input, key, shrink, onChange }) => <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding}`}>
        <InputGenerator
            key={key}
            fullWidth={true}
            InputLabelProps={shrink ? { shrink: true } : {}}
            margin="dense"
            {...input}
            onChange={event => onChange(event)}
        />
    </div>

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            this.getLeasings()
        }
        if (prevProps.open && !this.props.open) {
            this.setState({
                openModal: false
            })
        }
    }

    getLeasings = () => {
        this.props.getLeasings()
            .then(leasings => {
                let modalFieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))
                const leasingIndex = modalFieldsCopy.findIndex(field => field.name === 'leasingId')
                if (leasingIndex > -1) {
                    modalFieldsCopy[leasingIndex].options = [{ name: '', label: '' }].concat(leasings.rows.map(leasing => ({
                        name: leasing.id,
                        label: leasing.name
                    })))

                    if (this.props.leasingId) {
                        modalFieldsCopy[leasingIndex].value = this.props.leasingId
                    }
                }
                this.setState({ modalFields: modalFieldsCopy, openModal: true, renderPage: true })
            })
    }


    onCloseHandler = () => {
        this.props.onClose()
        this.setState({ modalFields: this.initialFields, openModal: false })
    }


    renderFields = () => {

        const InputWrapper = this.InputWrapper

        let jsonMap = {}
        this.state.modalFields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <>
                <InputWrapper input={jsonMap['leasingId']} key={'leasingId'} onChange={event => this.onChangeHandler(event)} />
            </>
        )

    }

    onChangeHandler = event => {
        const fieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))
        const currentIndex = fieldsCopy.findIndex(index => index.name === event.target.name)

        if (currentIndex > -1) {
            fieldsCopy[currentIndex].value = event.target.value
            this.setState({ modalFields: fieldsCopy })
        }
    }

    onCreate = () => {

        let leasingJson = {}
        this.state.modalFields.forEach(field => {
            leasingJson[field.name] = field.value
        })
        this.props.sendAnalysis(leasingJson["leasingId"])
    }

    render() {
        const { language } = this.props

        return this.state.renderPage ? <SimpleModal
            styles={this.props.classes}
            open={this.state.openModal}
            onClose={() => this.onCloseHandler()}
            onCancel={() => this.onCloseHandler()}
            onAccept={() => this.onCreate()}
            withCancelButton={false}
            acceptButtonText={this.props.displaySpinner ? <><span>{this.props.language.itemDetails.offer.sendLeasingAnalysis}</span>&nbsp;<CircularProgress className={this.props.classes.spinnerStyle} /></> : language.offer.createModal.leasing}
            cancelButtonText={language.offer.createModal.cancel}
            canSubmitForm={true}
            title={language.offer.createModal.leasingTitle}
            maxWidth={'sm'}
        >
            {this.renderFields()}
        </SimpleModal> : null
    }
}
const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getLeasings: () => dispatch(LEASINGS.get())
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LeasingModal))