import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Dialog, DialogContent, Button, withStyles,
    AppBar, Toolbar
} from '@material-ui/core'

import StockTabDetails from '../stock/StockTabDetails'
import FinancingCostTabDetails from '../costs/financingCost/FinancingCostTabDetails'
import LeasingCostTabDetails from '../costs/leasingCost/LeasingCostTabDetails'
import OfferListTimeline from '../offer/OfferListTimeline'
import OfferTabDetails from '../offer/offerTab/OfferTabDetails'
import Title from './subComponents/ModalTitleDetails'
import OfferStockTabDetails from '../offer/stockTab/OfferStockTabDetails'
import ClientOfferList from '../admin/client/ClientOfferList'
import * as CONSTANTS from '../../utils/constants'

const style = theme => ({
    dialogContentContainer: {
        height: '100%',
        paddingLeft: 0,
        paddingRight: 0,
        '&:first-child': {
            paddingTop: 8
        }
    },
    actionsContainer: {
        paddingTop: 23,
        display: 'flex',
        flexDirection: 'row'
    },
    createOfferButton: {
        float: 'right',
        marginRight: 18
    },
    paginationActions: {
        flex: 1,
        paddingLeft: 18
    },
    prevButton: {
        paddingLeft: 28,
        paddingRight: 28,
        marginRight: 12
    },
    nextButton: {
        paddingLeft: 20,
        paddingRight: 20
    },
    sellButton: {
        marginLeft: 'auto',
        marginRight: 18
    },
    contentContainer: {
        maxHeight: 550,
        minHeight: 250,
        border: '1px solid rgba(0,0,0,0.2)',
        borderLeft: 0,
        borderRight: 0,
        boxSizing: 'border-box',
        overflow: 'auto',
        width: '100%',
    },
    dialogPaper: {
        display: 'block',
        paddingLeft: 0,
        paddingRight: 0,
    },
    cancelButton: {
        marginRight: 'auto',
        marginLeft: 18
    },
    clickedOption: {
        color: "#3e3f42",
        borderBottom: '3px solid #00ad31'
    },
    modalTitle: {
        color: '#3e3f42',
        fontSize: 22,
        fontWeight: '500'
    },
    modalTitleContainer: {
        paddingBottom: 18
    },
    appBarContainer: {
        height: 55,
        backgroundColor: 'white',
        borderTop: '1px solid #eaedf3',
        color: 'black',
        boxShadow: 'none'
    },
    closeButton: {
        float: 'right',
        marginRight: 21,
        cursor: 'pointer'
    },
    optionContainer: {
        '&:last-child': {
            borderRight: 'none'
        },
        '&:hover': {
            backgroundColor: '#eee',
            color: 'black'
        },
        flex: 1,
        color: '#6b6c6f',
        display: 'flex',
        borderRight: '1px solid #eaedf3',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        cursor: 'pointer'
    },
    toolbarContainer: {
        height: '100%',
        paddingLeft: 0,
        paddingRight: 0
    },
    optionsHolder: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 25
    },
    nextPrevContainer: {
        paddingLeft: 14,
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    closeContainer: {
        paddingLeft: 14
    },
    deleteStockButton: {
        marginLeft: 18,
        backgroundColor: '#b01214'
    }
})

class ItemDetails extends Component {

    headers = [CONSTANTS.NAVBAR.STOCK_OFFER, CONSTANTS.NAVBAR.OFFERS_OFFER]

    state = {
        options: []
    }

    componentDidMount() {
        this.setState({
            options: this.props.navbarOptions ? this.props.navbarOptions.map((option, index) => {
                return (index === 0 && !this.props.defaultTab) || (this.props.defaultTab && this.props.defaultTab === option.toLowerCase()) ?
                    ({
                        name: option,
                        clicked: true
                    })
                    :
                    ({
                        name: option,
                        clicked: false
                    })
            }) : !this.props.noHeaders ? this.headers.map((option, index) => {
                return (index === 0 && !this.props.defaultTab) || (this.props.defaultTab && this.props.defaultTab === option.toLowerCase()) ?
                    ({
                        name: option,
                        clicked: true
                    })
                    :
                    ({
                        name: option,
                        clicked: false
                    })
            }) : []
        })
    }

    checkOption = option => {
        let optionIndex = this.state.options.findIndex(idx => idx.name === option)
        if (optionIndex > -1) {
            return this.state.options[optionIndex].clicked
        }

        return false
    }

    setClickedOption = option => {
        let optionsCopy = [...this.state.options]
        let optionIndex = optionsCopy.findIndex(idx => idx.name === option)

        if (optionIndex > -1) {
            optionsCopy = optionsCopy.map(option => {
                return ({
                    name: option.name,
                    clicked: false
                })
            })
            optionsCopy[optionIndex].clicked = true
        }

        //notify parent
        if (typeof this.props.onChangeTab === 'function') this.props.onChangeTab(option)
        this.setState({
            options: optionsCopy
        })
    }

    renderFooter = () => {
        if (this.props.footer === CONSTANTS.CANCEL_FOOTER) {
            return (
                <div className={this.props.classes.closeContainer}>
                    <Button className={this.props.classes.nextButton} onClick={this.props.onCancel} color="secondary">Inchide</Button>
                </div>

            )
        }

        if (this.props.footer === CONSTANTS.STOCK_FOOTER) {
            return (
                <>
                    <div className={this.props.classes.paginationActions}>
                        <Button onClick={this.props.onCancel} className={this.props.classes.prevButton} color="secondary">{this.props.language.offer.formModal.cancel}</Button>
                        {this.checkOption(CONSTANTS.NAVBAR.STOCK) && <Button className={this.props.classes.createOfferButton} onClick={this.props.editStock} color="primary">{this.props.language.buttons.modify}</Button>}
                        <Button onClick={this.props.onDelete} className={this.props.classes.deleteStockButton} color="primary">{this.props.language.stock.simpleTable.actions.delete}</Button>
                    </div>
                </>

            )
        }

        if (this.props.footer === CONSTANTS.STOCK_OFFER_FOOTER) {
            return (
                <>
                    <div className={this.props.classes.paginationActions}>
                        <Button onClick={this.props.onCancel} className={this.props.classes.prevButton} color="secondary">{this.props.language.offer.formModal.cancel}</Button>
                        <Button className={this.props.classes.createOfferButton} onClick={this.props.openCreateOffer} color="primary">{this.props.language.offer.formModal.add}</Button>
                    </div>
                </>
            )
        }

        if (!this.checkOption(CONSTANTS.NAVBAR.OFFERS_OFFER) && !this.checkOption(CONSTANTS.NAVBAR.STOCK_OFFER)) {
            return (
                <div className={this.props.classes.nextPrevContainer}>
                    <Button className={this.props.classes.prevButton} color="secondary" onClick={this.props.onCancel}>{this.props.language.offer.formModal.cancel}</Button>
                    {this.props.offerFromSale ? <Button onClick={() => this.props.onSellHandler(this.props.offer.id)} className={this.props.classes.sellButton} color="primary">{this.props.language.buttons.sell}</Button> : null}
                </div>
            )
        }
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                fullWidth={this.props.fullWidth || true}
                maxWidth={this.props.maxWidth || 'md'}
                onClose={this.props.onClose}
                classes={{
                    paper: this.props.classes.dialogPaper
                }}
            >
                {this.props.item && <DialogContent className={this.props.classes.dialogContentContainer}>
                    <div className={this.props.classes.modalTitleContainer}>
                        <div className={this.props.classes.titleContainer}>
                            <Title title={this.props.title ? this.props.title : ""} isOffer={this.props.offer ? true : false} onCancel={this.props.onCancel} item={this.props.item} />
                        </div>
                    </div>
                    {this.props.navbarOptions ? <AppBar className={this.props.classes.appBarContainer} position="static">
                        <Toolbar className={this.props.classes.toolbarContainer} variant="dense">
                            <div className={this.props.classes.optionsHolder}>
                                {this.props.navbarOptions.map((option, index) => {
                                    if (option === CONSTANTS.NAVBAR.MONITOR && this.props.item.provider.name !== 'IPMG') return null
                                    return (
                                        <div key={`${option}-${index}`} onClick={() => this.setClickedOption(option)} className={`${this.props.classes.optionContainer} ${this.checkOption(option) ? this.props.classes.clickedOption : ""}`}>
                                            <span key={index}>{option}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </Toolbar>
                    </AppBar> : null}
                    <div className={this.props.classes.contentContainer}>
                        {this.checkOption(CONSTANTS.NAVBAR.STOCK) && <StockTabDetails reloadCurrentStock={this.props.reloadCurrentStock} fullStock={this.props.item} stock={this.props.item} onClick={this.props.editStock} />}
                        {this.checkOption(CONSTANTS.NAVBAR.COSTS) && <LeasingCostTabDetails fullStock={this.props.item} />}
                        {this.checkOption(CONSTANTS.NAVBAR.FINANCING_COST) && <FinancingCostTabDetails fullStock={this.props.item} />}
                        {this.checkOption(CONSTANTS.NAVBAR.OFFERS) && <OfferListTimeline brand={this.props.item.carBrand.name} model={this.props.item.carModel.name} offers={this.props.item.offers} />}
                        {this.checkOption(CONSTANTS.NAVBAR.STOCK_OFFER) && <OfferStockTabDetails setOfferOption={() => this.setClickedOption(CONSTANTS.NAVBAR.OFFERS_OFFER)} getStocks={this.props.getStocks} fullStock={this.props.item} stock={this.props.item} onClick={this.props.editStock} />}
                        {this.checkOption(CONSTANTS.NAVBAR.OFFERS_OFFER) && <OfferListTimeline backOption={true} setDetailsOption={() => this.setClickedOption(CONSTANTS.NAVBAR.STOCK_OFFER)} brand={this.props.item.carBrand.name} model={this.props.item.carModel.name} offers={this.props.item.offers} />}
                        {this.props.fullStock && <StockTabDetails isForOffer={true} fullStock={this.props.fullStock} />}
                        {this.props.offer && <OfferTabDetails onCancel={this.props.onCancel} offer={this.props.offer} />}
                        {this.props.client && <ClientOfferList client={this.props.client} />}
                    </div>
                    <div className={this.props.classes.actionsContainer}>
                        {this.renderFooter()}
                    </div>
                </DialogContent>}
            </Dialog>
        )
    }
}


const mapStateToProps = state => ({
    language: state.languageReducer.i18n,
})

const mapDispatchToProps = dispatch => ({
})

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(ItemDetails))