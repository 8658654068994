import axios from '../../utils/axios'
import { errorHandler } from '../../utils/apiFunctions'
import queryString from 'query-string'

const baseRoute = '/monitoring'

export const get = queryParams => dispatch =>
    axios.get(baseRoute + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)
        
export const getById = monitorId => dispatch =>
    axios.get(`${baseRoute}/${monitorId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const create = monitor => dispatch =>
    axios.post(baseRoute, { monitor })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const edit = (monitorId, monitor) => dispatch =>
    axios.put(`${baseRoute}/${monitorId}`, { monitor })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const del = monitorId => dispatch =>
    axios.delete(`${baseRoute}/${monitorId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const uploadFile = (monitorId, formData) => dispatch =>
    axios.post(`${baseRoute}/${monitorId}/files`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const deleteFile = (monitorId, fileId) => dispatch =>
    axios.delete(`${baseRoute}/${monitorId}/files/${fileId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)