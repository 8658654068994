import React, { Component } from 'react'
import { JssProvider } from 'react-jss'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import {
    Settings as SettingsIcon,
    DirectionsCar as DirectionsCarsIcon,
    FeaturedPlayList as OfferIcon,
    People as UsersIcon,
    Loyalty as ClientsIcon,
    RecentActors as ProviderIcon,
    DriveEta as ModelIcon,
    MonetizationOn as SaleIcon,
    History as HistoryIcon,
    Search as MonitoringIcon,
    DriveEta as TestDriveIcon,
    LocalCarWash as RentIcon,
    Work as InWorkIcon,
    EventNote as DocumentIcon,
    Assessment as ReportsIcon
} from '@material-ui/icons'

import Login from './login/Login'
import Stock from './stock/Stock'
import Offer from './offer/Offer'
import Sale from './sale/Sale'
import Rent from './rent/Rent'
import TestDrive from './testDrive/TestDrive'
import InWork from './inWork/InWork'
import Admin from './admin/Admin'
import Monitoring from './monitoring/Monitoring'
import * as SIDEBAR from '../redux/actions/sidebar'
import * as LOGIN from '../redux/actions/login'
import * as ROLES from '../redux/actions/roles'
import * as CONSTANTS from '../utils/constants'
import RootSidebar from './sidebar/RootSidebar'

import { roleInArray } from '../utils/helpers'

class Root extends Component {
    state = {
        isLogged: false,
        renderPage: false,
        sidebarItems: [{
            role: [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.IPMG_SELLER, CONSTANTS.ROLES.EXTERNAL_SELLER],
            label: 'admin',
            nested: true,
            expandedText: 'admin',
            icon: <SettingsIcon />,
            nestedComponents: [
                {
                    role: [CONSTANTS.ROLES.ADMIN],
                    to: '/admin/car',
                    label: 'car',
                    icon: <ModelIcon />
                },
                {
                    role: [CONSTANTS.ROLES.ADMIN],
                    to: '/admin/provider',
                    label: 'provider',
                    icon: <ProviderIcon />
                },
                {
                    role: [CONSTANTS.ROLES.ADMIN],
                    to: '/admin/user',
                    label: 'user',
                    icon: <UsersIcon />
                },
                {
                    role: [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.IPMG_SELLER, CONSTANTS.ROLES.EXTERNAL_SELLER],
                    to: '/admin/client',
                    label: 'client',
                    icon: <ClientsIcon />
                },
                {
                    role: [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.IPMG_SELLER, CONSTANTS.ROLES.EXTERNAL_SELLER],
                    to: '/admin/leasing',
                    label: 'leasing',
                    icon: <DocumentIcon />
                },
                {
                    role: [CONSTANTS.ROLES.ADMIN],
                    to: '/admin/reports',
                    label: 'reports',
                    icon: <ReportsIcon />
                },
                {
                    role: [CONSTANTS.ROLES.ADMIN],
                    to: '/admin/settings',
                    label: 'settings',
                    icon: <SettingsIcon />
                },
                // {
                //     to: '/admin/trace',
                //     label: 'trace',
                //     icon: <HistoryIcon />
                // },
            ]
        }, {
            role: [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING, CONSTANTS.ROLES.EXTERNAL_SELLER],
            label: 'stock',
            to: '/stock',
            icon: <DirectionsCarsIcon />,
            withBorder: true
        },
        // {
        //     role: [CONSTANTS.ROLES.ADMIN],
        //     label: 'dashboard',
        //     to: '/'
        // },
        {
            role: [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.IPMG_SELLER, CONSTANTS.ROLES.EXTERNAL_SELLER, CONSTANTS.ROLES.FINANCING],
            label: 'offer',
            to: '/offer',
            icon: <OfferIcon />,
            withBorder: true
        },
        {
            role: [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING],
            label: 'rent',
            to: '/rent',
            icon: <RentIcon />
        },
        {
            role: [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING],
            label: 'testDrive',
            to: '/testDrive',
            icon: <TestDriveIcon />,
            withBorder: true
        },
        {
            role: [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING],
            label: 'inWork',
            to: '/inWork',
            icon: <InWorkIcon />
        },
        {
            role: [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING],
            label: 'monitoring',
            to: '/monitoring',
            icon: <MonitoringIcon />,
            withBorder: true
        },
        // {
        //     role: CONSTANTS.ROLES.MONITORING,
        //     label: 'monitoring',
        //     to: '/monitoring',
        //     icon: <EyeIcon />
        // },
        {
            role: [CONSTANTS.ROLES.ADMIN],
            label: 'sale',
            to: '/sale',
            icon: <SaleIcon />
        }
        ],
        roles: []
    }

    sidebarRoleRender() {
        return this.state.sidebarItems.filter(({ role }) => this.props.checkRights(role, CONSTANTS.OR))
    }


    getRoles = () => this.props.getRoles().then(roles => this.setState({ roles }))

    componentDidMount() {
        this.props.isLogged()
            .then(() => {
                this.getRoles()
                this.setState({ renderPage: true })
            })
            .catch(err => {
                this.setState({ renderPage: false })
            })
    }

    onLogoutHandler = () => {
        this.props.logout()
    }

    render() {
        if (this.state.renderPage) {
            return (<JssProvider>
                <Router>
                    {this.props.isLoggedIn ? (
                        <div className='fullHeight' style={{ display: 'flex' }}>
                            <RootSidebar
                                roles={this.props.roles}
                                displaySidebarHandler={display => this.props.setSidebarDisplay(display)}
                                items={this.sidebarRoleRender()}
                                onLogout={this.onLogoutHandler} />
                            <div className='contentContainer'>
                                <div className='baseContainer'>
                                    <Switch>
                                        {roleInArray(this.props.roles, [CONSTANTS.ROLES.ADMIN]) ? <Route path="/" exact component={Stock} /> : null}
                                        {roleInArray(this.props.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING, CONSTANTS.ROLES.IPMG_SELLER, CONSTANTS.ROLES.EXTERNAL_SELLER]) ? <Route path="/admin" component={Admin} /> : null}
                                        {roleInArray(this.props.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING, CONSTANTS.ROLES.EXTERNAL_SELLER]) ? <Route path="/stock/:STOCKID?/:CURRENT_TAB?" exact component={Stock} /> : null}
                                        {roleInArray(this.props.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING]) ? <Route path="/monitoring" exact component={Monitoring} /> : null}
                                        {roleInArray(this.props.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING, CONSTANTS.ROLES.IPMG_SELLER, CONSTANTS.ROLES.EXTERNAL_SELLER]) ? <Route path="/offer" exact component={Offer} /> : null}
                                        {roleInArray(this.props.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING]) ? <Route path="/rent" exact component={Rent} /> : null}
                                        {roleInArray(this.props.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING, CONSTANTS.ROLES.EXTERNAL_SELLER]) ? <Route path="/inWork" exact component={InWork} /> : null}
                                        {roleInArray(this.props.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING]) ? <Route path="/testDrive" exact component={TestDrive} /> : null}
                                        {roleInArray(this.props.roles, [CONSTANTS.ROLES.ADMIN]) ? <Route path="/sale" exact component={Sale} /> : null}
                                        {<Redirect to='/' />}
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    ) : <Login onLogin={() => this.getRoles()} />}
                </Router>
            </JssProvider>)
        } else return null
    }
}

const mapStateToProps = reducers => ({
    isLoggedIn: reducers.loginReducer.isLogged,
    roles: reducers.loginReducer.roles,
    allRoles: reducers.loginReducer.allRoles,
    rootText: reducers.languageReducer.i18n.root
})

const mapDispatchToProps = dispatch => ({
    isLogged: () => dispatch(LOGIN.isLogged()),
    getRoles: () => dispatch(LOGIN.getAllRoles()),
    checkRights: (role, type) => dispatch(ROLES.checkRights(role, type)),
    setSidebarDisplay: display => dispatch(SIDEBAR.setDisplaySidebar(display)),
    logout: () => dispatch(LOGIN.logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Root)