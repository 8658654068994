import queryString from 'query-string'
import axios from '../../utils/axios'
import { errorHandler } from '../../utils/apiFunctions'

const baseRoute = '/stocks'
const totalFinancingRoute = '/stocks/totalFinancing'
const optionalsRoute = '/optionals'
const rentRoute = '/rents'
const testDriveRoute = '/testDrives'

export const get = queryParams => dispatch =>
    axios.get(baseRoute + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getInWork = queryParams => dispatch =>
    axios.get(`${baseRoute}` + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getById = (stockId, blockEntity = false) => dispatch =>
    axios.get(`${baseRoute}/${stockId}?${blockEntity ? "blockEntity=true" : ""}`)
        .then(response => {
            let { data } = response

            data.images.sort((a, b) => a.order - b.order)

            return Promise.resolve(data)
        })
        .catch(errorHandler)

export const unblockStock = stockId => dispatch =>
    axios.delete(`${baseRoute}/${stockId}/unblock`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getByIdPopulate = stockId => dispatch =>
    axios.get(`${baseRoute}/${stockId}/populate`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const create = stock => dispatch =>
    axios.post(baseRoute, { stock })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const edit = (stockId, stock) => dispatch =>
    axios.put(`${baseRoute}/${stockId}`, { stock })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const del = stockId => dispatch =>
    axios.delete(`${baseRoute}/${stockId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const uploadFileToStock = (stockId, files) => dispatch => {
    return axios.put(`${baseRoute}/${stockId}/image`, files)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)
}

export const deleteImageFromStock = (stockId, imageId) => dispatch => {
    return axios.delete(`${baseRoute}/${stockId}/image/${imageId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)
}

export const uploadSa3 = (stockId, sa3) => dispatch => {
    return axios.post(`${baseRoute}/${stockId}/pdf`, sa3)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)
}

export const deleteSa3 = stockId => dispatch => {
    return axios.delete(`${baseRoute}/${stockId}/pdf`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)
}


// Rent section
export const getRented = queryParams => dispatch =>
    axios.get(`${rentRoute}` + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getRentedByStockId = (stockId, queryParams) => dispatch =>
    axios.get(`${rentRoute}/stocks/${stockId}` + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const createRent = (stockId, rent) => dispatch =>
    axios.post(rentRoute, { rent, stockId })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const createRentExtend = (rentId, from, to, price) => dispatch => {
    return axios.post(`${rentRoute}/${rentId}/extendContract`, { from, to, price })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)
}

export const closeRent = (rentId) => dispatch =>
    axios.delete(`${rentRoute}/${rentId}/endContract`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getRentExtend = (rentId, from, to) => dispatch =>
    axios.get(`${rentRoute}/${rentId}/extendContract?from=${from}&to=${to}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const sendRentContract = (rentId) => dispatch =>
    axios.post(`${rentRoute}/${rentId}/contract/sendEmail`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const sendPVPP = (rentId) => dispatch =>
    axios.post(`${rentRoute}/${rentId}/pvpp/sendEmail`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const sendExtendRentContract = (rentId) => dispatch =>
    axios.post(`${rentRoute}/${rentId}/extendContract/sendEmail`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const sendCancelRentContract = (rentId) => dispatch =>
    axios.post(`${rentRoute}/${rentId}/endContract/sendEmail`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

// Test drive section
export const getTestDrive = queryParams => dispatch =>
    axios.get(`${testDriveRoute}` + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getTestDriveByStockId = (stockId, queryParams) => dispatch =>
    axios.get(`${testDriveRoute}/stocks/${stockId}` + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)


export const createTestDrive = (stockId, testDrive) => dispatch =>
    axios.post(testDriveRoute, { testDrive, stockId })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const createTestDriveExtend = (testDriveId, from, to) => dispatch =>
    axios.post(`${testDriveRoute}/${testDriveId}/extendContract`, { from, to })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const closeTestDrive = (testDriveId) => dispatch =>
    axios.delete(`${testDriveRoute}/${testDriveId}/endContract`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getTestDriveExtend = (testDriveId, from, to) => dispatch =>
    axios.get(`${testDriveRoute}/${testDriveId}/extendContract?from=${from}&to=${to}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const sendTestDriveContract = (testDriveId) => dispatch =>
    axios.post(`${testDriveRoute}/${testDriveId}/contract/sendEmail`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const sendExtendTestDriveContract = (testDriveId) => dispatch =>
    axios.post(`${testDriveRoute}/${testDriveId}/extendContract/sendEmail`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const sendCancelTestDriveContract = (testDriveId) => dispatch =>
    axios.post(`${testDriveRoute}/${testDriveId}/endContract/sendEmail`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

// optionals

export const getOptionals = (carBrandId, code) => dispatch =>
    axios.get(`${optionalsRoute}?carBrandId=${carBrandId}&code=${code}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const createOptional = (optional) => dispatch =>
    axios.post(`${optionalsRoute}`, { optional })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getTotalFinancing = () => dispatch =>
    axios.get(totalFinancingRoute)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)