import config from '../config/local.json'

export const EDIT = 'edit'
export const CREATE = 'create'

export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss'
export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY'
export const INPUT_TYPE_DATE_FORMAT = 'YYYY-MM-DD'

export const AUTOCOMPLETE_MAX_RESULTS = 10

export const DEFAULT_DECIMAL_SIZE = 2
export const EXCHANGE_DECIMAL_SIZE = 4

export const EURO_EXCHANGE_RATE = 4.7

export const NEXT = 'NEXT'
export const PREV = 'PREV'
export const BMW = 'BMW'
export const YES = 'DA'
export const NO = 'NU'
export const HAS_FINANCING = 'hasFinancing'
export const TAGS = 'tags'
export const COMMAND = 'COMANDA'
export const PDI_VALUE = 560
export const THREE_MONTHS = 3
export const INVALID_DATE = 'INVALID DATE'
export const SIX_MONTHS = 6
export const BACKEND_PATH = config.BACKEND_PATH
export const BACKEND_URL = config.BACKEND_URL
export const PERCENT_SYMBOL = '%'
export const EURO_SYMBOL = '€'
export const RON_SYMBOL = 'RON'
export const XCARS = 'XCARS'
export const VAT = 19
export const END_DATE_EXPIRATION_DAYS = 7

export const RENT_OPTION = 'RENT_OPTION'
export const TEST_DRIVE_OPTION = 'TEST_DRIVE_OPTION'
export const IN_WORK_OPTION = 'IN_WORK_OPTION'

export const CANCEL_FOOTER = "CANCEL"
export const STOCK_FOOTER = "STOCK"
export const STOCK_OFFER_FOOTER = "STOCK_OFFER"

export const VIN_SUBSTR_LENGTH = 7
export const VIN_NUMBER_SIZE = 17
export const CUI_MAX_SIZE = 9
export const CUI_MIN_SIZE = 1

export const STOCK_TYPE_SH = 'sh'
export const STOCK_TYPE_EXTERNAL = 'external'

export const CARD_STOCK_FIELDS = [
    { field: 'carBrand' },
    { field: 'carModel' },
    { field: 'orderNumber' },
    { field: 'vinNumber' },
    { field: 'registrationNumber' },
    { field: 'listPrice', symbol: '€' }]
export const CARD_VALUES_FIELDS = [
    { field: 'discount', symbol: '%' },
    { field: 'discountEUR', symbol: '€' },
    { field: 'sellingPriceEUR', symbol: '€' },
    { field: 'carProfitEUR', symbol: '€' }
]

export const SQUARE_TITLES = {
    FINANCED_CARS: 'MASINI FINANTATE',
    OFFER_CARS: 'MASINI OFERTATE',
    NO_OFFER_CARS: 'MASINI NEOFERTATE',
    TOTAL_CARS: 'TOTAL MASINI'
}

export const STOCK_NEED_FINANCING = [
    { label: YES, value: false },
    { label: NO, value: false }
]

export const OFFER_NEED_LEASING = [
    { label: YES, value: false },
    { label: NO, value: false }
]

export const RESERVATION_OPTIONS = [
    { name: 'withAdvance', value: false },
    { name: 'withoutAdvance', value: false }
]

export const OFFER_OPTIONS = {
    SUMAR_OFFER: 'SUMAR_OFFER',
    SUMAR_STOCK_OFFER: 'SUMAR_STOCK_OFFER'
}

export const MONITORING_OPTIONS = {
    MONITORING_SOLD: 'MONITORING_SOLD',
    MONITORING_RENTED: 'MONITORING_RENTED'
}

export const MONITORING = 'MONITORING'

export const STOCK_TAGS = [
    { name: 'contracted', value: false },
    { name: 'paidInAdvance', value: false },
    { name: 'paid', value: false },
    { name: 'buyBack', value: false },
    { name: 'tradeIn', value: false },
    { name: 'leasing', value: false },
    { name: 'ecoticket', value: false }
]

export const IN_ADVANCE_PERCENT = [
    { label: '', value: false },
    { label: '12', value: false },
    { label: '24', value: false },
    { label: '36', value: false },
    { label: '48', value: false },
    { label: '60', value: false }
]

export const LEASING_PERCENT = [
    { label: '', value: false },
    { label: '0', value: false },
    { label: '0.5', value: false },
    { label: '1', value: false },
    { label: '1.5', value: false },
    { label: '2', value: false },
    { label: '2.5', value: false },
    { label: '3', value: false }
]

export const STOCK_TYPE = [
    { name: 'fleet', value: false },
    { name: 'demo', value: false },
    { name: 'retail', value: false },
    { name: 'bailment', value: false },
    { name: 'other', value: false },
    { name: 'sh', value: false },
    { name: 'external', value: false }
]

export const STOCK_MONTHS = [
    { name: '1', value: false },
    { name: '2', value: false },
    { name: '3', value: false },
    { name: '4', value: false },
    { name: '5', value: false },
    { name: '6', value: false },
    { name: '7', value: false },
    { name: '8', value: false },
    { name: '9', value: false },
    { name: '10', value: false },
    { name: '11', value: false },
    { name: '12', value: false }
]


export const STOCK_STATUS_LABELS = {
    // IN_MANUFACTURING: 'inManufacturing',
    PROVIDER_STOCK: 'providerStock',
    IPMG_STOCK: 'ipmgStock',
    // RESERVED: 'reserved',
    // SOLD: 'sold',
    // RENTED: 'rented',
    ORDER_MANUFACTURING: 'orderManufacturing'
}

export const SUBSEQ_ACC_TYPE = [
    { name: 'inPrice', value: true },
    { name: 'overPrice', value: false },
    { name: 'withoutCost', value: false }
]

export const STOCK_STATUS = [
    // { name: 'inManufacturing', value: false },
    { name: 'providerStock', value: false },
    // { name: 'reserved', value: false },
    // { name: 'sold', value: false },
    // { name: 'rented', value: false },
    { name: 'ipmgStock', value: false },
    { name: 'orderManufacturing', value: false }
]

export const STOCK_FILTERS = [
    { name: 'withFinancing', value: false },
    { name: 'onlyIPMG', value: false }
]

export const TABLE_FIELDS_VALUE_AND_DATE = ["value", "date"]

export const TABLE_FIELDS_VALUE_AND_COMMENT = ["value", "comment"]

export const HEADERS = {
    ACTIONS: 'ACTIUNI',
    LOGO: 'LOGO'
}

export const OFFER_DETAILS_FIELDS = {
    id: 'id',
    CUI: 'cui',
    phoneNumber: 'contactPhoneNumber',
    email: 'contactEmail',
    createdAt: 'date',
    name: 'name',
    discountEUR: 'discountEUR',
    sellingPriceEUR: 'sellingPriceEUR',
    discount: 'discount',
    carProfitEUR: 'carProfitEUR',
    totalProfitEUR: 'totalProfitEUR',
}

export const STOCK_DETAILS_FIELDS = {
    MILEAGE: 'mileage',
    LIST_PRICE: 'listPrice',
    DISCOUNTPERCENT: 'discountPercent',
    DISCOUNTEUR: 'discountEUR',
    ORDERNUMBER: 'orderNumber',
    VINNUMBER: 'vinNumber',
    REGISTRATIONDATE: 'registrationProductionDate',
    PURCHASEPRICE: 'purchasePrice',
    SHPURCHASEPRICE: 'ShPurchasePrice',
    DELIVERY_DATE: 'IPMGDeliveryDate',
    ACCESSORIES_PRICE: 'accessoriesPrice',
    PROVIDER: 'provider',
    TRANSMISSION: 'transmission',
    FUEL: 'fuel',
    GEARBOX: 'gearbox',
    CYLINDER_CAPACITY: 'cylinderCapacity',
    ACCELERATION: 'acceleration',
    CP: 'cp',
    URBAN_CONSUMPTION: 'urbanConsumption',
    MIXT_CONSUMPTION: 'mixtConsumption',
    EXTRA_URBAN_CONSUMPTION: 'extraUrbanConsumption',
    MAX_MASS: 'maximumMass',
    EMISSIONS: 'emissions',
    INTERIOR_COLOR: 'interiorColor',
    EXTERIOR_COLOR: 'exteriorColor'
}

export const RENT_FIELDS = {
    CONTRACT_NUMBER: 'contractNumber',
    CONTRACT_DATE: 'contractDate',
    END_DATE: 'endDate',
    PRICE: 'price',
    PERIOD_DAYS: 'periodDays',
    PROVIDER: 'provider',
    CLIENT: 'name',
    USER: 'user'
}

export const TEST_DRIVE_FIELDS = {
    CLIENT: 'name',
    CONTRACT_NUMBER: 'contractNumber',
    CONTRACT_DATE: 'contractDate',
    END_DATE: 'endDate',
    KM_LIMIT: 'kmLimit',
    PERIOD_DAYS: 'periodDays',
    PROVIDER: 'provider',
    USER: 'user'
}

export const IN_WORK_FIELDS = {
    CAR_PROFIT_EUR: 'carProfitEUR',
    SELLING_PRICE_EU: 'sellingPriceEUR',
    PROVIDER: 'provider',
    ORDERNUMBER: 'orderNumber',
    VINNUMBER: 'vinNumber',
    MILEAGE: 'mileage',
    LIST_PRICE: 'listPrice',
    DISCOUNTPERCENT: 'discount',
    DISCOUNTEUR: 'discountEUR',
    REGISTRATIONDATE: 'registrationProductionDate',
    CLIENT: 'client',
    NAME: 'name',
    USER: 'fullName'
}

export const SALE_FIELDS = {
    TOTAL_PROFIT: 'totalProfitEUR',
    SELLING_PRICE_EU: 'sellingPriceEUR',
    PROVIDER: 'provider',
    ORDERNUMBER: 'orderNumber',
    VINNUMBER: 'vinNumber',
    MILEAGE: 'mileage',
    LIST_PRICE: 'listPrice',
    DISCOUNTPERCENT: 'discount',
    DISCOUNTEUR: 'discountEUR',
    REGISTRATIONDATE: 'registrationProductionDate',
    CLIENT: 'client',
    NAME: 'name',
    SALE_DATE: 'createdAt',
    DATE_FORMAT: 'MM/YYYY'

}

export const YEAR_ROW = {
    "createdAt": " ",
    "offer": {
        "createdAt": " ",
        "discount": " ",
        "discountEUR": " ",
        "sellingPriceEUR": " ",
        "totalProfitEUR": " ",
        "client": {
            "name": " ",
        },
        "stock": {
            "id": " ",
            "listPrice": " ",
            "vinNumber": " ",
            "carBrand": {
                "name": " ",
            },
            "provider": {
                "name": " ",
            },
            "carModel": {
                "name": " ",
            },
        }
    },
    "show": true,
    "isYear": true,
}

export const FINANCING_COST_DETAILS_FIELDS = {
    REMAINEDVALUE: 'remainedValue',
    FINANCEDVALUE: 'financedValue',
    CONTRACTDATE: 'contractDate',
    VINNUMBER: 'vinNumber',
    ORDERNUMBER: 'orderNumber',
    CONTRACT_NUMBER: 'contractNumber',
    INITIALCONTRIBUTION: 'initialContribution',
    ANALYSISTAX1: 'analysisTax1',
    ANALYSISTAX2: 'analysisTax2',
    INTERESTRESTITUTION: 'interestRestitution',
    TOTALCONTRIBUTION: 'totalContribution',
    TOTAL: 'total',
    ENTRY_VALUE: 'entryValue'
}

export const LEASING_COST_DETAILS_FIELDS = {
    TOTAL_COSTS: 'totalCosts',
    TOTAL: 'totalPrice',
    PRT: 'prtPrice',
    RCA: 'rcaPrice',
    CASCO: 'cascoPrice',
    COMMISSION: 'commissionPrice',
    PDI: 'pdiPrice',
    REGISTRATION: 'registrationPrice',
    TAXATION: 'taxationPrice',
    TRANSPORT: 'transportPrice',
    VIGNETTE: 'vignettePrice',
    THIRD: 'thirdPartyPrice',
    FUEL: 'fuelPrice',
    ORDERNUMBER: 'orderNumber',
    SUBSEQUENT_ACCESSORIES: 'subsequentAccessoriesPrice'
}

export const LEASING_COST_OPTIONS = {
    PRT: 'prt',
    TRANSPORT: 'transport',
    CASCO: 'casco',
    VIGNIETTE: 'vignette',
    RCA: 'rca',
    REGISTRATION: 'registration',
    TAXATION: 'taxation',
    COMMISSION: 'commission',
    THIRDPARTY: 'thirdParty',
    PDI: 'pdi',
    FUEL: 'fuel',
    SUBSEQUENT_ACCESSORY: 'subsequentAccessory'
}

export const OPTIONS_TEXT = 'OPTIONS'
export const CLIENT_OPTIONS = 'OPTION_CLIENT'
export const TRACE_OPTION = 'TRACE_OPTION'
export const PROVIDER_OPTIONS = 'OPTION_PROVIDER'
export const REPORTS_OPTIONS = 'OPTION_REPORTS'
export const USER_OPTIONS = 'OPTION_USER'
export const SALE_OPTIONS = 'OPTION_SALE'
export const CAR_OPTIONS = {
    BRAND: 'OPTION_BRAND',
    MODEL: 'OPTION_MODEL'
}

export const OPTIONS = {
    CARDS: 'CARDS',
    TABLE: 'TABLE'
}

export const STOCK_OPTIONS = {
    SUMAR: 'SUMAR',
    FINANCIAL: 'FINANCIAR',
    COST: 'COST'
}

export const NAVBAR = {
    STOCK: 'Sumar',
    FINANCING_COST: 'Finantare',
    COSTS: 'Costuri',
    OFFERS: 'Oferte',
    MONITOR: 'Monitorizare',
    STOCK_OFFER: 'Sumar Masina',
    OFFERS_OFFER: 'Oferte Masina',
    SELECTED_OFFER: 'Oferta aleasa'
}

export const ROLES = {
    ADMIN: 'ADMIN',
    FINANCING: 'FINANCING',
    IPMG_SELLER: 'IPMG_SELLER',
    // EXTERNAL_SELLER: 'EXTERNAL_SELLER'
}

export const NEW_ROLES = {
    ADMIN: {
        CREATE_USER: 'CREATE_USER',
        DELETE_USER: 'DELETE_USER',
        READ_USER: 'READ_USER',
        UPDATE_USER: 'UPDATE_USER',
        SUPER_ADMIN: 'ADMIN'
    },
    SALE: {
        CREATE_SALE: 'CREATE_SALE',
        DELETE_SALE: 'DELETE_SALE',
        READ_SALE: 'READ_SALE',
        UPDATE_SALE: 'UPDATE_SALE',
        CREATE_SALE_FILE: 'CREATE_SALE_FILE',
        DELETE_SALE_FILE: 'DELETE_SALE_FILE',
        READ_SALE_FILE: 'READ_SALE_FILE',
        GENERATE_SALE: 'GENERATE_SALE'
    },
    CAR: {
        BRAND: {
            CAR_BRAND_READ: 'CAR_BRAND_READ',
            CAR_BRAND_CREATE: 'CAR_BRAND_CREATE',
            CAR_BRAND_UPDATE: 'CAR_BRAND_UPDATE',
            CAR_BRAND_DELETE: 'CAR_BRAND_DELETE',
            CAR_MODEL_CREATE: 'CAR_MODEL_CREATE'
        },
        MODEL: {
            CAR_MODEL_READ: 'CAR_MODEL_READ',
            CAR_MODEL_UPDATE: 'CAR_MODEL_UPDATE',
            CAR_MODEL_DELETE: 'CAR_MODEL_DELETE'
        }
    },
    CLIENT: {
        READ_CLIENT: 'READ_CLIENT',
        DELETE_CLIENT: 'DELETE_CLIENT',
        UPDATE_CLIENT: 'UPDATE_CLIENT',
        CREATE_CLIENT: 'CREATE_CLIENT'
    },
    FINANCING_COST: {
        READ_FINANCING_COST: 'READ_FINANCING_COST',
        DELETE_FINANCING_COST: 'DELETE_FINANCING_COST',
        UPDATE_FINANCING_COST: 'UPDATE_FINANCING_COST',
        CREATE_FINANCING_COST: 'CREATE_FINANCING_COST'
    },
    COST: {
        READ_COST: 'READ_COST',
        DELETE_COST: 'DELETE_COST',
        UPDATE_COST: 'UPDATE_COST',
        CREATE_COST: 'CREATE_COST'
    },
    MONITORING: {
        READ_MONITORING: 'READ_MONITORING',
        DELETE_MONITORING: 'DELETE_MONITORING',
        UPDATE_MONITORING: 'UPDATE_MONITORING',
        CREATE_MONITORING: 'CREATE_MONITORING',
        CREATE_MONITORING_FILE: 'CREATE_MONITORING_FILE',
        DELETE_MONITORING_FILE: 'DELETE_MONITORING_FILE',
        READ_MONITORING_FILE: 'READ_MONITORING_FILE',
        GENERATE_MONITORING: 'GENERATE_MONITORING'
    },
    OFFER: {
        READ_OFFER: 'READ_OFFER',
        DELETE_OFFER: 'DELETE_OFFER',
        UPDATE_OFFER: 'UPDATE_OFFER',
        CREATE_OFFER: 'CREATE_OFFER',
        CREATE_OFFER_FILE: 'CREATE_OFFER_FILE',
        DELETE_OFFER_FILE: 'DELETE_OFFER_FILE',
        READ_OFFER_FILE: 'READ_OFFER_FILE',
        READ_OFFER_CLIENT: 'READ_OFFER_CLIENT'
    },
    PROVIDER: {
        READ_PROVIDER: 'READ_PROVIDER',
        DELETE_PROVIDER: 'DELETE_PROVIDER',
        UPDATE_PROVIDER: 'UPDATE_PROVIDER',
        CREATE_PROVIDER: 'CREATE_PROVIDER'
    },
    STOCK: {
        READ_STOCK: 'READ_STOCK',
        DELETE_STOCK: 'DELETE_STOCK',
        UPDATE_STOCK: 'UPDATE_STOCK',
        CREATE_STOCK: 'CREATE_STOCK',
        POPULATE_STOCK: 'POPULATE_STOCK'
    }
}

export const ALL_ROWS = 10000
export const DEFAULT_ROWS_PER_PAGE = [5, 10, 15, 25, 50]
export const DEFAULT_ROWS_PER_PAGE_VALUE = 50
export const DEFAULT_MINIMODAL_ROWS_PER_PAGE_VALUE = 2

export const CREATE_USER_NEW_ROLES = [
    {
        name: 'ADMIN',
        value: false,
        subroles: ['CREATE_USER', 'READ_USER', 'UPDATE_USER', 'DELETE_USER']
    },
    {
        name: 'SALE',
        value: false,
        subroles: ['CREATE_SALE',
            'DELETE_SALE',
            'READ_SALE',
            'UPDATE_SALE',
            'CREATE_SALE_FILE',
            'DELETE_SALE_FILE',
            'READ_SALE_FILE',
            'GENERATE_SALE']
    },
    {
        name: 'CLIENT',
        value: false,
        subroles: ['READ_CLIENT',
            'DELETE_CLIENT',
            'UPDATE_CLIENT',
            'CREATE_CLIENT']
    },
    {
        name: 'FINANCING_COST',
        value: false,
        subroles: [
            'READ_FINANCING_COST',
            'DELETE_FINANCING_COST',
            'UPDATE_FINANCING_COST',
            'CREATE_FINANCING_COST'
        ]
    },
    {
        name: 'COST',
        value: false,
        subroles: [
            'READ_COST',
            'DELETE_COST',
            'UPDATE_COST',
            'CREATE_COST'
        ]
    },
    {
        name: 'MONITORING',
        value: false,
        subroles: [
            'READ_MONITORING',
            'DELETE_MONITORING',
            'UPDATE_MONITORING',
            'CREATE_MONITORING',
            'CREATE_MONITORING_FILE',
            'DELETE_MONITORING_FILE',
            'READ_MONITORING_FILE',
            'GENERATE_MONITORING'
        ]
    },
    {
        name: 'OFFER',
        value: false,
        subroles: [
            'READ_OFFER',
            'DELETE_OFFER',
            'UPDATE_OFFER',
            'CREATE_OFFER',
            'CREATE_OFFER_FILE',
            'DELETE_OFFER_FILE',
            'READ_OFFER_FILE',
            'READ_OFFER_CLIENT'
        ]
    },
    {
        name: 'PROVIDER',
        value: false,
        subroles: [
            'READ_PROVIDER',
            'DELETE_PROVIDER',
            'UPDATE_PROVIDER',
            'CREATE_PROVIDER'
        ]
    },
    {
        name: 'STOCK',
        value: false,
        subroles: [
            'READ_STOCK',
            'DELETE_STOCK',
            'UPDATE_STOCK',
            'CREATE_STOCK',
            'POPULATE_STOCK'
        ]
    }
]

export const CREATE_USER_ROLES = [
    {
        name: 'ADMIN',
        value: false
    },
    {
        name: 'STOCK',
        value: false
    },
    {
        name: 'FINANCING_COST',
        value: false
    },
    {
        name: 'COST',
        value: false
    },
    {
        name: 'OFFER',
        value: false
    },
    {
        name: 'SALE',
        value: false
    },
    {
        name: 'MONITORING',
        value: false
    }
]

export const OR = 'OR'
export const AND = 'AND'

export const SELL_OPTION = 'SELL'
export const ANALYSIS_OPTION = 'ANALYSIS'

export const INVALID_DATE_INPUT_ERROR = 'Invalid date'

export const STOCK_IMAGE_URL = `${BACKEND_PATH}/images/stock-images/`

export const CAR_MODEL_OPTIONS = {
    GEARBOX_TYPES: {
        AUTOMATIC: 'automatic',
        MANUAL: 'manual',
        SECVENTIAL: 'secvential'
    },
    TRANSMISSION_TYPES: {
        FRONT: 'front',
        BACK: 'back',
        INTEGRAL: 'integral'
    },
    FUEL_TYPES: {
        DIESEL: 'diesel',
        PETROL: 'petrol',
        DIESEL_MILD_HYBRID: 'diesel mild hybrid',
        DIESEL_PLUG_IN_HYBRID: 'diesel plug in hybrid',
        PETROL_MILD_HYBRID: 'benzina mild hybrid',
        PETROL_PLUG_IN_HYBRID: 'benzina plug in hybrid',
        ELECTRIC: 'electric',
    },
    POLLUTION_NORMS: {
        EURO4: 'Euro 4',
        EURO5: 'Euro 5',
        EURO6: 'Euro 6',
        EURO7: 'Euro 7',
        EURO8: 'Euro 8'
    }
}

export const PROVIDER_MODEL_OPTIONS = {
    TYPES: {
        INTERN: 'intern',
        EXTERN: 'extern'
    },
    PERSON_TYPES: {
        LEGAL: 'legal',
        INDIVIDUAL: 'individual'
    }
}
export const CLIENT_MODEL_OPTIONS = {
    TYPES: {
        INTERN: 'intern',
        EXTERN: 'extern'
    },
    PERSON_TYPES: {
        LEGAL: 'legal',
        INDIVIDUAL: 'individual'
    }
}