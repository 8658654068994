import React, { Component } from 'react'
import { withStyles, Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'

import SimpleModal from '../common/SimpleModal'
import MonitoringTab from './MonitoringTab'
import Header from '../common/SearchableHeader'
import InputGenerator from '../common/subComponents/InputGenerator'
import * as MONITORING from '../../redux/actions/monitoring'

const styles = theme => ({
    container: {
        padding: 27,
        overflow: 'auto'
    },
    withoutSidebar: {
        padding: '24px 36px 24px 0px'
    },
    modalFields:{
        paddingLeft: '20px'
    }
})

class Monitoring extends Component {

    initialFields = [{ type: 'checkbox', name: 'commission', utils:this.props.language.labels.commission, value: false}]

    state = {
        activeTab: 0,
        search: '',
        filterData: {},
        openFilterModal: false,
        modalFields: this.initialFields,
        monitoring: [],
        resetFilter: false,
    }

    onSearch = search => this.setState({ search })

    resetFilters = () => {
        if (window.confirm(this.props.language.utils.confirmReset)) {
            this.setState({ filterData: {}, modalFields: this.initialFields, resetFilter:true }, () => this.filterMonitoring())
        }
    }

    populateOptions = () => {
        const modalFieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))
        const commissionIndex = modalFieldsCopy.findIndex(field => field.name === 'commission')
        if (commissionIndex > -1) {
            if (this.state.filterData.commission)
                modalFieldsCopy[commissionIndex].value = this.state.filterData.commission.value
        }
        this.setState({ openFilterModal: true, modalFields: modalFieldsCopy })
    }

    filterMonitoring = () => {
            return this.props.getMonitoring({
                search: this.state.search,
                sold: true,
                filterData: JSON.stringify(this.state.filterData)
            }).then(data => {
                this.setState({stocks: data.stocks, renderPage: true, monitoring: data.monitoring, count: data.count, resetFilter:false })
                return Promise.resolve()
                })     
    }

    deleteMonitoring = () => {
        this.setState({ monitoring: false })
    }

    setFilter = () =>{
        let jsonMap = {}
        this.state.modalFields.forEach(field => {
            if (field.value) jsonMap[field.name] = field
        })
        this.setState({ filterData: jsonMap, openFilterModal: false}, () => this.filterMonitoring())
    }

    onChangeHandler = async event => {
        let modalFieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))
        const currentIndex = modalFieldsCopy.findIndex(field => field.name === event.target.name)
        if (currentIndex > -1) {
            if(event.target.name === 'commission')
            modalFieldsCopy[currentIndex].value = !modalFieldsCopy[currentIndex].value
        }
        this.setState({ modalFields: modalFieldsCopy })
    }

    renderModalFields = () => {
        let jsonMap = {}
        this.state.modalFields.forEach(field => {
            jsonMap[field.name] = field
        })
        return (
            <InputGenerator name='commission' type='checkbox' label={this.props.language.commission} onChange={this.onChangeHandler} value={jsonMap.commission.value}/>
        )
    }

    render() {
        let { classes } = this.props
        return (
            <>
                <div className={classes.header}>
                    <Header
                        title={this.props.language.monitoringTitle}
                        placeholder={this.props.searchPlaceholder}
                        onSearch={this.onSearch}
                        reportsFilter={this.state.filterData}
                        resetFilters={() => this.resetFilters()}
                        filterText={this.props.language.utils.filterText}
                        openFilterModal={() => this.populateOptions()}
                    />
                </div>
                <div className={`${!this.props.sidebarReducer.displaySidebar ? this.props.classes.withoutSidebar : ""} ${this.props.classes.container}`}>
                    <MonitoringTab
                        searchText={this.state.search}
                        modalFields = {this.state.modalFields}
                        filterData = {this.state.filterData}
                        monitoring = {this.state.monitoring}
                        filterMonitoring = { () => this.filterMonitoring() }
                        resetFilter = {this.state.resetFilter}
                    /> 
                    <SimpleModal
                        title={this.props.language.utils.modalTitle}
                        acceptButtonText={this.props.language.utils.accept}
                        cancelButtonText={this.props.language.utils.cancel}
                        onAccept={() => this.setFilter()}
                        onClose={() => this.setState({ openFilterModal: false, modalFields: this.initialFields })}
                        onCancel={() => this.setState({ openFilterModal: false, modalFields: this.initialFields})}
                        maxWidth={"sm"}
                        open={this.state.openFilterModal}>
                        <div className={this.props.classes.modalFields} >{this.renderModalFields()}</div>
                    </SimpleModal>
                </div>
            </>
        )
    }
}
const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.monitoring,
    searchPlaceholder: reducers.languageReducer.i18n.car.searchPlaceholder,
    soldTitle: reducers.languageReducer.i18n.monitoring.sold,
    sidebarReducer: reducers.sidebarReducer,
    rentedTitle: reducers.languageReducer.i18n.monitoring.rented
})

const mapDispatchToProps = dispatch => ({
    getMonitoring: queryParams => dispatch(MONITORING.get(queryParams)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Monitoring))