import React, { Component } from 'react'
import AddList from './subComponents/AddList'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, withStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { connect } from 'react-redux'
import moment from 'moment'

import InputGenerator from '../common/subComponents/InputGenerator'
import * as NOTIFICATION from '../../utils/notification'
import * as CONSTANTS from '../../utils/constants'
import * as CLIENT from '../../redux/actions/clients'
import { isValidField } from '../../utils/validationUtils'

const style = theme => ({
    dialogTitle: {
        borderBottom: '1px solid #eaedf3',
        marginBottom: 15,
        padding: '24px 7px 20px'
    },
    dialogActions: {
        borderTop: '1px solid #eaedf3',
        marginTop: 10,
        paddingTop: 25
    },
    acceptButton: {
        marginRight: 2
    },
    cancelButton: {
        marginRight: 'auto',
    },
    topRightCancelButton: {
        float: 'right',
        margin: '5px 5px 0px 0px',
        cursor: 'pointer',
        fontSize: 18
    },
    fullWidth: {
        width: '100%'
    }
})


class FormModal extends Component {
    state = {
        fields: [...this.props.fields.map(field => ({ ...field, error: false }))],
        canSubmit: false,
        type: this.props.type
    }

    todayValue = moment().format(CONSTANTS.INPUT_TYPE_DATE_FORMAT)

    onChange(event) {
        let fieldIndex = this.state.fields.findIndex(field => field.name === event.target.name)

        if (fieldIndex > -1) {
            let newFields = [...this.state.fields]
            if (event.target.type === 'file') {
                newFields[fieldIndex].files = event.target.files
                newFields[fieldIndex].value = event.target.value
            } else
                newFields[fieldIndex].value = event.target.value
            this.setState({ fields: [...newFields] })
        }
    }

    onFetchFromAnaf = () => {
        let cuiIndex = this.state.fields.findIndex(field => field.name === 'cui')
        let nameIndex = this.state.fields.findIndex(field => field.name === 'name')
        let addressIndex = this.state.fields.findIndex(field => field.name === 'address')
        let cityIndex = this.state.fields.findIndex(field => field.name === 'city')
        let ibanIndex = this.state.fields.findIndex(field => field.name === 'iban')
        let countryindex = this.state.fields.findIndex(field => field.name === 'country')
        let tradeRegister = this.state.fields.findIndex(field => field.name === 'tradeRegister')

        if (cuiIndex > -1 && nameIndex > -1) {
            let queryParams = {
                cui: this.state.fields[cuiIndex].value,
                date: this.todayValue
            }

            this.props.getFromAnaf(queryParams).then(anafData => {
                let newFields = [...this.state.fields]
                let data = anafData.data
                newFields[nameIndex].value = data.name

                if (addressIndex >= 0) newFields[addressIndex].value = data.address ? data.address.replace(data.address.split(',')[0] + ',', '').replace(data.address.split(',')[1] + ',', '') : ''
                if (cityIndex >= 0) newFields[cityIndex].value = data.address ? data.address.split(',')[1] : ''
                if (ibanIndex >= 0) newFields[ibanIndex].value = data.iban
                if (countryindex >= 0) newFields[countryindex].value = 'Romania'
                if (tradeRegister >= 0) newFields[tradeRegister].value = data.tradeRegister

                this.setState({ fields: [...newFields] })
            })
        }
    }

    handleChangeSelectItem(items) {
        this.setState({ selectedItems: items })
    }

    validate = () => {
        let newFields = [...this.state.fields]
        let isValid = true

        newFields.forEach((field, index) => {
            if (!isValidField(field)) {
                isValid = false
                newFields[index].error = true
            } else {
                newFields[index].error = false
            }
        })

        this.setState({ fields: [...newFields] })
        return isValid
    }

    saveChanges() {
        //generate output json
        let output = {}

        this.state.fields.forEach(element => {
            if (element.type === 'file') {
                output[element.name] = {
                    value: element.value,
                    files: element.files
                }
            }
            else output[element.name] = element.value
        })

        if (!this.validate()) return NOTIFICATION.error(this.props.validationLanguage.uncomplete)

        this.props.onSave({ type: this.props.type, fields: output })
    }

    renderFields() {
        return this.state.fields.map((field, index) => <InputGenerator
            key={index}
            {...field}
            fullWidth={true}
            margin='dense'
            onChange={event => this.onChange(event)}
        />)
    }

    render() {

        return (
            <div>
                <Dialog
                    open={this.props.open}
                    aria-labelledby="form-dialog-title"
                    className={this.props.className}
                    fullWidth={true}
                    maxWidth={this.props.maxWidth}
                >
                    <DialogTitle className={this.props.classes.dialogTitle} id="form-dialog-title">
                        {this.props.dialogTitle}
                        <Close onClick={this.props.onCancel} className={this.props.classes.topRightCancelButton} />
                    </DialogTitle>
                    <DialogContent>
                        {this.props.dialogText ? <DialogContentText>
                            {this.props.dialogText}
                        </DialogContentText> : null}
                        {this.renderFields()}
                        {this.props.fetchAnaf ? <Button className={this.props.classes.fullWidth} color="secondary" onClick={() => this.onFetchFromAnaf()}>{this.props.anafTitle}</Button> : null}
                        {this.props.addParameters && <AddList onChange={list => this.setState({ paramsList: list })} />}
                    </DialogContent>

                    <DialogActions className={this.props.classes.dialogActions}>
                        <Button className={this.props.classes.cancelButton} onClick={() => {
                            this.props.onCancel()
                        }} color="secondary">
                            {this.props.cancelButtonText}
                        </Button>
                        <Button className={this.props.classes.acceptButton} onClick={() => this.saveChanges()} color="primary">
                            {this.props.submitButtonText}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = reducers => ({
    validationLanguage: reducers.languageReducer.i18n.validation,
    anafTitle: reducers.languageReducer.i18n.offer.createModal.getAnaf
})

const mapDispatchToProps = dispatch => ({
    getFromAnaf: queryParams => dispatch(CLIENT.getFromAnaf(queryParams)),
})

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(FormModal))