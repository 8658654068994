import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography, Button, withStyles } from '@material-ui/core'

import * as STOCK from '../../redux/actions/stocks'
import * as CONSTANTS from '../../utils/constants'

import SearchableHeader from '../common/SearchableHeader'
import TestDriveExtendModal from './TestDriveExtendModal'
import SimpleList from '../common/SimpleList'

const styles = theme => ({
    listContainer: {
        padding: 24,
        overflow: 'auto'
    },
    subtitle: {
        fontSize: '36px',
        fontWeight: '500',
        paddingTop: '20px',
        color: theme.palette.primaryColor,
        display: 'block',
        width: '350px'
    },
    table: {
        marginTop: '20px'
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    withoutSidebar: {
        padding: '24px 36px 24px 0px'
    }
})

class TestDrive extends Component {


    state = {
        renderPage: false,
        testDrives: [],
        from: 0,
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        search: '',
        headerFields: this.props.language.simpleTable.headerTestDrive,
        openTestDriveExtendModal: false
    }

    componentDidMount() {
        this.getTestDrives()
    }

    getTestDrives = () => {
        let params = {
            search: this.state.search
        }

        return this.props.get({
            ...params
        }).then(testDriveResponse => {
            this.setState({
                testDrives: testDriveResponse.rows,
                count: testDriveResponse.count,
                renderPage: true
            })
            return Promise.resolve()
        })
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, () => this.getTestDrivesr())
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getTestDrives())
    }

    onSearch = search => this.setState({ search }, this.getTestDrives)

    render() {
        const { classes } = this.props
        if (this.state.renderPage) {
            return (<>
                <div className={classes.titleContainer}>
                    <SearchableHeader
                        placeholder={this.props.placeholderLanguage}
                        title={this.props.language.titleTestDrive}
                        onSearch={this.onSearch}
                    />
                </div>
                <div className={`${!this.props.sidebarReducer.displaySidebar ? this.props.classes.withoutSidebar : ""} ${this.props.classes.listContainer}`}>
                    <TestDriveExtendModal
                        onClose={() => this.setState({ selectedRow: null, openTestDriveExtendModal: false }, this.getTestDrives)}
                        row={this.state.selectedRow}
                        open={this.state.openTestDriveExtendModal} />
                    <SimpleList
                        setTestDriveExtendModal={row => this.setState({ openTestDriveExtendModal: true, selectedRow: row })}
                        headerFields={this.state.headerFields}
                        getTestDrives={this.getTestDrives}
                        items={this.state.testDrives}
                        countTitle={this.props.language.carsInStock}
                        title={<Typography className={this.props.classes.subtitle} component='span'>{this.props.language.titleTestDrive}</Typography>}
                        actionsTitle={this.props.language.simpleTable.actionsTitle}
                        disableBorders
                        selectedOption={CONSTANTS.TEST_DRIVE_OPTION}
                        onSelect={stock => this.onSelectHandler(stock)}
                        noHeader
                        actions={[]}
                        search={{
                            onSearch: this.onSearch
                        }}
                        rowsPerPage={this.state.rowsPerPage}
                        count={this.state.count}
                        changeRowsPerPage={this.changeRowsPerPageHandler}
                        changePageHandler={this.changePageHandler}
                    />
                </div>
            </>)
        }
        else return null
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.stock,
    login: state.loginReducer,
    sidebarReducer: state.sidebarReducer,
    placeholderLanguage: state.languageReducer.i18n.car.searchPlaceholder
})

const mapDispatchToProps = dispatch => ({
    get: options => dispatch(STOCK.getTestDrive(options))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TestDrive))