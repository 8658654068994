import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, withStyles } from '@material-ui/core'
import { Delete as DeleteIcon, Edit as EditIcon, AddCircleOutline as AddIcon } from '@material-ui/icons'
import moment from 'moment'

import { validations } from '../../../utils/validationUtils'
import SimpleList from '../../common/SimpleList'
import FormModal from '../../common/SimpleFormGenerator'
import ConfirmationDialog from '../../common/ConfirmationDialog'
import * as PROVIDER from '../../../redux/actions/providers'
import * as NOTIFICATION from '../../../utils/notification'
import * as CONSTANTS from '../../../utils/constants'
import Header from '../../common/SearchableHeader'
import * as CLIENT from '../../../redux/actions/clients'

const styles = theme => ({
    addButton: {
        padding: '8px 20px',
        fontSize: '15px',
        width: '100px',
        marginTop: '15px',
        display: 'flex'
    },
    subtitle: {
        fontSize: '35px',
        paddingTop: '20px',
        color: theme.palette.primaryColor,
        display: 'block',
        width: '350px'
    },
    header: {
        width: '100%',
        height: 70
    },
    listContainer: {
        padding: 27,
        overflow: 'auto'
    },
    addIcon: {
        paddingRight: 5
    },
    withoutSidebar: {
        padding: '24px 36px 24px 0px'
    }
})

class Provider extends Component {
    initialFields = [
        {
            value: CONSTANTS.PROVIDER_MODEL_OPTIONS.PERSON_TYPES.LEGAL,
            type: 'dropdownSelector',
            label: this.props.language.form.fields.isLegalEntity,
            name: 'isLegalEntity',
            validation: {
                checks: [validations.notEmpty]
            },
            utils: this.props.language.form.fields.isLegalEntity,
            selected: true,
            options: Object.values(CONSTANTS.PROVIDER_MODEL_OPTIONS.PERSON_TYPES).map(option => ({ label: this.props.language.form.options.person_type[option], name: option }))
        },
        { value: '', type: 'text', label: this.props.language.form.fields.name, name: 'name', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: this.props.language.form.fields.cui, name: 'cui', validation: { checks: [validations.notEmpty] } },
        {
            value: CONSTANTS.PROVIDER_MODEL_OPTIONS.TYPES.INTERN,
            type: 'dropdownSelector',
            label: this.props.language.form.fields.type,
            name: 'type',
            validation: {
                checks: [validations.notEmpty]
            },
            utils: this.props.language.form.fields.type,
            selected: true,
            options: Object.values(CONSTANTS.PROVIDER_MODEL_OPTIONS.TYPES).map(option => ({ label: this.props.language.form.options.type[option], name: option }))
        },
        { value: '', type: 'text', label: this.props.language.form.fields.IPMGClientNumber, name: 'IPMGClientNumber', validation: { checks: [validations.notEmpty] }, breakline: "true" },
        { value: '', type: 'text', label: this.props.language.form.fields.IBAN, name: 'IBAN', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: this.props.language.form.fields.tradeRegister, name: 'tradeRegister', validation: { checks: [validations.notEmpty] } },
        { value: false, type: 'checkbox', label: this.props.language.form.fields.tvaPayer, name: 'tvaPayer' },
        { value: '', type: 'text', label: this.props.language.form.fields.country, name: 'country', validation: { checks: [validations.notEmpty] }, breakline: "true" },
        { value: '', type: 'text', label: this.props.language.form.fields.city, name: 'city', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: this.props.language.form.fields.address, name: 'address', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: this.props.language.form.fields.websiteUrl, name: 'websiteUrl', validation: { checks: [validations.notEmpty] }, breakline: "true" },
        { value: '', type: 'text', label: this.props.language.form.fields.contactName, name: 'firstContactName', validation: { checks: [validations.notEmpty] }, breakline: "true" },
        { value: '', type: 'text', label: this.props.language.form.fields.contactPhone, name: 'firstContactPhoneNumber', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: this.props.language.form.fields.contactEmail, name: 'firstContactEmail', validation: { checks: [validations.notEmpty] } },
    ]

    extraFields = [
        { value: '', type: 'text', label: this.props.language.form.fields.contactName, name: 'secondContactName', validation: { checks: [validations.notEmpty] }, breakline: "true" },
        { value: '', type: 'text', label: this.props.language.form.fields.contactPhone, name: 'secondContactPhoneNumber', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: this.props.language.form.fields.contactEmail, name: 'secondContactEmail', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: this.props.language.form.fields.contactName, name: 'thirdContactName', validation: { checks: [validations.notEmpty] }, breakline: "true" },
        { value: '', type: 'text', label: this.props.language.form.fields.contactPhone, name: 'thirdContactPhoneNumber', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: this.props.language.form.fields.contactEmail, name: 'thirdContactEmail', validation: { checks: [validations.notEmpty] } }
    ]



    providerToEdit = {}
    providerToDelete = {}

    _deepClone = arr => [...arr].map(el => ({ ...el }))

    state = {
        providers: [],
        count: 0,
        from: 0,
        search: '',
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        openModal: false,
        openConfirmationModal: false,
        modalFields: this._deepClone(this.initialFields),
        modalType: CONSTANTS.CREATE,
        showAnafButton: true
    }

    componentDidMount() {
        this.getProviders()
    }

    getProviders = () =>
        this.props.get({
            search: this.state.search
        }).then(data => {
            this.setState({
                providers: data.providers.map((provider, index) => ({
                    index: index + 1,
                    _id: provider.id,
                    name: provider.name,
                    updatedAt: moment(provider.updatedAt).format(CONSTANTS.DEFAULT_DATE_TIME_FORMAT)
                })),
                count: data.count,
                openModal: false,
                openConfirmationModal: false
            })
            return Promise.resolve()
        })

    onCreateHandler = fields => {
        let model = {}

        fields.forEach(field => {
            if (field.name === 'isLegalEntity') model[field.name] = field.value === CONSTANTS.PROVIDER_MODEL_OPTIONS.PERSON_TYPES.LEGAL
            else model[field.name] = field.value
        })

        this.props.create(model).then(() => {
            NOTIFICATION.success(this.props.language.create.success)
            this._clearForm()
            this.getProviders()
        }).catch(() => NOTIFICATION.error(this.props.language.create.failure))
    }

    onEditHandler = fields => {
        let model = {}

        fields.forEach(field => {
            if (field.name === 'isLegalEntity') model[field.name] = field.value === CONSTANTS.PROVIDER_MODEL_OPTIONS.PERSON_TYPES.LEGAL
            else model[field.name] = field.value
        })

        this.props.edit(this.providerToEdit._id, model).then(() => {
            this.getProviders()
            this._clearForm()
            NOTIFICATION.success(this.props.language.edit.success)
        }).catch(() => NOTIFICATION.error(this.props.language.edit.failure))
    }

    onDeleteHandler = () => {
        this.props.delete(this.providerToDelete._id).then(() => {
            NOTIFICATION.success(this.props.language.delete.success)
            this.getProviders()
        }).catch(() => NOTIFICATION.error(this.props.language.delete.failure))
    }

    toggleEditModal = provider => {
        this.providerToEdit = provider
        this.props.getById(provider._id).then(providerResponse => {
            let fields = this._deepClone(this.initialFields)
                .map(field => ({ ...field, value: providerResponse[field.name] }))
                .filter(field => field.name !== 'type' && field.name !== 'isLegalEntity')

            if (providerResponse.type === CONSTANTS.PROVIDER_MODEL_OPTIONS.TYPES.EXTERN)
                fields = fields.concat(this._deepClone(this.extraFields))

            this.setState({
                modalFields: fields,
                openModal: true,
                modalType: CONSTANTS.EDIT,
                showAnafButton: false
            })
        }).catch(() => NOTIFICATION.error(this.props.language.get.notFound))
    }

    _clearForm = () => {
        this.setState({ modalFields: this._deepClone(this.initialFields), showAnafButton: true })
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, () => this.getProviders())
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getProviders())
    }

    onSearch = search => this.setState({ search }, this.getProviders)

    onChangeHandler = event => {
        let fieldsCopy = this._deepClone(this.state.modalFields)

        let fieldIndex = fieldsCopy.findIndex(field => field.name === event.target.name)

        if (event.target.name === 'type' && event.target.value === CONSTANTS.PROVIDER_MODEL_OPTIONS.TYPES.EXTERN && fieldsCopy.every(field => field.name !== 'secondContactName')) {
            fieldsCopy = fieldsCopy.concat(this._deepClone(this.extraFields))
        } else if (event.target.name === 'type' && event.target.value === CONSTANTS.PROVIDER_MODEL_OPTIONS.TYPES.INTERN) {
            fieldsCopy = this._deepClone(fieldsCopy.filter(field => field.name !== 'secondContactName' &&
                field.name !== 'thirdContactName' &&
                field.name !== 'secondContactEmail' &&
                field.name !== 'thirdContactEmail' &&
                field.name !== 'thirdContactPhoneNumber' &&
                field.name !== 'secondContactPhoneNumber'))
        }

        if (fieldIndex < 0) return

        fieldsCopy[fieldIndex].value = event.target.value

        let typeIndex = fieldsCopy.findIndex(field => field.name === 'type')
        let isLegalEntityIndex = fieldsCopy.findIndex(field => field.name === 'isLegalEntity')

        let showAnaf = {}

        if (!(typeIndex < 0 || isLegalEntityIndex < 0)) {
            showAnaf.showAnafButton = fieldsCopy[typeIndex].value === CONSTANTS.PROVIDER_MODEL_OPTIONS.TYPES.INTERN &&
                CONSTANTS.PROVIDER_MODEL_OPTIONS.PERSON_TYPES.LEGAL === fieldsCopy[isLegalEntityIndex].value
        }

        this.setState({
            modalFields: fieldsCopy,
            ...showAnaf
        })
    }

    onAnafClickHandler = () => {
        let cuiIndex = this.state.modalFields.findIndex(field => field.name === 'cui')

        if (cuiIndex < 0) return

        let cui = this.state.modalFields[cuiIndex].value

        return this.props.getAnafData(cui)
            .then(({ data }) => {
                let newFields = this._deepClone(this.state.modalFields)

                let nameIndex = this.state.modalFields.findIndex(field => field.name === 'name')
                let city = this.state.modalFields.findIndex(field => field.name === 'city')
                let country = this.state.modalFields.findIndex(field => field.name === 'country')
                let address = this.state.modalFields.findIndex(field => field.name === 'address')
                let cifIndex = this.state.modalFields.findIndex(field => field.name === 'cui')
                let tvaPayer = this.state.modalFields.findIndex(field => field.name === 'tvaPayer')
                let IBAN = this.state.modalFields.findIndex(field => field.name === 'IBAN')
                let tradeRegister = this.state.modalFields.findIndex(field => field.name === 'tradeRegister')

                if (nameIndex < 0 || city < 0 || country < 0 || address < 0 || cifIndex < 0 || tvaPayer < 0 || IBAN < 0) return

                newFields[nameIndex].value = data.name
                newFields[address].value = data.address ? data.address.replace(data.address.split(',')[0] + ',', '').replace(data.address.split(',')[1] + ',', '') : ''
                newFields[cifIndex].value = data.cui
                newFields[country].value = this.props.language.form.defaultCountry
                newFields[city].value = data.address ? data.address.split(',')[1] : ''
                newFields[tvaPayer].value = data.scpTVA
                newFields[IBAN].value = data.iban
                if(tradeRegister > 0) newFields[tradeRegister].value = data.tradeRegister

                this.setState({ modalFields: newFields }, () => NOTIFICATION.success(this.props.language.form.anaf.success))
            })
            .catch(e => NOTIFICATION.error(this.props.language.form.anaf.error))
    }

    render() {
        const { classes } = this.props
        return <>
            <ConfirmationDialog
                open={this.state.openConfirmationModal}
                cancelButtonText={this.props.language.confirmationDialog.cancelButtonText}
                acceptButtonText={this.props.language.confirmationDialog.acceptButtonText}
                text={`${this.props.language.confirmationDialog.text} ${this.providerToDelete.name}?`}
                onCancel={() => this.setState({ openConfirmationModal: false })}
                onClose={() => this.setState({ openConfirmationModal: false })}
                onAccept={this.onDeleteHandler}
            />

            <FormModal
                canSubmit
                fields={[...this.state.modalFields]}
                key={this.state.openModal}
                open={this.state.openModal}
                onCancel={() => this.setState({ openModal: false }, this._clearForm)}
                onSave={() => this.state.modalType === CONSTANTS.CREATE ? this.onCreateHandler(this.state.modalFields) : this.onEditHandler(this.state.modalFields)}
                dialogTitle={this.state.modalType === CONSTANTS.CREATE ? this.props.language.formModal.add : this.props.language.formModal.title}
                cancelButtonText={this.props.language.confirmationDialog.cancelButtonText}
                submitButtonText={this.state.modalType === CONSTANTS.CREATE ? this.props.language.formModal.add : this.props.language.formModal.edit}
                type={this.state.modalType}
                validate={true}
                maxWidth='sm'
                onChange={this.onChangeHandler}
            >
                {this.state.showAnafButton ? <Button color="secondary" onClick={this.onAnafClickHandler}>{this.props.language.form.anaf.name}</Button> : null}
            </FormModal>

            <div className={classes.header}>
                <Header
                    title={this.props.language.simpleTable.title}
                    placeholder={this.props.language.searchPlaceholder}
                    onSearch={this.onSearch}
                />
            </div>
            <div className={`${!this.props.sidebarReducer.displaySidebar ? this.props.classes.withoutSidebar : ""} ${this.props.classes.listContainer}`}>
                <SimpleList
                    items={this.state.providers}
                    headerFields={this.props.language.providerHeaderFields}
                    selectedOption={CONSTANTS.PROVIDER_OPTIONS}
                    actionsTitle={this.props.language.simpleTable.actionsTitle}
                    disableBorders
                    actions={[
                        {
                            tooltip: this.props.language.simpleTable.actions.delete,
                            onClick: (event, row) => {
                                this.providerToDelete = row
                                this.setState({ openConfirmationModal: true })
                            },
                            icon: <DeleteIcon />
                        },
                        {
                            tooltip: this.props.language.simpleTable.actions.edit,
                            onClick: (event, row) => this.toggleEditModal(row),
                            icon: <EditIcon />
                        }
                    ]}
                    onSelect={provider => this.toggleEditModal(provider)}
                    headerButton={{
                        tooltip: this.props.language.simpleTable.headerButton.text,
                        icon: <Button onClick={() => this.setState({ openModal: true })} className={classes.addButton} variant='contained' color='primary'>
                            <AddIcon className={classes.addIcon} />
                            {this.props.language.simpleTable.headerButton.buttonText}
                        </Button>,
                        onClick: () => this.setState({ openModal: true, modalType: CONSTANTS.CREATE })
                    }}
                    search={{
                        onSearch: this.onSearch
                    }}
                    rowsPerPage={this.state.rowsPerPage}
                    count={this.state.count}
                    countTitle={this.props.language.providers}
                    changeRowsPerPage={this.changeRowsPerPageHandler}
                    changePageHandler={this.changePageHandler}
                />
            </div>
        </>
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.provider,
    sidebarReducer: reducers.sidebarReducer
})

const mapDispatchToProps = dispatch => ({
    get: queryParams => dispatch(PROVIDER.get(queryParams)),
    edit: (providerId, provider) => dispatch(PROVIDER.edit(providerId, provider)),
    create: provider => dispatch(PROVIDER.create(provider)),
    delete: providerId => dispatch(PROVIDER.del(providerId)),
    getById: providerId => dispatch(PROVIDER.getById(providerId)),
    getAnafData: cui => dispatch(CLIENT.getFromAnaf({ cui, date: moment().format(CONSTANTS.INPUT_TYPE_DATE_FORMAT) }))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Provider))