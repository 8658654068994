import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { withStyles } from '@material-ui/core'

import SimpleModal from '../common/SimpleModal'

import * as CONSTANTS from '../../utils/constants'
import * as STOCKS from '../../redux/actions/stocks'

import InputGenerator from '../common/subComponents/InputGenerator'

const styles = theme => ({
    modalContainer: {
        paddingTop: 18,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        color: 'black'
    },
    rentModalContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    clientContainer: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid rgba(0,0,0,0.1)'
    },
    detailsContainer: {
        flex: 1,
        padding: 20
    },
    equalFlex: {
        flex: 1
    },
    inputPadding: {
        paddingRight: 10,
        paddingTop: 2
    },
})

class RentExtendModal extends Component {

    initialFields = [
        { value: '', type: 'text', label: this.props.language.itemDetails.offer.rent.annexNr, name: 'annexNr' },
        { value: '', type: 'date', label: this.props.language.itemDetails.offer.rent.from, name: 'from' },
        { value: '', type: 'date', label: this.props.language.itemDetails.offer.rent.to, name: 'to' },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.offer.rent.price, name: 'price' }
    ]

    state = {
        openModal: false,
        renderPage: false,
        modalFields: this.initialFields,
    }

    InputWrapper = ({ input, keyProps, shrink, onChange }) => <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding}`}>
        <InputGenerator
            key={keyProps}
            fullWidth={true}
            InputLabelProps={shrink ? { shrink: true } : {}}
            margin="dense"
            {...input}
            onChange={event => onChange(event)}
        />
    </div>

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            this.openHandler()
        }
        if (prevProps.open && !this.props.open) {
            this.setState({
                openModal: false
            })
        }
    }


    onCloseHandler = () => {
        this.props.onClose()
        this.setState({ modalFields: this.initialFields, openModal: false })
    }

    openHandler = () => {
        let fieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))
        const fromDateIndex = fieldsCopy.findIndex(index => index.name === 'from')
        if (fromDateIndex > -1) {

            let lastDate = this.props.row.endDate
            if (this.props.row.extendRents && this.props.row.extendRents.length) lastDate = this.props.row.extendRents[this.props.row.extendRents.length - 1].to
            fieldsCopy[fromDateIndex].value = moment(lastDate).format(CONSTANTS.INPUT_TYPE_DATE_FORMAT)

            this.setState({
                openModal: true,
                renderPage: true,
                modalFields: fieldsCopy
            })
        }
    }


    renderFields = () => {

        const InputWrapper = this.InputWrapper

        let jsonMap = {}
        this.state.modalFields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <>
                <InputWrapper input={jsonMap['from']} key={'from'} onChange={event => this.onChangeHandler(event)} shrink={true} />
                <InputWrapper input={jsonMap['to']} key={'to'} onChange={event => this.onChangeHandler(event)} shrink={true} />
                <InputWrapper input={jsonMap['price']} key={'price'} onChange={event => this.onChangeHandler(event)} shrink={true} />
            </>
        )

    }

    onChangeHandler = event => {
        const fieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))
        const currentIndex = fieldsCopy.findIndex(index => index.name === event.target.name)

        if (currentIndex > -1) {
            fieldsCopy[currentIndex].value = event.target.value
            this.setState({ modalFields: fieldsCopy })
        }
    }

    onCreate = () => {

        let jsonMap = {}
        this.state.modalFields.forEach(field => {
            jsonMap[field.name] = field
        })
        this.props.extendRent(this.props.row.id, jsonMap['from'].value, jsonMap['to'].value, jsonMap['price'].value || 0)
            .then(() => {
                window.open(`${CONSTANTS.BACKEND_URL}rents/${this.props.row.id}/extendContract`, "_blank")
                this.props.onClose()
            })
    }

    render() {
        const { language } = this.props

        return this.state.renderPage ? <SimpleModal
            styles={this.props.classes}
            open={this.state.openModal}
            onClose={() => this.onCloseHandler()}
            onCancel={() => this.onCloseHandler()}
            onAccept={() => this.onCreate()}
            withCancelButton={true}
            acceptButtonText={language.offer.createModal.generate}
            cancelButtonText={language.offer.createModal.cancel}
            canSubmitForm={true}
            title={language.offer.createModal.extendRentTitle}
            maxWidth={'sm'}
        >
            {this.renderFields()}
        </SimpleModal> : null
    }
}
const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    extendRent: (rentId, from, to, price) => dispatch(STOCKS.createRentExtend(rentId, from, to, price))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(RentExtendModal))