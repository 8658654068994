import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { withStyles, Button, List, ListItem, ListItemText } from '@material-ui/core'
import { GetApp as CloudDown } from '@material-ui/icons'

import { validations, isValidField } from '../../../../utils/validationUtils'
import SimpleModal from '../../../common/SimpleModal'
import OfferModalTitle from '../../../common/subComponents/ModalTitleDetails'

import * as STOCK from '../../../../redux/actions/stocks'
import * as CLIENT from '../../../../redux/actions/clients'
import * as OFFER from '../../../../redux/actions/offers'
import * as NOTIFICATION from '../../../../utils/notification'
import * as CONSTANTS from '../../../../utils/constants'

import { formatNumber } from '../../../../utils/helpers'
import InputGenerator from '../../../common/subComponents/InputGenerator'

const styles = theme => ({
    modalContainer: {
        paddingTop: 18,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        color: 'black'
    },
    closeStyle: {
        marginRight: 'auto',
        marginLeft: 20
    },
    detailsContainer: {
        paddingTop: 30
    },
    leasingDetailsWidth: {
        width: 150
    },
    offerDetailsWidth: {
        width: 110
    },
    moveRight: {
        marginLeft: 'auto'
    },
    clientContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid rgba(0,0,0,0.1)'
    },
    schemaText: {
        color: '#9ea0a5',
        fontWeight: 500,
        fontSize: 14
    },
    offerContainer: {
        flex: 2,
        display: 'flex',
        flexDirection: 'row'
    },
    offerDetails: {
        flex: 4,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 18
    },
    displayColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    displayRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    offerDiv: {
        paddingRight: 20
    },
    anafText: {
        paddingRight: 2,
        paddingTop: 2,
        fontSize: 12,
        fontWeight: 'normal',
        color: "#3e3f42"
    },
    clientTitle: {
        color: '#9ea0a5',
        fontSize: 14,
        fontWeight: 500,
        marginBottom: 8
    },
    clientDiv: {
        paddingLeft: 20,
        paddingRight: 12,
        position: 'relative'
    },
    cloudImage: {
        marginRight: 4
    },
    fullWidth: {
        width: '100%'
    },
    leasingDetails: {
        flex: 2,
        float: 'right',
        paddingLeft: 18,
    },
    offerData: {
        flex: 1,
    },
    formulasContainer: {
        flex: 1
    },
    equalFlex: {
        flex: 1
    },
    inputPadding: {
        paddingRight: 10,
        paddingTop: 2
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    noMargins: {
        margin: 0,
        padding: 0
    },
    detailsText: {
        color: '#6b6c6f',
        fontSize: 14,
        fontWeight: 'normal'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    hasFinancingContainer: {
        marginLeft: '-14px'
    },
    extraLeftPadding: {
        paddingLeft: '8px'
    },
    anafButttonContainer: {
        paddingRight: '10px',
        paddingTop: '12px'
    },
    list: {
      width: '90%',
      backgroundColor: '#ffffff',
      position: 'absolute',
      zIndex: '100',
      boxSizing: 'border-box',
      border: '1px solid rgba(0, 0, 0, 0.2)',
      margin: '0px',
    },
    listItem: {
      padding: '10px',
      '&:hover': {
        backgroundColor: '#e0e0e0'
      }
    },
})

class OfferModal extends Component {

    initialFields = [
        // { value: 0, type: 'numberFormat', disabled: true, label: this.props.language.itemDetails.offer.listPrice, name: 'listPrice', breakline: true },
        // { value: 0, type: 'numberFormat', disabled: true, label: this.props.language.itemDetails.offer.purchasePrice, name: 'purchasePrice', breakline: true },
        // { value: 0, type: 'numberFormat', label: this.props.language.itemDetails.offer.rounding, name: 'rounding', validation: { checks: [validations.notEmpty] } },
        // { value: 0, type: 'numberFormat', label: this.props.language.itemDetails.offer.sellingExchangeRate, name: 'sellingExchangeRate', validation: { checks: [validations.notEmpty] } },
        // { value: 0, type: 'numberFormat', label: this.props.language.itemDetails.offer.discountPercent, name: 'discount', validation: { checks: [validations.notEmpty] } },
        // { value: 0, type: 'numberFormat', disabled: true, label: this.props.language.itemDetails.offer.discount, name: 'discountEUR', validation: { checks: [validations.notEmpty] } },
        // { value: 0, type: 'numberFormat', disabled: true, label: this.props.language.itemDetails.offer.sellingPrice, name: 'sellingPriceEUR', breakline: true },
        // { value: 0, type: 'numberFormat', disabled: true, label: this.props.language.itemDetails.offer.carProfit, name: 'carProfitEUR', breakline: true },
        { type: 'radioSelector', name: 'hasLeasing', utils: this.props.language.itemDetails.offer.leasing, value: CONSTANTS.OFFER_NEED_LEASING[1].label, options: CONSTANTS.OFFER_NEED_LEASING },
        { type: 'dropdownSelector', disabled: true, name: 'clientLeasingSchemaOneMonths', utils: this.props.language.itemDetails.offer.financing, value: '', options: CONSTANTS.IN_ADVANCE_PERCENT },
        { value: 0, type: 'number', disabled: true, label: this.props.language.itemDetails.offer.percentage, name: 'clientLeasingSchemaOnePercentage' },

        { type: 'dropdownSelector', disabled: true, name: 'clientLeasingSchemaTwoMonths', utils: this.props.language.itemDetails.offer.financing, value: '', options: CONSTANTS.IN_ADVANCE_PERCENT },
        { value: 0, type: 'number', disabled: true, label: this.props.language.itemDetails.offer.percentage, name: 'clientLeasingSchemaTwoPercentage' },

        { type: 'dropdownSelector', disabled: true, name: 'clientLeasingSchemaThreeMonths', utils: this.props.language.itemDetails.offer.financing, value: '', options: CONSTANTS.IN_ADVANCE_PERCENT },
        { value: 0, type: 'number', disabled: true, label: this.props.language.itemDetails.offer.percentage, name: 'clientLeasingSchemaThreePercentage' },
        //{ value: 0, type: 'numberFormat', disabled: true, label: this.props.language.itemDetails.offer.leasingEUR, name: 'leasingEUR', breakline: true },
        //{ value: 0, type: 'numberFormat', disabled: true, label: this.props.language.itemDetails.offer.totalProfit, name: 'totalProfitEUR', breakline: true },
    ]

    todayValue = moment().format(CONSTANTS.INPUT_TYPE_DATE_FORMAT)

    clientFields = [
      { value: '', type: 'text', label: this.props.language.itemDetails.offer.searchClient, name: 'searchClient', validation: {}  },
        { value: '', checked: false, type: 'checkbox', label: this.props.language.itemDetails.offer.individualPerson, name: 'isNonLegalEntity' },
        { value: '', type: 'text', label: this.props.language.itemDetails.offer.cui, name: 'cui', validation: {} },
        { value: '', type: 'text', label: this.props.language.itemDetails.offer.email, name: 'email', validation: { } },
        { value: '', type: 'text', label: this.props.language.itemDetails.offer.phoneNumber, name: 'phoneNumber', validation: { checks: [validations.isPhoneNumber] } },
        { value: '', type: 'text', label: this.props.language.itemDetails.offer.companyName, name: 'companyName' },
        { value: this.todayValue, type: 'date', label: this.props.language.itemDetails.offer.date, name: 'date', validation: { checks: [validations.notEmpty] } }
    ]

    clientNonLegalEntityFields = [
        { value: '', type: 'text', label: this.props.language.itemDetails.offer.namePrename, name: 'name', validation: { checks: [validations.notEmpty] } },
    ]

    state = {
        displaySpinner: false,
        stock: null,
        renderPage: false,
        openModal: true,
        expandDetails: false,
        isNonLegalEntity: false,
        hasLeasing: false,
        clientFields: [...this.clientFields.map(field => ({ ...field, error: false }))],
        modalFields: [...this.initialFields.map(field => ({ ...field, error: false }))],
        searchClient: '',
        clients: []
    }

    onChangeSearchClient = (event) => {
        const value = event.target.value
        this.setState({ searchClient: value })
        this.props.searchClient({
          search: value,
          limit: 5
        }).then(response => {
          this.setState({
            // empty array makes modal for clients to dissapear
            clients: value ? response.clients : []
          })
        })
    }

    InputWrapper = ({ input, keyProps, shrink }) => <div className={`${`${this.props.classes.offerDetailsWidth} ${this.props.classes.inputPadding}`}`}>
        <InputGenerator
            key={keyProps}
            fullWidth={true}
            InputLabelProps={shrink ? { shrink: true } : {}}
            margin="dense"
            {...input}
            onChange={event => this.onChange(event)}
        />
    </div>


    validate = () => {
        let newFields = [...this.state.modalFields]
        let newClientFields = [...this.state.clientFields].map(field => ({ ...field }))
        let isValid = true

        newFields.forEach((field, index) => {
            if (!isValidField(field)) {
                isValid = false
                newFields[index].error = true
            } else {
                newFields[index].error = false
            }
        })

        const entityTypeIndex = newClientFields.findIndex(index => index.name === 'isNonLegalEntity')
        const cuiIndex = newClientFields.findIndex(index => index.name === 'cui')

        newClientFields.forEach((field, index) => {
            if (!isValidField(field)) {
                isValid = false
                newClientFields[index].error = true
            } else {
                newClientFields[index].error = false
            }
        })

        if (!this.state.isNonLegalEntity && entityTypeIndex > -1 && cuiIndex > -1) {
            if (String(newClientFields[cuiIndex].value).length > CONSTANTS.CUI_MAX_SIZE || String(newClientFields[cuiIndex].value).length < CONSTANTS.CUI_MIN_SIZE) {
                isValid = false
                newClientFields[cuiIndex].error = true
            }
        }

        this.setState({ modalFields: [...newFields], clientFields: newClientFields })

        return isValid
    }

    // setPredefinedFormulas = stock => {
    //     let modalFieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))

    //     let listPriceIndex = this.state.modalFields.findIndex(idx => idx.name === 'listPrice')
    //     let purchasePriceIndex = this.state.modalFields.findIndex(idx => idx.name === 'purchasePrice')

    //     if (listPriceIndex > -1 && purchasePriceIndex > -1) {
    //         modalFieldsCopy[listPriceIndex].value = stock.listPrice
    //         modalFieldsCopy[purchasePriceIndex].value = stock.purchasePrice

    //         this.setState({ modalFields: modalFieldsCopy })
    //     }
    // }


    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open && this.props.stocks && this.props.stocks.length)
            this.getStock(this.props.stocks[0].stockId)
        if (prevProps.open && !this.props.open) {
            this.setState({
                openModal: false
            })
        }
    }

    // renderFields = () => {

    //     let jsonMap = {}
    //     this.state.modalFields.forEach(field => {
    //         jsonMap[field.name] = field
    //     })

    //     // let exchangeRateDiff = Number(Number(jsonMap['sellingExchangeRate'].value) - Number(this.state.stock.purchaseExchangeRate))
    //     // let exchangeRateDiffRon = Number(Number(jsonMap['sellingPriceEUR'].value) * Number(exchangeRateDiff))
    //     //let difExchangeRateEur = Number(Number(exchangeRateDiffRon) / Number(this.state.stock.purchaseExchangeRate))

    //     return (
    //         <div className={this.props.classes.inputContainer}>
    //             {/* <div className={this.props.classes.offerContainer}>
    //                 <div className={`${this.props.classes.inputPadding} ${this.props.classes.offerDetailsWidth}`}>
    //                     <InputGenerator
    //                         key={'listPrice'}
    //                         fullWidth={true}
    //                         margin="dense"
    //                         {...jsonMap['listPrice']}
    //                         value={parseFloat(jsonMap['listPrice'].value)}
    //                         onChange={event => this.onChange(event)}
    //                     />
    //                 </div>
    //                 <div className={`${this.props.classes.inputPadding} ${this.props.classes.offerDetailsWidth}`}>
    //                     <InputGenerator
    //                         key={'purchasePrice'}
    //                         fullWidth={true}
    //                         margin="dense"
    //                         {...jsonMap['purchasePrice']}
    //                         value={parseFloat(jsonMap['purchasePrice'].value)}
    //                         onChange={event => this.onChange(event)}
    //                     />
    //                 </div>
    //             </div> */}
    //             {/* <div className={this.props.classes.offerContainer}>
    //                 <div className={`${this.props.classes.inputPadding} ${this.props.classes.offerDetailsWidth}`}>
    //                     <InputGenerator
    //                         key={'rounding'}
    //                         fullWidth={true}
    //                         margin="dense"
    //                         {...jsonMap['rounding']}
    //                         onChange={event => this.onChange(event)}
    //                     />
    //                 </div>
    //                 <div className={`${this.props.classes.inputPadding} ${this.props.classes.offerDetailsWidth}`}>
    //                     <InputGenerator
    //                         key={'listPriceIndex'}
    //                         fullWidth={true}
    //                         margin="dense"
    //                         {...jsonMap['sellingExchangeRate']}
    //                         onChange={event => this.onChange(event)}
    //                     />
    //                 </div>
    //                 <div className={`${this.props.classes.inputPadding} ${this.props.classes.offerDetailsWidth}`}>
    //                     <InputGenerator
    //                         key={'listPriceIndex'}
    //                         fullWidth={true}
    //                         margin="dense"
    //                         {...jsonMap['discount']}
    //                         onChange={event => this.onChange(event)}
    //                     />
    //                 </div>
    //                 <div className={`${this.props.classes.inputPadding} ${this.props.classes.offerDetailsWidth}`}>
    //                     <InputGenerator
    //                         key={"listPriceIndex"}
    //                         fullWidth={true}
    //                         margin="dense"
    //                         {...jsonMap['discountEUR']}
    //                         value={parseFloat(jsonMap['discountEUR'].value)}
    //                         onChange={event => this.onChange(event)}
    //                     />
    //                 </div>
    //             </div> */}
    //             <div className={this.props.classes.offerContainer}>
    //                 <div className={`${this.props.classes.inputPadding} ${this.props.classes.offerDetailsWidth}`}>
    //                     <InputGenerator
    //                         key={"listPriceIndex"}
    //                         fullWidth={true}
    //                         margin="dense"
    //                         {...jsonMap['sellingPriceEUR']}
    //                         value={parseFloat(jsonMap['sellingPriceEUR'].value)}
    //                         onChange={event => this.onChange(event)}
    //                     />
    //                 </div>
    //                 <div className={`${this.props.classes.inputPadding} ${this.props.classes.offerDetailsWidth}`}>
    //                     <InputGenerator
    //                         key={"listPriceIndex"}
    //                         fullWidth={true}
    //                         margin="dense"
    //                         {...jsonMap['carProfitEUR']}
    //                         value={parseFloat(jsonMap['carProfitEUR'].value)}
    //                         onChange={event => this.onChange(event)}
    //                     />
    //                 </div>
    //             </div>
    //         </div>

    //     )
    // }

    renderLeasingFields = () => {

        let jsonMap = {}
        this.state.modalFields.forEach(field => {
            jsonMap[field.name] = field
        })

        // let exchangeRateDiff = Number(Number(jsonMap['sellingExchangeRate'].value) - Number(this.state.stock.purchaseExchangeRate))
        // let exchangeRateDiffRon = Number(Number(jsonMap['sellingPriceEUR'].value) * Number(exchangeRateDiff))
        // let difExchangeRateEur = Number(Number(exchangeRateDiffRon) / Number(this.state.stock.purchaseExchangeRate))

        return (
            <div className={this.props.displayColumn}>
                <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding} ${this.props.classes.hasFinancingContainer}`}>
                    <InputGenerator
                        style={{ width: 50 }}
                        key={"listPriceIndex"}
                        fullWidth={true}
                        margin="dense"
                        {...jsonMap['hasLeasing']}
                        onChange={event => this.onChange(event)}
                    />
                </div>
                <span className={this.props.classes.schemaText}>{this.props.language.offer.schema}</span>
                <div className={this.props.classes.flexRow}>
                    <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding} ${this.props.classes.leasingDetailsWidth}`}>
                        <InputGenerator
                            key={"listPriceIndex"}
                            fullWidth={true}
                            margin="dense"
                            {...jsonMap['clientLeasingSchemaOneMonths']}
                            onChange={event => this.onChange(event)}
                        />
                    </div>
                    <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding} ${this.props.classes.leasingDetailsWidth}`}>
                        <InputGenerator
                            key={"listPriceIndex"}
                            fullWidth={true}
                            margin="dense"
                            {...jsonMap['clientLeasingSchemaOnePercentage']}
                            onChange={event => this.onChange(event)}
                        />
                    </div>
                </div>

                <div className={this.props.classes.flexRow}>
                    <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding} ${this.props.classes.leasingDetailsWidth}`}>
                        <InputGenerator
                            key={"listPriceIndex"}
                            fullWidth={true}
                            margin="dense"
                            {...jsonMap['clientLeasingSchemaTwoMonths']}
                            onChange={event => this.onChange(event)}
                        />
                    </div>
                    <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding} ${this.props.classes.leasingDetailsWidth}`}>
                        <InputGenerator
                            key={"listPriceIndex"}
                            fullWidth={true}
                            margin="dense"
                            {...jsonMap['clientLeasingSchemaTwoPercentage']}
                            onChange={event => this.onChange(event)}
                        />
                    </div>
                </div>

                <div className={this.props.classes.flexRow}>
                    <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding} ${this.props.classes.leasingDetailsWidth}`}>
                        <InputGenerator
                            key={"listPriceIndex"}
                            fullWidth={true}
                            margin="dense"
                            {...jsonMap['clientLeasingSchemaThreeMonths']}
                            onChange={event => this.onChange(event)}
                        />
                    </div>
                    <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding} ${this.props.classes.leasingDetailsWidth}`}>
                        <InputGenerator
                            key={"listPriceIndex"}
                            fullWidth={true}
                            margin="dense"
                            {...jsonMap['clientLeasingSchemaThreePercentage']}
                            onChange={event => this.onChange(event)}
                        />
                    </div>
                </div>
                {/* <div className={this.props.classes.displayRow}>
                    <div className={`${this.props.classes.inputPadding} ${this.props.classes.leasingDetailsWidth}`}>
                        <InputGenerator
                            key={"leasingEURIndex"}
                            fullWidth={true}
                            margin="dense"
                            {...jsonMap['leasingEUR']}
                            value={parseFloat(jsonMap['leasingEUR'].value)}
                            onChange={event => this.onChange(event)}
                        />
                    </div>
                    <div className={`${this.props.classes.moveRight} ${this.props.classes.inputPadding} ${this.props.classes.leasingDetailsWidth}`}>
                        <InputGenerator
                            key={"listPriceIndex"}
                            fullWidth={true}
                            margin="dense"
                            {...jsonMap['totalProfitEUR']}
                            value={parseFloat(jsonMap['totalProfitEUR'].value)}
                            onChange={event => this.onChange(event)}
                        />
                    </div>
                </div> */}
            </div>
        )
    }

    selectClient = (event, clientArg) => {
      // name and company name are treated the same
      const client = {
        ...clientArg, 
        companyName: clientArg.name,
        phoneNumber: clientArg.contactPhoneNumber,
        email: clientArg.contactEmail
      }

      // pre complete client inputs
      let newLegalEntityValue = null
      const newClientFields = [...this.state.clientFields, ...this.clientNonLegalEntityFields].map(field => {
        if (field.name === 'date') return field;
        if (field.name === 'isNonLegalEntity') {
          if (client.isLegalEntity !== !this.state.isNonLegalEntity)
            newLegalEntityValue = !client.isLegalEntity;
          return { ...field, checked: !client.isLegalEntity, value: !client.isLegalEntity };
        }
        return { ...field, value: client[field.name] || '' };
      })

      if(newLegalEntityValue !== null){
       this.setState({
          clients: [],
          clientFields: newClientFields
       }, () => this.setState({
         isNonLegalEntity: newLegalEntityValue
       }))
      }else{
        this.setState({
          clients: [],
          clientFields: newClientFields
        })
      }
     
    }

    renderClientFields = () => {

        let jsonMap = {}
        this.state.clientFields.forEach(field => {
            jsonMap[field.name] = field
        })

        const listItems = this.state.clients.map((client, index) =>
          <ListItem key={index} divider={true} button className={this.props.classes.listItem} onMouseDown={event => this.selectClient(event, client)}>
            <ListItemText
              primary={`${client.name} - ${client.cui}`}
            />
          </ListItem>)

        return (
            <div className={this.props.classes.inputContainer}>
                <div className={this.props.classes.clientFieldsContainer}>
                    <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding}`}>
                      <InputGenerator
                        key={"searchClient"}
                        fullWidth={true}
                        margin="dense"
                        {...jsonMap['searchClient']}
                        value={this.state.searchClient}
                        onChange={event => this.onChangeSearchClient(event)}
                      />
                     {listItems.length ? <List dense={true} className={this.props.classes.list}>
                       {listItems}
                    </List> : null }
                    </div>
                    <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding} ${this.props.classes.extraLeftPadding}`}>
                        <InputGenerator
                            key={"isNonLegalEntityKey"}
                            fullWidth={true}
                            margin="dense"
                            {...jsonMap['isNonLegalEntity']}
                            onChange={event => {
                                let newFields = [...this.state.clientFields].map(field => ({ ...field }))

                                let isLegalEntityIndex = newFields.findIndex(field => field.name === event.target.name)

                                if (isLegalEntityIndex < 0) return

                                newFields[isLegalEntityIndex].value = event.target.value

                                if (event.target.value === true) {
                                    newFields = newFields.concat([...this.clientNonLegalEntityFields])
                                } else newFields = newFields.filter(field => field.name !== 'name')

                                this.setState({ isNonLegalEntity: event.target.value, clientFields: newFields })
                            }}
                        />
                    </div>
                    <div className={this.props.classes.flexRow}>
                        <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding}`}>
                            <InputGenerator
                                key={"cuiIndex"}
                                fullWidth={true}
                                margin="dense"
                                {...jsonMap['cui']}
                                onChange={event => this.onChangeClient(event)}
                            />
                        </div>
                        {!this.state.isNonLegalEntity && <div className={`${this.props.classes.anafButttonContainer}`}>
                            <Button color="secondary" onClick={() => this.onFetchFromAnaf()}>
                                <CloudDown className={this.props.classes.cloudImage} /> <span className={this.props.classes.anafText}>{this.props.language.offer.createModal.getAnaf}</span></Button>
                        </div>}
                    </div>
                    <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding}`}>
                        <InputGenerator
                            key={"emailIndex"}
                            fullWidth={true}
                            margin="dense"
                            {...jsonMap['email']}
                            onChange={event => this.onChangeClient(event)}
                        />
                    </div>
                    <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding}`}>
                        <InputGenerator
                            key={"phoneIndex"}
                            fullWidth={true}
                            margin="dense"
                            {...jsonMap['phoneNumber']}
                            onChange={event => this.onChangeClient(event)}
                        />
                    </div>
                    {this.state.isNonLegalEntity ? <>
                        <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding}`}>
                            <InputGenerator
                                key={"nameIndex"}
                                fullWidth={true}
                                margin="dense"
                                {...jsonMap['name']}
                                onChange={event => this.onChangeClient(event)}
                            />
                        </div>

                    </> : null}
                    <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding}`}>
                        <InputGenerator
                            key={"dateIndex"}
                            fullWidth={true}
                            margin="dense"
                            {...jsonMap['date']}
                            onChange={event => this.onChangeClient(event)}
                        />
                    </div>
                    {!this.state.isNonLegalEntity && <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding}`}>
                        <InputGenerator
                            key={"companyNameIndex"}
                            fullWidth={true}
                            margin="dense"
                            {...jsonMap['companyName']}
                            onChange={event => this.onChangeClient(event)}
                        />
                    </div>}
                </div>
            </div>

        )
    }

    // renderFormulaDetails = () => {
    //     let jsonMap = {}
    //     this.state.modalFields.forEach(field => {
    //         jsonMap[field.name] = field
    //     })

    //     let exchangeRateDiff = Number(Number(jsonMap['sellingExchangeRate'].value) - Number(this.state.stock.purchaseExchangeRate))
    //     let exchangeRateDiffRon = Number(Number(jsonMap['sellingPriceEUR'].value) * Number(exchangeRateDiff))
    //     let difExchangeRateEur = Number(Number(exchangeRateDiffRon) / Number(this.state.stock.purchaseExchangeRate))

    //     return (
    //         <div className={this.props.classes.detailsContainer}>
    //             <Button color="secondary" onClick={() => this.setState({ expandDetails: !this.state.expandDetails })}>{this.props.language.offer.createModal.detailsButton}</Button>
    //             {this.state.expandDetails &&
    //                 <div>
    //                     <p className={`{${this.props.classes.noMargins} ${this.props.classes.detailsText}`}>{this.props.language.offer.createModal.exchangeRateDiff}: {formatNumber(jsonMap['sellingExchangeRate'].value)} - {formatNumber(this.state.stock.purchaseExchangeRate)} = {formatNumber(exchangeRateDiff)}</p>
    //                     <p className={`{${this.props.classes.noMargins} ${this.props.classes.detailsText}`}>{this.props.language.offer.createModal.exchangeRateDiffRon}: {formatNumber(jsonMap['sellingPriceEUR'].value)} * {formatNumber(exchangeRateDiff)} = {formatNumber(exchangeRateDiffRon)}</p>
    //                     <p className={`{${this.props.classes.noMargins} ${this.props.classes.detailsText}`}>{this.props.language.offer.createModal.diffExchangeRateEur}: {formatNumber(exchangeRateDiffRon)} / {formatNumber(this.state.stock.purchaseExchangeRate)} = {formatNumber(difExchangeRateEur)}</p>
    //                 </div>}
    //         </div>
    //     )
    // }

    getStock = stockId => {
        this.props.getStockById(stockId)
            .then(stock => {
                this.setState({ stock, renderPage: true, openModal: true })
                // this.setPredefinedFormulas(stock)
            })
    }

    onFetchFromAnaf = () => {
        let cuiIndex = this.state.clientFields.findIndex(idx => idx.name === 'cui')
        let dateIndex = this.state.clientFields.findIndex(idx => idx.name === 'date')

        if (cuiIndex > -1 && dateIndex > -1) {
            let queryParams = {
                cui: this.state.clientFields[cuiIndex].value,
                date: this.state.clientFields[dateIndex].value
            }

            this.props.getFromAnaf(queryParams).then(anafData => {
                let clientModalCopy = [...this.state.clientFields].map(field => ({ ...field }))
                let companyNameIndex = this.state.clientFields.findIndex(idx => idx.name === 'companyName')

                if (companyNameIndex > -1) {
                    clientModalCopy[companyNameIndex].value = anafData.data.name

                    this.setState({ clientFields: clientModalCopy })
                }
            })
        }
    }

    onCreate = () => {
        if (!this.validate()) return NOTIFICATION.error(this.props.language.validation.uncomplete)

        let createJson = {}
        let createJsonClient = {}
        this.state.modalFields.forEach(field => createJson[field.name] = field.value)
        this.state.clientFields.forEach(field => createJsonClient[field.name] = field.value)
        let stocksToSend = []
        this.props.stocks.forEach(stockFields => {
            let currentStock = stockFields
            currentStock.financingProcent = createJson['hasLeasing'] === CONSTANTS.NO ? false : true
            if (currentStock.financingProcent) {
                currentStock.clientLeasingSchemaOneMonths = Number(createJson['clientLeasingSchemaOneMonths'])
                currentStock.clientLeasingSchemaOnePercentage = Number(createJson['clientLeasingSchemaOnePercentage'])

                currentStock.clientLeasingSchemaTwoMonths = Number(createJson['clientLeasingSchemaTwoMonths'])
                currentStock.clientLeasingSchemaTwoPercentage = Number(createJson['clientLeasingSchemaTwoPercentage'])

                currentStock.clientLeasingSchemaThreeMonths = Number(createJson['clientLeasingSchemaThreeMonths'])
                currentStock.clientLeasingSchemaThreePercentage = Number(createJson['clientLeasingSchemaThreePercentage'])
            }
            currentStock.client = createJsonClient
            currentStock.date = createJsonClient['date']
            stocksToSend.push(currentStock)
        })
        this.setState({ displaySpinner: true }, () => {
            this.props.create({ stocks: stocksToSend }).then(createdOffer => {
                this.onCloseHandler()
                this.setState({ modalFields: this.initialFields, clientFields: this.clientFields, hasLeasing: false, expandDetails: false, displaySpinner: false })
                NOTIFICATION.success(this.props.language.offer.createModal.success)
            }).catch(() => {
                this.onCloseHandler()
                this.setState({ modalFields: this.initialFields, clientFields: this.clientFields, hasLeasing: false, expandDetails: false, displaySpinner: false })
                NOTIFICATION.error(this.props.language.offer.createModal.failure)
            })
        })
    }

    onChangeClient = event => {
        let currentIndex = this.state.clientFields.findIndex(field => field.name === event.target.name)
        if (currentIndex > -1) {
            let modalFieldsCopy = [...this.state.clientFields].map(field => ({ ...field }))

            modalFieldsCopy[currentIndex].value = event.target.value
            modalFieldsCopy[currentIndex].touched = true

            this.setState({ clientFields: modalFieldsCopy })
        }
    }

    onChange = event => {
        let currentIndex = this.state.modalFields.findIndex(field => field.name === event.target.name)
        if (currentIndex > -1) {
            let modalFieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))

            modalFieldsCopy[currentIndex].value = event.target.value
            modalFieldsCopy[currentIndex].touched = true

            // let sellingPriceIndex = this.state.modalFields.findIndex(idx => idx.name === 'sellingPriceEUR')
            // let carProfitIndex = this.state.modalFields.findIndex(idx => idx.name === 'carProfitEUR')

            // /* Formulas */
            // let purchasePriceIndex = this.state.modalFields.findIndex(idx => idx.name === 'purchasePrice')
            // let discountIndex = this.state.modalFields.findIndex(idx => idx.name === 'discount')
            // let discountEURIndex = this.state.modalFields.findIndex(idx => idx.name === 'discountEUR')
            // let totalProfitEURIndex = this.state.modalFields.findIndex(idx => idx.name === 'totalProfitEUR')
            //let leasingEURIndex = this.state.modalFields.findIndex(idx => idx.name === 'leasingEUR')
            // let roundingIndex = this.state.modalFields.findIndex(idx => idx.name === 'rounding')

            let clientLeasingSchemaOnePercentageIndex = this.state.modalFields.findIndex(idx => idx.name === 'clientLeasingSchemaOnePercentage')
            let clientLeasingSchemaOneMonthsIndex = this.state.modalFields.findIndex(idx => idx.name === 'clientLeasingSchemaOneMonths')

            let clientLeasingSchemaTwoPercentageIndex = this.state.modalFields.findIndex(idx => idx.name === 'clientLeasingSchemaTwoPercentage')
            let clientLeasingSchemaTwoMonthsIndex = this.state.modalFields.findIndex(idx => idx.name === 'clientLeasingSchemaTwoMonths')

            let clientLeasingSchemaThreePercentageIndex = this.state.modalFields.findIndex(idx => idx.name === 'clientLeasingSchemaThreePercentage')
            let clientLeasingSchemaThreeMonthsIndex = this.state.modalFields.findIndex(idx => idx.name === 'clientLeasingSchemaThreeMonths')

            if (modalFieldsCopy[modalFieldsCopy.findIndex(idx => idx.name === 'hasLeasing')].value.toLowerCase() === CONSTANTS.YES.toLowerCase()) {
                modalFieldsCopy[clientLeasingSchemaOnePercentageIndex].disabled = false
                modalFieldsCopy[clientLeasingSchemaOneMonthsIndex].disabled = false

                modalFieldsCopy[clientLeasingSchemaTwoPercentageIndex].disabled = false
                modalFieldsCopy[clientLeasingSchemaTwoMonthsIndex].disabled = false

                modalFieldsCopy[clientLeasingSchemaThreePercentageIndex].disabled = false
                modalFieldsCopy[clientLeasingSchemaThreeMonthsIndex].disabled = false
                //modalFieldsCopy[leasingEURIndex].value = Number(Number(modalFieldsCopy[sellingPriceIndex].value) * Number(modalFieldsCopy[financingIndex].value / 100))
            }
            else {
                modalFieldsCopy[clientLeasingSchemaOnePercentageIndex].disabled = true
                modalFieldsCopy[clientLeasingSchemaOnePercentageIndex].value = 0

                modalFieldsCopy[clientLeasingSchemaOneMonthsIndex].disabled = true
                modalFieldsCopy[clientLeasingSchemaOneMonthsIndex].value = 0

                modalFieldsCopy[clientLeasingSchemaTwoPercentageIndex].disabled = true
                modalFieldsCopy[clientLeasingSchemaTwoPercentageIndex].value = 0

                modalFieldsCopy[clientLeasingSchemaTwoMonthsIndex].disabled = true
                modalFieldsCopy[clientLeasingSchemaTwoMonthsIndex].value = 0

                modalFieldsCopy[clientLeasingSchemaThreePercentageIndex].disabled = true
                modalFieldsCopy[clientLeasingSchemaThreePercentageIndex].value = 0

                modalFieldsCopy[clientLeasingSchemaThreeMonthsIndex].disabled = true
                modalFieldsCopy[clientLeasingSchemaThreeMonthsIndex].value = 0

                // modalFieldsCopy[leasingEURIndex].value = 0
            }

            // if (discountIndex > -1 && discountEURIndex > -1 && sellingPriceIndex > -1 && carProfitIndex > -1 && leasingEURIndex > -1) {
            //     modalFieldsCopy[discountEURIndex].value = Number(Number(this.state.stock.listPrice) - (Number(this.state.stock.listPrice * (Number(1 - modalFieldsCopy[discountIndex].value / 100)))))
            //     //modalFieldsCopy[sellingPriceIndex].value = Number(Number(this.state.stock.listPrice) - Number(modalFieldsCopy[discountEURIndex].value) + Number(this.state.stock.PDI) + Number(this.state.stock.accessoriesPrice) - Number(modalFieldsCopy[roundingIndex].value))
            //     modalFieldsCopy[sellingPriceIndex].value = Number(Number(this.state.stock.listPrice) * (Number(1 - modalFieldsCopy[discountIndex].value / 100)) + Number(this.state.stock.accessoriesPrice) - Number(modalFieldsCopy[roundingIndex].value))
            //     modalFieldsCopy[carProfitIndex].value = Number(Number(modalFieldsCopy[sellingPriceIndex].value) - Number(modalFieldsCopy[purchasePriceIndex].value) - Number(this.state.stock.cost.totalPriceEUR))

            //     let sellingExchangeRateIndex = this.state.modalFields.findIndex(idx => idx.name === 'sellingExchangeRate')
            //     let exchangeRateDiff = Number(Number(modalFieldsCopy[sellingExchangeRateIndex].value) - Number(this.state.stock.purchaseExchangeRate))
            //     let exchangeRateDiffRon = Number(Number(modalFieldsCopy[sellingPriceIndex].value) * Number(exchangeRateDiff))
            //     let difExchangeRateEur = Number(Number(exchangeRateDiffRon) / Number(this.state.stock.purchaseExchangeRate))
            //     modalFieldsCopy[totalProfitEURIndex].value = Number(Number(modalFieldsCopy[carProfitIndex].value) + Number(difExchangeRateEur) + (Number(modalFieldsCopy[leasingEURIndex].value)))
            // }



            this.setState({ modalFields: modalFieldsCopy })
        }
    }

    onCloseHandler = () => {
        this.props.onClose()

        this.setState({
            modalFields: [...this.initialFields.map(field => ({ ...field, error: false }))],
            clientFields: [...this.clientFields.map(field => ({ ...field, error: false }))],
            isNonLegalEntity: false,
            hasLeasing: false,
            expandDetails: false,
            openModal: false
        })
    }

    render() {
        const { language } = this.props

        return this.state.renderPage ? <SimpleModal
            spinner={this.state.displaySpinner}
            spinnerText={language.offer.sendingMail}
            styles={this.props.classes}
            open={this.state.openModal}
            onClose={() => this.onCloseHandler()}
            onCancel={() => this.onCloseHandler()}
            onAccept={() => this.onCreate()}
            withCancelButton={true}
            acceptButtonText={language.offer.createModal.create}
            cancelButtonText={language.offer.createModal.cancel}
            canSubmitForm={true}
            title={<OfferModalTitle onCancel={() => this.onCloseHandler()} item={this.state.stock} />}
            maxWidth={'md'}
        >
            <div className={this.props.classes.modalContainer}>
                <div className={this.props.classes.clientContainer}>
                    <div className={this.props.classes.clientDiv}>
                        <span className={this.props.classes.clientTitle}>{this.props.language.offer.createModal.client}</span>
                        {this.renderClientFields()}
                    </div>
                </div>
                <div className={`${this.props.classes.offerContainer} ${this.props.classes.offerDiv}`}>
                    <div className={this.props.classes.offerDetails}>
                        {/* <div className={this.props.classes.offerData}>
                            <span className={this.props.classes.clientTitle}>{this.props.language.offer.createModal.offer}</span>
                            {this.renderFields()}
                        </div> */}
                        <div className={this.props.classes.formulasContainer}>
                            {this.renderLeasingFields()}
                        </div>
                    </div>
                    {/* <div className={this.props.classes.leasingDetails}>
                        <div>
                            <span className={this.props.classes.clientTitle}>{this.props.language.offer.createModal.details}</span>
                            {this.renderFormulaDetails()}
                        </div>
                    </div> */}
                </div>
            </div>
        </SimpleModal> : null
    }
}
const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getStockById: stockId => dispatch(STOCK.getById(stockId)),
    getFromAnaf: queryParams => dispatch(CLIENT.getFromAnaf(queryParams)),
    searchClient: queryParams => dispatch(CLIENT.get(queryParams)),
    create: offer => dispatch(OFFER.create(offer))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OfferModal))