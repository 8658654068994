import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { withStyles } from '@material-ui/core'

import SimpleModal from '../common/SimpleModal'

import * as CONSTANTS from '../../utils/constants'
import * as OFFERS from '../../redux/actions/offers'
import * as NOTIFICATION from '../../utils/notification'

import InputGenerator from '../common/subComponents/InputGenerator'

const styles = theme => ({
    modalContainer: {
        paddingTop: 18,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        color: 'black'
    },
    rentModalContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    clientContainer: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid rgba(0,0,0,0.1)'
    },
    detailsContainer: {
        flex: 1,
        padding: 20
    },
    equalFlex: {
        flex: 1
    },
    inputPadding: {
        paddingRight: 10,
        paddingTop: 2
    },
    paddingToLeft: {
        padding: '4px 0px 4px 12px'
    },
    paddingToRight: {
        padding: '4px 12px 4px 0px'
    },
})

class SaleContractModal extends Component {

    remainedSumMap = {}

    initialFields = [
        { value: '', type: 'number', label: this.props.language.itemDetails.offer.rent.contractNumber, name: 'contractNumber' },
        { value: null, type: 'date', label: this.props.language.itemDetails.offer.rent.contractDate, name: 'contractDate' },
        { value: '', disabled: true, type: 'numberFormat', label: 'Valoare avans €', name: 'advanceValue' },
        { value: '', disabled: true, type: 'numberFormat', label: 'Pret vanzare €', name: 'sellingPriceEUR' },
        { value: '', disabled: true, type: 'numberFormat', label: 'Ramas de plata €', name: 'remainToPay' },
        { value: null, type: 'date', label: 'Scadenta Rest de plata', name: 'remainedToPayDate' },
        //{ value: '', type: 'numberFormat', decimalSize: CONSTANTS.EXCHANGE_DECIMAL_SIZE, label: 'Curs vanzare', name: 'sellingExchangeRate' },
        { value: null, type: 'date', label: this.props.language.itemDetails.offer.rent.dueDate, name: 'dueDate1' },
        { value: null, type: 'date', label: this.props.language.itemDetails.offer.rent.dueDate, name: 'dueDate2' },
        { value: null, type: 'date', label: this.props.language.itemDetails.offer.rent.dueDate, name: 'dueDate3' },
        { value: null, type: 'date', label: this.props.language.itemDetails.offer.rent.dueDate, name: 'dueDate4' },
        { value: null, type: 'date', label: this.props.language.itemDetails.offer.rent.dueDate, name: 'dueDate5' },
        { value: null, type: 'date', label: this.props.language.itemDetails.offer.rent.dueDate, name: 'dueDate6' },
        { value: null, type: 'date', label: this.props.language.itemDetails.offer.rent.dueDate, name: 'dueDate7' },
        { value: null, type: 'date', label: this.props.language.itemDetails.offer.rent.dueDate, name: 'dueDate8' },
        { value: null, type: 'date', label: this.props.language.itemDetails.offer.rent.dueDate, name: 'dueDate9' },
        { value: null, type: 'date', label: this.props.language.itemDetails.offer.rent.dueDate, name: 'dueDate10' },
        { value: null, type: 'date', label: this.props.language.itemDetails.offer.rent.dueDate, name: 'dueDate11' },
        { value: null, type: 'date', label: this.props.language.itemDetails.offer.rent.dueDate, name: 'dueDate12' },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.offer.rent.sum, name: 'sum1' },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.offer.rent.sum, name: 'sum2' },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.offer.rent.sum, name: 'sum3' },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.offer.rent.sum, name: 'sum4' },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.offer.rent.sum, name: 'sum5' },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.offer.rent.sum, name: 'sum6' },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.offer.rent.sum, name: 'sum7' },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.offer.rent.sum, name: 'sum8' },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.offer.rent.sum, name: 'sum9' },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.offer.rent.sum, name: 'sum10' },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.offer.rent.sum, name: 'sum11' },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.offer.rent.sum, name: 'sum12' },

    ]

    state = {
        openModal: false,
        renderPage: false,
        modalFields: this.initialFields,
        remainToPay: null,
        totalSum: null
    }

    InputWrapper = ({ input, keyProps, shrink, onChange, value }) => <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding}`}>
        <InputGenerator
            key={keyProps}
            fullWidth={true}
            InputLabelProps={shrink ? { shrink: true } : {}}
            margin="dense"
            {...input}
            onChange={event => onChange(event)}
            value={value ? value : input.value}
        />
    </div>

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            this.getById()
        }
        if (prevProps.open && !this.props.open) {
            this.setState({
                openModal: false
            })
        }
    }

    getById = () => {
        this.props.getInWorkById(this.props.row.id).then(result => {
            let fieldsCopy = this.state.modalFields.map(field => ({ ...field }))
            fieldsCopy.forEach((input, index) => {
                if (input.name === 'sellingPriceEUR') fieldsCopy[index].value = result['offer'][input.name] + (result['offer'][input.name] * CONSTANTS.VAT / 100)
                else if (input.name === 'advanceValue') fieldsCopy[index].value = result['offer'][input.name]
                else if (input.type === 'date') fieldsCopy[index].value = result[input.name] === CONSTANTS.INVALID_DATE_INPUT_ERROR ? null : moment(result[input.name]).format(CONSTANTS.INPUT_TYPE_DATE_FORMAT)
                else fieldsCopy[index].value = result[input.name]
            })

            const remainToPayIndex = fieldsCopy.findIndex(index => index.name === 'remainToPay')
            const sellingPriceEURIndex = fieldsCopy.findIndex(index => index.name === 'sellingPriceEUR')
            const advanceValueIndex = fieldsCopy.findIndex(index => index.name === 'advanceValue')
            if (remainToPayIndex > -1 && sellingPriceEURIndex > -1 && advanceValueIndex > -1) {
                let jsonMap = {}
                // Create jsonmap for sums
                fieldsCopy.forEach(field => {
                    if (field.name === 'sum1' ||
                        field.name === 'sum2' ||
                        field.name === 'sum3' ||
                        field.name === 'sum4' ||
                        field.name === 'sum5' ||
                        field.name === 'sum6' ||
                        field.name === 'sum7' ||
                        field.name === 'sum8' ||
                        field.name === 'sum9' ||
                        field.name === 'sum10' ||
                        field.name === 'sum11' ||
                        field.name === 'sum12') {
                        jsonMap[field.name] = field.value
                    }
                })
                // Calculate sum to be extracted
                let sumToExtract

                sumToExtract = Object.values(jsonMap).reduce(((sum, val) => sum + val), 0)

                fieldsCopy[remainToPayIndex].value = fieldsCopy[sellingPriceEURIndex].value - fieldsCopy[advanceValueIndex].value - sumToExtract

                this.setState({
                    openModal: true,
                    renderPage: true,
                    modalFields: fieldsCopy,
                    remainToPay: fieldsCopy[remainToPayIndex].value,
                    totalSum: fieldsCopy[sellingPriceEURIndex].value - fieldsCopy[advanceValueIndex].value
                })
            }

        })
    }


    onCloseHandler = () => {
        this.props.onClose()
        this.setState({ modalFields: this.initialFields, openModal: false })
    }


    renderFields = () => {

        const InputWrapper = this.InputWrapper

        let jsonMap = {}
        this.state.modalFields.forEach(field => {
            jsonMap[field.name] = field
        })
        return (
            <>
                <InputWrapper input={jsonMap['contractNumber']} key={'rentContractNumber'} onChange={this.onChangeHandler} />
                <InputWrapper input={jsonMap['contractDate']} key={'rentContractDate'} onChange={this.onChangeHandler} shrink={true} />
                <div className={this.props.classes.flexRow}>
                    <InputWrapper input={jsonMap['sellingPriceEUR']} key={'sellingPriceEUR'} onChange={() => { }} />
                    <InputWrapper input={jsonMap['advanceValue']} key={'advanceValue'} onChange={() => { }} />
                    <InputWrapper value={this.state.remainToPay} input={jsonMap['remainToPay']} key={'remainToPay'} onChange={() => { }} />
                    <InputWrapper input={jsonMap['remainedToPayDate']} key={'remainedToPayDate'} onChange={this.onChangeHandler} shrink={true} />
                    {/* <InputWrapper input={jsonMap['sellingExchangeRate']} key={'sellingExchangeRate'} onChange={this.onChangeHandler} /> */}
                </div>
                <div className={this.props.classes.flexRow}>
                    <div className={this.props.classes.equalFlex}>
                        <div className={this.props.classes.paddingToRight}>
                            <InputWrapper input={jsonMap['sum1']} key={'sum1'} onChange={this.onChangeHandler} />
                            <InputWrapper input={jsonMap['dueDate1']} key={'dueDate1'} onChange={this.onChangeHandler} shrink={true} />
                        </div>
                        <div className={this.props.classes.paddingToRight}>
                            <InputWrapper input={jsonMap['sum2']} key={'sum2'} onChange={this.onChangeHandler} />
                            <InputWrapper input={jsonMap['dueDate2']} key={'dueDate2'} onChange={this.onChangeHandler} shrink={true} />
                        </div>
                        <div className={this.props.classes.paddingToRight}>
                            <InputWrapper input={jsonMap['sum3']} key={'sum3'} onChange={this.onChangeHandler} />
                            <InputWrapper input={jsonMap['dueDate3']} key={'dueDate3'} onChange={this.onChangeHandler} shrink={true} />
                        </div>
                        <div className={this.props.classes.paddingToRight}>
                            <InputWrapper input={jsonMap['sum4']} key={'sum4'} onChange={this.onChangeHandler} />
                            <InputWrapper input={jsonMap['dueDate4']} key={'dueDate4'} onChange={this.onChangeHandler} shrink={true} />
                        </div>
                        <div className={this.props.classes.paddingToRight}>
                            <InputWrapper input={jsonMap['sum5']} key={'sum5'} onChange={this.onChangeHandler} />
                            <InputWrapper input={jsonMap['dueDate5']} key={'dueDate5'} onChange={this.onChangeHandler} shrink={true} />
                        </div>
                        <div className={this.props.classes.paddingToRight}>
                            <InputWrapper input={jsonMap['sum6']} key={'sum6'} onChange={this.onChangeHandler} />
                            <InputWrapper input={jsonMap['dueDate6']} key={'dueDate6'} onChange={this.onChangeHandler} shrink={true} />
                        </div>
                    </div>
                    <div className={this.props.classes.equalFlex}>
                        <div className={this.props.classes.paddingToLeft}>
                            <InputWrapper input={jsonMap['sum7']} key={'sum7'} onChange={this.onChangeHandler} />
                            <InputWrapper input={jsonMap['dueDate7']} key={'dueDate7'} onChange={this.onChangeHandler} shrink={true} />
                        </div>
                        <div className={this.props.classes.paddingToLeft}>
                            <InputWrapper input={jsonMap['sum8']} key={'sum8'} onChange={this.onChangeHandler} />
                            <InputWrapper input={jsonMap['dueDate8']} key={'dueDate8'} onChange={this.onChangeHandler} shrink={true} />
                        </div>
                        <div className={this.props.classes.paddingToLeft}>
                            <InputWrapper input={jsonMap['sum9']} key={'sum9'} onChange={this.onChangeHandler} />
                            <InputWrapper input={jsonMap['dueDate9']} key={'dueDate9'} onChange={this.onChangeHandler} shrink={true} />
                        </div>
                        <div className={this.props.classes.paddingToLeft}>
                            <InputWrapper input={jsonMap['sum10']} key={'sum10'} onChange={this.onChangeHandler} />
                            <InputWrapper input={jsonMap['dueDate10']} key={'dueDate10'} onChange={this.onChangeHandler} shrink={true} />
                        </div>
                        <div className={this.props.classes.paddingToLeft}>
                            <InputWrapper input={jsonMap['sum11']} key={'sum11'} onChange={this.onChangeHandler} />
                            <InputWrapper input={jsonMap['dueDate11']} key={'dueDate11'} onChange={this.onChangeHandler} shrink={true} />
                        </div>
                        <div className={this.props.classes.paddingToLeft}>
                            <InputWrapper input={jsonMap['sum12']} key={'sum12'} onChange={this.onChangeHandler} />
                            <InputWrapper input={jsonMap['dueDate12']} key={'dueDate12'} onChange={this.onChangeHandler} shrink={true} />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    onChangeHandler = event => {
        const fieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))
        const currentIndex = fieldsCopy.findIndex(index => index.name === event.target.name)

        if (currentIndex > -1) {
            fieldsCopy[currentIndex].value = event.target.value

            const remainToPayIndex = fieldsCopy.findIndex(index => index.name === 'remainToPay')
            if (remainToPayIndex > -1) {
                if (event.target.name.includes('sum')) {
                    // set remain to pay value
                    let sum = 0
                    for (let i = 1; i <= 12; i++) {
                        const currentSumIndex = fieldsCopy.findIndex(index => index.name === `sum${i}`)
                        sum += fieldsCopy[currentSumIndex].value || 0
                    }
                    const remainToPayValue = this.state.totalSum - sum
                    if (Number(remainToPayValue.toFixed(2)) < 0) {
                        NOTIFICATION.error(this.props.language.itemDetails.offer.rent.exceededLimit)
                        fieldsCopy[currentIndex].value = 0
                        this.setState({ modalFields: fieldsCopy, remainToPay: remainToPayValue + event.target.value })
                    }
                    else if (Number(remainToPayValue.toFixed(2)) === 0) {
                        fieldsCopy[remainToPayIndex].value = 0
                        this.setState({ modalFields: fieldsCopy, remainToPay: 0 })
                    }
                    else this.setState({ modalFields: fieldsCopy, remainToPay: remainToPayValue })
                }
                else {
                    this.setState({ modalFields: fieldsCopy })
                }
            }
        }
    }

    onCreate = () => {

        let jsonMap = {}
        this.state.modalFields.forEach(field => {
            jsonMap[field.name] = field.value
        })

        let inWorkJson = {}

        Object.keys(jsonMap).forEach(key => {
            if (jsonMap[key] === CONSTANTS.INVALID_DATE_INPUT_ERROR || key === 'remainToPay') return;
            inWorkJson[key] = jsonMap[key] || null
        })

        this.props.generateSaleInvoice(this.props.row.id, inWorkJson).then(() => {
            window.open(`${CONSTANTS.BACKEND_URL}inWorks/${this.props.row.id}/saleContract`, "_blank")
            this.props.onClose()
        })

    }

    render() {
        const { language } = this.props

        return this.state.renderPage ? <SimpleModal
            styles={this.props.classes}
            open={this.state.openModal}
            onClose={() => this.onCloseHandler()}
            onCancel={() => this.onCloseHandler()}
            onAccept={() => this.onCreate()}
            acceptButtonText={language.offer.createModal.generate}
            cancelButtonText={language.offer.createModal.cancel}
            canSubmitForm={true}
            title={language.offer.createModal.saleContractTitle}
            maxWidth={'md'}
        >
            {this.renderFields()}
        </SimpleModal> : null
    }
}
const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    generateSaleInvoice: (inWorkId, inWork) => dispatch(OFFERS.generateSaleContract(inWorkId, inWork)),
    getInWorkById: (inWorkId) => dispatch(OFFERS.getInWorkById(inWorkId))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SaleContractModal))