import React from 'react'
import { Avatar, withStyles } from '@material-ui/core'

const styles = theme => ({
    brandModelAvatarWrapper: {
        width: '150px',
        display: 'flex',
        flexDirection: 'row'
    },
    marcaModelWrapper: {
        flex: 1,
        marginLeft: '15px'
    },
    primaryAvatar: {

    },
    primaryAvatarImg: {
        height: '35px'
    },
    brandText: {
        fontSize: 14,
        color: '#6b6c6f',
        paddingBottom: '6px'
    }
})

const ListAvatarAndCarBrand = props => {
    const { classes } = props
    return <div className={classes.brandModelAvatarWrapper} style={props.disableWidth ? { width: '100%' } : {}}>
        <Avatar classes={{ img: classes.primaryAvatarImg }} src={props.logoPath} />
        <div className={classes.marcaModelWrapper}>
            <div className={classes.brandText}>{props.carBrandName}</div>
            <span>{props.carModelName}</span>
        </div>
    </div>
}

export default withStyles(styles)(ListAvatarAndCarBrand)