import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, TableCell, Tooltip, TableRow } from '@material-ui/core'

import ListAvatarAndCarBrand from '../../common/subComponents/ListAvatarAndCarBrand'
import { ClickWrapper } from '../../../utils/helpers'
import DropdownMenu from '../../common/DropdownMenu'
import config from '../../../config/local.json'
import * as CONSTANTS from '../../../utils/constants'

const styles = theme => ({
    ovalYellow: {
        width: 14,
        height: 14,
        borderRadius: 7,
        backgroundColor: '#f8ac14',
        marginRight: 10
    },
    ovalBlue: {
        width: 14,
        height: 14,
        borderRadius: 7,
        backgroundColor: '#86afec',
        marginRight: 10
    }
})

class StockForOfferList extends Component {

    render() {

        const logoPath = `${config.baseUrl}/cars/brands/${this.props.item.carBrandId}/image`
        return (
            <Tooltip placement="top" title={this.props.item.comment}>
                <TableRow>
                    <ClickWrapper onClick={() => this.props.onSelect(this.props.item)}>
                        <TableCell>
                            <ListAvatarAndCarBrand
                                carBrandName={this.props.item.carBrand.name}
                                carModelName={this.props.item.carModel.name}
                                logoPath={logoPath}
                            />
                        </TableCell>
                        <TableCell>{this.props.item.provider.name}</TableCell>
                        <TableCell>{parseFloat(this.props.item.purchasePrice).toFixed(2)} {CONSTANTS.EURO_SYMBOL}</TableCell>
                        <TableCell>{
                            this.props.item.offers.length ?
                                <div className={this.props.classes.ovalBlue}>
                                </div> :
                                <div className={this.props.classes.ovalYellow}>
                                </div>
                        }
                        </TableCell>
                    </ClickWrapper>
                    <TableCell>
                        {this.props.actions ? <DropdownMenu type={'ACTIONS'} item={this.props.item} items={this.props.actions} />
                            : null}
                    </TableCell>
                </TableRow>
            </Tooltip>)
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails
})

const mapDispatchToProps = dispatch => ({

})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(StockForOfferList))