import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, withStyles } from '@material-ui/core'
import { ExpandMore as Expand, Add } from '@material-ui/icons'

import SimpleList from '../../common/SimpleList'
import SimpleCards from '../../common/SimpleCards'
import ItemDetails from '../../common/ItemDetails'
import CreateOfferModal from './Modals/CreateOfferModal'
import RentModal from './Modals/RentModal'
import TestDriveModal from './Modals/TestDriveModal'
import * as STOCK from '../../../redux/actions/stocks'
import * as SETTINGS from '../../../redux/actions/settings'
import * as CONSTANTS from '../../../utils/constants'
import * as OFFER from '../../../redux/actions/offers'

const styles = theme => ({
    subtitle: {
        fontSize: '35px',
        paddingTop: '20px',
        color: theme.palette.primaryColor,
        display: 'block',
        width: '350px'
    },
    stocktabContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    createOfferContainer: {
        marginLeft: 'auto',
        paddingRight: 28,
        paddingTop: 12
    }
})

class StockTab extends Component {

    stockToEdit = {}

    state = {
        stocks: [],
        count: 0,
        from: 0,
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        search: '',
        openRentModal: false,
        openTestDriveModal: false,
        openDetailsModal: false,
        openOfferModal: false,
        offerStockId: null,
        renderPage: false,
        activeTab: 0,
        stocksForOffer: [],
        settings: null,
        resetCheckboxes: false,
        stockToView: null
    }

    componentDidMount() {
        this.get()
        this.getSettings()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.searchText !== this.props.searchText) {
            this.setState({ search: this.props.searchText }, () => this.get())
        }
    }

    getSettings = () => {
        this.props.getSettings().then(result => {
            this.setState({ settings: result, renderPage: true })
        })
    }

    get = () => {
        this.props.get({
            from: this.state.from,
            limit: this.state.rowsPerPage,
            search: this.state.search,
        }).then(result => {
            this.setState({
                stocks: result.stocks.map(stock => ({
                    ...stock,
                    listPrice: stock.type === 'sh' || stock.type === 'external' ? stock.purchasePrice : stock.listPrice,
                    purchasePrice: stock.type === 'sh' || stock.type === 'external' ? stock.ShPurchasePrice : stock.purchasePrice
                })),
                countOffers: result.count
            })
        })
    }

    openDetailsModal = row => {
        this.props.getById(row.id).then(res => {
            this.setState({ openDetailsModal: true, stockToView: res })
        })
    }

    closeDetailsModal = () => {
        this.setState({
            openDetailsModal: false
        })
    }

    openOfferModal = row => {
        this.setState({
            stockToView: row,
            offerStockId: row.id,
            openOfferModal: true
        })
    }

    closeOfferModal = () => {
        this.setState({
            openOfferModal: false,
            offerStockId: null,
            resetCheckboxes: true,
            stocksForOffer: []
        })
    }

    closeRentModal = () => {
        this.setState({
            openRentModal: false,
            offerStockId: null,
        }, this.get())
    }

    closeTestDriveModal = () => {
        this.setState({
            openTestDriveModal: false,
            offerStockId: null,
        }, this.get())
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, () => this.get())
    }

    changePageHandler = (option) => {
        if (option === CONSTANTS.NEXT) {
            this.setState((prevState) => ({ from: prevState.from + prevState.rowsPerPage }), () => this.get())
        }
        else if (option === CONSTANTS.PREV) {
            this.setState((prevState) => ({ from: prevState.from - prevState.rowsPerPage }), () => this.get())
        }
    }

    onSearch = search => this.setState({ search }, () => this.get())

    openStockDetailsModal = row => {
        this.props.getById(row.id).then(res => {
            this.stockToEdit = row
            this.setState({ openDetailsModal: true, stockToView: res })
        })
    }

    selectStock = (checked, stock) => {
        if (checked) {
            let stocksForOfferCopy = this.state.stocksForOffer.map(stock => ({ ...stock }))
            const currentStockIndex = stocksForOfferCopy.findIndex(index => index.stockId === stock.stockId)
            if (currentStockIndex > -1) {
                stocksForOfferCopy[currentStockIndex] = stock
            }
            else {
                stocksForOfferCopy.push(stock)
            }

            this.setState({ stocksForOffer: stocksForOfferCopy, resetCheckboxes: false })
        }
        else {
            if (this.state.stocksForOffer.length) {
                const newStockList = this.state.stocksForOffer.filter(stockIndex => stockIndex.stockId !== stock.stockId);
                this.setState({ stocksForOffer: newStockList, resetCheckboxes: false })
            }
        }
    }

    createOfferHandler = () => {
        this.setState({ openOfferModal: true })
    }

    openStockDetails = stockId => {
        this.props.getById(stockId).then(stock => {
            this.setState({
                openDetailsModal: true,
                stockToView: stock
            })
        })
    }


    render() {
        return this.state.renderPage ? <div className={this.props.classes.stocktabContainer}>
            {this.state.stocksForOffer.length ? <div className={this.props.classes.createOfferContainer}><Button onClick={() => this.createOfferHandler()} color="primary">{this.props.language.createOffer}</Button></div> : null}
            <ItemDetails
                open={this.state.openDetailsModal}
                item={this.state.stockToView || {}}
                fullStock={this.state.stockToView || {}}
                maxWidth={'md'}
                noHeaders={true}
                onCancel={this.closeDetailsModal} />
            <CreateOfferModal
                open={this.state.openOfferModal}
                stocks={this.state.stocksForOffer}
                onClose={() => this.closeOfferModal()}
            />
            <RentModal
                open={this.state.openRentModal}
                stockId={this.state.offerStockId}
                onClose={() => this.closeRentModal()}
            />
            <TestDriveModal
                open={this.state.openTestDriveModal}
                stockId={this.state.offerStockId}
                onClose={() => this.closeTestDriveModal()}
            />
            <SimpleCards
                resetCheckboxes={this.state.resetCheckboxes}
                openRentModal={stockId => {
                    this.setState({ openRentModal: true, offerStockId: stockId })
                }}
                openTestDriveModal={stockId => {
                    this.setState({ openTestDriveModal: true, offerStockId: stockId })
                }}
                offerForSingleStock={item => this.setState({ stocksForOffer: Array(item), openOfferModal: true })}
                numberOfStocksForOffer={this.state.stocksForOffer && this.state.stocksForOffer.length ? this.state.stocksForOffer.length : 0}
                stocksForOffer={this.state.stocksForOffer && this.state.stocksForOffer.length ? this.state.stocksForOffer : []}
                settings={this.state.settings}
                selectStock={(event, stock) => this.selectStock(event, stock)}
                headerFields={this.props.headerFields}
                noHeader
                openStockDetails={stockId => this.openStockDetails(stockId)}
                cards={this.state.stocks}
                actionsTitle={this.props.language.simpleTable.actionsTitle}
                headerFirstOptions={[]}
                headerSecondOptions={[]}
                disableBorders
                onSelect={stock => this.openStockDetailsModal(stock)}
                selectedOption={CONSTANTS.OFFER_OPTIONS.SUMAR_STOCK_OFFER}
                actions={[
                    {
                        tooltip: this.props.language.simpleTable.actions.add,
                        onClick: (_, row) => this.openOfferModal(row),
                        icon: <Add />
                    },
                    {
                        tooltip: this.props.language.simpleTable.actions.expand,
                        onClick: (_, row) => this.openDetailsModal(row),
                        icon: <Expand />
                    }
                ]}
                search={{
                    onSearch: this.onSearch
                }}
                rowsPerPage={this.state.rowsPerPage}
                count={this.state.countOffers}
                changeRowsPerPage={this.changeRowsPerPageHandler}
                changePageHandler={this.changePageHandler}
            />
        </div> : null
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.stock,
    headerFields: reducers.languageReducer.i18n.itemDetails.stock.stockTabHeaderFields,
})

const mapDispatchToProps = dispatch => ({
    getSettings: () => dispatch(SETTINGS.get()),
    get: queryParams => dispatch(OFFER.getStocks(queryParams)),
    getById: stockId => dispatch(STOCK.getById(stockId))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(StockTab))