import React from 'react'
import { TextField, MenuItem, Checkbox, FormControlLabel, InputAdornment } from '@material-ui/core'
import NumberFormat from 'react-number-format'

import AutoCompletionInput from './AutoCompleteInput/AutoCompleteInput'
import MultipleSelector from '../MultipleSelector'
import RadioSelector from '../RadioSelector'
import DropdownSelector from '../DropdownSelector'
import * as CONSTANTS from '../../../utils/constants'


class InputGenerator extends React.Component {

    shouldComponentUpdate(prevProps) {
        if (this.props.value !== prevProps.value
            || this.props.name !== prevProps.name
            || this.props.disabled !== prevProps.disabled
            || this.props.error !== prevProps.error
            || (this.props.options !== prevProps.options)
            || (this.props.options && prevProps.options && this.props.optionsTag !== prevProps.optionsTag)
        ) {
            return true
        }
        return false
    }

    NumberFormatWrapper({ inputRef, defaultSize, value, name, onChange, decimalSize, ...other }) {
        return <NumberFormat
            {...other}
            name={name}
            getInputRef={inputRef}
            value={value}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={decimalSize || CONSTANTS.DEFAULT_DECIMAL_SIZE}
            onValueChange={values => {
                const { floatValue } = values
                onChange({
                    target: {
                        name: name,
                        value: floatValue
                    }
                })
            }} />
    }

    render() {
        let { startAdornment, endAdornment, noOptions, readOnly, decimalSize, ...props } = this.props

        const inputProps = {
            InputProps: {
                startAdornment: startAdornment ? (
                    <InputAdornment>
                        {startAdornment}
                    </InputAdornment>
                ) : null,
                endAdornment: endAdornment ? (
                    <InputAdornment>
                        {endAdornment}
                    </InputAdornment>
                ) : null,
                readOnly
            }
        }

        switch (props.type) {
            case 'select':
                return <TextField
                    label={props.label}
                    onClick={props.onClick ? props.onClick : () => { }}
                    {...props}
                    select
                    variant="outlined"  >
                    {props.options.map((option, index) => {
                        return <MenuItem
                            key={index}
                            value={option.value}>
                            {option.name}
                        </MenuItem>
                    })
                    }
                </TextField>
            case 'autoCompletionInput':
                return <AutoCompletionInput {...props} />
            case 'multiselector':
                return <MultipleSelector
                    {...props}
                />
            case 'radioSelector':
                return <RadioSelector
                    {...props}
                />
            case 'dropdownSelector':
                return <DropdownSelector
                    {...props}
                />
            case 'numberFormat':
                return <TextField
                    {...props}
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                        inputComponent: this.NumberFormatWrapper,
                        inputProps: { decimalSize: decimalSize }
                    }}
                />
            case 'checkbox':
                return <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.value}
                            onChange={() => props.onChange({
                                target: {
                                    name: props.name,
                                    value: !props.value
                                }
                            })}
                        />
                    }
                    label={props.label}
                />
            default:
                return <TextField
                    variant="outlined"
                    {...props}
                    {...inputProps}
                />
        }
    }
}


export default InputGenerator