import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { withStyles, Button } from '@material-ui/core'
import { AddCircleOutline as AddIcon } from '@material-ui/icons'
import * as COSTS from '../../../redux/actions/leasingCosts'
import * as STOCKS from '../../../redux/actions/stocks'
import * as CONSTANTS from '../../../utils/constants'

import { formatNumber } from '../../../utils/helpers'
import FormModal from '../../common/FormModal'
import Timeline from '../../common/Timeline'
import ConfirmationModal from '../../common/ConfirmationDialog'

const styles = theme => ({
    detailsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        height: 'auto'
    },
    leftList: {
        width: '250px',
        padding: '0px 25px 0px 25px',
        listStyle: 'none'
    },
    listItem: {
        color: '#34aa44',
        fontSize: '16px',
        marginTop: '10px',
        fontWeight: 400,
        '&:hover': {
            cursor: 'pointer'
        },
        '& > span.total': {
            float: 'right',
            color: '#3e3f42',
            fontWeight: 500
        },
        '& > span.name': {
            borderBottom: '3px solid #ffffff',
            paddingRight: '2px',
            width: 'calc(100% - 100px)',
            display: 'inline-block'
        },
        '& > span.name.active': {
            borderBottom: '3px solid #00ad31'
        }
    },
    listTotal: {
        color: '#34aa44',
        fontSize: '18px',
        fontWeight: 400,
        width: '250px',
        marginTop: 10
    },
    financingPriceContainer: {
        color: '#34aa44',
        fontSize: '18px',
        fontWeight: 400,
        width: '250px',
        marginTop: '10px'
    },
    financingPrice: {
        float: 'right',
        color: '#3e3f42',
        fontWeight: 500
    },
    listTotalValue: {
        float: 'right',
        color: '#3e3f42',
        fontWeight: 500
    },
    addButton: {
        float: 'right',
        top: '0px',
        right: '10px'
    },
    addTextStyle: {
        paddingTop: 2
    },
    detailsHead: {
        width: '100%',
        height: '50px'
    },
    detailsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        flex: 4,
        height: 450,
        boxSizing: 'border-box',
        padding: '10px 10px 10px 30px',
        marginLeft: '50px',
        overflow: 'scroll'
    },
    detailsTitle: {
        color: '#3e3f42',
        fontWeight: 500,
        fontSize: 20
    },
    noCosts: {
        width: '100%',
        textAlign: 'center',
        marginTop: '150px',
        color: '#6b6c6f',
        fontSize: 20
    },
    addIconStyle: {
        paddingRight: 5
    }
})

const todayValue = moment().format(CONSTANTS.INPUT_TYPE_DATE_FORMAT)

const cascoFields = [
    { value: '', autoFocus: true, type: 'numberFormat', label: 'Valoare', name: 'value', breakline: true },
    { value: todayValue, type: 'date', label: 'Data incepere', name: 'validFromDate' },
    { value: todayValue, type: 'date', label: 'Data expirare', name: 'expirationDate' }
]

const commissionFields = [
    { value: '', autoFocus: true, type: 'numberFormat', label: 'Valoare', name: 'value' },
    { value: '', type: 'text', label: 'Comentariu', name: 'note' }
]

const registrationFields = [
    { value: '', autoFocus: true, type: 'numberFormat', label: 'Valoare', name: 'value' },
    // { value: todayValue, type: 'date', label: 'Data expirare', name: 'expirationDate' }
]

const transportFields = [
    { value: '', autoFocus: true, type: 'numberFormat', label: 'Valoare', name: 'value' },
    { value: '', type: 'text', label: 'Comentariu', name: 'note' }
]

const pdiFields = [
    { value: '', autoFocus: true, type: 'numberFormat', label: 'Valoare', name: 'value' },
    { value: '', type: 'text', label: 'Comentariu', name: 'note' }
]

const taxationField = [
    { value: '', autoFocus: true, type: 'numberFormat', label: 'Valoare', name: 'value' },
    { value: '', type: 'text', label: 'Comentariu', name: 'note' }
]

const thirdPartyFields = [
    { value: '', autoFocus: true, type: 'numberFormat', label: 'Valoare', name: 'value' },
    { value: '', type: 'text', label: 'Comentariu', name: 'note' }
]

const vignetteFields = [
    { value: '', autoFocus: true, type: 'numberFormat', label: 'Valoare', name: 'value' },
    { value: todayValue, type: 'date', label: 'Data incepere', name: 'validFromDate' },
    { value: todayValue, type: 'date', label: 'Data expirare', name: 'expirationDate' }
]

const rcaFields = [
    { value: '', autoFocus: true, type: 'numberFormat', label: 'Valoare', name: 'value' },
    { value: todayValue, type: 'date', label: 'Data incepere', name: 'validFromDate' },
    { value: todayValue, type: 'date', label: 'Data expirare', name: 'expirationDate' }
]

const fuelFields = [
    { value: '', autoFocus: true, type: 'numberFormat', label: 'Valoare', name: 'value' },
    { value: '', type: 'text', label: 'Comentariu', name: 'note' }
]

//ITP
const prtFields = [
    { value: '', autoFocus: true, type: 'numberFormat', label: 'Valoare', name: 'value' },
    { value: todayValue, type: 'date', label: 'Data incepere', name: 'validFromDate' },
    { value: todayValue, type: 'date', label: 'Data expirare', name: 'expirationDate' }
]

//ACCESORII ULTERIOARE
const subsequentAccesoryFields = [
    { value: '', autoFocus: true, type: 'numberFormat', label: 'Valoare', name: 'value' },
    { value: '', type: 'text', label: 'Comentariu', name: 'note' }
]


class LeasingCostTabDetails extends Component {

    elementToDelete = {}

    fieldsToDisplay = [
        { field: CONSTANTS.LEASING_COST_DETAILS_FIELDS.RCA, name: this.props.language.lCost.rca, db: 'rcas', total: 'rcaPrice' },
        { field: CONSTANTS.LEASING_COST_DETAILS_FIELDS.FUEL, name: this.props.language.lCost.fuel, db: 'fuel', total: 'fuelPrice' },
        { field: CONSTANTS.LEASING_COST_DETAILS_FIELDS.CASCO, name: this.props.language.lCost.casco, db: 'cascos', total: 'cascoPrice' },
        { field: CONSTANTS.LEASING_COST_DETAILS_FIELDS.COMMISSION, name: this.props.language.lCost.commission, db: 'commissions', total: 'commissionPrice' },
        { field: CONSTANTS.LEASING_COST_DETAILS_FIELDS.PDI, name: this.props.language.lCost.pdi, db: 'pdis', total: 'pdiPrice' },
        { field: CONSTANTS.LEASING_COST_DETAILS_FIELDS.REGISTRATION, name: this.props.language.lCost.registration, db: 'registrations', total: 'registrationPrice' },
        { field: CONSTANTS.LEASING_COST_DETAILS_FIELDS.TAXATION, name: this.props.language.lCost.taxation, db: 'taxations', total: 'taxationPrice' },
        { field: CONSTANTS.LEASING_COST_DETAILS_FIELDS.TRANSPORT, name: this.props.language.lCost.transport, db: 'transports', total: 'transportPrice' },
        { field: CONSTANTS.LEASING_COST_DETAILS_FIELDS.VIGNETTE, name: this.props.language.lCost.vignette, db: 'vignettes', total: 'vignettePrice' },
        { field: CONSTANTS.LEASING_COST_DETAILS_FIELDS.PRT, name: this.props.language.lCost.prt, db: 'prts', total: 'prtPrice' },
        { field: CONSTANTS.LEASING_COST_DETAILS_FIELDS.THIRD, name: this.props.language.lCost.third, db: 'thirdParties', total: 'thirdPartyPrice' },
        { field: CONSTANTS.LEASING_COST_DETAILS_FIELDS.SUBSEQUENT_ACCESSORIES, name: this.props.language.lCost.subsequentAccessory, db: 'subsequentAccessories', total: 'subsequentAccessoryPrice' },
    ]

    state = {
        page: 0,
        rowsPerPage: 2,
        openConfirmationModal: false,
        renderPage: false,
        currentStock: null,
        modalFields: cascoFields,
        openCreateEditModal: false,
        modalType: CONSTANTS.CREATE,
        selectedOption: CONSTANTS.LEASING_COST_OPTIONS.CASCO,
        currentCost: this.fieldsToDisplay[0]
    }

    
    componentDidMount() {
        this.getStock(this.props.fullStock.id)
    }

    getStock = stockId => {
        this.props.getStockById(stockId).then(stock => {
            this.setState({ currentStock: stock, renderPage: true })
        })
    }

    changeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value })
    }

    changePage = (event, page) => {
        this.setState({ page })
    }

    setCurrentStockHandler = () => {
        this.props.getStockById(this.state.currentStock.id).then(stock => {
            this.setState({ currentStock: stock, openCreateEditModal: false })
        })
    }

    createCostHandler = () => {
        let costToCreate = {
            stockId: this.props.fullStock.id
        }

        this.props.createCost(costToCreate).then(() => {
            this.props.getStockById(this.state.currentStock.id).then(stock => {
                this.setState({ currentStock: stock })
            })
        })
    }

    changeCostHandler = cost => {
        let findItemIndex = this.fieldsToDisplay.findIndex(item => item.db === cost)

        if (findItemIndex > -1) {
            this.setState({ currentCost: this.fieldsToDisplay[findItemIndex] })
        }
    }

    onOpenModalHandler = option => {
        if (option === CONSTANTS.LEASING_COST_DETAILS_FIELDS.FUEL) this.setState({ openCreateEditModal: true, modalType: CONSTANTS.CREATE, modalFields: fuelFields, selectedOption: CONSTANTS.LEASING_COST_OPTIONS.FUEL })
        if (option === CONSTANTS.LEASING_COST_DETAILS_FIELDS.TRANSPORT) this.setState({ openCreateEditModal: true, modalType: CONSTANTS.CREATE, modalFields: transportFields, selectedOption: CONSTANTS.LEASING_COST_OPTIONS.TRANSPORT })
        if (option === CONSTANTS.LEASING_COST_DETAILS_FIELDS.CASCO) this.setState({ openCreateEditModal: true, modalType: CONSTANTS.CREATE, modalFields: cascoFields, selectedOption: CONSTANTS.LEASING_COST_OPTIONS.CASCO })
        if (option === CONSTANTS.LEASING_COST_DETAILS_FIELDS.VIGNETTE) this.setState({ openCreateEditModal: true, modalType: CONSTANTS.CREATE, modalFields: vignetteFields, selectedOption: CONSTANTS.LEASING_COST_OPTIONS.VIGNIETTE })
        if (option === CONSTANTS.LEASING_COST_DETAILS_FIELDS.RCA) this.setState({ openCreateEditModal: true, modalType: CONSTANTS.CREATE, modalFields: rcaFields, selectedOption: CONSTANTS.LEASING_COST_OPTIONS.RCA })
        if (option === CONSTANTS.LEASING_COST_DETAILS_FIELDS.REGISTRATION) this.setState({ openCreateEditModal: true, modalType: CONSTANTS.CREATE, modalFields: registrationFields, selectedOption: CONSTANTS.LEASING_COST_OPTIONS.REGISTRATION })
        if (option === CONSTANTS.LEASING_COST_DETAILS_FIELDS.TAXATION) this.setState({ openCreateEditModal: true, modalType: CONSTANTS.CREATE, modalFields: taxationField, selectedOption: CONSTANTS.LEASING_COST_OPTIONS.TAXATION })
        if (option === CONSTANTS.LEASING_COST_DETAILS_FIELDS.COMMISSION) this.setState({ openCreateEditModal: true, modalType: CONSTANTS.CREATE, modalFields: commissionFields, selectedOption: CONSTANTS.LEASING_COST_OPTIONS.COMMISSION })
        if (option === CONSTANTS.LEASING_COST_DETAILS_FIELDS.THIRD) this.setState({ openCreateEditModal: true, modalType: CONSTANTS.CREATE, modalFields: thirdPartyFields, selectedOption: CONSTANTS.LEASING_COST_OPTIONS.THIRDPARTY })
        if (option === CONSTANTS.LEASING_COST_DETAILS_FIELDS.PDI) this.setState({ openCreateEditModal: true, modalType: CONSTANTS.CREATE, modalFields: pdiFields, selectedOption: CONSTANTS.LEASING_COST_OPTIONS.PDI })
        if (option === CONSTANTS.LEASING_COST_DETAILS_FIELDS.PRT) this.setState({ openCreateEditModal: true, modalType: CONSTANTS.CREATE, modalFields: prtFields, selectedOption: CONSTANTS.LEASING_COST_OPTIONS.PRT })
        if (option === CONSTANTS.LEASING_COST_DETAILS_FIELDS.SUBSEQUENT_ACCESSORIES) this.setState({ openCreateEditModal: true, modalType: CONSTANTS.CREATE, modalFields: subsequentAccesoryFields, selectedOption: CONSTANTS.LEASING_COST_OPTIONS.SUBSEQUENT_ACCESSORY })
    }

    onCreateHandler = fields => {
        if (this.state.selectedOption === CONSTANTS.LEASING_COST_OPTIONS.FUEL) this.props.addFuel(fields, this.state.currentStock.costId).then(() => this.setCurrentStockHandler())
        if (this.state.selectedOption === CONSTANTS.LEASING_COST_OPTIONS.TRANSPORT) this.props.addTransport(fields, this.state.currentStock.costId).then(() => this.setCurrentStockHandler())
        if (this.state.selectedOption === CONSTANTS.LEASING_COST_OPTIONS.CASCO) this.props.addCasco(fields, this.state.currentStock.costId).then(() => this.setCurrentStockHandler())
        if (this.state.selectedOption === CONSTANTS.LEASING_COST_OPTIONS.VIGNIETTE) this.props.addVigniette(fields, this.state.currentStock.costId).then(() => this.setCurrentStockHandler())
        if (this.state.selectedOption === CONSTANTS.LEASING_COST_OPTIONS.RCA) this.props.addRca(fields, this.state.currentStock.costId).then(() => this.setCurrentStockHandler())
        if (this.state.selectedOption === CONSTANTS.LEASING_COST_OPTIONS.REGISTRATION) this.props.addRegistration(fields, this.state.currentStock.costId).then(() => this.setCurrentStockHandler())
        if (this.state.selectedOption === CONSTANTS.LEASING_COST_OPTIONS.TAXATION) this.props.addTaxation(fields, this.state.currentStock.costId).then(() => this.setCurrentStockHandler())
        if (this.state.selectedOption === CONSTANTS.LEASING_COST_OPTIONS.COMMISSION) this.props.addCommission(fields, this.state.currentStock.costId).then(() => this.setCurrentStockHandler())
        if (this.state.selectedOption === CONSTANTS.LEASING_COST_OPTIONS.THIRDPARTY) this.props.addThirdParty(fields, this.state.currentStock.costId).then(() => this.setCurrentStockHandler())
        if (this.state.selectedOption === CONSTANTS.LEASING_COST_OPTIONS.PDI) this.props.addPdi(fields, this.state.currentStock.costId).then(() => this.setCurrentStockHandler())
        if (this.state.selectedOption === CONSTANTS.LEASING_COST_OPTIONS.PRT) this.props.addPrt(fields, this.state.currentStock.costId).then(() => this.setCurrentStockHandler())
        if (this.state.selectedOption === CONSTANTS.LEASING_COST_OPTIONS.SUBSEQUENT_ACCESSORY) this.props.addSubsequentAccessory(fields, this.state.currentStock.costId).then(() => this.setCurrentStockHandler())
    }

    closeConfirmationModalHandler = () => this.setState({ openConfirmationModal: false })

    deleteElementHandler = () => {
        if (this.elementToDelete.field === CONSTANTS.LEASING_COST_DETAILS_FIELDS.FUEL) this.props.deleteFuel(this.elementToDelete.id, this.state.currentStock.costId).then(() => this.props.getStockById(this.props.fullStock.id).then(stock => {
            this.setState({ currentStock: stock, openConfirmationModal: false })
        }))
        if (this.elementToDelete.field === CONSTANTS.LEASING_COST_DETAILS_FIELDS.TRANSPORT) this.props.deleteTransport(this.elementToDelete.id, this.state.currentStock.costId).then(() => this.props.getStockById(this.props.fullStock.id).then(stock => {
            this.setState({ currentStock: stock, openConfirmationModal: false })
        }))
        if (this.elementToDelete.field === CONSTANTS.LEASING_COST_DETAILS_FIELDS.CASCO) this.props.deleteCasco(this.elementToDelete.id, this.state.currentStock.costId).then(() => this.props.getStockById(this.props.fullStock.id).then(stock => {
            this.setState({ currentStock: stock, openConfirmationModal: false })
        }))
        if (this.elementToDelete.field === CONSTANTS.LEASING_COST_DETAILS_FIELDS.VIGNETTE) this.props.deleteVignette(this.elementToDelete.id, this.state.currentStock.costId).then(() => this.props.getStockById(this.props.fullStock.id).then(stock => {
            this.setState({ currentStock: stock, openConfirmationModal: false })
        }))
        if (this.elementToDelete.field === CONSTANTS.LEASING_COST_DETAILS_FIELDS.RCA) this.props.deleteRca(this.elementToDelete.id, this.state.currentStock.costId).then(() => this.props.getStockById(this.props.fullStock.id).then(stock => {
            this.setState({ currentStock: stock, openConfirmationModal: false })
        }))
        if (this.elementToDelete.field === CONSTANTS.LEASING_COST_DETAILS_FIELDS.REGISTRATION) this.props.deleteRegistration(this.elementToDelete.id, this.state.currentStock.costId).then(() => this.props.getStockById(this.props.fullStock.id).then(stock => {
            this.setState({ currentStock: stock, openConfirmationModal: false })
        }))
        if (this.elementToDelete.field === CONSTANTS.LEASING_COST_DETAILS_FIELDS.TAXATION) this.props.deleteTaxation(this.elementToDelete.id, this.state.currentStock.costId).then(() => this.props.getStockById(this.props.fullStock.id).then(stock => {
            this.setState({ currentStock: stock, openConfirmationModal: false })
        }))
        if (this.elementToDelete.field === CONSTANTS.LEASING_COST_DETAILS_FIELDS.COMMISSION) this.props.deleteCommission(this.elementToDelete.id, this.state.currentStock.costId).then(() => this.props.getStockById(this.props.fullStock.id).then(stock => {
            this.setState({ currentStock: stock, openConfirmationModal: false })
        }))
        if (this.elementToDelete.field === CONSTANTS.LEASING_COST_DETAILS_FIELDS.THIRD) this.props.deleteThirdParty(this.elementToDelete.id, this.state.currentStock.costId).then(() => this.props.getStockById(this.props.fullStock.id).then(stock => {
            this.setState({ currentStock: stock, openConfirmationModal: false })
        }))
        if (this.elementToDelete.field === CONSTANTS.LEASING_COST_DETAILS_FIELDS.PDI) this.props.deletePdi(this.elementToDelete.id, this.state.currentStock.costId).then(() => this.props.getStockById(this.props.fullStock.id).then(stock => {
            this.setState({ currentStock: stock, openConfirmationModal: false })
        }))
        if (this.elementToDelete.field === CONSTANTS.LEASING_COST_DETAILS_FIELDS.PRT) this.props.deletePrt(this.elementToDelete.id, this.state.currentStock.costId).then(() => this.props.getStockById(this.props.fullStock.id).then(stock => {
            this.setState({ currentStock: stock, openConfirmationModal: false })
        }))
        if (this.elementToDelete.field === CONSTANTS.LEASING_COST_DETAILS_FIELDS.SUBSEQUENT_ACCESSORIES) this.props.deleteSubsequentAccessory(this.elementToDelete.id, this.state.currentStock.costId).then(() => this.props.getStockById(this.props.fullStock.id).then(stock => {
            this.setState({ currentStock: stock, openConfirmationModal: false })
        }))
    }

    onDelete = item => {
        this.elementToDelete = { ...item }
        this.setState({ openConfirmationModal: true })
    }

    render() {
        const { classes } = this.props
        const i18n = this.props.language

        if (this.state.renderPage) {
            return (
                <>
                    <ConfirmationModal
                        text={`${this.props.language.delete} ${this.elementToDelete.id} ?`}
                        cancelButtonText={this.props.language.cancel}
                        acceptButtonText={this.props.language.confirm}
                        open={this.state.openConfirmationModal}
                        onClose={this.closeConfirmationModalHandler}
                        onCancel={this.closeConfirmationModalHandler}
                        onAccept={() => this.deleteElementHandler()}>
                    </ConfirmationModal>
                    <FormModal
                        fields={this.state.modalFields}
                        key={this.state.openCreateEditModal}
                        open={this.state.openCreateEditModal}
                        onCancel={() => this.setState({ openCreateEditModal: false })}
                        onSave={data => this.state.modalType === CONSTANTS.CREATE ? this.onCreateHandler(data.fields) : null}
                        dialogTitle={this.state.modalType === CONSTANTS.CREATE ? `${this.props.language.add} ${this.props.language.tables[this.state.selectedOption].toUpperCase()}` : ""}
                        cancelButtonText={this.props.language.cancel}
                        submitButtonText={this.state.modalType === CONSTANTS.CREATE ? this.props.language.add : ""}
                        type={this.state.modalType}
                        validate={true}
                        canSubmit={this.state.canSubmit}
                        maxWidth="xs"
                    />
                    <div className={classes.detailsWrapper}>
                        <ul className={classes.leftList}>
                            {this.fieldsToDisplay.map((field, index) => {
                                return (
                                    <li key={index} onClick={() => this.changeCostHandler(field.db)} className={classes.listItem}>
                                        <span className={`name ${field.db === this.state.currentCost.db ? 'active' : ''}`}>{field.name}</span>
                                        <span className='total'>{formatNumber(this.state.currentStock.cost[field.total])}</span>
                                    </li>
                                )
                            })}
                            <div className={classes.financingPriceContainer}>
                                {i18n.financingTotal}
                                <span className={classes.financingPrice}>{formatNumber(this.state.currentStock.financingCost.total)}</span>
                            </div>
                            <div className={classes.financingPriceContainer}>
                                {i18n.total}
                                <span className={classes.financingPrice}>{formatNumber(Number(this.state.currentStock.cost.totalPrice))} </span>
                            </div>
                        </ul>
                        <div className={classes.detailsContainer}>
                            {this.state.currentStock && <>
                                <div className={classes.detailsHead}>
                                    <Button color="secondary" onClick={() => this.onOpenModalHandler(this.state.currentCost.field)} className={classes.addButton}>
                                        <AddIcon className={this.props.classes.addIconStyle} />
                                        <span className={this.props.classes.addTextStyle}>{i18n.add}</span>
                                    </Button>
                                    <h3 className={classes.detailsTitle}>{i18n.costs} {this.state.currentCost.name}</h3>
                                </div>
                                {this.state.currentStock.cost[this.state.currentCost.db].length ? <Timeline
                                    onChange={itemName => this.changeCostHandler(itemName)}
                                    isCost={true}
                                    onDelete={this.onDelete}
                                    items={this.state.currentStock.cost[this.state.currentCost.db].map(cost => ({
                                        ...cost,
                                        add: true,
                                        author: cost.user.fullName,
                                        category: this.state.currentCost.name,
                                        type: this.state.currentCost.db,
                                        field: this.state.currentCost.field
                                    }))}
                                /> : <div className={classes.noCosts}>
                                        {i18n.noCosts}
                                    </div>}
                            </>}
                        </div>
                    </div>
                </>)
        }
        else return null

    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails,
})

const mapDispatchToProps = dispatch => ({
    createCost: cost => dispatch(COSTS.create(cost)),
    addFuel: (fuel, costId) => dispatch(COSTS.addFuel(fuel, costId)),
    addRca: (rca, costId) => dispatch(COSTS.addRca(rca, costId)),
    addCasco: (casco, costId) => dispatch(COSTS.addCasco(casco, costId)),
    addCommission: (commission, costId) => dispatch(COSTS.addCommission(commission, costId)),
    addSubsequentAccessory: (subsequentAccesories, costId) => dispatch(COSTS.addSubsequentAccessory(subsequentAccesories, costId)),
    addRegistration: (registration, costId) => dispatch(COSTS.addRegistration(registration, costId)),
    addTransport: (transport, costId) => dispatch(COSTS.addTransport(transport, costId)),
    addPdi: (pdi, costId) => dispatch(COSTS.addPdi(pdi, costId)),
    addPrt: (prt, costId) => dispatch(COSTS.addPrt(prt, costId)),
    addTaxation: (taxation, costId) => dispatch(COSTS.addTaxation(taxation, costId)),
    addVigniette: (vigniette, costId) => dispatch(COSTS.addVigniette(vigniette, costId)),
    addThirdParty: (thirdParty, costId) => dispatch(COSTS.addThirdParty(thirdParty, costId)),
    deleteFuel: (fuelId, costId) => dispatch(COSTS.deleteFuel(fuelId, costId)),
    deleteCasco: (cascoId, costId) => dispatch(COSTS.deleteCasco(cascoId, costId)),
    deleteRca: (id, costId) => dispatch(COSTS.deleteRca(id, costId)),
    deleteCommission: (id, costId) => dispatch(COSTS.deleteCommission(id, costId)),
    deleteRegistration: (id, costId) => dispatch(COSTS.deleteRegistration(id, costId)),
    deleteSubsequentAccessory: (id, costId) => dispatch(COSTS.deleteSubsequentAccessory(id, costId)),
    deleteTransport: (id, costId) => dispatch(COSTS.deleteTransport(id, costId)),
    deletePdi: (id, costId) => dispatch(COSTS.deletePdi(id, costId)),
    deletePrt: (id, costId) => dispatch(COSTS.deletePrt(id, costId)),
    deleteTaxation: (id, costId) => dispatch(COSTS.deleteTaxation(id, costId)),
    deleteVignette: (id, costId) => dispatch(COSTS.deleteVignette(id, costId)),
    deleteThirdParty: (id, costId) => dispatch(COSTS.deleteThirdParty(id, costId)),
    getStockById: stockId => dispatch(STOCKS.getById(stockId))
})
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LeasingCostTabDetails))
