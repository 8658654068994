import React, { Component } from 'react'
import { withStyles, GridList, GridListTile, GridListTileBar } from '@material-ui/core'
import { Close as DeleteIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import Gallery from './Gallery'
import ConfirmationDialog from '../common/ConfirmationDialog'
import * as CONSTANTS from '../../utils/constants'
import { deleteImageFromStock } from '../../redux/actions/stocks'

const styles = theme => ({
    imgStyle: {
        padding: '10px',
        maxHeight: '250px',
        maxWidth: '300px',
        margin: 'auto',
        boxSizing: 'border-box'
    },
    photoGalery: {
        padding: '0px 10px 0px 10px',
        boxSizing: 'border-box',
        height: '90px',
        overflow: 'auto',
        overflowY: 'auto'
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        height: '250px',
        cursor: 'pointer'
    },
    gridList: {
        padding: 5,
    },
    imageWrapper: {
        width: 'auto'
    },
    gridImg: {
        border: '1px solid transparent',
        maxWidth: 80,
        cursor: 'pointer',
        '&:hover': {
            border: '1px solid #e0e0e0'
        },
        '&:hover div.delete': {
            display: 'block !important'
        }
    },
    tileBar: {
        height: '17px',
        width: '17px',
        padding: '0px',
        display: 'block'
    },
    deleteIcon: {
        color: '#ffffff',
        fontSize: '15px',
        margin: '1px'
    }
})

class MiniGallery extends Component {
    state = {
        currentImage: this.props.images.length ? `${CONSTANTS.STOCK_IMAGE_URL}${this.props.images[0].fileName}` : 'https://via.placeholder.com/250x200',
        openGallery: false,
        openConfirmationModal: false
    }

    imageToDelete = null

    changeImage = img => this.setState({ currentImage: `${CONSTANTS.STOCK_IMAGE_URL}${img}` })

    onOpenImageConfirmation = id => {
        this.setState({ openConfirmationModal: true })
        this.imageToDelete = id
    }

    onDeleteImage = (stockId, imageId) => {
        this.props.deleteImage(stockId, imageId).then(() => {
            this.setState({ openConfirmationModal: false })
            this.props.reloadImages()
        })
    }

    render() {
        const { classes, i18n } = this.props
        return <div className={classes.imageWrapper}>
            <ConfirmationDialog
                acceptButtonText={i18n.delete}
                cancelButtonText={i18n.cancel}
                text={i18n.deleteConfirmation}
                open={this.state.openConfirmationModal}
                onAccept={() => this.onDeleteImage(this.props.stockId, this.imageToDelete)}
                onCancel={() => this.setState({ openConfirmationModal: false })}
                onClose={() => this.setState({ openConfirmationModal: false })}
            />
            {this.props.displayMainPhoto && <><Gallery
                images={this.props.images.map(img => ({ src: `${CONSTANTS.STOCK_IMAGE_URL}${img.fileName}` }))}
                open={this.state.openGallery}
                onClose={() => this.setState({ openGallery: false })}
            />
                <div className={classes.imageContainer} onClick={() => this.setState({ openGallery: true })}>
                    <img alt="" className={classes.imgStyle} src={this.state.currentImage} />
                </div></>}
            <div className={classes.photoGalery}>
                <GridList cellHeight={80} className={classes.gridList} cols={4}>
                    {this.props.images.map(carImage => (
                        <GridListTile onClick={() => this.props.isForOffer ? this.props.onImageClick(carImage.fileName) : {}} className={classes.gridImg} key={carImage.path} cols={carImage.cols || 1}>
                            <img src={`${CONSTANTS.STOCK_IMAGE_URL}${carImage.fileName}`} alt="car" onClick={() => this.changeImage(carImage.fileName)} />
                            <GridListTileBar
                                actionIcon={!this.props.isForOffer ? <DeleteIcon onClick={() => this.onOpenImageConfirmation(carImage.id)} className={classes.deleteIcon} /> : null}
                                titlePosition="top"
                                className={classes.tileBar}
                            />
                        </GridListTile>
                    ))}
                </GridList>
            </div>
        </div>
    }
}

const mapDispatchToProps = dispatch => ({
    deleteImage: (stockId, imageId) => dispatch(deleteImageFromStock(stockId, imageId))
})

const mapStateToProps = reducers => ({
    i18n: reducers.languageReducer.i18n.gallery
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MiniGallery))