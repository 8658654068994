import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tooltip, withStyles, Chip} from '@material-ui/core'
import { formatNumber } from '../../../utils/helpers'
import moment from 'moment'

import * as REPORTS from '../../../redux/actions/reports'
import * as PROVIDERS from '../../../redux/actions/providers'
import * as LEASINGS from '../../../redux/actions/leasing'
import * as CARS from '../../../redux/actions/cars'
import * as CLIENTS from '../../../redux/actions/clients'
import * as CONSTANTS from '../../../utils/constants'
import Header from '../../common/SearchableHeader'

import SimpleModal from '../../common/SimpleModal'
import SimpleList from '../../common/SimpleList'
import InputGenerator from '../../common/subComponents/InputGenerator'

const styles = theme => ({
    subtitle: {
        fontSize: '36px',
        fontWeight: '500',
        paddingTop: '20px',
        color: theme.palette.primaryColor,
        display: 'block',
        width: '350px'
    },
    modalContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    displayRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    listContainer: {
        padding: 24,
        overflow: 'auto'
    },
    addIcon: {
        paddingRight: 5
    },
    withoutSidebar: {
        padding: '24px 36px 24px 0px'
    },
    equalFlex: {
        flex: 1
    },
    inputPadding: {
        padding: 8
    },
    legend: {
        width: 'auto',
        marginLeft: 'auto',
        marginTop: '15px',
        color: 'rgba(0, 0, 0, 0.87)',
        '& span': {
            display: 'inline-block',
            marginLeft: '20px',
            marginRight: '5px',
            position: 'relative',
        }
    },
    chips: {
        margin: '5px !important'
    },
    modelWrapper: {
        display: 'inline-block'
    },
    colorLegend: {
        width: 'auto',
        marginLeft: 'auto',
        marginTop: '15px',
        color: 'rgba(0, 0, 0, 0.87)',
        float: "right !important",
        '& span': {
            width: '15px',
            height: '15px',
            display: 'inline-block',
            marginLeft: '20px',
            marginRight: '5px',
            position: 'relative',
            bottom: '-1px',
            borderRadius: '50%'
        }
    },
    withProfit: {
        backgroundColor: 'rgb(52, 170, 68)',
    },
    withoutProfit: {
        backgroundColor: 'rgb(227, 51, 113)',
    }
})

class Reports extends Component {

    initialFields = [
        { type: 'date', name: 'start', label: this.props.language.labels.start, value: '' },
        { type: 'date', name: 'end', label: this.props.language.labels.end, value: '' },
        { type: 'dropdownSelector', name: 'provider', utils: this.props.language.labels.provider, value: '', options: [] },
        { type: 'dropdownSelector', name: 'financer', utils: this.props.language.labels.financer, value: '', options: [] },
        { type: 'dropdownSelector', name: 'client', utils: this.props.language.labels.client, value: '', options: [] },
        { type: 'dropdownSelector', name: 'brands', utils: this.props.language.labels.brands, value: '', options: [] },
        { type: 'dropdownSelector', name: 'models', utils: this.props.language.labels.models, value: '', options: [] },
    ]

    state = {
        reports: [],
        from: 0,
        limit: 10,
        rowsPerPage: 50,
        count: '',
        search: '',
        openFilterModal: false,
        filterData: {},
        modalFields: this.initialFields,
        saleProfit: 0,
        totalProfitEuro: 0,
        totalProfitRon: 0,
        legend: '',
        modelLabels: [],
    }

    componentDidMount() {
        this.getReports()
    }

    getReports = () => {
        return this.props.get({
            search: this.state.search || ''
        }, this.state.filterData).then(data => {
            this.computeTotalProfit(data.reports)
            let jsonMap = {}
            this.state.modalFields.forEach(field => {
                if(field.value)
                    if(field.name === 'models')
                        jsonMap[field.name] = this.state.modelLabels    
                jsonMap[field.name] = field
            })
            let tempLegend = ''
            if(Object.keys(this.state.filterData).length){
                tempLegend = (
                    <div className={this.props.classes.legend}>
                        <table>
                            <tr>
                                <th>{ jsonMap.start.value ? (<span>{ this.props.language.labels.start }</span>) : null }</th>
                                <th>{ jsonMap.end.value ? (<span>{ this.props.language.labels.end }</span>) : null }</th>
                                <th>{ jsonMap.start || jsonMap.end ? (<span>{ this.props.language.filters.totalSales }</span>) : null }</th>
                                <th>{ jsonMap.start || jsonMap.end ? (<span>{ this.props.language.filters.totalProfit } {CONSTANTS.EURO_SYMBOL}:</span>) : null }</th>
                                <th>{ jsonMap.start || jsonMap.end ? (<span>{ this.props.language.filters.totalProfitRON }</span>) : null }</th>
                            </tr>
                            <tr>
                                <td>
                                    { jsonMap.start.value ? (<span>{moment(jsonMap.start.value).format("DD/MM/YYYY")}</span>) : null }
                                </td>
                                <td>
                                    { jsonMap.end.value ? (<span>{moment(jsonMap.end.value).format("DD/MM/YYYY")}</span>) : null }
                                </td>
                                <td>
                                    { jsonMap.start || jsonMap.end ? (<span>{this.state.saleProfit} {CONSTANTS.EURO_SYMBOL}</span>) : null }
                                </td>
                                <td>
                                    { jsonMap.start || jsonMap.end ? (<span>{this.state.totalProfitEuro} {CONSTANTS.EURO_SYMBOL}</span>) : null }
                                </td>
                                <td>
                                    { jsonMap.start || jsonMap.end ? (<span>{this.state.totalProfitRon}</span>) : null }
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            }
            this.setState({
                reports: data.reports,
                count: data.count,
                modalFields: this.initialFields,
                openFilterModal: false,
                legend: tempLegend
            })
            return Promise.resolve()
        })
    }

    setFilter = () => {
        let jsonMap = {}
        let currentModelLabels = this.state.modelLabels
        this.state.modalFields.forEach(field => {
            if(field.value || field.name === 'models'){
                if(field.name === 'models')
                    currentModelLabels.forEach(model => {
                        jsonMap[field.name] = currentModelLabels
                    })
                else jsonMap[field.name] = field
            }
        })
        this.setState({ filterData: jsonMap }, () => this.getReports())
    }

    InputWrapper = ({ input, keyProps, shrink, tooltipText }) => <div className={`${`${this.props.classes.equalFlex} ${this.props.classes.inputPadding}`}`}>
        <Tooltip placement="top" title={tooltipText || ''}><InputGenerator
            key={keyProps}
            fullWidth={true}
            InputLabelProps={shrink ? { shrink: true } : {}}
            margin="dense"
            {...input}
            onChange={event => this.onChangeHandler(event)}
        /></Tooltip>
    </div>

    onChangeHandler = async event => {
        let modalFieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))
        const currentIndex = modalFieldsCopy.findIndex(field => field.name === event.target.name)
        let tempModels = this.state.modelLabels
        if (currentIndex > -1) {
            modalFieldsCopy[currentIndex].value = event.target.value
            const modelsIndex = modalFieldsCopy.findIndex(field => field.name === 'models')
            if (event.target.name === 'brands'){
                if (event.target.value.length){
                    const associatedModels = await this.props.getModels(event.target.value)
                    const mappedModels = [{ name: '', value: false, label: '' }].concat(associatedModels.carModels.map(model => ({ name: model.id, label: model.name, value: false })))
                    modalFieldsCopy[modelsIndex].options = mappedModels
                    modalFieldsCopy[modelsIndex].value = ''
                }
                else
                    modalFieldsCopy[modelsIndex].options = []
                tempModels = []
            }
            if(event.target.name === 'models'){
                const modelValue = modalFieldsCopy[modelsIndex].value
                const modelLabelIndex = modalFieldsCopy[modelsIndex].options.findIndex(field => field.name == modelValue)
                const modelLabel = modalFieldsCopy[modelsIndex].options[modelLabelIndex].label
                if(tempModels.indexOf(modelLabel) === -1 && modelLabel)
                    tempModels.push(modelLabel)
            }
            this.setState({ modalFields: modalFieldsCopy, modelLabels: tempModels })
        }
    }

    handleCancelModal = () => {
        if(!Object.keys(this.state.filterData).length) 
            this.setState({ openFilterModal: false, modalFields: this.initialFields, modelLabels:[] })
        else this.setState({ openFilterModal: false, modalFields: this.state.modalFields, modalLabels: this.state.modelLabels })
    }

    handleDelete = (index) => {
        let remainingLabels = this.state.modelLabels.filter((element, indexOfElement) => indexOfElement !== index)
        this.setState({ modelLabels: remainingLabels })
    }

    renderModalFields = () => {
        const InputWrapper = this.InputWrapper
        let jsonMap = {}
        this.state.modalFields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <div className={this.props.classes.modalContainer}>
                <div className={this.props.classes.displayRow}>
                    <InputWrapper shrink={true} input={jsonMap['start']} key={'start'} />
                    <InputWrapper shrink={true} input={jsonMap['end']} key={'end'} />
                </div>
                <InputWrapper shrink={true} input={jsonMap['provider']} key={'provider'} />
                <InputWrapper shrink={true} input={jsonMap['financer']} key={'financer'} />
                <InputWrapper shrink={true} input={jsonMap['client']} key={'client'} />
                <InputWrapper shrink={true} input={jsonMap['brands']} key={'brands'} />
                {this.state.modelLabels ? 
                    (<div>{this.state.modelLabels.map((model, index) => (
                        <Chip className={this.props.classes.chips} label={model} variant='outlined' onDelete={ () => this.handleDelete(index) } />
                    ))}</div>) : null
                }
                <InputWrapper shrink={true} input={jsonMap['models']} key={'models'} />
            </div>
        )
    }

    populateOptions = () => {
        const getProviders = this.props.getProviders()
        const getLeasings = this.props.getLeasings()
        const getClients = this.props.getClients()
        const getBrands = this.props.getBrands()

        Promise.all([getProviders, getLeasings, getClients, getBrands]).then(async result => {

            const modalFieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))
            const mappedProviders = [{ name: '', value: false, label: '' }].concat(result[0].providers.map(provider => ({ name: provider.id, label: provider.name, value: false })))
            const mappedLeasings = [{ name: '', value: false, label: '' }].concat(result[1].rows.map(leasing => ({ name: leasing.id, label: leasing.name, value: false })))
            const mappedClients = [{ name: '', value: false, label: '' }].concat(result[2].clients.map(client => ({ name: client.id, label: client.name, value: false })))
            const mappedBrands = [{ name: '', value: false, label: '' }].concat(result[3].carBrands.map(brand => ({ name: brand.id, label: brand.name, value: false })))

            const clientIndex = modalFieldsCopy.findIndex(field => field.name === 'client')
            const providerIndex = modalFieldsCopy.findIndex(field => field.name === 'provider')
            const financerIndex = modalFieldsCopy.findIndex(field => field.name === 'financer')
            const brandsIndex = modalFieldsCopy.findIndex(field => field.name === 'brands')
            const modelsIndex = modalFieldsCopy.findIndex(field => field.name === 'models')

            const startDateIndex = modalFieldsCopy.findIndex(field => field.name === 'start')
            const endDateIndex = modalFieldsCopy.findIndex(field => field.name === 'end')

            if (startDateIndex > -1) {
                if (this.state.filterData.start)
                    modalFieldsCopy[startDateIndex].value = this.state.filterData.start.value
            }
            if (endDateIndex > -1) {
                if (this.state.filterData.end)
                    modalFieldsCopy[endDateIndex].value = this.state.filterData.end.value
            }
            if (clientIndex > -1) {
                modalFieldsCopy[clientIndex].options = mappedClients

                if (this.state.filterData.client)
                    modalFieldsCopy[clientIndex].value = this.state.filterData.client.value
            }
            if (providerIndex > -1) {
                modalFieldsCopy[providerIndex].options = mappedProviders

                if (this.state.filterData.provider)
                    modalFieldsCopy[providerIndex].value = this.state.filterData.provider.value
            }
            if (financerIndex > -1) {
                modalFieldsCopy[financerIndex].options = mappedLeasings

                if (this.state.filterData.financer)
                    modalFieldsCopy[financerIndex].value = this.state.filterData.financer.value
            }
            if (brandsIndex > -1) {
                modalFieldsCopy[brandsIndex].options = mappedBrands

                if (this.state.filterData.brands) {
                    modalFieldsCopy[brandsIndex].value = this.state.filterData.brands.value
                    const associatedModels = await this.props.getModels(this.state.filterData.brands.value)
                    const mappedModels = [{ name: '', value: false, label: '' }].concat(associatedModels.carModels.map(model => ({ name: model.id, label: model.name, value: false })))
                    modalFieldsCopy[modelsIndex].options = mappedModels
                    if (this.state.filterData.models) {
                        modalFieldsCopy[modelsIndex].value = this.state.filterData.models.value
                    }
                }
            }
            this.setState({ openFilterModal: true, modalFields: modalFieldsCopy })
        })
    }

    resetFilters = () => {
        if (window.confirm(this.props.language.utils.confirmReset)) 
            this.setState({ filterData: {}, modelLabels: [], legend: '' }, () => this.getReports())
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getReports())
    }

    computeTotalProfit(reports){
        let profitEUR = 0
        let profitRON = 0
        let totalSaleProfit = 0
        
        reports.map((obj, index) => {
            profitEUR = profitEUR + obj.offer.totalProfitEUR
            profitRON = profitRON + (obj.offer.carProfitEUR * obj.offer.stock.purchaseExchangeRate + (obj.offer.sellingPriceEUR * (obj.offer.sellingExchangeRate - obj.offer.stock.purchaseExchangeRate)) + (obj.offer.leasingEUR * obj.leasingExchangeRate))
            totalSaleProfit = totalSaleProfit + obj.offer.sellingPriceEUR    
        })
        this.setState({
            totalProfitEuro: formatNumber(profitEUR),
            totalProfitRon: formatNumber(profitRON),
            saleProfit: formatNumber(totalSaleProfit)
        })
    }

    render() {
        const { classes } = this.props
        return <>
            <div className={classes.header}>
                <Header
                    reportsFilter={this.state.filterData}
                    resetFilters={() => this.resetFilters()}
                    filterText={this.props.language.utils.filterText}
                    openFilterModal={() => this.populateOptions()}
                    title={this.props.language.title}
                    placeholder={this.props.language.searchPlaceholder}
                />
            </div>
            <div className={`${!this.props.sidebarReducer.displaySidebar ? this.props.classes.withoutSidebar : ""} ${this.props.classes.listContainer}`}>
                <SimpleList
                    items={this.state.reports}
                    headerFields={this.props.language.tableHeader}
                    selectedOption={CONSTANTS.REPORTS_OPTIONS}
                    disableBorders
                    onSelect={() => { }}
                    rowsPerPage={this.state.rowsPerPage}
                    count={this.state.count}
                    countTitle={this.props.language.countTitle}
                    changeRowsPerPage={this.changeRowsPerPageHandler}
                    changePageHandler={this.changePageHandler}
                    legend={this.state.legend}
                />
            <div className={this.props.classes.colorLegend}>
                <span className={ classes.withProfit }></span>{this.props.language.filters.profit}
                <span className={ classes.withoutProfit }></span>{this.props.language.filters.noProfit}
            </div>
                <SimpleModal
                    title={this.props.language.utils.modalTitle}
                    acceptButtonText={this.props.language.utils.accept}
                    cancelButtonText={this.props.language.utils.cancel}
                    onAccept={() => this.setFilter()}
                    onClose={() => this.setState({ openFilterModal: false })}
                    onCancel={() => this.handleCancelModal()}
                    maxWidth={"sm"}
                    open={this.state.openFilterModal}>
                    {this.renderModalFields()}
                </SimpleModal>
            </div>
        </>
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.reports,
    sidebarReducer: reducers.sidebarReducer
})

const mapDispatchToProps = dispatch => ({
    get: (queryParams, filterData) => dispatch(REPORTS.get(queryParams, filterData)),
    getBrands: () => dispatch(CARS.brand.get()),
    getModels: brandId => dispatch(CARS.model.get(brandId)),
    getProviders: () => dispatch(PROVIDERS.get()),
    getClients: () => dispatch(CLIENTS.get()),
    getLeasings: () => dispatch(LEASINGS.get())
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Reports))