import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, TableRow, TableCell } from '@material-ui/core'

import DropdownMenu from '../../common/DropdownMenu'

const styles = theme => ({

})

class CarModelList extends Component {

    render() {
        return (
            <TableRow>
                <TableCell>{this.props.item.name}</TableCell>
                <TableCell>
                    {this.props.actions ? <DropdownMenu type={'ACTIONS'} item={this.props.item} items={this.props.actions} />
                        : null}
                </TableCell>

            </TableRow>
        )
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails
})

const mapDispatchToProps = dispatch => ({

})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CarModelList))