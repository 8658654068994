import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as OFFER from '../../../redux/actions/offers'
import * as CONSTANTS from '../../../utils/constants'
import SimpleList from '../../common/SimpleList'
import FileModal from '../../inWork/Files/FilesModal'


class OfferListSold extends Component {
    state = {
        offers: [],
        count: 0,
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        from: 0,
        offerId: null,
        openFileModal: false
    }

    componentDidMount() {
        this.getOffers()
    }


    getOffers = () => {
        this.props.get(this.props.stockId, { from: this.state.from, limit: this.state.rowsPerPage })
            .then(result => {
                this.setState({ offers: result.offers, count: result.count })
            })
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getOffers())
    }

    render() {
        return (<>
            <FileModal offerId={this.state.offerId} onClose={() => this.setState({ openFileModal: false })} open={this.state.openFileModal} />
            <SimpleList
                noHeader
                headerFields={this.props.headerFields}
                items={this.state.offers}
                actionsTitle={this.props.language.simpleTable.actionsTitle}
                disableBorders
                onSelect={(row) => {
                    this.setState({ offerId: row.id, openFileModal: true })
                }}
                selectedOption={CONSTANTS.OFFER_OPTIONS.SUMAR_OFFER}
                rowsPerPage={this.state.offersRowsPerPage}
                count={this.state.count}
                changePageHandler={this.changePageHandler} /></>)
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.stock,
    headerFields: state.languageReducer.i18n.itemDetails.offer.offerTabHeaderFields
})

const mapDispatchToProps = dispatch => ({
    get: (stockId, options) => dispatch(OFFER.getByStockId(stockId, options))
})

export default connect(mapStateToProps, mapDispatchToProps)(OfferListSold)