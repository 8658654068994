import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Button, Menu, MenuItem, ListItemText } from '@material-ui/core'
import { UnfoldMore as UnfoldMoreIcon } from '@material-ui/icons'

import * as CONSTANTS from '../../utils/constants'

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        height: 10,
        textAlign: 'center',
        borderRight: '1px solid #d8dce6',
        '&:hover': {
            backgroundColor: '#eee',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            }
        },
        '&:focus': {
            backgroundColor: 'white'
        },
    }
}))(MenuItem);

const style = theme => ({
    buttonContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    buttonClass: {
        backgroundColor: 'white',
        padding: '6px 8px',
        color: '#3e3f42',
        border: '1px solid #d8dce6',
        '&:hover': {
            backgroundColor: '#ccc',
            color: 'black'
        }
    },
    buttonIcon: {
        fontSize: 14,
        float: 'right',
        paddingLeft: 5
    },
    eyeIcon: {
        paddingRight: 5
    },
    itemText: {
        fontSize: 14,
        textTransform: 'uppercase'
    },
    oval: {
        width: 10,
        height: 10,
        borderRadius: 5,
    },
    colorYellow: {
        backgroundColor: '#ffe224'
    },
    colorOrange: {
        backgroundColor: '#eda20c'
    },
    colorBlue: {
        backgroundColor: '#86afec'
    },
    colorRed: {
        backgroundColor: 'red'
    },
    colorGreen: {
        backgroundColor: 'green'
    },
    colorPurple: {
        backgroundColor: 'purple'
    },
    currentStatus: {
        display: 'block',
        width: '80px',
        height: '20px',
        overflow: 'hidden'
    },
    withoutOutline: {
        outlineColor: 'white'
    }
})

class DropdownSelector extends React.Component {

    state = {
        anchorEl: null,
        selectedItem: this.props.currentStatus
    }



    renderOvalHandler = () => {
        if (!this.props.currentStatus) return null
        switch (this.props.currentStatus) {
            // case CONSTANTS.STOCK_STATUS_LABELS.IN_MANUFACTURING:
            //     return this.props.classes.colorYellow
            case CONSTANTS.STOCK_STATUS_LABELS.ORDER_MANUFACTURING:
                return this.props.classes.colorOrange
            case CONSTANTS.STOCK_STATUS_LABELS.SOLD:
                return this.props.classes.colorBlue
            case CONSTANTS.STOCK_STATUS_LABELS.PROVIDER_STOCK:
                return this.props.classes.colorGreen
            case CONSTANTS.STOCK_STATUS_LABELS.RESERVED:
                return this.props.classes.colorPurple
            case CONSTANTS.STOCK_STATUS_LABELS.RENTED:
                return this.props.classes.colorRed
            default:
                return this.props.classes.colorGreen
        }
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    clickStatusHandler = newStatus => {
        this.props.onEditHandler(newStatus).then(() => {
            this.handleClose()
            this.setState({ selectedItem: newStatus })
        })
    }

    render() {
        return (
            <div>
                <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    id='dropdownMenu'
                    variant="contained"
                    color="primary"
                    onClick={this.handleClick}
                    className={this.props.classes.buttonClass}

                >
                    {this.props.type === 'ACTIONS' ?
                        <div className={this.props.classes.buttonContent}>
                            <span>Actiuni</span>
                            <UnfoldMoreIcon className={this.props.classes.buttonIcon} />
                        </div>
                        : <div className={this.props.classes.buttonContent}>
                            <span className={`${this.props.classes.oval} ${this.renderOvalHandler()}`} />
                            <span className={this.props.classes.currentStatus}>{this.props.language.stock.statusLabel[this.props.currentStatus]}</span>
                            <UnfoldMoreIcon className={this.props.classes.buttonIcon} />
                        </div>}
                </Button>
                <StyledMenu
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                >
                    {this.props.type === 'ACTIONS' && this.props.items.map((action, index) => {
                        if ((action.deleteIf && !action.deleteIf(this.props.item)) || !action.deleteIf) {
                            return (
                                <div key={index} className={this.props.classes.withoutOutline} style={{
                                    borderBottom: action.withoutBorder ? '' : '1px solid #d8dce6'
                                }}>
                                    <StyledMenuItem key={index} onClick={event => {
                                        this.handleClose()
                                        return action.onClick ? action.onClick(event, this.props.item) : () => { }

                                    }}>
                                        <ListItemText key={index} classes={{
                                            primary: this.props.classes.itemText
                                        }} primary={action.tooltip} />
                                    </StyledMenuItem>
                                </div>

                            )
                        }
                        return null
                    })}
                    {this.props.type === 'STATUS' && this.props.items.map((item, index) => {
                        return (
                            <StyledMenuItem key={index} onClick={() => this.clickStatusHandler(item.name)}>
                                <ListItemText key={index} classes={{
                                    primary: this.props.classes.itemText
                                }} primary={item.label} />
                            </StyledMenuItem>
                        )
                    })}
                </StyledMenu>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails
})

const mapDispatchToProps = dispatch => ({

})

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(DropdownSelector))
