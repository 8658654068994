import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Button } from '@material-ui/core'

const styles = theme => ({
    optionals: {
        width: '100%',
        marginTop: '20px',
        '& table': {
            width: '100%',
            '& td': {
                padding: '5px',
                borderBottom: '1px solid #e0e0e0',
                '&:nth-of-type(2n+1)': {
                    color: '#3e3f42',
                    fontWeight: '500',
                    fontSize: '14px',
                    minWidth: '50px',
                    width: '75px'
                },
                '&:nth-of-type(2n)': {
                    color: '#84868c',
                    fontSize: '14px'
                }
            }
        }
    },
    small: {
        width: '30px !important',
        padding: '0px !important'
    }
})

class OptionalsList extends React.Component {

    state = {
        list: ''
    }

    deleteRow = line => {
        const newList = this.state.list.replace(line, '')
        this.setState({
            list: newList
        })
        this.props.onChangeList(newList)
    }

    componentWillReceiveProps(props) {
        if (props.list && props.list !== this.state.list) {
            this.setState({
                list: props.list
            })
        }
    }

    parseText = text => {
        let html = []
        text.split('#').forEach(line => {
            if (line.length) {
                let lineSplit = line.split('@')
                if (lineSplit.length === 2) {
                    html.push(<tr>
                        <td>{lineSplit[0].trim()}</td>
                        <td>{lineSplit[1].trim()}</td>
                        <td className={this.props.classes.small}><Button onClick={() => this.deleteRow(`${line}#`)}>x</Button></td>
                    </tr>)
                }
            }
        })
        return html
    }

    render() {
        const { classes } = this.props
        const { list } = this.state
        return <div className={classes.optionals}>
            <table>
                <tbody>
                    {this.parseText(list)}
                </tbody>
            </table>
        </div>
    }
}

const mapStateToProps = reducers => ({
})

const mapDispatchToProps = dispatch => ({
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OptionalsList))