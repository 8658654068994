import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Car from './car/Car'
import Provider from './provider/Provider'
import Client from './client/Client'
import User from './user/User'
import Trace from './trace/Trace'
import Settings from './settings/Settings'
import Leasing from './leasing/Leasing'
import Reports from './reports/Reports'

import { roleInArray } from '../../utils/helpers'
import * as CONSTANTS from '../../utils/constants'

class Admin extends Component {
    render() {
        const { match } = this.props
        return (
            <>
                {roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN]) && <Route path={`${match.path}/car`} exact component={Car} />}
                {roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.EXTERNAL_SELLER, CONSTANTS.ROLES.IPMG_SELLER])
                    && <Route path={`${match.path}/client`} exact component={Client} />}
                {roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN]) && <Route path={`${match.path}/provider`} exact component={Provider} />}
                {roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN]) && <Route path={`${match.path}/user`} exact component={User} />}
                {roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN]) && <Route path={`${match.path}/leasing`} exact component={Leasing} />}
                {roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN]) && <Route path={`${match.path}/trace`} exact component={Trace} />}
                {roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN]) && <Route path={`${match.path}/reports`} exact component={Reports} />}
                {roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN]) && <Route path={`${match.path}/settings`} exact component={Settings} />}
            </>
        )
    }
}

const mapStateToProps = reducers => ({
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Admin))
