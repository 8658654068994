import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Paper, Typography } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { formatNumber } from '../../../utils/helpers'

import InputGenerator from '../../common/subComponents/InputGenerator'
import * as NOTIFICATION from '../../../utils/notification'
import * as SETTINGS from '../../../redux/actions/settings'
import * as STOCKS from '../../../redux/actions/stocks'
import Header from '../../common/SearchableHeader'

const styles = theme => ({
    header: {
        width: '100%',
        height: 70
    },
    listContainer: {
        padding: 27,
        overflow: 'auto'
    },
    addIconStyle: {
        paddingRight: 5
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '12px',
        paddingTop: '12px',
        borderBottom: '1px solid #e0e0e0',
        '&:last-child': {
            borderBottom: 'none'
        },
        '& > div > div:not(:first-child)': {
            marginLeft: '20px'
        }
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    halfSection: {
        width: '50%',
    },
    subtitle: {
        fontSize: '18px',
        '&:not(:first-child)': {
            marginTop: '20px'
        }
    },
    contentContainer: {
        padding: '20px'
    },
    save: {
        paddingTop: '20px',
        borderTop: '1px solid #e0e0e0',
        display: 'flex',
        flexDirection: 'row',
        '& > button': {
            marginLeft: 'auto'
        }
    }
})

class Settings extends Component {

    state = {}

    componentDidMount() {
        this.props.get().then(settings => {
            this.setState({ ...settings })
        })
        this.props.getTotalFinancing().then(financingRemainder => {
            this.setState({financingRemainder: formatNumber(this.state.maxFinancingValue - financingRemainder.totalFinancing.financingCost.dataValues.financedValue), maxFinancingValue: this.state.maxFinancingValue})
        })
    }

    onChange = (event) => {
        if(event.target.name === 'maxFinancingValue')
            this.setState({ [event.target.name]: event.target.value ? event.target.value : 0})
        else this.setState({ [event.target.name]: event.target.value})
    }

    onUpdate = () => {
        const i18n = this.props.languageReducer
        this.props.edit({ ...this.state }).then(() => {
            NOTIFICATION.success(i18n.updated)
        }).catch(() => {
            NOTIFICATION.error(i18n.updateError)
        })
        this.props.getTotalFinancing().then(financingRemainder => {
            this.setState({financingRemainder: formatNumber(this.state.maxFinancingValue - financingRemainder.totalFinancing.financingCost.dataValues.financedValue), maxFinancingValue: Number(this.state.maxFinancingValue)})
        })
    }

    render() {
        const { classes } = this.props
        const i18n = this.props.languageReducer
        return <>
            <div className={classes.header}>
                <Header
                    title={i18n.title}
                />
            </div>
            <div className={classes.listContainer}>
                <Paper className={classes.contentContainer}>
                    <Typography className={classes.subtitle}>{i18n.discount.addition}</Typography>
                    <div className={`${classes.section} ${classes.halfSection}`}>
                        <div className={classes.row}>
                            <InputGenerator
                                type='number'
                                value={this.state.addition || ''}
                                label={i18n.discount.lowerLimit}
                                name='addition'
                                fullWidth={true}
                                onChange={this.onChange}
                                margin="dense"
                            />
                        </div>
                    </div>
                    <div className={`${classes.section} ${classes.halfSection}`}>
                        <div className={classes.row}>
                            <InputGenerator
                                type='number'
                                value={this.state.recommendedAddition || ''}
                                label={i18n.discount.recommendedAddition}
                                name='recommendedAddition'
                                fullWidth={true}
                                onChange={this.onChange}
                                margin="dense"
                            />
                        </div>
                    </div>
                    <Typography className={classes.subtitle}>{i18n.smtp.title}</Typography>
                    <div className={`${classes.section}`}>
                        <div className={classes.row}>
                            <InputGenerator
                                type='text'
                                value={this.state.smtpUsername || ''}
                                label={i18n.smtp.username}
                                name='smtpUsername'
                                onChange={this.onChange}
                                fullWidth={true}
                                margin="dense"
                            />
                            <InputGenerator
                                type='password'
                                value={this.state.smtpPassword || ''}
                                name='smtpPassword'
                                onChange={this.onChange}
                                label={i18n.smtp.password}
                                fullWidth={true}
                                margin="dense"
                            />
                        </div>
                    </div>
                    <div className={`${classes.section} ${classes.halfSection}`}>
                        <div className={classes.row}>
                            <InputGenerator
                                type='text'
                                value={this.state.smtpNotificationUsername || ''}
                                label={i18n.smtp.notificationUsername}
                                name='smtpNotificationUsername'
                                fullWidth={true}
                                onChange={this.onChange}
                                margin="dense"
                            />
                        </div>
                    </div>

                    <Typography className={classes.subtitle}>{i18n.financing.title}</Typography>
                    <div className={`${classes.section}`}>
                        <div className={classes.row}>
                            <InputGenerator
                                type='numberFormat'
                                value={this.state.maxFinancingValue || ''}
                                label={i18n.financing.value}
                                name='maxFinancingValue'
                                onChange={this.onChange}
                                fullWidth={true}
                                margin="dense"
                            />
                            <InputGenerator
                                value={this.state.financingRemainder || i18n.financing.remainderDefault}
                                name='financingRemainder'
                                onChange={this.onChange}
                                label={i18n.financing.remainder}
                                fullWidth={true}
                                margin="dense"
                                inputProps={
                                    { readOnly: true, }
                                }
                            />
                        </div>
                    </div>
                    <div className={classes.save}>
                        <Button color="primary" onClick={this.onUpdate}>{i18n.save}</Button>
                    </div>
                </Paper>
            </div>
        </>

    }
}

const mapStateToProps = reducers => ({
    languageReducer: reducers.languageReducer.i18n.settings
})

const mapDispatchToProps = dispatch => ({
    get: () => dispatch(SETTINGS.get()),
    edit: settings => dispatch(SETTINGS.edit(settings)),
    getTotalFinancing: () => dispatch(STOCKS.getTotalFinancing())

})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Settings))