import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { Delete as DeleteIcon, Edit as EditIcon, AddCircleOutline as AddIcon } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import moment from 'moment'

import { validations } from '../../../utils/validationUtils'
import SimpleList from '../../common/SimpleList'
import FormModal from '../../common/SimpleFormGenerator'
import ItemDetails from '../../common/ItemDetails'
import ConfirmationDialog from '../../common/ConfirmationDialog'
import * as CLIENT from '../../../redux/actions/clients'
import * as NOTIFICATION from '../../../utils/notification'
import * as CONSTANTS from '../../../utils/constants'
import Header from '../../common/SearchableHeader'

const styles = theme => ({
    addButton: {
        padding: '8px 20px',
        fontSize: '15px',
        width: '100px',
        marginTop: '15px',
        display: 'flex'
    },
    subtitle: {
        fontSize: '35px',
        paddingTop: '20px',
        color: theme.palette.primaryColor,
        display: 'block',
        width: '350px'
    },
    header: {
        width: '100%',
        height: 70
    },
    listContainer: {
        padding: 27,
        overflow: 'auto'
    },
    addIcon: {
        marginRight: 'auto'
    },
    addIconStyle: {
        paddingRight: 5
    },
    withoutSidebar: {
        padding: '24px 36px 24px 0px'
    }
})

class Client extends Component {
    clientToEdit = {}
    clientToDelete = {}

    _deepClone = arr => [...arr].map(el => ({ ...el }))

    initialFields = [
        {
            value: CONSTANTS.CLIENT_MODEL_OPTIONS.PERSON_TYPES.LEGAL,
            type: 'dropdownSelector',
            label: this.props.language.form.fields.isLegalEntity,
            name: 'isLegalEntity',
            validation: {
                checks: [validations.notEmpty]
            },
            utils: this.props.language.form.fields.isLegalEntity,
            selected: true,
            options: Object.values(CONSTANTS.CLIENT_MODEL_OPTIONS.PERSON_TYPES).map(option => ({ label: this.props.language.form.options.person_type[option], name: option }))
        },
        { value: '', type: 'text', label: this.props.language.form.fields.cui, name: 'cui', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: this.props.language.form.fields.name, name: 'name', validation: { checks: [validations.notEmpty] },  disableWhenPF: true},
        {
            value: CONSTANTS.CLIENT_MODEL_OPTIONS.TYPES.INTERN,
            type: 'dropdownSelector',
            label: this.props.language.form.fields.type,
            name: 'type',
            validation: {
                checks: [validations.notEmpty]
            },
            utils: this.props.language.form.fields.type,
            selected: true,
            options: Object.values(CONSTANTS.CLIENT_MODEL_OPTIONS.TYPES).map(option => ({ label: this.props.language.form.options.type[option], name: option }))
        },
        { value: '', type: 'text', label: this.props.language.form.fields.country, name: 'country', validation: { checks: [validations.notEmpty] }, breakline: "true" },
        { value: '', type: 'text', label: this.props.language.form.fields.city, name: 'city', validation: { checks: [validations.notEmpty] }, disableWhenPF: true },
        { value: '', type: 'text', label: this.props.language.form.fields.address, name: 'address', validation: { checks: [validations.notEmpty] }, disableWhenPF: true },
        { value: '', type: 'text', label: this.props.language.form.fields.contactName, name: 'contactName', validation: { checks: [validations.notEmpty] }, breakline: "true" },
        { value: '', type: 'text', label: this.props.language.form.fields.contactEmail, name: 'contactEmail', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: this.props.language.form.fields.contactPhone, name: 'contactPhoneNumber', validation: { checks: [validations.notEmpty] } },

    ]

    extraInternFields = [
        { value: '', type: 'text', label: this.props.language.form.fields.code, name: 'code', validation: { checks: [validations.notEmpty] }, breakline: "true" },
        { value: '', type: 'text', label: this.props.language.form.fields.tradeRegister, name: 'tradeRegister', validation: { checks: [validations.notEmpty] }, disableWhenPF: true },
        { value: false, type: 'checkbox', label: this.props.language.form.fields.tvaPayer, name: 'tvaPayer' },
        { value: '', type: 'text', label: this.props.language.form.fields.IBAN, name: 'IBAN', validation: { checks: [validations.notEmpty] }, breakline: "true" },
        { value: '', type: 'text', label: this.props.language.form.fields.bank, name: 'bank', validation: { checks: [validations.notEmpty] } },
    ]

    extraNonLegalEntityFields = [
        { value: '', type: 'text', label: this.props.language.form.fields.nonLegalIDSeries, name: 'nonLegalIDSeries', validation: { checks: [validations.notEmpty] }, breakline: "true" },
        { value: '', type: 'text', label: this.props.language.form.fields.nonLegalIDNumber, name: 'nonLegalIDNumber', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: this.props.language.form.fields.nonLegalIDEmitter, name: 'nonLegalIDEmitter', validation: { checks: [validations.notEmpty] } },
        { value: moment().format('YYYY-MM-DD'), type: 'date', label: this.props.language.form.fields.nonLegalIDEmittedAt, name: 'nonLegalIDEmittedAt', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: this.props.language.form.fields.nonLegalCity, name: 'nonLegalCity', validation: { checks: [validations.notEmpty] }, breakline: "true" },
        { value: '', type: 'text', label: this.props.language.form.fields.nonLegalStreet, name: 'nonLegalStreet', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: this.props.language.form.fields.nonLegalStreetNumber, name: 'nonLegalStreetNumber', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: this.props.language.form.fields.nonLegalBuilding, name: 'nonLegalBuilding', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: this.props.language.form.fields.nonLegalApartment, name: 'nonLegalApartment', validation: { checks: [validations.notEmpty] } },
    ]

    state = {
        clients: [],
        count: 0,
        from: 0,
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        search: '',
        openModal: false,
        openConfirmationModal: false,
        modalFields: this._deepClone(this.initialFields.concat(this.extraInternFields)),
        modalType: CONSTANTS.CREATE,
        selectedClient: null,
        detailsModal: false,
        showAnafButton: true
    }

    componentDidMount() {
        this.getClients()
    }

    getClients = () =>
        this.props.get({
            search: this.state.search
        }).then(data => {
            this.setState({
                clients: data.clients.map((client, index) => ({
                    index: index + 1,
                    _id: client.id,
                    name: client.isLegalEntity ? client.name : client.contactName,
                    cui: client.cui ? client.cui : '-',
                    contactEmail: client.contactEmail ? client.contactEmail : '-',
                    contactPhoneNumber: client.contactPhoneNumber ? client.contactPhoneNumber : '-',
                    createdAt: moment(client.createdAt).format('ll')
                })),
                count: data.count,
                openModal: false,
                openConfirmationModal: false
            })
            return Promise.resolve()
        })

    onCreateHandler = fields => {
        let model = {}

        fields.forEach(field => {
            if (field.name === 'isLegalEntity') model[field.name] = field.value === CONSTANTS.CLIENT_MODEL_OPTIONS.PERSON_TYPES.LEGAL
            else model[field.name] = field.value
        })

        this.props.create(model).then(() => {
            NOTIFICATION.success(this.props.language.create.success)
            this._clearForm()
            this.getClients()
        }).catch(() => NOTIFICATION.error(this.props.language.create.failure))
    }

    onEditHandler = fields => {
        let model = {}

        fields.forEach(field => {
            if (field.name === 'isLegalEntity') model[field.name] = field.value === CONSTANTS.CLIENT_MODEL_OPTIONS.PERSON_TYPES.LEGAL
            else model[field.name] = field.value
        })

        this.props.edit(this.clientToEdit._id, model).then(() => {
            this.getClients()
            this._clearForm()
            NOTIFICATION.success(this.props.language.edit.success)
        }).catch(() => NOTIFICATION.error(this.props.language.edit.failure))
    }

    onDeleteHandler = () => {
        this.props.delete(this.clientToDelete._id).then(() => {
            NOTIFICATION.success(this.props.language.delete.success)
            this.getClients()
        }).catch(() => NOTIFICATION.error(this.props.language.delete.failure))
    }

    _clearForm = () => {
        this.setState({ modalFields: this._deepClone(this.initialFields.concat(this.extraInternFields)), showAnafButton: true })
    }

    toggleEditModal = client => {
        this.clientToEdit = client

        this.props.getById(client._id).then(clientResponse => {
            let modalFields = []

            modalFields = modalFields.concat(this._deepClone(this.initialFields))
                .filter(field => field.name !== 'type' && field.name !== 'isLegalEntity')
                .map(field => ({ ...field, value: clientResponse[field.name] }))

            if (clientResponse.type === CONSTANTS.CLIENT_MODEL_OPTIONS.TYPES.INTERN)
                modalFields = modalFields.concat(this._deepClone(this.extraInternFields))
                    .map(field => ({ ...field, value: clientResponse[field.name] }))

            if (clientResponse.isLegalEntity == false) {
                modalFields = modalFields.concat(this._deepClone(this.extraNonLegalEntityFields))
                    .map(field => ({ ...field, value: clientResponse[field.name] }))
            }

            if (!clientResponse.isLegalEntity) {
                modalFields = modalFields.filter(field => !field.disableWhenPF)
            }


            this.setState({
                modalFields,
                openModal: true,
                modalType: CONSTANTS.EDIT,
                showAnafButton: false
            })
        }).catch(() => NOTIFICATION.error(this.props.language.get.notFound))
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, () => this.getClients())
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getClients())
    }


    onSearch = search => this.setState({ search }, this.getClients)

    onChangeHandler = event => {
        let fieldsCopy = this._deepClone(this.state.modalFields)

        let fieldIndex = fieldsCopy.findIndex(field => field.name === event.target.name)

        if (fieldIndex < 0) return

        if (event.target.name === 'type' &&
            event.target.value === CONSTANTS.CLIENT_MODEL_OPTIONS.TYPES.INTERN &&
            fieldsCopy.every(field => field.name !== 'tvaPayer')) {
            fieldsCopy = fieldsCopy.concat(this._deepClone(this.extraInternFields))
        } else if (event.target.name === 'type' &&
            event.target.value === CONSTANTS.CLIENT_MODEL_OPTIONS.TYPES.EXTERN) {
            fieldsCopy = fieldsCopy.filter(field => this.extraInternFields.every(extraField => extraField.name !== field.name))
        } else if (event.target.name === 'isLegalEntity'
            && event.target.value === CONSTANTS.PROVIDER_MODEL_OPTIONS.PERSON_TYPES.INDIVIDUAL
            && fieldsCopy.every(field => field.name !== 'nonLegalCity')) {
            fieldsCopy = fieldsCopy.concat(this._deepClone(this.extraNonLegalEntityFields))
        } else if (event.target.name === 'isLegalEntity'
            && event.target.value === CONSTANTS.PROVIDER_MODEL_OPTIONS.PERSON_TYPES.LEGAL) {
            fieldsCopy = fieldsCopy.filter(field => this.extraNonLegalEntityFields.every(extraField => extraField.name !== field.name))
        }

        if (event.target.name === 'isLegalEntity' && event.target.value === CONSTANTS.PROVIDER_MODEL_OPTIONS.PERSON_TYPES.INDIVIDUAL) {
            fieldsCopy = fieldsCopy.filter(field => !field.disableWhenPF)
        } else {
            if (event.target.name === 'isLegalEntity' && event.target.value === CONSTANTS.PROVIDER_MODEL_OPTIONS.PERSON_TYPES.LEGAL)
                fieldsCopy = this._deepClone(this.initialFields.concat(this.extraInternFields))
        }

        fieldsCopy[fieldIndex].value = event.target.value

        let typeIndex = fieldsCopy.findIndex(field => field.name === 'type')
        let isLegalEntityIndex = fieldsCopy.findIndex(field => field.name === 'isLegalEntity')

        let showAnaf = {}

        if (!(typeIndex < 0 || isLegalEntityIndex < 0)) {
            showAnaf.showAnafButton = fieldsCopy[typeIndex].value === CONSTANTS.PROVIDER_MODEL_OPTIONS.TYPES.INTERN &&
                CONSTANTS.PROVIDER_MODEL_OPTIONS.PERSON_TYPES.LEGAL === fieldsCopy[isLegalEntityIndex].value
        }

        this.setState({
            modalFields: fieldsCopy,
            ...showAnaf
        })
    }

    onAnafClickHandler = () => {
        let cuiIndex = this.state.modalFields.findIndex(field => field.name === 'cui')
        let typeIndex = this.state.modalFields.findIndex(field => field.name === 'type')

        if (cuiIndex < 0 || typeIndex < 0) return

        if (this.state.modalFields[typeIndex].value !== CONSTANTS.CLIENT_MODEL_OPTIONS.TYPES.INTERN) {
            return NOTIFICATION.error(this.props.language.form.anaf.error)
        }

        this.props.getAnafData({ cui: this.state.modalFields[cuiIndex].value })
            .then(({ data }) => {
                let nameIndex = this.state.modalFields.findIndex(field => field.name === 'name')
                let tvaPayer = this.state.modalFields.findIndex(field => field.name === 'tvaPayer')
                let IBAN = this.state.modalFields.findIndex(field => field.name === 'IBAN')
                let city = this.state.modalFields.findIndex(field => field.name === 'city')
                let country = this.state.modalFields.findIndex(field => field.name === 'country')
                let address = this.state.modalFields.findIndex(field => field.name === 'address')
                let tradeRegister = this.state.modalFields.findIndex(field => field.name === 'tradeRegister')

                let modalFields = this._deepClone(this.state.modalFields)

                if (nameIndex < 0 || tvaPayer < 0 || IBAN < 0 || city < 0 || country < 0 || address < 0) return

                modalFields[nameIndex].value = data.name
                modalFields[tvaPayer].value = data.scpTVA
                modalFields[IBAN].value = data.iban
                modalFields[address].value = data.address ? data.address.replace(data.address.split(',')[0] + ',', '').replace(data.address.split(',')[1] + ',', '') : ''
                modalFields[country].value = this.props.language.form.defaultCountry
                modalFields[city].value = data.address ? data.address.split(',')[1] : ''
                if(tradeRegister > 0) modalFields[tradeRegister].value = data.tradeRegister

                this.setState({ modalFields }, () => NOTIFICATION.success(this.props.language.form.anaf.success))
            })
            .catch(err => NOTIFICATION.error(this.props.language.form.anaf.invalidCui))
    }

    render() {
        const { classes } = this.props

        return <>
            {this.state.selectedClient && <ItemDetails
                footer={CONSTANTS.CANCEL_FOOTER}
                client={this.state.selectedClient}
                open={this.state.detailsModal}
                item={this.state.selectedClient}
                title={this.props.language.clientTitle}
                noHeaders={true}
                onCancel={() => this.setState({ selectedClient: null, detailsModal: false })} />
            }
            <ConfirmationDialog
                open={this.state.openConfirmationModal}
                cancelButtonText={this.props.language.confirmationDialog.cancelButtonText}
                acceptButtonText={this.props.language.confirmationDialog.acceptButtonText}
                text={`${this.props.language.confirmationDialog.text} ${this.clientToDelete.name}?`}
                onCancel={() => this.setState({ openConfirmationModal: false })}
                onClose={() => this.setState({ openConfirmationModal: false })}
                onAccept={this.onDeleteHandler}
            />

            <FormModal
                canSubmit
                fields={this.state.modalFields}
                key={this.state.openModal}
                open={this.state.openModal}
                onCancel={() => {
                    this._clearForm()
                    this.setState({ openModal: false })
                }}
                onSave={() => this.state.modalType === CONSTANTS.CREATE ? this.onCreateHandler(this.state.modalFields) : this.onEditHandler(this.state.modalFields)}
                dialogTitle={this.state.modalType === CONSTANTS.CREATE ? this.props.language.formModal.add : this.props.language.formModal.title}
                cancelButtonText={this.props.language.confirmationDialog.cancelButtonText}
                submitButtonText={this.state.modalType === CONSTANTS.CREATE ? this.props.language.formModal.add : this.props.language.formModal.edit}
                type={this.state.modalType}
                validate={true}
                maxWidth='sm'
                onChange={this.onChangeHandler}
            >
                {this.state.showAnafButton ? <Button color="secondary" onClick={this.onAnafClickHandler}>{this.props.language.form.anaf.name}</Button> : null}
            </FormModal>

            <div className={classes.header}>
                <Header
                    title={this.props.language.simpleTable.title}
                    placeholder={this.props.language.searchPlaceholder}
                    onSearch={this.onSearch}
                />
            </div>
            <div className={`${!this.props.sidebarReducer.displaySidebar ? this.props.classes.withoutSidebar : ""} ${this.props.classes.listContainer}`}>
                <SimpleList
                    headerFields={this.props.language.headerOptionsSimpleList}
                    selectedOption={CONSTANTS.CLIENT_OPTIONS}
                    rows={this.state.clients}
                    items={this.state.clients}
                    actionsTitle={this.props.language.simpleTable.actionsTitle}
                    disableBorders
                    actions={[
                        {
                            tooltip: this.props.language.simpleTable.actions.delete,
                            onClick: (event, row) => {
                                this.clientToDelete = row
                                this.setState({ openConfirmationModal: true })
                            },
                            icon: <DeleteIcon />
                        },
                        {
                            tooltip: this.props.language.simpleTable.actions.edit,
                            onClick: (event, row) => this.toggleEditModal(row),
                            icon: <EditIcon />
                        }
                    ]}
                    onSelect={row => this.setState({ selectedClient: row, detailsModal: true })}
                    headerButton={{
                        tooltip: '',
                        icon: <Button onClick={() => this.setState({ openModal: true })} className={classes.addButton} variant='contained' color='primary'>
                            <AddIcon className={classes.addIconStyle} />
                            {this.props.language.simpleTable.headerButton.buttonText}
                        </Button>,
                        onClick: () => this.setState({ openModal: true, modalType: CONSTANTS.CREATE })
                    }}
                    search={{
                        onSearch: this.onSearch
                    }}
                    rowsPerPage={this.state.rowsPerPage}
                    count={this.state.count}
                    countTitle={this.props.language.clients}
                    changeRowsPerPage={this.changeRowsPerPageHandler}
                    changePageHandler={this.changePageHandler}
                />
            </div>
        </>
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.client,
    sidebarReducer: reducers.sidebarReducer
})

const mapDispatchToProps = dispatch => ({
    get: queryParams => dispatch(CLIENT.get(queryParams)),
    edit: (clientId, client) => dispatch(CLIENT.edit(clientId, client)),
    create: client => dispatch(CLIENT.create(client)),
    delete: clientId => dispatch(CLIENT.del(clientId)),
    getById: clientId => dispatch(CLIENT.getById(clientId)),
    getAnafData: params => dispatch(CLIENT.getFromAnaf({ ...params, date: moment().format(CONSTANTS.INPUT_TYPE_DATE_FORMAT) }))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Client))