import queryString from 'query-string'
import axios from '../../utils/axios'
import { errorHandler } from '../../utils/apiFunctions'

const baseRoute = '/financingCosts'

export const get = queryParams => dispatch =>
    axios.get(baseRoute + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getById = (financingCostId, blockEntity = false) => dispatch =>
    axios.get(`${baseRoute}/${financingCostId}?${blockEntity ? "blockEntity=true" : ""}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const unblockStock = financingCostId => dispatch =>
    axios.delete(`${baseRoute}/${financingCostId}/unblock`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const create = financingCost => dispatch =>
    axios.post(baseRoute, { financingCost })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const edit = (financingCostId, financingCost) => dispatch =>
    axios.put(`${baseRoute}/${financingCostId}`, { financingCost })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const del = financingCostId => dispatch =>
    axios.delete(`${baseRoute}/${financingCostId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)
