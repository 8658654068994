import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, TableCell, TableRow, Tooltip, Table } from '@material-ui/core'
import ListAvatarAndCarBrand from '../../common/subComponents/ListAvatarAndCarBrand'
import { formatNumber } from '../../../utils/helpers'
import * as CONSTANTS from '../../../utils/constants'
import config from '../../../config/local.json'
import moment from 'moment'


const styles = theme => ({
    flexContainer: {
        display: 'flex',
        width: '100%'
    },
    flexChild: {
        flex: 1,
        marginLeft: '20px',
        borderLeft: '1px solid #f0f0f0'
    },
    details: {
        '& td': {
            borderBottom: '1px solid #e0e0e0',
            padding: '4px'
        },
        '& tr:last-child td': {
            borderBottom: 'none',
        },
        '& tr td:nth-of-type(2n)': {
            width: '50%'
        }
    },
    tableCell: {
        borderBottom: '3px solid #d0d0d0',
        paddingTop: '10px'
    },
    profit: {
        borderLeft: '5px solid rgb(52, 170, 68) !important'
    },
    nonProfit: {
        borderLeft: '5px solid rgb(227, 51, 113) !important'
    }

})

class ReportsList extends Component {

    render() {
        let { item, classes, language } = this.props
        return (
            <Tooltip placement="top" title={item.offer.stock.comment}>
                <TableRow className={item.offer.totalProfitEUR > 0 ? classes.profit : classes.nonProfit}>
                    <TableCell className={classes.tableCell} style={{ paddingTop: '0px', marginTop: '0px' }}>
                        <Table className={classes.details}>
                            <tbody>
                                <tr><td colspan="2"> <ListAvatarAndCarBrand
                                    disableWidth={true}
                                    carBrandName={item.offer.stock.carBrand.name}
                                    carModelName={item.offer.stock.carModel.name}
                                    logoPath={`${config.baseUrl}/cars/brands/${item.offer.stock.carBrand.id}/image`}
                                /></td></tr>
                                <tr><td>{language.deliveryDate}</td><td>{moment(item.offer.stock.IPMGDeliveryDate).format(CONSTANTS.DEFAULT_DATE_FORMAT) !== CONSTANTS.INVALID_DATE_INPUT_ERROR ? moment(item.offer.stock.IPMGDeliveryDate).format(CONSTANTS.DEFAULT_DATE_FORMAT) : '-'}</td></tr>
                                <tr><td>{language.orderNumber}</td><td><span className={classes.fieldText}>{`${item.offer.stock.orderNumber || '-'}`}</span></td></tr>
                                <tr><td>{language.vin}</td><td><Tooltip title={item.offer.stock.vinNumber}><span className={classes.fieldText}>{`${item.offer.stock.vinNumber.substr(-1 * CONSTANTS.VIN_SUBSTR_LENGTH) || '-'}`}</span></Tooltip></td></tr>
                                <tr><td>{language.client}</td><td><span className={classes.fieldText}>{`${item.offer.client.name || '-'}`}</span></td></tr>
                                <tr><td>{language.provider}</td><td><span className={classes.fieldText}>{`${item.offer.stock.provider.name || '-'}`}</span></td></tr>
                                <tr><td>{language.financer}</td><td><span className={classes.fieldText}>{`${item.offer.leasing ? item.offer.leasing.name : '-'}`}</span></td></tr>
                            </tbody>
                        </Table>
                    </TableCell>
                    <TableCell className={classes.tableCell} style={{ width: '75%' }}>
                        <div className={classes.flexContainer}>
                            <div className={classes.flexChild} style={{ marginLeft: '0px' }}>
                                <Table className={classes.details}>
                                    <tbody>
                                        <tr><td>{language.financingPercentage}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.financingProcent) || '-'} %`}</span></td></tr>
                                        <tr><td>{language.listPrice}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.stock.listPrice) || '-'} ${CONSTANTS.EURO_SYMBOL}`}</span></td></tr>
                                        <tr><td>{language.PDI}</td><td><span className={classes.fieldText}>{`${item.offer.stock.PDI || '-'} ${CONSTANTS.EURO_SYMBOL}`}</span></td></tr>
                                        <tr><td>{language.discountPercentage}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.stock.discountPercent, 4) || '-'} %`}</span></td></tr>
                                        <tr><td>{language.discountEUR}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.stock.discountEUR) || '-'} ${CONSTANTS.EURO_SYMBOL}`}</span></td></tr>
                                        <tr><td>{language.purchasePrice}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.stock.type.toLowerCase() === CONSTANTS.STOCK_TYPE_SH.toLowerCase() || item.offer.stock.type.toLowerCase() === CONSTANTS.STOCK_TYPE_EXTERNAL.toLowerCase() ? item.offer.stock.ShPurchasePrice : item.offer.stock.purchasePrice) || '-'} ${CONSTANTS.EURO_SYMBOL}`}</span></td></tr>
                                        <tr><td>{language.purchaseExchangeRate}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.stock.purchaseExchangeRate, 4) || '-'}`}</span></td></tr>
                                        <tr><td>{language.purchasePriceRON}</td><td><span className={classes.fieldText}>{`${formatNumber((item.offer.stock.type.toLowerCase() === CONSTANTS.STOCK_TYPE_SH.toLowerCase() || item.offer.stock.type.toLowerCase() === CONSTANTS.STOCK_TYPE_EXTERNAL.toLowerCase() ? item.offer.stock.ShPurchasePrice : item.offer.stock.purchasePrice) * item.offer.stock.purchaseExchangeRate) || '-'} RON`}</span><br /></td></tr>
                                    </tbody>
                                </Table>
                            </div>
                            <div className={classes.flexChild}>
                                <Table className={classes.details}>
                                    <tbody>
                                        <tr><td>{language.clientDiscountPercentage}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.discount, 4) || '-'} %`}</span></td></tr>
                                        <tr><td>{language.clientDiscountEUR}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.discountEUR) || '-'} ${CONSTANTS.EURO_SYMBOL}`}</span></td></tr>
                                        <tr><td>{language.sellingPrice}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.sellingPriceEUR) || '-'} ${CONSTANTS.EURO_SYMBOL}`}</span></td></tr>
                                        <tr><td>{language.sellingExchangeRate}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.sellingExchangeRate, 4) || '-'}`}</span></td></tr>
                                        <tr><td>{language.sellingPriceRON}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.sellingPriceEUR * item.offer.sellingExchangeRate) || '-'} RON`}</span></td></tr>
                                        <tr><td>{language.accessories}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.stock.accessoriesPrice) || '-'} ${CONSTANTS.EURO_SYMBOL}`}</span></td></tr>
                                        <tr><td>{language.exchangeDiff}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.sellingExchangeRate - item.offer.stock.purchaseExchangeRate, 4) || '-'}`}</span></td></tr>
                                        <tr><td>{language.exchangeDiffRON}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.sellingPriceEUR * (item.offer.sellingExchangeRate - item.offer.stock.purchaseExchangeRate)) || '-'} RON`}</span></td></tr>
                                        <tr><td>{language.exchangeDiffEUR}</td><td><span className={classes.fieldText}>{`${formatNumber((item.offer.sellingPriceEUR * (item.offer.sellingExchangeRate - item.offer.stock.purchaseExchangeRate)) / item.offer.stock.purchaseExchangeRate) || '-'} ${CONSTANTS.EURO_SYMBOL}`}</span></td></tr>
                                    </tbody>
                                </Table>
                            </div>
                            <div className={classes.flexChild}>
                                <Table className={classes.details}>
                                    <tbody>
                                        <tr><td>{language.leasingEUR}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.leasingEUR) || '-'} ${CONSTANTS.EURO_SYMBOL}`}</span></td></tr>
                                        <tr><td>{language.leasingRON}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.leasingEUR * item.leasingExchangeRate) || '-'} RON`}</span></td></tr>
                                        <tr><td>{language.leasingExchangeRate}</td><td><span className={classes.fieldText}>{`${formatNumber(item.leasingExchangeRate, 4) || '-'}`}</span></td></tr>
                                        <tr><td>{language.costRON}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.stock.cost.totalPrice) || '-'} RON`}</span></td></tr>
                                        <tr><td>{language.costEUR}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.stock.cost.totalPriceEUR) || '-'} ${CONSTANTS.EURO_SYMBOL}`}</span></td></tr>
                                        <tr><td>{language.carProfitEUR}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.carProfitEUR) || '-'} ${CONSTANTS.EURO_SYMBOL}`}</span></td></tr>
                                        <tr><td>{language.carProfitRON}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.carProfitEUR * item.offer.stock.purchaseExchangeRate) || '-'} RON`}</span></td></tr>
                                        <tr><td>{language.totalProfitEUR}</td><td><span className={classes.fieldText}>{`${formatNumber(item.offer.totalProfitEUR) || '-'} ${CONSTANTS.EURO_SYMBOL}`}</span></td></tr>
                                        <tr><td>{language.totalProfitRON}</td><td>{formatNumber(item.offer.carProfitEUR * item.offer.stock.purchaseExchangeRate + (item.offer.sellingPriceEUR * (item.offer.sellingExchangeRate - item.offer.stock.purchaseExchangeRate)) + (item.offer.leasingEUR * item.leasingExchangeRate)) || '-'} RON</td></tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </TableCell>
                </TableRow>
            </Tooltip>
        )
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.reports.tableData
})

const mapDispatchToProps = dispatch => ({

})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ReportsList))