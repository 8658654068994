const CONSTANTS = require('../../utils/constants')

export const checkRights = (receivedRoles, type = CONSTANTS.AND) => (dispatch, getState) => {
    let { loginReducer } = getState()
    let roles = loginReducer.roles

    if (typeof receivedRoles === 'string')
        return roles.includes(receivedRoles)
    else {
        switch (type) {
            case CONSTANTS.AND:
                return receivedRoles.every(role => roles.includes(role))
            case CONSTANTS.OR:
                return receivedRoles.some(role => roles.includes(role))
            default:
                return receivedRoles.every(role => roles.includes(role))
        }
    }
}