import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography, TextField, InputAdornment, withStyles, Button, Tooltip } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { AccountCircle as AccountCircleIcon, UnfoldMore as UnfoldMoreIcon, Close } from '@material-ui/icons'
import sha256 from 'sha256'
import moment from 'moment'


import CheckboxMenu from './CheckboxMenu'
import TooltipMenu from './TooltipMenu'
import * as USER from '../../redux/actions/users'
import * as CONSTANTS from '../../utils/constants'
import * as NOTIFICATION from '../../utils/notification'
import Alerts from '../common/Alerts'
import SimpleFormGenerator from '../common/SimpleFormGenerator'

import { roleInArray } from '../../utils/helpers'

const styles = theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        height: 70,
        borderBottom: '1px solid rgba(0,0,0,0.1)'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        height: '100%'
    },
    subtitle: {
        paddingLeft: 35,
        fontSize: 20,
        display: 'block',
        width: 350,
        fontFamily: 'Roboto',
        color: theme.palette.mainColor,
        fontWeight: 500
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'row'
    },
    searchIcon: {
        fontSize: 30,
        color: '#9ea0a5',
        fontWeight: 40,
        opacity: 0.8
    },
    text: {
        height: 40,
        paddingLeft: 20,
        paddingRight: 10,
        fontFamily: 'Roboto',
        border: 'solid 1px #e2e5ed',
        borderRadius: 4
    },
    searchInput: {
        width: '60%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    afterSearch: {
        height: '60%',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto'
    },
    halfAndCenter: {
        width: '50%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        paddingLeft: 10
    },
    bellIcon: {
        fontSize: 28,
        color: '#9ea0a5',
        fontWeight: 40,
        paddingLeft: 10
    },
    userIcon: {
        fontSize: 40,
        color: '#9ea0a5',
        fontWeight: 40
    },
    arrows: {
        color: '#9ea0a5',
        fontSize: 16
    },
    iconAndArrows: {
        width: '50%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        paddingRight: 25,
        cursor: 'pointer'
    },
    filters: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 10
    },
    filterText: {
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 2,
        fontSize: 14,
        cursor: 'pointer'
    },
    tooltipText: {
        fontSize: 14
    },
    resetButton: {
        cursor: 'pointer'
    }
})

class SearchableHeader extends Component {
    userForm = [
        { value: '', type: 'text', label: this.props.rootText.userForm.username, name: 'username', breakline: true },
        { value: '', type: 'text', label: this.props.rootText.userForm.fullName, name: 'fullName', breakline: true },
        { value: '', type: 'text', label: this.props.rootText.userForm.email, name: 'email', breakline: true },
        { value: '', type: 'text', label: this.props.rootText.userForm.phoneNumber, name: 'phoneNumber', breakline: true },
        { value: '', type: 'password', label: this.props.rootText.userForm.password, name: 'password', breakline: true },
        { value: '', type: 'password', label: this.props.rootText.userForm.confirmPassword, name: 'confirmPassword' },
    ]

    state = {
        userFormFields: this.userForm,
        userEditForm: false,
        input: '',
        tooltips: [{ key: 'edit', value: this.props.rootText.tooltips.edit }]
    }

    formSubmitHandler = () => {

        const usernameIndex = this.state.userFormFields.findIndex(field => field.name === 'username')
        const fullnameIndex = this.state.userFormFields.findIndex(field => field.name === 'fullName')
        const passwordIndex = this.state.userFormFields.findIndex(field => field.name === 'password')
        const emailIndex = this.state.userFormFields.findIndex(field => field.name === 'email')
        const confirmPasswordIndex = this.state.userFormFields.findIndex(field => field.name === 'confirmPassword')
        const phoneNumber = this.state.userFormFields.findIndex(field => field.name === 'phoneNumber')

        if (usernameIndex < 0 || fullnameIndex < 0 || passwordIndex < 0 || confirmPasswordIndex < 0 || emailIndex < 0 || phoneNumber < 0)
            return

        let updateProfile = {}

        if (this.state.userFormFields[passwordIndex].value.length && !(this.state.userFormFields[passwordIndex].value === this.state.userFormFields[confirmPasswordIndex].value)) {
            return NOTIFICATION.error(this.props.rootText.updateUser.passwordMissMatch)
        } else {
            if (this.state.userFormFields[passwordIndex].value.length) {
                updateProfile.password = sha256(this.state.userFormFields[passwordIndex].value)
            }
        }


        if (this.state.userFormFields[usernameIndex].value !== this.myAccount.username)
            updateProfile.username = this.state.userFormFields[usernameIndex].value
        if (this.state.userFormFields[fullnameIndex].value !== this.myAccount.fullName)
            updateProfile.fullName = this.state.userFormFields[fullnameIndex].value

        if (this.state.userFormFields[emailIndex].value !== this.myAccount.email)
            updateProfile.email = this.state.userFormFields[emailIndex].value

        if (this.state.userFormFields[phoneNumber].value !== this.myAccount.phoneNumber)
            updateProfile.phoneNumber = this.state.userFormFields[phoneNumber].value


        if (Object.keys(updateProfile).length) {
            return this.props.updateMe(updateProfile)
                .then(() => {
                    NOTIFICATION.success(this.props.rootText.updateUser.success)
                    this.setState({ userEditForm: false, userFormFields: this.userForm })
                }).catch(() => {
                    NOTIFICATION.error(this.props.rootText.updateUser.failure)
                })
        } else {
            NOTIFICATION.error(this.props.rootText.updateUser.noInput)
        }
    }

    onSelectTooltipItemHandler = key => {
        if (key === 'edit') {
            this.props.getMe().then(me => {
                this.userForm.forEach(input => input.name !== 'password' ? input.value = me[input.name] : null)
                this.setState({ userEditForm: true })
                this.myAccount = me
            })
        }
    }

    onCloseUserFormHandler = () => this.setState({ userEditForm: false })

    formChangeHandler = event => {
        let newForm = [...this.state.userFormFields]
        let index = newForm.findIndex(field => field.name === event.target.name)

        if (index > -1) {
            newForm[index] = { ...this.state.userFormFields[index] }
            newForm[index].value = event.target.value
        }

        this.setState({ userFormFields: newForm })
    }

    onInputChangeHandler = event => this.setState({ input: event.target.value }, () => this.props.onSearch(this.state.input))

    renderTooltip = () => {
        return (
            Object.keys(this.props.reportsFilter).map(key => {
                let displayValueIndex = ''
                let displayValue = ''
                if (this.props.reportsFilter[key].options) {
                    displayValueIndex = this.props.reportsFilter[key].options.findIndex(field => String(field.name) === String(this.props.reportsFilter[key].value))
                    displayValue = this.props.reportsFilter[key].options[displayValueIndex].label
                }
                else {
                    if(this.props.reportsFilter.commission)
                        displayValue = this.props.reportsLanguage.commission
                    else
                        displayValue = moment(this.props.reportsFilter[key].value).format(CONSTANTS.DEFAULT_DATE_FORMAT)                    
                }
                return (
                    <p className={this.props.classes.tooltipText}>{this.props.reportsFilter[key].utils || this.props.reportsFilter[key].label}: {displayValue}</p>
                )
            })
        )

    }

    render() {
        let { classes } = this.props

        let hasFilterOptions = this.props.reportsFilter && Object.keys(this.props.reportsFilter).length

        return (
            <>
                <SimpleFormGenerator
                    fields={this.state.userFormFields}
                    open={this.state.userEditForm}
                    onChange={this.formChangeHandler}
                    onCancel={this.onCloseUserFormHandler}
                    onSave={this.formSubmitHandler}
                    dialogTitle={`${this.props.rootText.userForm.title} ${this.props.username}`}
                    submitButtonText={this.props.rootText.userForm.submitButton}
                    cancelButtonText={this.props.rootText.userForm.cancelButton}
                    validate={false}
                    maxWidth='xs'
                />
                <div className={classes.root}>
                    <div className={classes.title}>
                        <Typography className={classes.subtitle}>{this.props.title}</Typography>
                    </div>
                    {this.props.hasFilter && <div className={classes.filters}>
                        <CheckboxMenu
                            color='secondary'
                            initialText={<span>{this.props.filterText}</span>}
                            items={this.props.filterItems}
                            onChange={this.props.onChangeFilterItems}
                        />
                    </div>}
                    {this.props.reportsFilter && <div className={classes.filters}>
                        <Button onClick={() => this.props.openFilterModal()} color="secondary">{this.props.filterText}</Button>
        {hasFilterOptions ? <Tooltip title={(<>{this.renderTooltip()}</>)}><span className={classes.filterText}>{Object.keys(this.props.reportsFilter).length} {this.props.reportsLanguage.utils.selectedFilters}</span></Tooltip> : null}
                        {hasFilterOptions ? <Tooltip classes={{
                            tooltip: classes.tooltipText
                        }} title={this.props.reportsLanguage.utils.resetFilter}><Close className={classes.resetButton} onClick={() => this.props.resetFilters()} /></Tooltip> : null}
                    </div>}
                    <div className={classes.content}>
                        {this.props.onSearch ? <div className={classes.searchInput}>
                            <TextField
                                onChange={this.onInputChangeHandler}
                                value={this.state.input}
                                placeholder={this.props.placeholder}
                                onKeyPress={event => event.key === 'Enter' ? this.props.onSearch(this.state.input) : () => { }}
                                InputProps={{
                                    className: classes.text,
                                    disableUnderline: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon className={classes.searchIcon} />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div> : null}
                        <div className={classes.afterSearch}>
                            <div className={classes.halfAndCenter}>
                                {roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING]) && <Alerts />}
                            </div>
                            <div className={classes.iconAndArrows}>
                                <TooltipMenu
                                    open
                                    options={this.state.tooltips}
                                    onSelect={this.onSelectTooltipItemHandler}
                                >
                                    <AccountCircleIcon className={classes.userIcon} />
                                    <UnfoldMoreIcon className={classes.arrows} />
                                </TooltipMenu>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = reducers => ({
    username: reducers.loginReducer.username,
    rootText: reducers.languageReducer.i18n.root,
    reportsLanguage: reducers.languageReducer.i18n.reports,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    getMe: () => dispatch(USER.getMe()),
    updateMe: user => dispatch(USER.updateMe(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchableHeader))