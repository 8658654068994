import axios from '../../utils/axios'
import { errorHandler } from '../../utils/apiFunctions'
import queryString from 'query-string'

const baseRoute = '/sales'

export const get = queryParams => dispatch =>
    axios.get(baseRoute + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getById = saleId => dispatch =>
    axios.get(`${baseRoute}/${saleId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const create = sale => dispatch =>
    axios.post(baseRoute, { sale })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const edit = (saleId, sale) => dispatch =>
    axios.put(`${baseRoute}/${saleId}`, { sale })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const del = saleId => dispatch =>
    axios.delete(`${baseRoute}/${saleId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const uploadFile = (saleId, formData) => dispatch =>
    axios.post(`${baseRoute}/${saleId}/files`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const deleteFile = (saleId, fileId) => dispatch =>
    axios.delete(`${baseRoute}/${saleId}/files/${fileId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)