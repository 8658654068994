import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Button, CircularProgress } from '@material-ui/core'
import { ExpandMore as Expand, Delete, AddCircleOutline as Add } from '@material-ui/icons'

import SimpleTable from '../../common/SimpleTable'
import * as CONSTANTS from '../../../utils/constants'
import * as OFFER from '../../../redux/actions/offers'
import * as NOTIFICATION from '../../../utils/notification'

const styles = theme => ({
    offersList: {
        flex: 2,
        padding: 26
    },
    addButton: {
        padding: '8px 10px',
        fontSize: '15px',
        minWidth: '110px',
        display: 'flex'
    },
    getFile: {
        display: 'none'
    },
    getFileLabel: {
        display: 'flex',
        flexDirection: 'row',
        height: 'auto',
        minWidth: 110
    },
    loadingTitle: {
        paddingTop: '2px',
        paddingLeft: '6px'
    },
    buttonTitle: {
        paddingTop: '2px',
        paddingLeft: '14px'
    },
    circularStyle: {
        color: 'white',
        height: '25px !important',
        width: '25px !important'
    }
})

class FilesList extends Component {

    state = {
        files: [],
        count: 0,
        from: 0,
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        renderPage: false,
        activeTab: 0,
        search: '',
        loading: true
    }

    componentDidMount() {
        this.getFiles()
    }

    getFiles = () => {
        this.props.getFiles(this.props.offerId)
            .then(result => {
                this.setState({
                    files: result.map((file, index) => ({
                        _fileName: file.fileName,
                        index: index + 1,
                        _id: file.id,
                        originalName: file.originalName,
                        username: file.user.fullName
                    })),
                    renderPage: true,
                    count: result.length,
                    loading: false
                })
            })
    }

    onItemDetailsCancelHandler = () => {
        this.getFiles()
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, () => this.getFiles())
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getFiles())
    }

    uploadFile = event => {
        let files = Array.from(event.target.files)
        if (!files.length) return;

        const formData = new FormData()
        files.forEach((file, i) => {
            formData.append('file', file)
        })

        this.setState({ loading: true }, () => {
            this.props.uploadFiles(this.props.offerId, formData).then(() => {
                NOTIFICATION.success(this.props.language.addSuccess)
                this.getFiles()
            }).catch(() => {
                NOTIFICATION.error(this.props.language.addFail)
                this.setState({ loading: false })
            })
        })
    }

    render() {

        const { classes } = this.props
        if (this.state.renderPage) {
            return (
                <><div className={classes.offersList}>
                    <SimpleTable
                        disableTableContainer={true}
                        title={""}
                        header={this.props.language.headers}
                        rows={this.state.files}
                        onClickRow={(row) => window.open(`${CONSTANTS.BACKEND_PATH}/uploaded-documents/${row._fileName}`, "_blank")}
                        headerButton={{
                            tooltip: this.props.language.add,
                            onClick: (row) => { },
                            icon: <>
                                <Button disabled={this.state.loading} variant={"contained"} color="primary" className={this.props.classes.addButton}>
                                    <label className={this.props.classes.getFileLabel} id="getFileLabel" htmlFor="getFile">
                                        {!this.state.loading ? <><Add />
                                            <span className={this.props.classes.buttonTitle}>{this.props.language.file}</span></>
                                            : <><CircularProgress className={this.props.classes.circularStyle} /> <span className={this.props.classes.loadingTitle}>{this.props.language.fileLoading}</span></>}
                                    </label>
                                </Button>
                                <input multiple="multiple" onChange={event => this.uploadFile(event)} className={this.props.classes.getFile} type="file" id="getFile" /></>
                        }}
                        actions={[{
                            tooltip: this.props.language.delete,
                            onClick: (event, row) => {
                                if (window.confirm(this.props.language.deleteFileConfirm)) {
                                    this.props.deleteFile(this.props.offerId, row._id)
                                        .then(() => {
                                            this.getFiles()
                                            NOTIFICATION.success(this.props.language.deleteSuccess)
                                        })
                                        .catch(() => NOTIFICATION.error(this.props.language.addFail))
                                }
                            },
                            icon: <Delete />
                        }]}
                        disableBorders
                        count={this.state.count}
                        rowsPerPage={this.state.rowsPerPage}
                    />
                </div></>
            )
        }
        else return null
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.file
})

const mapDispatchToProps = dispatch => ({
    getFiles: offerId => dispatch(OFFER.getFiles(offerId)),
    uploadFiles: (offerId, files) => dispatch(OFFER.uploadFile(offerId, files)),
    deleteFile: (offerId, fileId) => dispatch(OFFER.deleteFile(offerId, fileId))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FilesList))