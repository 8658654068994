import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, TableRow, TableCell, Tooltip } from '@material-ui/core'
import moment from 'moment'

import ListAvatarAndCarBrand from '../../common/subComponents/ListAvatarAndCarBrand'

import * as NOTIFICATION from '../../../utils/notification'
import * as CONSTANTS from '../../../utils/constants'
import * as STOCK from '../../../redux/actions/stocks'
import { formatNumber, ClickWrapper } from '../../../utils/helpers'
import config from '../../../config/local.json'

const styles = theme => ({
    fieldText: {
        fontWeight: 'normal',
        fontSize: 14,
        paddingTop: 4
    },
    title: {
        width: 350
    },
    clickedButton: {
        color: 'green'
    },
    listWrapper: {
        '& span:not(:first-child)': {
            display: 'inline-block',
            marginLeft: '5px'
        }
    }
})

class StockList extends Component {

    statusWithLabels = CONSTANTS.STOCK_STATUS.map(status => ({ ...status, disabled: false, label: this.props.language.stock.statusLabel[status.name] }))

    onEditHandler = newStatus => {
        let newStock = {
            stockStatus: newStatus
        }
        return this.props.editStock(this.props.item.id, newStock).then(result => {
            this.props.getStocks()
            NOTIFICATION.success(this.props.language.stock.message.updatedStatus)
            return Promise.resolve(result)
        })
            .catch(err => NOTIFICATION.error(this.props.language.stock.message.failedStatus))
    }

    render() {
        const logoPath = `${config.baseUrl}/cars/brands/${this.props.item.carBrandId}/image`
        let fieldsToRenderInPrimaryText = [
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.PROVIDER, name: this.props.language.stock.provider },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.ORDERNUMBER, name: this.props.language.stock.orderNumber },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.VINNUMBER, name: this.props.language.stock.vinNumber },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.REGISTRATIONDATE, name: this.props.language.stock.registrationProductionDate },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.MILEAGE, name: this.props.language.km },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.LIST_PRICE, name: this.props.language.stock.price, symbol: CONSTANTS.EURO_SYMBOL },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.DISCOUNTPERCENT, name: this.props.language.stock.discountePercent, symbol: CONSTANTS.PERCENT_SYMBOL },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.DISCOUNTEUR, name: this.props.language.stock.discountEUR, symbol: CONSTANTS.EURO_SYMBOL },
            { field: this.props.item.type === CONSTANTS.STOCK_TYPE_SH || this.props.item.type === CONSTANTS.STOCK_TYPE_EXTERNAL ? CONSTANTS.STOCK_DETAILS_FIELDS.SHPURCHASEPRICE : CONSTANTS.STOCK_DETAILS_FIELDS.PURCHASEPRICE, name: this.props.language.stock.purchasePrice, symbol: CONSTANTS.EURO_SYMBOL }
        ]

        const { stockStatus, tags } = this.props.item
        return (
            <Tooltip placement="top" title={this.props.item.comment}>
                <TableRow>
                    <ClickWrapper onClick={() => this.props.onSelect(this.props.item)}>
                        <TableCell>
                            <ListAvatarAndCarBrand
                                carBrandName={this.props.item.carBrand.name}
                                carModelName={this.props.item.carModel.name}
                                logoPath={logoPath}
                            />
                        </TableCell>
                        {fieldsToRenderInPrimaryText.map((fieldObj, index) => {
                            if (fieldObj.field === CONSTANTS.STOCK_DETAILS_FIELDS.PROVIDER) {
                                return (
                                    <TableCell key={index} >
                                        {this.props.item[fieldObj.field] ? <span className={this.props.classes.fieldText}>{`${this.props.item[fieldObj.field].name}`}</span> : null}
                                    </TableCell>
                                )
                            }
                            if (fieldObj.field === CONSTANTS.STOCK_DETAILS_FIELDS.VINNUMBER) {
                                return (
                                    <TableCell key={index} >
                                        {this.props.item[fieldObj.field] ? <Tooltip placement="top" title={this.props.item[fieldObj.field]}><span className={this.props.classes.fieldText}>{`${this.props.item[fieldObj.field].substr(-1 * CONSTANTS.VIN_SUBSTR_LENGTH)}`}</span></Tooltip> : null}
                                    </TableCell>
                                )
                            }
                            if (fieldObj.field === CONSTANTS.STOCK_DETAILS_FIELDS.REGISTRATIONDATE) {
                                return (
                                    <TableCell size='small' key={index} >
                                        {this.props.item[fieldObj.field] ? <span className={this.props.classes.fieldText}>{`${moment(this.props.item[fieldObj.field]).format(CONSTANTS.DEFAULT_DATE_FORMAT)}`} </span> : <span className={this.props.classes.fieldText}>-</span>}
                                    </TableCell>
                                )
                            }
                            if (fieldObj.field === CONSTANTS.STOCK_DETAILS_FIELDS.MILEAGE || fieldObj.field === CONSTANTS.STOCK_DETAILS_FIELDS.ORDERNUMBER) {
                                return (
                                    <TableCell key={index} >
                                        {this.props.item[fieldObj.field] ? <span className={this.props.classes.fieldText}>{`${this.props.item[fieldObj.field]} ${fieldObj.symbol ? fieldObj.symbol : ""}`} </span> : <span className={this.props.classes.fieldText}>{`0 ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span>}
                                    </TableCell>
                                )
                            }
                            return (
                                <TableCell key={index} >
                                    {this.props.item[fieldObj.field] ?
                                        <span className={this.props.classes.fieldText}>
                                            {`${formatNumber(this.props.item[fieldObj.field])} ${fieldObj.symbol ? fieldObj.symbol : ""}`} </span>
                                        : <span className={this.props.classes.fieldText}>{`0 ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span>}
                                </TableCell>
                            )
                        })}
                    </ClickWrapper>
                    <Tooltip placement="top" title={<div>
                        <span>{stockStatus && stockStatus.length ? stockStatus.map(status => <div key={status}>{this.props.language.stock.statusLabel[status]}</div>) : <div> {this.props.language.stock.statusLabel.unknown} </div>}</span>
                        <span>{this.props.item && this.props.item.hasFinancing ? this.props.language.stock.statusLabel.hasFinancing : ''}</span>
                    </div>}>
                        <TableCell size='small'>
                            <span className={`${this.props.classes.fieldText} ${this.props.classes.listWrapper}`}>
                                {stockStatus && stockStatus.length ?
                                    stockStatus.map(status => <span key={status}>{this.props.language.stock.statusLabelMin[status]}</span>) :
                                    <span>{this.props.language.stock.statusLabel.unknown}</span>
                                }
                                {this.props.item && this.props.item.hasFinancing ? <span> {this.props.language.stock.statusLabelMin.hasFinancing} </span> : ''}
                            </span>
                        </TableCell>
                    </Tooltip>
                    <Tooltip placement="top" title={tags.length ? tags.map(tag => <div key={tag}>{this.props.language.stock.tagsLabel[tag]}</div>) : this.props.language.stock.tagsLabel.unknown}>
                        <TableCell size='small'>
                            <span className={`${this.props.classes.fieldText} ${this.props.classes.listWrapper}`}>{
                                tags.length ?
                                    tags.map(tag => <span key={tag}>{this.props.language.stock.tagsLabelMin[tag]}</span>) :
                                    this.props.language.stock.tagsLabel.unknown
                            }</span>
                        </TableCell>
                    </Tooltip>
                </TableRow >
            </Tooltip>
        )
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails
})

const mapDispatchToProps = dispatch => ({
    editStock: (stockId, stock) => dispatch(STOCK.edit(stockId, stock)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(StockList))