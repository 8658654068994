import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography, withStyles } from '@material-ui/core'

import * as OFFER from '../../redux/actions/offers'
import * as CONSTANTS from '../../utils/constants'

import FileModal from './Files/FilesModal'
import SearchableHeader from '../common/SearchableHeader'
import SimpleList from '../common/SimpleList'
import SalePVPPModal from './SalePVPPModal'
import SaleContractModal from './SaleContractModal'

const styles = theme => ({
    listContainer: {
        padding: 24,
        overflow: 'auto'
    },
    subtitle: {
        fontSize: '36px',
        fontWeight: '500',
        paddingTop: '20px',
        color: theme.palette.primaryColor,
        display: 'block',
        width: '350px'
    },
    table: {
        marginTop: '20px'
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    withoutSidebar: {
        padding: '24px 36px 24px 0px'
    }
})

class InProgress extends Component {
    state = {
        renderPage: false,
        inWork: [],
        offerId: null,
        from: 0,
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        openSaleContractModal: false,
        openPVPPContractModal: false,
        selectedRow: null,
        search: '',
        openFileModal: false,
        headerFields: this.props.language.simpleTable.headerInWork,
    }

    componentDidMount() {
        this.getInWork()
    }

    getInWork = () => {
        let params = {
            search: this.state.search
        }

        return this.props.get({
            ...params
        }).then(inWorkResponse => {
            this.setState({
                inWork: inWorkResponse.rows,
                count: inWorkResponse.count,
                renderPage: true
            })
            return Promise.resolve()
        })
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, () => this.getInWork())
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getInWork())
    }

    onSearch = search => this.setState({ search }, () => this.getInWork())

    generateSaleContract = row => {
        this.setState({ openSaleContractModal: true, selectedRow: row })
    }

    generatePVPPContract = row => {
        this.setState({ openPVPPContractModal: true, selectedRow: row })
    }

    onSelectHandler = inWork => {
        this.setState({ openFileModal: true, offerId: inWork.offer.id })
    }

    render() {
        const { classes } = this.props
        if (this.state.renderPage) {
            return (<>
                <div className={classes.titleContainer}>
                    <SearchableHeader
                        placeholder={this.props.placeholderLanguage}
                        title={this.props.language.inWorkTitle}
                        onSearch={this.onSearch}
                    />
                </div>
                <div className={`${!this.props.sidebarReducer.displaySidebar ? this.props.classes.withoutSidebar : ""} ${this.props.classes.listContainer}`}>
                    <FileModal offerId={this.state.offerId} onClose={() => this.setState({ openFileModal: false })} open={this.state.openFileModal} />
                    <SaleContractModal
                        onClose={() => this.setState({ selectedRow: null, openSaleContractModal: false }, () => this.getInWork())}
                        row={this.state.selectedRow}
                        open={this.state.openSaleContractModal} />
                    <SalePVPPModal
                        onClose={() => this.setState({ selectedRow: null, openPVPPContractModal: false }, () => this.getInWork())}
                        row={this.state.selectedRow}
                        open={this.state.openPVPPContractModal} />
                    <SimpleList
                        generateSaleContract={row => this.generateSaleContract(row)}
                        generatePVPPContract={row => this.generatePVPPContract(row)}
                        headerFields={this.state.headerFields}
                        getInWork={this.getInWork}
                        items={this.state.inWork}
                        countTitle={this.props.language.carsInStock}
                        title={<Typography className={this.props.classes.subtitle} component='span'>{this.props.language.inWorkTitle}</Typography>}
                        actionsTitle={this.props.language.simpleTable.actionsTitle}
                        disableBorders
                        selectedOption={CONSTANTS.IN_WORK_OPTION}
                        onSelect={inWork => this.onSelectHandler(inWork)}
                        noHeader
                        search={{
                            onSearch: this.onSearch
                        }}
                        rowsPerPage={this.state.rowsPerPage}
                        count={this.state.count}
                        changeRowsPerPage={this.changeRowsPerPageHandler}
                        changePageHandler={this.changePageHandler}
                    />
                </div>
            </>)
        }
        else return null
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.stock,
    login: state.loginReducer,
    sidebarReducer: state.sidebarReducer,
    placeholderLanguage: state.languageReducer.i18n.car.searchPlaceholder
})

const mapDispatchToProps = dispatch => ({
    get: options => dispatch(OFFER.getInWork(options))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InProgress))