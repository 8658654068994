import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'

const styles = theme => ({
    firstTtitle: {
        fontSize: 20,
        color: '#3e3f42',
        fontWeight: 500
    },
    secondTitle: {
        fontSize: 14,
        color: '#6b6c6f',
        fontWeight: 'normal'
    },
    modalContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    cancelButton: {
        marginLeft: 'auto',
        paddingRight: 14,
    },
    topRightCancelButton: {
        cursor: 'pointer',
        fontSize: 18
    }
})

class OfferModalTitle extends Component {

    render() {
        if (this.props.item) {
            if (this.props.title) {
                return (
                    <div className={this.props.classes.modalContainer}>
                        <div className={this.props.classes.titleContainer}>
                            <div className={this.props.classes.firstTtitle}>{this.props.title}</div>
                        </div>
                        <div className={this.props.classes.cancelButton}>
                            <Close onClick={this.props.onCancel} className={this.props.classes.topRightCancelButton} />
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className={this.props.classes.modalContainer}>
                        <div className={this.props.classes.firstTtitle}>
                            {this.props.isOffer ? this.props.language.withOfferTitle : this.props.title ? this.props.title : this.props.language.noOffer}&nbsp;
                        </div>
                        <div className={this.props.classes.titleContainer}>
                            <div className={this.props.classes.firstTtitle}>{this.props.item.carBrand.name} {this.props.item.carModel.name}</div>
                            <div className={this.props.classes.secondTitle}>{this.props.language.offerId} {this.props.item.id} - {this.props.language.command} {this.props.item.orderNumber}</div>
                        </div>
                        <div className={this.props.classes.cancelButton}>
                            <Close onClick={this.props.onCancel} className={this.props.classes.topRightCancelButton} />
                        </div>
                    </div>
                )
            }
        }
        else return null;
    }
}


const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.itemDetails.offer
})

const mapDispatchToProps = dispatch => ({

})


export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OfferModalTitle))