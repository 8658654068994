import React from 'react'
import { connect } from 'react-redux'
import { withStyles, Button } from '@material-ui/core'
import InputGenerator from '../../common/subComponents/InputGenerator'
import * as STOCKS from '../../../redux/actions/stocks'
import OptionalsList from './OptionalsList'

const styles = theme => ({
    inputRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    col: {
        display: 'flex',
        flexDirection: 'row',
        '&:nth-of-type(1)': {
            flex: 1
        },
        '&:nth-of-type(2)': {
            flex: 3,
            marginLeft: 12,
        },
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        marginTop: 15,
        borderTop: '1px solid #e0e0e0',
        paddingTop: 15
    },
    code: {
        width: 100
    },
    description: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    addButton: {
        height: 45,
        marginTop: 7,
        marginLeft: 12
    },
    flex1: {
        flex: 1
    }
})

// TODO: optionals list is still TEXT

class Optionals extends React.Component {

    carBrandId = null

    state = {
        providedOptionals: [],
        inputType: 'text',
        codeValue: '',
        optionalValue: '',
        optionalsText: '',
    }

    componentWillReceiveProps(props) {
        if (props.carBrandId && props.carBrandId !== this.carBrandId) {
            this.carBrandId = props.carBrandId
        }
        if (props.optionalsText && props.optionalsText !== this.state.optionalsText) {
            this.setState({
                optionalsText: props.optionalsText
            })
        }
    }

    componentDidMount() {
        if (this.props.optionalsText) {
            this.setState({
                optionalsText: this.props.optionalsText
            })
        }
        if (this.props.carBrandId) {
            this.carBrandId = this.props.carBrandId
        }
    }

    onChangeCode = event => {
        this.setState({ codeValue: event.target.value })
        this.props.getOptionals(
            this.carBrandId,
            event.target.value
        ).then(({ optionals }) => {
            this.setState({
                providedOptionals: optionals.map(o => ({
                    label: o.value,
                    name: o.id,
                    code: o.code,
                })),
                inputType: !optionals.length || !this.state.codeValue.length ? 'text' : 'dropdownSelector',
                optionalValue: optionals.length && this.state.codeValue.length ? optionals[0].id : ''
            })
        })
    }

    onSelectOptional = event => {
        this.setState({
            optionalValue: event.target.value
        })
    }

    onChangeList = newList => {
        this.setState({
            optionalsText: newList
        })
        this.props.onChangeOptionalText(newList)
    }

    optionalToText = optional => `${optional.code}@${optional.value}#`

    addOption = () => {
        if(!this.state.optionalValue.toString().trim() && this.state.codeValue.toString().trim()) return null

        if (this.state.inputType === 'text' && this.state.optionalValue) {
            const optionalObj = {
                carBrandId: this.carBrandId,
                value: this.state.optionalValue,
                code: this.state.codeValue
            }
            this.props.createOptional(optionalObj).then(() => {
                let optionalText = `${this.state.optionalsText}${this.optionalToText(optionalObj)}`
                this.setState({
                    optionalsText: optionalText,
                    optionalValue: '',
                    codeValue: ''
                })
                this.props.onChangeOptionalText(optionalText)
            })
        } else {
            const currentOptional = this.state.providedOptionals.find(o => o.name === Number(this.state.optionalValue))
            if (currentOptional) {
                let optionalText = `${this.state.optionalsText}${this.optionalToText({
                    code: currentOptional.code,
                    value: currentOptional.label
                })}`
                this.setState({
                    optionalsText: optionalText,
                    optionalValue: '',
                    codeValue: '',
                    inputType: 'text'
                })
                this.props.onChangeOptionalText(optionalText)
            }
        }
    }

    render() {
        const { classes, language } = this.props

        return <div className={classes.wrapper}>
            <div className={classes.inputRow}>
                <div className={`${classes.col} ${classes.code}`}>
                    <InputGenerator
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        margin="dense"
                        type="text"
                        value={this.state.codeValue}
                        label={language.optionalsCode}
                        onChange={this.onChangeCode}
                    />
                </div>
                <div className={`${classes.col}`}>
                    <div className={classes.flex1} >
                        <InputGenerator
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            className={`${classes.description}`}
                            margin="dense"
                            type = {this.state.inputType ? this.state.inputType : 'text'}
                            name="optionalDescription"
                            utils={language.optionalsValue}
                            value={this.state.optionalValue}
                            label={language.optionalsValue}
                            options={this.state.providedOptionals}
                            onChange={this.onSelectOptional}
                        />
                    </div>
                    <Button variant="contained" className={classes.addButton} onClick={this.addOption}>+</Button>
                </div>
            </div>
            <div className={classes.inputRow}>
                <OptionalsList onChangeList={this.onChangeList} list={this.state.optionalsText} />
            </div>
        </div>
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails.stock,
})

const mapDispatchToProps = dispatch => ({
    getOptionals: (carBrandId, code) => dispatch(STOCKS.getOptionals(carBrandId, code)),
    createOptional: (optional) => dispatch(STOCKS.createOptional(optional))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Optionals))