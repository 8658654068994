import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, TableRow, TableCell, Tooltip } from '@material-ui/core'
//import { DirectionsCar as CarIcon, People as ProviderIcon } from '@material-ui/icons'
import { formatNumber, ClickWrapper } from '../../../utils/helpers'
import ListAvatarAndCarBrand from '../../common/subComponents/ListAvatarAndCarBrand'

import * as CONSTANTS from '../../../utils/constants'
import config from '../../../config/local.json'


const styles = theme => ({
    fieldText: {
        fontWeight: 'normal',
        fontSize: 14,
        paddingTop: 4
    },
    title: {
        width: 350
    },
    clickedButton: {
        color: 'green'
    }
})

class FinancingStockList extends Component {

    calculateEntryValue = () => {
        if (this.props.item.type === 'external') return formatNumber(this.props.item.ShPurchasePrice * this.props.item.purchaseExchangeRate)

        let total = 0
        if (this.props.item.type === 'sh') {
            total = this.props.item.ShPurchasePrice * this.props.item.purchaseExchangeRate
        } else {
            total = this.props.item.purchasePrice * this.props.item.purchaseExchangeRate
        }
        let result = formatNumber(total + total * (CONSTANTS.VAT / 100))

        return result
    }

    render() {
        const logoPath = `${config.baseUrl}/cars/brands/${this.props.item.carBrandId}/image`
        let fieldsToRenderInPrimaryText = [
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.ORDERNUMBER, name: this.props.language.stock.orderNumber },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.VINNUMBER, name: this.props.language.stock.vinNumber },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.CONTRACT_NUMBER, name: this.props.language.fCost.contractNumber },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.ENTRY_VALUE, name: this.props.language.fCost.entryValue, symbol: CONSTANTS.RON_SYMBOL },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.TOTALCONTRIBUTION, name: this.props.language.fCost.initialContribution, symbol: CONSTANTS.RON_SYMBOL },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.FINANCEDVALUE, name: this.props.language.fCost.financedValue, symbol: CONSTANTS.RON_SYMBOL },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.REMAINEDVALUE, name: this.props.language.fCost.remainedValue, symbol: CONSTANTS.RON_SYMBOL },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.TOTAL, name: this.props.language.fCost.total, symbol: CONSTANTS.RON_SYMBOL },
        ]

        return (
            <Tooltip placement="top" title={this.props.item.comment}>
                <TableRow>
                    <ClickWrapper onClick={() => this.props.onSelect(this.props.item)}>
                        <TableCell>
                            <ListAvatarAndCarBrand
                                carBrandName={this.props.item.carBrand.name}
                                carModelName={this.props.item.carModel.name}
                                logoPath={logoPath}
                            />
                        </TableCell>
                        {fieldsToRenderInPrimaryText.map((fieldObj, index) => {

                            if (fieldObj.field === CONSTANTS.FINANCING_COST_DETAILS_FIELDS.ORDERNUMBER ||
                                fieldObj.field === CONSTANTS.FINANCING_COST_DETAILS_FIELDS.VINNUMBER) {
                                return (<TableCell key={index} className={this.props.classes.primaryTextContainer}>
                                    {this.props.item[fieldObj.field] ?
                                        <span className={this.props.classes.fieldText}>{this.props.item[fieldObj.field]}</span> :
                                        <span className={this.props.classes.fieldText}>-</span>
                                    }
                                </TableCell>)
                            }

                            if (fieldObj.field === CONSTANTS.FINANCING_COST_DETAILS_FIELDS.CONTRACT_NUMBER) {
                                return (
                                    <TableCell key={index} className={this.props.classes.primaryTextContainer}>
                                        {this.props.item.financingCost && this.props.item.financingCost[fieldObj.field] ?
                                            <span className={this.props.classes.fieldText}>{this.props.item.financingCost[fieldObj.field]}</span> :
                                            <span className={this.props.classes.fieldText}>-</span>
                                        }
                                    </TableCell>
                                )
                            }

                            if (fieldObj.field === CONSTANTS.FINANCING_COST_DETAILS_FIELDS.ENTRY_VALUE) {
                                return (
                                    <TableCell key={index} className={this.props.classes.primaryTextContainer}>
                                        {
                                            <span className={this.props.classes.fieldText}>{`${this.calculateEntryValue()} ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span>
                                        }
                                    </TableCell>
                                )
                            }

                            if (fieldObj.field === CONSTANTS.FINANCING_COST_DETAILS_FIELDS.FINANCEDVALUE ||
                                fieldObj.field === CONSTANTS.FINANCING_COST_DETAILS_FIELDS.REMAINEDVALUE ||
                                fieldObj.field === CONSTANTS.FINANCING_COST_DETAILS_FIELDS.TOTALCONTRIBUTION) {
                                return (
                                    <TableCell key={index} className={this.props.classes.primaryTextContainer}>
                                        {this.props.item.financingCost && this.props.item.financingCost[fieldObj.field] && this.props.item.hasFinancing ?
                                            <span className={this.props.classes.fieldText}>{`${formatNumber(this.props.item.financingCost[fieldObj.field])} ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span> :
                                            <span className={this.props.classes.fieldText}>{`0 ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span>
                                        }
                                    </TableCell>
                                )
                            }

                            return (
                                <TableCell key={index} className={this.props.classes.primaryTextContainer}>
                                    {this.props.item.financingCost && this.props.item.financingCost[fieldObj.field] ?
                                        <span className={this.props.classes.fieldText}>{`${formatNumber(this.props.item.financingCost[fieldObj.field])} ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span> :
                                        <span className={this.props.classes.fieldText}>{`0 ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span>
                                    }
                                </TableCell>
                            )
                        })}
                    </ClickWrapper>
                </TableRow>
            </Tooltip >
        )
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails,
})

const mapDispatchToProps = dispatch => ({

})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FinancingStockList))