import queryString from 'query-string'
import axios from '../../utils/axios'
import { errorHandler } from '../../utils/apiFunctions'

const baseRoute = '/settings'

export const get = queryParams => dispatch =>
    axios.get(baseRoute + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const edit = settings => dispatch =>
    axios.put(`${baseRoute}`, { settings })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)