import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SimpleModal from './SimpleModal'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
    currentImage: {
        height: '500px',
        display: 'block',
        '& img': {
            height: '100%',
            display: 'block',
            margin: 'auto'
        },
        borderBottom: '1px solid #e0e0e0',
        paddingBottom: '5px'
    },
    thumbnails: {
        height: '100px',
        paddingTop: '10px',
        cursor: 'pointer',
        width: '100%',
        maxWidth: '100%',
        overflow: 'auto',
        '& img': {
            float: 'left',
            padding: 5,
            boxSizing: 'border-box',
            height: '100%'
        }
    }
})

function Gallery(props) {
    const { classes } = props
    const [images, setImages] = useState(props.images)
    const [currentImage, setCurrentImage] = useState(props.images.length ? props.images[0].src : 'https://via.placeholder.com/250x200')

    useEffect(() => {
        setImages(props.images)
    })

    return <SimpleModal
        open={props.open}
        size="md"
        onClose={props.onClose}
        onCancel={props.onClose}
    >
        <div className={classes.currentImage}>
            <img src={currentImage} alt={currentImage} />
        </div>
        <div className={classes.thumbnails}>
            {images.map(({ src }) => <img key={src} src={src} alt={src} onClick={() => setCurrentImage(src)} />)}
        </div>
    </SimpleModal>
}

Gallery.propTypes = {
    images: PropTypes.array.isRequired,
}

export default withStyles(styles)(Gallery)