import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import Timeline from '../common/Timeline'


const styles = theme => ({
    offersContainer: {
        padding: '10px 10px 20px 30px'
    },
    addButton: {
        float: 'right',
        top: '0px',
        right: '10px'
    },
    detailsTitle: {
        color: '#3e3f42',
        fontWeight: 500,
        fontSize: 20
    },
    detailsHead: {
        width: '100%',
        height: '50px'
    },
    addIconStyle: {
        paddingRight: 5
    },
    back: {
        cursor: 'pointer',
        color: '#6b6c6f',
        fontSize: 12
    }
})

class OfferListTimeline extends Component {
    render() {
        const { classes } = this.props
        const i18n = this.props.language
        return <div className={classes.offersContainer}>
            <div className={classes.detailsHead}>
                {/*<Button color="secondary" className={classes.addButton}>
                    <AddIcon className={this.props.classes.addIconStyle} />
                    <span className={this.props.classes.addTextStyle}>{i18n.add}</span>
                </Button> */}
                <h3 className={classes.detailsTitle}>{i18n.history}</h3>
            </div>
            <Timeline
                isCost={false}
                items={this.props.offers.map(offer => ({
                    createdAt: offer.createdAt,
                    author: offer.user.fullName,
                    clientName: offer.client.name,
                    sellingPriceEUR: offer.sellingPriceEUR,
                    discount: offer.discount
                }))}
            />
            <div>
                {
                    this.props.backOption && <span className={classes.back} onClick={() => this.props.setDetailsOption()}>{this.props.language.backToDetails}</span>
                }
            </div>
        </div>
    }
}


const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.itemDetails.offer
})

const mapDispatchToProps = dispatch => ({

})


export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OfferListTimeline))