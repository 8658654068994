import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tooltip, Paper, Table, TableHead, TableCell, TableBody, TableRow, Toolbar, withStyles } from '@material-ui/core'

import * as CONSTANTS from '../../utils/constants'
import StockList from '../stock/List/StockList'
import FinancingStockList from '../stock/List/FinancingStockList'
import CostsStockList from '../stock/List/CostsStockList'
import OfferList from '../offer/OfferList'
import StockForOfferList from '../offer/stockTab/StockForOfferList'
import ClientList from '../admin/client/ClientList'
import ProviderList from '../admin/provider/ProviderList'
import UserList from '../admin/user/UserList'
import CarBrandList from '../admin/car/CarBrandList'
import CarModelList from '../admin/car/CarModelList'
import TraceList from '../admin/trace/TraceList'
import SaleList from '../sale/SaleList'
// import MonitoringSoldList from '../monitoring/soldTab/SoldList'
// import MonitoringRentedList from '../monitoring/rentedTab/RentedList'
import MonitoringList from '../monitoring/MonitoringList'
import RentList from '../rent/RentList'
import TestDriveList from '../testDrive/TestDriveList'
import InWorkList from '../inWork/InWorkList'
import ReportList from '../admin/reports/ReportsList'

import { roleInArray } from '../../utils/helpers'

const style = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    headerActionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    renderOptionsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    headerDivider: {
        borderLeft: '1px solid rgba(0,0,0,0.1)',
        height: 40,
    },
    listToolbar: {
        display: 'flex',
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        position: 'sticky',
        top: -27,
        backgroundColor: '#ffffff',
        zIndex: 100
    },
    tableWrapperFullWidth: {
        borderRadius: 4,
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.08)',
        border: '1px solid rgba(0,0,0,0.1)'
    },
    elementCount: {
        paddingLeft: 30
    },
    elementCountText: {
        color: '#9ea0a5',
        fontSize: 14
    },
    actions: {
        padding: '0px 30px 14px 0px'
    },
    actionsContainer: {
        paddingRight: 12
    },
    listContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
    },
    pageButtonsContainer: {
        paddingTop: 14,
        display: 'flex'
    },
    firstOptionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    searchInput: {
        '&:before': {
            borderBottom: 0
        },
        '&:after': {
            borderBottom: 0
        },
        border: '1px solid rgba(0,0,0,0.1)',
        borderRadius: 4,
        marginRight: '30px'
    },
    disableBorders: {
        boxShadow: 'none'
    },
    disableToolbarPadding: {
        paddingLeft: '0px'
    },
    listPaper: {
        minHeight: '100%',
        width: '100%'
    },
    title: {
        width: 350
    },
    clickedOption: {
        borderBottom: '2px solid #252529'
    },
    optionText: {
        color: '#252529',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 500,
        transition: '0.15s'
    },
    optionDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginRight: 12
    },
    tableCellHeader: {
        position: 'sticky',
        top: 38,
        backgroundColor: '#ffffff',
        zIndex: 100
    },
    tableCellNoHeader: {
        position: 'sticky',
        top: -27,
        backgroundColor: '#ffffff',
        zIndex: 100
    },
    table: {
    },
})

class SimpleList extends Component {
    state = {
        searchInput: '',
        page: 0,
        rowsPerPage: this.props.rowsPerPage || CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE
    }

    changeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value }, () => this.props.changeRowsPerPage(this.state.rowsPerPage))
    }

    changePagePrev = () => {
        if (this.state.page > 0) {
            this.setState(prevState => ({ page: prevState.page - 1 }), () => {
                let currentFrom = this.state.page * this.state.rowsPerPage
                this.props.changePageHandler(parseInt(currentFrom))
            })
        }
    }


    changePageNext = () => this.setState(prevState => ({ page: prevState.page + 1 }), () => {
        let currentFrom = this.state.page * this.state.rowsPerPage
        this.props.changePageHandler(currentFrom)
    })


    renderHeaderTabs = headerTabs => {
        return (
            <TableRow>
                {headerTabs.map((field, index) => {
                    return (
                        <TableCell key={index} className={`${this.props.noHeader ? this.props.classes.tableCellNoHeader : this.props.classes.tableCellHeader}`}>
                            {field}
                        </TableCell>
                    )
                })}
                {this.props.actions ? <TableCell className={`${this.props.noHeader ? this.props.classes.tableCellNoHeader : this.props.classes.tableCellHeader}`}>
                    {CONSTANTS.HEADERS.ACTIONS}
                </TableCell> : null}
            </TableRow>
        )
    }

    headerTabsReder = () => {
        if (this.props.headerFields) return this.renderHeaderTabs(this.props.headerFields)
    }

    render() {
        const addButtonRoles = this.props.selectedOption === CONSTANTS.CLIENT_OPTIONS ? [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.EXTERNAL_SELLER, CONSTANTS.ROLES.IPMG_SELLER] : [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.EXTERNAL_SELLER]

        return (
            <>
                <div className={this.props.classes.tableWrapperFullWidth}>
                    <Paper className={`${this.props.listClass}  ${this.props.disableBorders ? this.props.classes.disableBorders : ''} ${this.props.classes.listPaper}`}>
                        {this.props.noHeader ? null :
                            <Toolbar className={`${this.props.classes.listToolbar} ${this.props.disableToolbarPadding ? this.props.classes.disableToolbarPadding : ''}`}>
                                <div className={this.props.classes.headerActionsContainer}>
                                    <div className={this.props.classes.actions}>
                                        {this.props.headerButton && roleInArray(this.props.loginReducer.roles, addButtonRoles) &&
                                            <Tooltip title={this.props.headerButton.tooltip}>
                                                <div onClick={this.props.headerButton.onClick} color={this.props.headerButton.color || 'secondary'} aria-label={this.props.headerButton.tooltip}>
                                                    {this.props.headerButton.icon}
                                                </div>
                                            </Tooltip>}
                                    </div>
                                    {roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.EXTERNAL_SELLER]) && <div className={this.props.classes.headerDivider}></div>}
                                    <div className={this.props.classes.elementCount}>
                                        {this.props.count && this.props.countTitle ?
                                            <span className={this.props.classes.elementCountText}>{this.props.count || null} {this.props.countTitle}</span> : ''}
                                    </div>
                                </div>
                                <div className={this.props.classes.renderOptionsContainer}>
                                    <div className={this.props.classes.optionsContainer}>
                                        <div className={this.props.classes.firstOptionsContainer}>
                                            {this.props.headerFirstOptions ? this.props.headerFirstOptions.map((option, index) => {
                                                if (roleInArray(this.props.loginReducer.roles, option.role)) return (
                                                    <div key={index} className={`${option.clicked ? this.props.classes.clickedOption : ""} ${this.props.classes.optionDiv}`}>
                                                        <span className={`${this.props.classes.optionText}`} onClick={() => option.onClick(option)}>{option.name}</span>
                                                    </div>
                                                )
                                                else return null
                                            }) : null}
                                        </div>

                                    </div>
                                </div>

                            </Toolbar>}
                        <div className={this.props.classes.listContainer}>
                            <Table className={`${this.props.classes.table}`}>
                                <TableHead>
                                    {this.headerTabsReder()}
                                </TableHead>

                                <TableBody>
                                    {
                                        this.props.items.map((item, index) => {
                                            switch (this.props.selectedOption) {
                                                // case CONSTANTS.MONITORING_OPTIONS.MONITORING_SOLD:
                                                //     return <MonitoringSoldList
                                                //         headerFields={this.props.headerFields}
                                                //         item={item}
                                                //         key={index}
                                                //         reloadList={this.props.reloadList}
                                                //         onSelect={(stockId) => this.props.onSelect(stockId)}
                                                //     />
                                                // case CONSTANTS.MONITORING_OPTIONS.MONITORING_RENTED:
                                                //     return <MonitoringRentedList
                                                //         headerFields={this.props.headerFields}
                                                //         item={item}
                                                //         key={index}
                                                //         reloadList={this.props.reloadList}
                                                //         onSelect={(stockId) => this.props.onSelect(stockId)}
                                                //     />
                                                case CONSTANTS.MONITORING:
                                                    return <MonitoringList
                                                        headerFields={this.props.headerFields}
                                                        item={item}
                                                        key={index}
                                                        reloadList={this.props.reloadList}
                                                        onChangeLogistics={this.props.onChangeLogistics}
                                                        onChangeRefound={this.props.onChangeRefound}
                                                        onSelect={(stockId) => this.props.onSelect(stockId)}
                                                    />

                                                case CONSTANTS.TRACE_OPTION:
                                                    return <TraceList
                                                        headerFields={this.props.headerFields}
                                                        item={item}
                                                        key={index}
                                                        onSelect={(stockId) => this.props.onSelect(stockId)}
                                                    />
                                                case CONSTANTS.STOCK_OPTIONS.SUMAR:
                                                    return (
                                                        <StockList
                                                            item={item}
                                                            getStocks={this.props.getStocks}
                                                            key={index}
                                                            headerFields={this.props.headerFields}
                                                            actions={this.props.actions}
                                                            onSelect={this.props.onSelect}
                                                        />
                                                    )
                                                case CONSTANTS.STOCK_OPTIONS.COST:
                                                    return (
                                                        <CostsStockList
                                                            item={item}
                                                            key={index}
                                                            actions={this.props.actions}
                                                            onSelect={this.props.onSelect}
                                                        />
                                                    )
                                                case CONSTANTS.STOCK_OPTIONS.FINANCIAL:
                                                    return (
                                                        <FinancingStockList
                                                            item={item}
                                                            key={index}
                                                            actions={this.props.actions}
                                                            onSelect={this.props.onSelect}
                                                        />
                                                    )
                                                case CONSTANTS.OFFER_OPTIONS.SUMAR_OFFER:
                                                    return (
                                                        <OfferList
                                                            item={item}
                                                            key={index}
                                                            actions={this.props.actions}
                                                            onSelect={this.props.onSelect}
                                                        />
                                                    )
                                                case CONSTANTS.OFFER_OPTIONS.SUMAR_STOCK_OFFER:
                                                    return (
                                                        <StockForOfferList
                                                            item={item}
                                                            key={index}
                                                            actions={this.props.actions}
                                                            onSelect={this.props.onSelect}
                                                        />
                                                    )
                                                case CONSTANTS.CLIENT_OPTIONS:
                                                    return (
                                                        <ClientList
                                                            item={item}
                                                            key={index}
                                                            actions={this.props.actions}
                                                            onSelect={this.props.onSelect}
                                                        />
                                                    )
                                                case CONSTANTS.PROVIDER_OPTIONS:
                                                    return (
                                                        <ProviderList
                                                            item={item}
                                                            key={index}
                                                            actions={this.props.actions}
                                                            onSelect={this.props.onSelect}
                                                        />
                                                    )
                                                case CONSTANTS.USER_OPTIONS:
                                                    return (
                                                        <UserList
                                                            item={item}
                                                            key={index}
                                                            actions={this.props.actions}
                                                            onSelect={this.props.onSelect}
                                                        />
                                                    )
                                                case CONSTANTS.CAR_OPTIONS.BRAND:
                                                    return (
                                                        <CarBrandList
                                                            selectedItem={this.props.selectedRow}
                                                            item={item}
                                                            key={index}
                                                            actions={this.props.actions}
                                                            onSelect={this.props.onSelect}
                                                        />
                                                    )
                                                case CONSTANTS.CAR_OPTIONS.MODEL:
                                                    return (
                                                        <CarModelList
                                                            item={item}
                                                            key={index}
                                                            actions={this.props.actions}
                                                            onSelect={this.props.onSelect}
                                                        />
                                                    )
                                                case CONSTANTS.SALE_OPTIONS:
                                                    return (
                                                        <SaleList
                                                            item={item}
                                                            key={index}
                                                            actions={this.props.actions}
                                                            onSelect={this.props.onSelect}
                                                            addDetails={this.props.addDetails}
                                                        />
                                                    )
                                                case CONSTANTS.RENT_OPTION:
                                                    return (
                                                        <RentList
                                                            item={item}
                                                            setRentPVPPModal={this.props.setRentPVPPModal}
                                                            setRentExtendModal={this.props.setRentExtendModal}
                                                            getRents={this.props.getRents}
                                                            key={index}
                                                            headerFields={this.props.headerFields}
                                                            actions={this.props.actions}
                                                            onSelect={this.props.onSelect}
                                                        />
                                                    )
                                                case CONSTANTS.TEST_DRIVE_OPTION:
                                                    return (
                                                        <TestDriveList
                                                            setTestDriveExtendModal={this.props.setTestDriveExtendModal}
                                                            item={item}
                                                            getTestDrives={this.props.getTestDrives}
                                                            key={index}
                                                            headerFields={this.props.headerFields}
                                                            actions={this.props.actions}
                                                            onSelect={this.props.onSelect}
                                                        />
                                                    )
                                                case CONSTANTS.IN_WORK_OPTION:
                                                    return (
                                                        <InWorkList
                                                            generateSaleContract={(row) => this.props.generateSaleContract(row)}
                                                            generatePVPPContract={this.props.generatePVPPContract}
                                                            item={item}
                                                            getInWork={this.props.getInWork}
                                                            key={index}
                                                            headerFields={this.props.headerFields}
                                                            actions={this.props.actions}
                                                            onSelect={this.props.onSelect}
                                                        />
                                                    )
                                                case CONSTANTS.REPORTS_OPTIONS:
                                                    return (
                                                        <ReportList
                                                            item={item}
                                                            headerFields={this.props.headerFields}
                                                        />
                                                    )
                                                default:
                                                    return <StockList
                                                        item={item}
                                                        key={index}
                                                        getStocks={this.props.getStocks}
                                                        headerFields={this.props.headerFields}
                                                        actions={this.props.actions}
                                                        onSelect={this.props.onSelect}
                                                    />
                                            }
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </Paper>
                </div>
                <div className={this.props.classes.pageButtonsContainer}>
                    {this.props.legend ? this.props.legend : null}
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n,
    loginReducer: state.loginReducer
})

const mapDispatchToProps = dispatch => ({
})

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(SimpleList))