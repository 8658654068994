import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'

import * as SALE from '../../redux/actions/sales'
import * as STOCK from '../../redux/actions/stocks'

import SimpleModal from '../common/SimpleModal'
import SimpleTabs from '../common/SimpleTabs'
import OffersList from './lists/OffersList'
import RentsList from './lists/RentsList'
import TestDrivesList from './lists/TestDrivesList'
import ItemDetails from '../common/ItemDetails'
import LeasingCostTabDetails from '../costs/leasingCost/LeasingCostTabDetails'

const styles = theme => ({

})

class SaleModal extends Component {
    state = {
        activeTab: 0,
        openStockDetails: false,
        stockForDetails: null
    }

    handleTitleClick = () => {
        this.getStockById(this.props.item.id)
    }

    getStockById = stockId => {
        this.props.getStockById(stockId).then(stock => {
            this.setState({ stockForDetails: stock, openStockDetails: true })
        })
    }

    render() {
        return this.props.item ? (
            <>
                {this.state.stockForDetails && <ItemDetails
                    open={this.state.openStockDetails}
                    item={this.state.stockForDetails}
                    fullStock={this.state.stockForDetails}
                    maxWidth={'md'}
                    noHeaders={true}
                    onCancel={() => this.setState({ openStockDetails: false })} />}
                <SimpleModal
                    actionOnTitle={() => this.handleTitleClick()}
                    open={this.props.open}
                    onCancel={this.props.onClose}
                    cancelButtonText={this.props.language.cancel}
                    onClose={this.props.onClose}
                    title={`${this.props.item.carBrand.name} ${this.props.item.carModel.name}`}
                    maxWidth='xl'
                    fullScreen={true}
                >
                    <SimpleTabs
                        tabs={[{
                            label: this.props.language.offers,
                            index: 0
                        },
                        {
                            label: this.props.language.rented,
                            index: 1
                        },
                        {
                            label: this.props.language.testDrives,
                            index: 2
                        },
                        {
                            label: this.props.language.costs,
                            index: 3
                        }
                        ]}
                        value={this.state.activeTab}
                        onChangeHandler={index => {
                            this.setState({ activeTab: index, search: ''})
                        }}
                    >
                        <SimpleTabs.Tab index={0} >
                            <OffersList stockId={this.props.item.id} />
                        </SimpleTabs.Tab>
                        <SimpleTabs.Tab index={1}>
                            <RentsList stockId={this.props.item.id} />
                        </SimpleTabs.Tab>
                        <SimpleTabs.Tab index={2}>
                            <TestDrivesList stockId={this.props.item.id} />
                        </SimpleTabs.Tab>
                        <SimpleTabs.Tab index={3}>
                            <LeasingCostTabDetails fullStock={this.props.item} stockId={this.props.item.id} id={this.props.item.id} />
                        </SimpleTabs.Tab>
                    </SimpleTabs>
                </SimpleModal>
            </>) : null
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.sale,
})

const mapDispatchToProps = dispatch => ({
    get: options => dispatch(SALE.get(options)),
    getStockById: stockId => dispatch(STOCK.getById(stockId))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SaleModal))