import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography, InputAdornment, Tooltip, Paper, Toolbar, TextField, Button, withStyles } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import SumarCard from '../stock/Cards/SumarCard'
import FinancingCard from '../stock/Cards/FinancingCard'
import CostCard from '../stock/Cards/CostsCard'
import SwitchPage from './subComponents/SwitchPage'

import * as CONSTANTS from '../../utils/constants'


const style = theme => ({
    tableBackground: {
        backgroundColor: theme.palette.common.white
    },
    spacer: {
        flex: '1 1 100%'
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    cardsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0px 14px 24px 0px',
        flexDirection: 'column'
    },
    pageButtonsContainer: {
        textAlign: 'center'
    },
    searchIcon: {
        fontSize: '21px'
    },
    clickedButton: {
        color: 'green'
    },
    searchInput: {
        fontSize: '',
        width: '350px',
        marginRight: '30px'
    },
    searchInputUnderline: {
        '&:after': {
            borderBottom: '2px solid ' + theme.palette.redColor,
        }
    },
    disableBorders: {
        boxShadow: 'none'
    },
    disableToolbarPadding: {
        paddingLeft: '0px'
    },
    cardsPaper: {
        minHeight: '100%'
    },
    title: {
        width: 350
    },
    optionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 12
    },
    firstOptionsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingLeft: 24
    },
    secondOptionsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingRight: 24
    }
})

class SimpleCards extends Component {

    state = {
        searchInput: '',
        page: 0,
        rowsPerPage: this.props.rowsPerPage
    }

    changeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value }, () => this.props.changeRowsPerPage(this.state.rowsPerPage))
    }

    changePagePrev = () => {
        if (this.state.page >= 1) {
            this.setState((prevState) => ({ page: prevState.page >= 1 ? prevState.page - 1 : prevState.page }), this.props.changePageHandler(CONSTANTS.PREV))
        }
    }


    changePageNext = () => {
        this.setState((prevState) => ({ page: prevState.page + 1 }), this.props.changePageHandler(CONSTANTS.NEXT))
    }

    render() {

        return (
            <div className={this.props.classes.tableWrapperFullWidth}>
                <Paper className={`${this.props.cardsClass}  ${this.props.disableBorders ? this.props.classes.disableBorders : ''} ${this.props.classes.cardsPaper}`}>
                    <div className={this.props.classes.cardsContainer}>
                        {

                            this.props.cards.map((card, index) => {
                                return (
                                    <div key={index} className={this.props.classes.cardContainer}>
                                        <SumarCard
                                            key={index}
                                            stocksForOffer={this.props.stocksForOffer}
                                            openRentModal={this.props.openRentModal}
                                            openTestDriveModal={this.props.openTestDriveModal}
                                            openStockDetails={this.props.openStockDetails}
                                            resetCheckboxes={this.props.resetCheckboxes}
                                            offerForSingleStock={item => this.props.offerForSingleStock(item)}
                                            numberOfStocksForOffer={this.props.numberOfStocksForOffer}
                                            settings={this.props.settings}
                                            selectStock={(event, stock) => this.props.selectStock(event, stock)}
                                            cardImage={this.props.displayImages}
                                            subtitle={card.provider.name}
                                            title={card.carBrand.name + " " + card.carModel.name}
                                            item={card}
                                            actions={this.props.actions} />
                                    </div>)
                            })

                        }
                    </div>
                </Paper>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n,
})

const mapDispatchToProps = dispatch => ({
})

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(SimpleCards))