import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles, TableRow, TableCell, Tooltip } from '@material-ui/core';

import moment from 'moment';

import ListAvatarAndCarBrand from '../common/subComponents/ListAvatarAndCarBrand';
import DropdownMenu from '../common/DropdownMenu';

import * as CONSTANTS from '../../utils/constants';
import { formatNumber, ClickWrapper } from '../../utils/helpers';
import config from '../../config/local.json';

const styles = (theme) => ({
  fieldText: {
    fontSize: '14px',
    paddingTop: '4px',
  },
  title: {
    width: 350,
  },
  clickedButton: {
    color: 'green',
  },
  listWrapper: {
    '& span:not(:first-child)': {
      display: 'inline-block',
      marginLeft: '5px',
    },
  },
  hrefStyle: {
    color: 'black',
    paddingRight: 8,
  },
  iconStyle: {
    fontSize: 24,
  },
  tooltipStyle: {
    fontSize: '16px !important',
  },
  ulStyle: {
    marginLeft: '8px',
    paddingLeft: '8px',
  },
  emptyField:{
    borderBottom: 'none',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  populatedField:{
    fontWeight: '500',
    borderBottom: 'none',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    padding: '4px 10px 4px 15px !important', 
  },
  dateRow:{
    backgroundColor: "#303034"
  },
  date:{
    color: "white",
    fontSize: '18px',
  }
});

class SaleList extends Component {
  computeProfit = () => {
    let exchangeRateDiff =
      Number(this.props.item.offer.sellingExchangeRate || 0) -
      Number(this.props.item.offer.stock.purchaseExchangeRate || 0);
    let exchangeRateDiffRON =
      this.props.item.offer.sellingPriceEUR * exchangeRateDiff;
    let exchangeRateDiffEUR =
      exchangeRateDiffRON /
      Number(this.props.item.offer.stock.purchaseExchangeRate || 1);

    return (
      <ul className={this.props.classes.ulStyle}>
        <li>
          {this.props.language.offer.carProfitEUR}:{' '}
          {formatNumber(this.props.item.offer.carProfitEUR)} €
        </li>
        <li>
          {this.props.language.offer.exchangeRateProfitEUR}:{' '}
          {formatNumber(exchangeRateDiffEUR)} €
        </li>
        <li>
          {this.props.language.offer.leasingProfitEUR}:{' '}
          {formatNumber(this.props.item.offer.leasingEUR)} €
        </li>
      </ul>
    )
  }

  render() {
    const logoPath = `${config.baseUrl}/cars/brands/${this.props.item.offer.stock.carBrand.id}/image`;
    let fieldsToRenderInPrimaryText = [
      {
        field: CONSTANTS.SALE_FIELDS.SALE_DATE,
        name: this.props.language.createdAt,
      },
      {
        field: CONSTANTS.SALE_FIELDS.PROVIDER,
        name: this.props.language.offer.provider,
      },
      {
        field: CONSTANTS.SALE_FIELDS.VINNUMBER,
        name: this.props.language.offer.vinNumber,
        populate: 'stock',
      },
      {
        field: CONSTANTS.SALE_FIELDS.REGISTRATIONDATE,
        name: this.props.language.offer.registrationDate,
        populate: 'stock',
      },
      {
        field: CONSTANTS.SALE_FIELDS.LIST_PRICE,
        name: this.props.language.offer.listPrice,
        populate: 'stock',
        symbol: CONSTANTS.EURO_SYMBOL,
      },
      {
        field: CONSTANTS.SALE_FIELDS.DISCOUNTPERCENT,
        name: this.props.language.offer.discountPercent,
        symbol: '%',
      },
      {
        field: CONSTANTS.SALE_FIELDS.DISCOUNTEUR,
        name: this.props.language.offer.discountEUR,
        symbol: CONSTANTS.EURO_SYMBOL,
      },
      {
        field: CONSTANTS.SALE_FIELDS.SELLING_PRICE_EU,
        name: this.props.language.offer.sellingPriceEUR,
        symbol: CONSTANTS.EURO_SYMBOL,
      },
      {
        field: CONSTANTS.SALE_FIELDS.TOTAL_PROFIT,
        name: this.props.language.offer.sellingPriceEUR,
        symbol: CONSTANTS.EURO_SYMBOL,
      },
      {
        field: CONSTANTS.SALE_FIELDS.NAME,
        name: this.props.language.offer.clientName,
        populate: 'client',
      },
    ]
    if(this.props.item.isYear)
      return (
      
        <TableRow className={this.props.classes.dateRow}>
            <TableCell>
            <span
              className={this.props.classes.date}
            >
              {this.props.item.year.year}&nbsp;/&nbsp;{this.props.item.month.month}
            </span>
            </TableCell>
            <TableCell>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
        </TableRow>
      )

    return (
      <Tooltip placement='top' title={this.props.item.offer.stock.comment}>
        <TableRow>
          <ClickWrapper onClick={() => this.props.onSelect(this.props.item)}>
            {fieldsToRenderInPrimaryText.map((fieldObj, index) => {
              if (fieldObj.populate) {
                if (fieldObj.field === CONSTANTS.SALE_FIELDS.REGISTRATIONDATE) {
                  return (
                    <TableCell key={index}>
                      {this.props.item.offer[fieldObj.populate][
                        fieldObj.field
                      ] ? (
                        <span
                          className={this.props.classes.fieldText}
                        >{`${moment(
                          this.props.item.offer[fieldObj.populate][
                          fieldObj.field
                          ]
                        ).format(CONSTANTS.DEFAULT_DATE_FORMAT)}`}</span>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  )
                }
                if (
                  typeof this.props.item.offer[fieldObj.populate][
                  fieldObj.field
                  ] === 'number'
                ) {
                  return (
                    <TableCell key={index}>
                      {this.props.item.offer[fieldObj.populate][
                        fieldObj.field
                      ] ? (
                        <span className={this.props.classes.fieldText}>
                          {`${formatNumber(
                            this.props.item.offer[fieldObj.populate][
                            fieldObj.field
                            ]
                          )} ${fieldObj.symbol ? fieldObj.symbol : ''}`}{' '}
                        </span>
                      ) : (
                        <span className={this.props.classes.fieldText}>{`0 ${fieldObj.symbol ? fieldObj.symbol : ''
                          }`}</span>
                      )}
                    </TableCell>
                  )
                }
                return (
                  <TableCell key={index}>
                    {this.props.item.offer[fieldObj.populate][
                      fieldObj.field
                    ] ? (
                      <span className={this.props.classes.fieldText}>{`${this.props.item.offer[fieldObj.populate][fieldObj.field]
                        }`}</span>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                )
              }
              if (fieldObj.field === CONSTANTS.SALE_FIELDS.TOTAL_PROFIT) {
                return (
                  <TableCell key={index}>
                    {this.props.item.offer[fieldObj.field] ? (
                      <Tooltip
                        classes={{ tooltip: this.props.classes.tooltipStyle }}
                        placement={'bottom'}
                        title={this.computeProfit()}
                      >
                        <span className={this.props.classes.fieldText}>
                          {`${formatNumber(
                            this.props.item.offer[fieldObj.field]
                          )} ${fieldObj.symbol ? fieldObj.symbol : ''}`}{' '}
                        </span>
                      </Tooltip>
                    ) : (
                      <span className={this.props.classes.fieldText}>{`0 ${fieldObj.symbol ? fieldObj.symbol : ''
                        }`}</span>
                    )}
                  </TableCell>
                )
              }
              if(fieldObj.field === CONSTANTS.SALE_FIELDS.SALE_DATE) {
                return (
                  <>
                    <TableCell key={index}>
                      {this.props.item.offer[fieldObj.field] ? (
                        <Tooltip
                          classes={{ tooltip: this.props.classes.tooltipStyle }}
                          placement={'bottom'}
                          title={this.computeProfit()}
                        >
                          <span className={this.props.classes.fieldText}>
                            {new Date(
                              this.props.item.createdAt
                            ).toLocaleDateString()}{' '}
                          </span>
                        </Tooltip>
                      ) : (
                        <span className={this.props.classes.fieldText}>{`0 ${fieldObj.symbol ? fieldObj.symbol : ''
                          }`}</span>
                      )}
                    </TableCell>
                    <TableCell>
                      <ListAvatarAndCarBrand
                        carBrandName={this.props.item.offer.stock.carBrand.name}
                        carModelName={this.props.item.offer.stock.carModel.name}
                        logoPath={logoPath}
                      />
                    </TableCell>
                  </>
                )
              }
              if (fieldObj.field === CONSTANTS.SALE_FIELDS.PROVIDER) {
                return (
                  <TableCell key={index}>
                    {this.props.item.offer.stock[fieldObj.field] ? (
                      <span className={this.props.classes.fieldText}>{`${this.props.item.offer.stock[fieldObj.field].name
                        }`}</span>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                )
              }
              return (
                <TableCell key={index}>
                  {this.props.item.offer[fieldObj.field] ? (
                    <span className={this.props.classes.fieldText}>
                      {`${formatNumber(
                        this.props.item.offer[fieldObj.field]
                      )} ${fieldObj.symbol ? fieldObj.symbol : ''}`}{' '}
                    </span>
                  ) : (
                    <span className={this.props.classes.fieldText}>{`0 ${fieldObj.symbol ? fieldObj.symbol : ''
                      }`}</span>
                  )}
                </TableCell>
              )
            })}
          </ClickWrapper>
          {
            <TableCell>
              <DropdownMenu
                type={this.props.language.stock.actions}
                item={this.props.item}
                items={[
                  {
                    tooltip: this.props.language.stock.details,
                    onClick: (_, row) => this.props.addDetails(row),
                  },
                  // {
                  //     tooltip: this.props.language.stock.getProformaInvoice,
                  //     onClick: (_, row) => window.open(`${CONSTANTS.BACKEND_URL}offers/${this.props.item.id}/inWork/invoice`, '_blank'),

                  // },
                  // {
                  //     tooltip: this.props.language.stock.leasingAnalysis,
                  //     onClick: (_, row) => { },

                  // },
                  // {
                  //     tooltip: this.props.language.stock.sell,
                  //     onClick: (_, row) => { },

                  // }
                ]}
              />
            </TableCell>
          }
        </TableRow>
      </Tooltip>
    )
  }
}

const mapStateToProps = (state) => ({
  language: state.languageReducer.i18n.itemDetails,
})

const mapDispatchToProps = (dispatch) => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SaleList)
)
