import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { Delete as DeleteIcon, Edit as EditIcon, AddCircleOutlineOutlined as AddIcon } from '@material-ui/icons'
import { Button } from '@material-ui/core'

import { validations } from '../../../utils/validationUtils'
import SimpleList from '../../common/SimpleList'
import UserFormModal from './UserFormModal'
import ConfirmationDialog from '../../common/ConfirmationDialog'
import NoOptions from '../../common/subComponents/AutoCompleteInput/NoOptions'
import * as USER from '../../../redux/actions/users'
import * as PROVIDERS from '../../../redux/actions/providers'
import * as NOTIFICATION from '../../../utils/notification'
import * as CONSTANTS from '../../../utils/constants'
import Header from '../../common/SearchableHeader'

const styles = theme => ({
    brand: {
        borderRight: '1px solid #e0e0e0',
        width: '50%'
    },
    model: {
        width: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    addButton: {
        padding: '8px 20px',
        fontSize: '15px',
        width: '110px',
        marginTop: '15px',
        display: 'flex'
    },
    subtitle: {
        fontSize: '35px',
        paddingTop: '20px',
        color: theme.palette.primaryColor,
        display: 'block',
        width: '350px'
    },
    header: {
        width: '100%',
        height: 70
    },
    listContainer: {
        padding: 27,
        overflow: 'auto'
    },
    addIconStyle: {
        paddingRight: 5
    },
    withoutSidebar: {
        padding: '24px 36px 24px 0px'
    }
})

class User extends Component {

    userToEdit = {}
    userToDelete = {}

    state = {
        users: [],
        providers: [],
        count: 0,
        from: 0,
        search: '',
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        roles: [],
        openModal: false,
        modalFields: this.initialFields,
        openConfirmationModal: false,
        modalType: CONSTANTS.CREATE
    }

    initialFields = [
        {
            value: '', type: 'autoCompletionInput', label: this.props.languageReducer.labels.provider, name: 'providerId',
            options: [],
            getOptions: search => this.getProviders(search),
            noOptions: <NoOptions text='Add a provider' classes={styles.addButton}></NoOptions>,
            validation: {
                checks: [validations.notEmpty]
            }
        },
        {
            value: '', type: 'text', label: this.props.languageReducer.labels.username, name: 'username',
            validation: {
                checks: [validations.notEmpty]
            }
        },
        {
            value: '', type: 'text', label: this.props.languageReducer.labels.fullName, name: 'fullName',
            validation: {
                checks: [validations.notEmpty]
            }
        },
        {
            value: '', type: 'text', label: this.props.languageReducer.labels.email, name: 'email',
            validation: {
                checks: [validations.notEmpty]
            }
        },
        { value: true, type: 'checkbox', label: this.props.languageReducer.labels.isActive, name: 'isActive' },
        { type: 'multiselector', name: 'roles', utils: this.props.languageReducer.labels.roles, value: this.state.roles }
    ]

    componentDidMount() {
        let allRoles = []
        Object.keys(CONSTANTS.ROLES).forEach(role => {
            allRoles.push({ name: CONSTANTS.ROLES[role], value: false, label: this.props.languageReducer.roles[role] })
            // role.subroles.forEach(subrole => {
            //     allRoles.push({name: subrole, value: false})
            // })
        })
        this.setState({
            roles: allRoles
        })
        this.getUsers()
    }

    getProviders = search => this.props.getProviders({
        search
    }).then(response => Promise.resolve(this.mapForOptions(response.providers)))

    //aux function returns initialField index
    indexOfInitialField = (initialFields, searchedNameInFields) => initialFields.findIndex(f => f.name === searchedNameInFields)

    //aux function map for options
    mapForOptions = rows => rows.map(row => ({
        label: row.name,
        value: row.id
    }))

    //populate modalFieldsCopy
    populateModalFieldsOptions = (modalFieldsRef, field, values) => {
        let index = this.indexOfInitialField(modalFieldsRef, field)

        if (index === -1) {
            modalFieldsRef[index].options = []
        } else {
            modalFieldsRef[index].options = this.mapForOptions(values)
        }
    }

    getUsers = () => {
        this.props.get({
            search: this.state.search
        }).then(userResponse => {
            this.setState({
                users: userResponse.users.map((user, index) => {
                    return ({
                        index: index + 1,
                        _id: user.id,
                        provider: user.provider.name,
                        username: user.username,
                        fullName: user.fullName,
                        roles: !user.roles ? '-' : user.roles.join(', '),
                        isActive: user.isActive ? this.props.languageReducer.active : this.props.languageReducer.notActive,
                    })
                }),
                openModal: false,
                count: userResponse.count,
                modalFields: this.initialFields,
                openConfirmationModal: false
            })
        })
    }

    clearForm = () => {
        //first clear options from 
        this.setState({ modalFields: this.initialFields })
    }

    openFormModalHandler = () => {
        this.clearForm()
        this.props.getProviders(this.state.from, this.state.rowsPerPage).then(providerResponse => {
            let modalFieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))
            let rolesIndex = modalFieldsCopy.findIndex(field => field.name === 'roles')
            if (rolesIndex > -1) {
                modalFieldsCopy[rolesIndex].value = this.state.roles
            }
            this.populateModalFieldsOptions(modalFieldsCopy, 'providerId', providerResponse.providers)

            this.setState({
                openModal: true,
                modalType: CONSTANTS.CREATE,
                modalFields: modalFieldsCopy
            })
        })
    }

    onCreateHandler = fields => {
        let arrayOfRoles = []
        fields.roles = fields.roles.filter(role => role.value).map(role => role.name)
        Object.keys(CONSTANTS.ROLES).forEach(role => {
            if (fields.roles.includes(CONSTANTS.ROLES[role])) {
                arrayOfRoles = arrayOfRoles.concat(CONSTANTS.ROLES[role])
            }
        })
        fields.roles = arrayOfRoles

        this.props.create(fields).then(() => {
            NOTIFICATION.success(this.props.languageReducer.create.success)
            this.getUsers()
        }).catch(() => NOTIFICATION.error(this.props.languageReducer.create.failure))
    }

    onEditHandler = fields => {

        let { username, fullName, roles, ...restOfObject } = fields

        if (username !== this.userToEdit.username) {
            restOfObject.username = username
        }
        if (fullName !== this.userToEdit.fullName) {
            restOfObject.fullName = fullName
        }

        let arrayOfRoles = []
        roles = roles.filter(role => role.value).map(role => role.name)
        Object.keys(CONSTANTS.ROLES).forEach(role => {
            if (roles.includes(CONSTANTS.ROLES[role])) {
                arrayOfRoles = arrayOfRoles.concat(CONSTANTS.ROLES[role])
            }
        })
        restOfObject.roles = arrayOfRoles

        return this.props.edit(this.userToEdit._id, restOfObject).then(() => {
            this.getUsers()
            NOTIFICATION.success(this.props.languageReducer.edit.success)
        }).catch(() => NOTIFICATION.error(this.props.languageReducer.edit.failure))
    }

    onDeleteHandler = () => {
        this.props.delete(this.userToDelete._id).then(() => {
            NOTIFICATION.success(this.props.languageReducer.delete.success)
            this.getUsers()
        }).catch(() => NOTIFICATION.error(this.props.languageReducer.delete.failure))
    }

    toggleEditModal = user => {

        let getProviders = this.props.getProviders({ from: this.state.from, limit: this.state.rowsPerPage }).then(result => result.providers)
        let getUserById = this.props.getByIdPopulate(user._id).then(result => result)
        this.userToEdit = user

        Promise.all([getProviders, getUserById]).then(values => {
            let modalFieldsCopy = [...this.state.modalFields].map(field => {
                return field.name === 'providerId' ?
                    ({ ...field, label: values[1]['provider'].name, value: values[1][field.name] }) :
                    ({ ...field, value: values[1][field.name] })
            })

            let updatedRoles = [...this.state.roles]
            let rolesIndex = modalFieldsCopy.findIndex(field => field.name === 'roles')

            if (rolesIndex > -1) {
                let userRolesArray = modalFieldsCopy[rolesIndex].value
                updatedRoles = updatedRoles.map(role => userRolesArray.includes(role.name) ?
                    ({
                        name: role.name, value: true, label: this.props.languageReducer.roles[role.name]
                    }) :
                    ({
                        name: role.name, value: false, label: this.props.languageReducer.roles[role.name]
                    })
                )
                modalFieldsCopy[rolesIndex].value = updatedRoles
            }

            this.populateModalFieldsOptions(modalFieldsCopy, 'providerId', values[0])

            this.setState({
                modalFields: modalFieldsCopy,
                openModal: true,
                modalType: CONSTANTS.EDIT
            })
        })
    }

    closeFormModalHandler = () => this.setState({ openModal: false })
    openConfirmationModalHandler = () => this.setState({ openConfirmationModal: true })
    closeConfirmationModalHandler = () => this.setState({ openConfirmationModal: false })


    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, () => this.getUsers())
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getUsers())
    }

    onCancelHandler = () => {
        this.setState({ openModal: false, modalFields: this.initialFields })
    }

    onSearch = search => this.setState({ search }, this.getUsers)

    render() {
        const { classes } = this.props
        return <>
            <ConfirmationDialog
                open={this.state.openConfirmationModal}
                cancelButtonText={this.props.languageReducer.confirmationDialog.cancelButtonText}
                acceptButtonText={this.props.languageReducer.confirmationDialog.acceptButtonText}
                text={`${this.props.languageReducer.confirmationDialog.text} ${this.userToDelete.username}?`}
                onCancel={() => this.setState({ openConfirmationModal: false })}
                onClose={() => this.setState({ openConfirmationModal: false })}
                onAccept={this.onDeleteHandler}
            />
            {this.state.openModal ? <UserFormModal
                language={this.props.languageReducer}
                type={this.state.modalType}
                fields={this.state.modalFields}
                open={this.state.openModal}
                submitButtonText={this.state.modalType === CONSTANTS.CREATE ? this.props.languageReducer.formModal.create : this.props.languageReducer.formModal.firstEdit}
                onSave={data => this.state.modalType === CONSTANTS.CREATE ? this.onCreateHandler(data.fields) : this.onEditHandler(data.fields)}
                onCancel={this.onCancelHandler}
                title={this.state.modalType === CONSTANTS.CREATE ? this.props.languageReducer.formModal.add : this.props.languageReducer.formModal.edit}
                maxWidth='sm'
            /> : null}
            <div className={classes.header}>
                <Header
                    title={this.props.languageReducer.simpleTable.title}
                    onSearch={this.onSearch}
                    placeholder={this.props.languageReducer.searchPlaceholder}
                />
            </div>
            <div className={`${!this.props.sidebarReducer.displaySidebar ? this.props.classes.withoutSidebar : ""} ${this.props.classes.listContainer}`}>
                <SimpleList
                    selectedOption={CONSTANTS.USER_OPTIONS}
                    headerFields={['FURNIZOR', 'USERNAME', 'FULLNAME', 'STATUS']}
                    items={this.state.users}
                    actionsTitle={this.props.languageReducer.simpleTable.actionsTitle}
                    disableBorders
                    actions={[
                        {
                            tooltip: this.props.languageReducer.simpleTable.actions.delete,
                            onClick: (event, row) => {
                                this.userToDelete = row
                                this.setState({ openConfirmationModal: true })
                            },
                            icon: <DeleteIcon />
                        },
                        {
                            tooltip: this.props.languageReducer.simpleTable.actions.edit,
                            onClick: (event, row) => this.toggleEditModal(row),
                            icon: <EditIcon />
                        }
                    ]}
                    onSelect={user => this.toggleEditModal(user)}
                    headerButton={{
                        tooltip: '',
                        icon: <Button className={classes.addButton} variant='contained' color='primary'>
                            <AddIcon className={classes.addIconStyle} />
                            {this.props.languageReducer.simpleTable.headerButton.buttonText}
                        </Button>,
                        onClick: this.openFormModalHandler
                    }}
                    search={{
                        onSearch: this.onSearch
                    }}
                    countTitle={this.props.languageReducer.users}
                    rowsPerPage={this.state.rowsPerPage}
                    count={this.state.count}
                    changeRowsPerPage={this.changeRowsPerPageHandler}
                    changePageHandler={this.changePageHandler}
                />
            </div>
        </>
    }
}

const mapStateToProps = reducers => ({
    languageReducer: reducers.languageReducer.i18n.user,
    sidebarReducer: reducers.sidebarReducer
})

const mapDispatchToProps = dispatch => ({
    get: queryParams => dispatch(USER.get(queryParams)),
    getByIdPopulate: userId => dispatch(USER.getById(userId)),
    getProviders: queryParams => dispatch(PROVIDERS.get(queryParams)),
    edit: (userId, user) => dispatch(USER.edit(userId, user)),
    create: user => dispatch(USER.create(user)),
    delete: userId => dispatch(USER.del(userId)),
    getById: userId => dispatch(USER.getById(userId))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(User))