import queryString from 'query-string'
import axios from '../../utils/axios'
import { errorHandler } from '../../utils/apiFunctions'

const baseRoute = '/inWorks'

export const get = queryParams => dispatch =>
    axios.get(baseRoute + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getById = id => dispatch =>
    axios.get(`${baseRoute}/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const edit = (id, inWork) => dispatch =>
    axios.put(`${baseRoute}/${id}`, { inWork })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const sendSaleViaMail = workId => dispatch => axios.get(`${baseRoute}/${workId}/sendSaleContract`)
    .then(res => res.data)
    .catch(errorHandler)

export const sendInvoiceViaMail = workId => dispatch => axios.get(`${baseRoute}/${workId}/sendInvoice`)
    .then(res => res.data)
    .catch(errorHandler)