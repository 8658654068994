import validator from 'validator'

let validateField = field => {
    if (!field.validation || !field.validation.checks) return true

    const functionRules = field.validation.checks.filter(rule => typeof rule === 'function')

    let isValid = functionRules.every(rule => {
        if (field.value !== undefined) return rule(String(field.value))
        return true
    })

    field.error = !isValid

    return isValid
}

export const addErrAndTouched = arr => [...arr].map(element => ({ ...element, touched: false, error: false }))

export const validateForm = fields => fields.every(field => {
    if (field.validation) {
        if (validateField(field)) {
            field.error = false

            return true    
        } else { 
            field.error = true

            return false
        }
    }

    field.error = false

    return true
})
export const checkIfAllUntouched = fields => fields.every(field => {
    if (typeof field.touched !== undefined) return !field.touched
    else return false
})
export const checkIfAllTouched = fields => fields.every(field => {
    if (typeof field.touched !== undefined
        && field.type !== 'date') return field.touched
    return true
})
export const unTouchAll = fields => fields.forEach(field => field.touched = true)

export const validations = {
    notEmpty: value => !validator.isEmpty(value),
    isEmail: value => validator.isEmail(value),
    notZero: value => value > 0,
    isPhoneNumber: value => validator.isMobilePhone(value)
}

export const isValidField = field => {
    if (!field.validation || !field.validation.checks) return true

    const functionRules = field.validation.checks.filter(rule => typeof rule === 'function')

    let isValid = functionRules.every(rule => {
        if (field.value !== undefined) return rule(String(field.value))
        return true
    })

    return isValid
}