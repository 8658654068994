import React from 'react'
import { connect } from 'react-redux'
import { withStyles, Button, Tooltip, Card, CardContent, Checkbox } from '@material-ui/core'
import InputGenerator from '../../common/subComponents/InputGenerator'
import moment from 'moment'

import { roleInArray } from '../../../utils/helpers'
import config from '../../../config/local.json'

import { formatNumber } from '../../../utils/helpers'
import * as CONSTANTS from '../../../utils/constants'
import * as NOTIFICATIONS from '../../../utils/notification'

const styles = theme => ({
    card: {
        flex: 1,
        width: '100%',
        marginRight: 12,
        marginTop: 12
    },
    media: {
        height: 0,
        paddingTop: '90%'
    },
    avatarContainer: {
        width: 25,
        height: 25,
        color: 'black'
    },
    fieldTextAvatar: {

    },
    fieldText: {
        fontWeight: 400,
        color: '#3e3f42'
    },
    colorBlack: {
        fontWeight: 500,
        color: 'rgba(0,0,0,1)'
    },
    menu: {
        maxHeight: 48 * 4.5,
        width: 200,
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    infoImage: {
        '& img': {
            marginTop: '25px'
        }
    },
    infoDetails: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        marginLeft: 10,
        maxHeight: 150,
        cursor: 'pointer'
    },
    infoDetailsWithInput: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        paddingLeft: 12,
        maxHeight: 135
    },
    discountInput: {
        marginLeft: 'auto',
        width: 274
    },
    optionButtons: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        paddingTop: 4,
        '& button:nth-of-type(1)': {
            marginLeft: '0px'
        }
    },
    mainContent: {
        paddingBottom: 16
    },
    secondaryContent: {
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box'
    },
    secondaryTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 6
    },
    primaryTextContainer: {
        padding: '2px 20px',
        fontSize: 14
    },
    missing: {
        color: 'red',
        fontWeight: 500,
        paddingRight: 8
    },
    accepted: {
        color: 'green',
        fontWeight: 500,
        paddingRight: 8
    },
    menuContainer: {
        maxHeight: 48 * 4.5,
        width: 200,
    },
    cover: {
        width: 151,
    },
    rentButton: {
        marginLeft: 15
    },
    optionButtonsContainer: {
        marginLeft: 'auto'
    },
    noBorder: {
        borderBottom: 'unset !important'
    }
})

class SumarCard extends React.Component {
    initialFields = [
        { value: 0, type: 'numberFormat', disabled: true, label: this.props.language.offer.listPrice, name: 'listPrice', breakline: true },
        //{ value: 0, type: 'numberFormat', disabled: true, label: this.props.language.offer.purchasePrice, name: 'purchasePrice', breakline: true },
        { value: 0, type: 'numberFormat', label: this.props.language.offer.rounding, name: 'rounding' },
        { value: 0, type: 'numberFormat', label: this.props.language.offer.sellingExchangeRate, name: 'sellingExchangeRate' },
        { value: '', type: 'numberFormat', label: this.props.language.offer.discountPercent, name: 'discount', decimalSize: CONSTANTS.EXCHANGE_DECIMAL_SIZE },
        { value: 0, type: 'numberFormat', disabled: true, label: this.props.language.offer.discount, name: 'discountEUR' },
        { value: 0, type: 'numberFormat', disabled: true, label: this.props.language.offer.sellingPrice, name: 'sellingPriceEUR', breakline: true },
        { value: 0, type: 'numberFormat', disabled: true, label: this.props.language.offer.carProfit, name: 'carProfitEUR', breakline: true },
        { type: 'radioSelector', name: 'hasLeasing', utils: this.props.language.offer.leasing, value: CONSTANTS.OFFER_NEED_LEASING[1].label, options: CONSTANTS.OFFER_NEED_LEASING, breakline: true },
        { type: 'dropdownSelector', disabled: true, name: 'financingProcent', utils: this.props.language.offer.financing, value: CONSTANTS.IN_ADVANCE_PERCENT[0].value, options: CONSTANTS.IN_ADVANCE_PERCENT, breakline: true },
        { value: 0, type: 'numberFormat', disabled: true, label: this.props.language.offer.leasingEUR, name: 'leasingEUR', breakline: true },
        { value: 0, type: 'numberFormat', disabled: true, label: this.props.language.offer.totalProfit, name: 'totalProfitEUR', breakline: true },
    ]

    state = {
        modalFields: this.initialFields,
        expandSettings: false,
        anchorEl: null,
        checked: false,
        maxDiscount: null
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.resetCheckboxes && nextProps.resetCheckboxes) {
            this.setState({
                checked: false
            })
        }
        if (this.props.item.id !== nextProps.item.id) {
            this.calculatePriceWithAddition(nextProps.item)
            this.checkExists(nextProps.item, nextProps.stocksForOffer)
        }
    }

    componentDidMount() {
        this.calculatePriceWithAddition(this.props.item)
    }

    checkExists = (item, arr) => {
        if (!arr.length) return
        if (arr.map(el => el.stockId).includes(item.id)) this.setState({ checked: true })
        else this.setState({ checked: false })
    }

    calculatePriceWithAddition = item => {
        if (this.props.settings && item) {

            const priceWithMinAddition = item.purchasePrice + (item.purchasePrice * this.props.settings.addition) / 100
            const priceWithRecomAddition = item.purchasePrice + (item.purchasePrice * this.props.settings.recommendedAddition) / 100

            let maxDiscount;
            let recomDiscount;
            if (item.type === 'sh' || item.type === 'external') {
                maxDiscount = 100
                recomDiscount = 0
            } else {
                maxDiscount = (1 - (priceWithMinAddition / item.listPrice)) * 100
                recomDiscount = (1 - (priceWithRecomAddition / item.listPrice)) * 100
            }

            const modalFieldsCopy = this.state.modalFields.map(field => ({ ...field }))
            const discountIndex = modalFieldsCopy.findIndex(index => index.name === 'discount')
            if (discountIndex > -1) {
                modalFieldsCopy[discountIndex].value = recomDiscount < 0 ? 0 : formatNumber(recomDiscount)
            }
            this.setState({ modalFields: modalFieldsCopy, maxDiscount: maxDiscount })

        }

    }

    onSettingsClickedHandler = event => {
        this.setState({ expandSettings: true, anchorEl: event.currentTarget })
    }

    onOptionClickedHandler = (event, action) => {
        if (action.onClick) {
            action.onClick(event, this.props.item)
        }
        this.setState({ expandSettings: false, anchorEl: null })
    }

    InputWrapper = ({ input, keyProps, shrink }) => <div className={this.props.classes.discountInput}>
        <InputGenerator
            key={keyProps}
            fullWidth={true}
            InputLabelProps={shrink ? { shrink: true } : {}}
            margin="dense"
            {...input}
            onChange={event => this.onChange(event)}
        />
    </div>

    computeStockJSON = stock => {
        if (stock) {
            let jsonMap = {}
            this.state.modalFields.forEach(field => {
                jsonMap[field.name] = field
            })

            let stockJson = {}

            stockJson.stockId = stock.id
            stockJson.discount = jsonMap['discount'].value
            stockJson.costsEUR = stock.cost ? stock.cost.totalPriceEUR : 0

            return stockJson
        }
    }

    onChange = event => {
        let currentIndex = this.state.modalFields.findIndex(field => field.name === event.target.name)
        if (currentIndex > -1) {
            let modalFieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))

            if (event.target.name === 'discount') {
                if (event.target.value === undefined) {
                    modalFieldsCopy[currentIndex].value = 0
                }
                else if (this.state.maxDiscount && (event.target.value > Number(Number(this.state.maxDiscount).toFixed(2)))) {
                    modalFieldsCopy[currentIndex].value = formatNumber(this.state.maxDiscount < 0 ? 0 : this.state.maxDiscount)
                    NOTIFICATIONS.error(this.props.language.discountToBig)
                }
                else {
                    modalFieldsCopy[currentIndex].value = event.target.value
                    modalFieldsCopy[currentIndex].touched = true

                    let sellingPriceIndex = this.state.modalFields.findIndex(idx => idx.name === 'sellingPriceEUR')
                    let carProfitIndex = this.state.modalFields.findIndex(idx => idx.name === 'carProfitEUR')

                    /* Formulas */
                    let discountIndex = this.state.modalFields.findIndex(idx => idx.name === 'discount')
                    let discountEURIndex = this.state.modalFields.findIndex(idx => idx.name === 'discountEUR')
                    let totalProfitEURIndex = this.state.modalFields.findIndex(idx => idx.name === 'totalProfitEUR')
                    let leasingEURIndex = this.state.modalFields.findIndex(idx => idx.name === 'leasingEUR')
                    let roundingIndex = this.state.modalFields.findIndex(idx => idx.name === 'rounding')

                    if (discountIndex > -1 && discountEURIndex > -1 && sellingPriceIndex > -1 && carProfitIndex > -1 && leasingEURIndex > -1) {
                        modalFieldsCopy[discountEURIndex].value = Number(Number(this.props.item.listPrice) - (Number(this.props.item.listPrice * (Number(1 - modalFieldsCopy[discountIndex].value / 100)))))
                        //modalFieldsCopy[sellingPriceIndex].value = Number(Number(this.props.item.listPrice) - Number(modalFieldsCopy[discountEURIndex].value) + Number(this.props.item.PDI) + Number(this.props.item.accessoriesPrice) - Number(modalFieldsCopy[roundingIndex].value))
                        modalFieldsCopy[sellingPriceIndex].value = Number(Number(this.props.item.listPrice) * (Number(1 - modalFieldsCopy[discountIndex].value / 100)) + Number(this.props.item.accessoriesPrice) - Number(modalFieldsCopy[roundingIndex].value))
                        modalFieldsCopy[carProfitIndex].value = Number(Number(modalFieldsCopy[sellingPriceIndex].value) - Number(this.props.item.purchasePrice) - Number(this.props.item.cost.totalPriceEUR))
                        // let sellingExchangeRateIndex = this.state.modalFields.findIndex(idx => idx.name === 'sellingExchangeRate')
                        // let exchangeRateDiff = Number(Number(modalFieldsCopy[sellingExchangeRateIndex].value) - Number(this.props.item.purchaseExchangeRate))
                        // let exchangeRateDiffRon = Number(Number(modalFieldsCopy[sellingPriceIndex].value) * Number(exchangeRateDiff))
                        // let difExchangeRateEur = Number(Number(exchangeRateDiffRon) / Number(this.props.item.purchaseExchangeRate))
                        // //modalFieldsCopy[totalProfitEURIndex].value = Number(Number(modalFieldsCopy[carProfitIndex].value) + Number(difExchangeRateEur) + (Number(modalFieldsCopy[leasingEURIndex].value)))
                        modalFieldsCopy[totalProfitEURIndex].value = Number((Number(modalFieldsCopy[sellingPriceIndex].value) - Number(this.props.item.purchasePrice) - this.props.item.cost.totalPriceEUR))
                    }
                }
                this.setState({ modalFields: modalFieldsCopy }, () => {
                    this.props.selectStock(this.state.checked, this.computeStockJSON(this.props.item))
                })
            }
        }
    }

    getFieldValue = field => {
        let fieldIndex = this.state.modalFields.findIndex(index => index.name === field);

        if (fieldIndex > -1) {
            if (this.state.modalFields[fieldIndex].value) {
                // We want to see it in ron
                if (this.state.modalFields[fieldIndex].name === 'totalProfitEUR') return { value: formatNumber(this.state.modalFields[fieldIndex].value * (this.props.item.purchaseExchangeRate || CONSTANTS.EURO_EXCHANGE_RATE)) }
                return { value: formatNumber(this.state.modalFields[fieldIndex].value) }
            }
        }

        return { value: null };
    }

    findIndexByName = name => {
        let modalFieldsCopy = this.state.modalFields.map(field => ({ ...field }))
        let currentNameIndex = modalFieldsCopy.findIndex(index => index.name === name)

        if (currentNameIndex > -1) {
            return modalFieldsCopy[currentNameIndex]
        }

        return null
    }

    render() {
        const InputWrapper = this.InputWrapper
        const { classes } = this.props

        let reservedUntill = this.props.item && this.props.item.offers && this.props.item.offers.length ?
            this.props.item.offers.filter(offer => offer.reservedUntil)
                .map(offer => offer.reservedUntil)
                .sort((a, b) => new Date(b) - new Date(a))[0] : null

        let discountModalField = this.findIndexByName('discount')
        const currentImage = this.props.item.carBrandId ? `${config.baseUrl}/cars/brands/${this.props.item.carBrandId}/image` : 'https://via.placeholder.com/250x200'
        return (
            <>
                <Checkbox checked={this.state.checked} onChange={(event) => {
                    this.setState({ checked: event.target.checked })
                    this.props.selectStock(event.target.checked, this.computeStockJSON(this.props.item))
                }} />
                <Card className={classes.card}>
                    {/* <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            {!this.props.item.carBrand ? "" : !this.props.item.carBrand.name ? "" : this.props.item.carBrand.name[0]}
                        </Avatar>
                    }
                    action={
                        this.props.actions && <div>
                            <IconButton onClick={event => this.onSettingsClickedHandler(event)} aria-label="settings" aria-controls="settings-menu">
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="settings-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={this.state.expandSettings}
                                onClose={() => this.setState({ expandSettings: false, anchorEl: null })}
                            >
                                {
                                    this.props.actions.map(act => {
                                        return (
                                            <MenuItem key={act.tooltip} onClick={event => this.onOptionClickedHandler(event, act)}>
                                                {act.tooltip}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Menu>
                        </div>
                    }
                    title={this.props.title}
                    subheader={this.props.subtitle}
                /> */}
                    <CardContent classes={{
                        root: this.props.classes.noBorder
                    }}>
                        <div className={classes.infoContainer}>
                            <div className={classes.infoImage}>
                                <img src={currentImage} height={50} width={50} />
                            </div>
                            <div onClick={() => this.props.openStockDetails(this.props.item.id)} className={classes.infoDetails}>
                                {CONSTANTS.CARD_STOCK_FIELDS.map((field, index) => {
                                    if (this.props.item[field.field]) {
                                        if (field.field === 'vinNumber') {
                                            return (
                                                <div key={index} className={this.props.classes.primaryTextContainer}>
                                                    {<Tooltip key={index} placement={"top"} title={this.props.item[field.field]}><span className={this.props.classes.colorBlack}>
                                                        <span key={index} className={this.props.classes.fieldText}>{this.props.language.stock[field.field]}:</span> {this.props.item[field.field] === CONSTANTS.COMMAND ?
                                                            this.props.item[field.field] : this.props.item[field.field].substr(-1 * CONSTANTS.VIN_SUBSTR_LENGTH)}
                                                    </span></Tooltip>}
                                                </div>
                                            )
                                        }
                                        if (field.field === 'carBrand' || field.field === 'carModel') {
                                            return (
                                                <div key={index} className={this.props.classes.primaryTextContainer}>
                                                    {<span key={index} className={this.props.classes.colorBlack}><span className={this.props.classes.fieldText}>{this.props.language.stock[field.field]}</span>: {this.props.item[field.field].name} </span>}
                                                </div>
                                            )
                                        }
                                        return (
                                            <div key={index} className={this.props.classes.primaryTextContainer}>
                                                {<span key={index} className={this.props.classes.colorBlack}><span className={this.props.classes.fieldText}>{this.props.language.stock[field.field]}:</span> {typeof (this.props.item[field.field]) === 'number' ? formatNumber(this.props.item[field.field]) : this.props.item[field.field]} {field.symbol ? field.symbol : ''} </span>}
                                            </div>
                                        )
                                    }
                                })}
                            </div>


                            {this.props.item && this.props.item.offers && this.props.item.offers.length > 0 ? <div className={classes.infoDetails}>
                                <div className={this.props.classes.primaryTextContainer}>
                                    {this.props.language.sumar.offer.offers}:
                                    <span className={this.props.classes.colorBlack}>
                                        {this.props.item.offers && this.props.item.offers.length ? `   ${this.props.item.offers.length}` || 0 : ''}
                                    </span>
                                </div>

                                <div className={this.props.classes.primaryTextContainer}>
                                    {this.props.language.sumar.offer.withoutAdvance}:
                                    <span className={this.props.classes.colorBlack}>
                                        {this.props.item.offers && this.props.item.offers.length ? `   ${this.props.item.offers.filter(offer => !offer.withAdvance && offer.reservedUntil).length}` || 0 : ''}
                                    </span>
                                </div>

                                <div className={this.props.classes.primaryTextContainer}>
                                    {this.props.language.sumar.offer.withAdvance}:
                                    <span className={this.props.classes.colorBlack}>
                                        {this.props.item.offers && this.props.item.offers.length ? `   ${this.props.item.offers.filter(offer => offer.withAdvance && offer.reservedUntil).length}` || 0 : ''}
                                    </span>
                                </div>

                                {reservedUntill ? <div className={this.props.classes.primaryTextContainer}>
                                    {this.props.language.sumar.offer.reservedUntill}:
                                    <span className={this.props.classes.colorBlack}>
                                        {this.props.item.offers && this.props.item.offers.length ? `   ${moment(reservedUntill).format(CONSTANTS.DEFAULT_DATE_FORMAT)}` || 0 : ''}
                                    </span>
                                </div> : null}

                            </div> : <div className={classes.infoDetails}></div>}

                            <div className={classes.infoDetailsWithInput}>
                                {CONSTANTS.CARD_VALUES_FIELDS.map((field, index) => {
                                    return (
                                        <div key={index} className={this.props.classes.primaryTextContainer}>
                                            {<span key={index} className={this.props.classes.colorBlack}><span className={this.props.classes.fieldText}>{this.props.language.offer[field.field]}:</span> {this.getFieldValue(field.field).value ? this.getFieldValue(field.field).value : '-'} {field.symbol ? field.symbol : ''}</span>}
                                        </div>
                                    )

                                })}
                                <InputWrapper input={discountModalField} key={'discount'} />
                                {!this.props.numberOfStocksForOffer ? <div className={this.props.classes.optionButtons}>
                                    {roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.IPMG_SELLER]) && <Button onClick={() => this.props.openRentModal(this.props.item.id)} className={this.props.classes.rentButton} color="secondary">{this.props.language.stock.rent}</Button>}
                                    {roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.IPMG_SELLER]) && <Button onClick={() => this.props.openTestDriveModal(this.props.item.id)} className={this.props.classes.rentButton} color="secondary">{this.props.language.stock.testDrive}</Button>}
                                    <Button onClick={() => this.props.offerForSingleStock(this.computeStockJSON(this.props.item))} className={this.props.classes.rentButton} color="secondary">{this.props.language.stock.offer}</Button>
                                </div> : null}
                            </div>

                        </div>
                    </CardContent>
                    {/* {this.props.actions && <CardActions >
                    {this.props.actions.map(action => {
                        if ((action.deleteIf && !action.deleteIf(this.props.item)) || !action.deleteIf) {
                            return <Tooltip key={action.tooltip} title={action.tooltip} placement='top'>
                                <IconButton
                                    onClick={event => action.onClick ? action.onClick(event, this.props.item) : () => { }}
                                    color={action.color || 'primary'}
                                    aria-label={action.tooltip || ''}>
                                    {action.icon}
                                </IconButton>
                            </Tooltip>
                        } else return null
                    })}

                </CardActions>} */}
                </Card >
            </>
        );
    }

}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails,
    loginReducer: state.loginReducer
})

const mapDispatchToProps = dispatch => ({

})


export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SumarCard))