import queryString from 'query-string'
import axios from '../../utils/axios'
import { errorHandler } from '../../utils/apiFunctions'

const baseRoute = '/providers'

export const get = queryParams => dispatch =>
    axios.get(baseRoute + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getById = providerId => dispatch =>
    axios.get(`${baseRoute}/${providerId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)


export const create = provider => dispatch =>
    axios.post(baseRoute, { provider })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const edit = (providerId, provider) => dispatch =>
    axios.put(`${baseRoute}/${providerId}`, { provider })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const del = providerId => dispatch =>
    axios.delete(`${baseRoute}/${providerId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)