import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Menu, Button, MenuItem, FormControlLabel, Checkbox, withStyles } from '@material-ui/core'

const styles = theme => ({

})

function CheckboxMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null)
    const [items, setItems] = useState(props.items)


    useEffect(() => {
        setItems(props.items)
    }, [props.items])

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const onChange = (name, value) => {
        let newItems = [...items].map(obj => ({ ...obj }))
        let itemIndex = newItems.findIndex(item => item.name === name)
        if (itemIndex > -1) {
            let canChange = props.canChange ? props.canChange(newItems, { name, value: !value }) : true
            if (canChange) {
                newItems[itemIndex].value = !value
                props.onChange(newItems, { name, value: !value })
                setItems(newItems)
            }
        }
    }

    return <>
        <Button aria-controls="checkbox-menu" aria-haspopup="true" onClick={handleClick} color={props.color ? props.color : 'default'}>
            {props.initialText}
        </Button>
        <Menu
            id="checkbox-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {items.map((item, index) =>
                <MenuItem key={`${index}-${item.name}`}>
                    <FormControlLabel control={<Checkbox disabled={item.disabled} checked={item.value} onChange={() => onChange(item.name, item.value)} value={item.name} name={item.name} />} label={item.label} />
                </MenuItem>
            )}
        </Menu>
    </>
}

CheckboxMenu.propTypes = {
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    initialText: PropTypes.object.isRequired
}

export default withStyles(styles)(CheckboxMenu)