import React from 'react'
import { NotificationsActive, NotificationsNoneOutlined } from '@material-ui/icons'
import { IconButton, Badge, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import * as ALERT from '../../../redux/actions/alerts'

const styles = theme => ({
    notification: {
        fontSize: 30,
        padding: 0,
        color: '#9ea0a5'
    },
    boxBadge: {
        backgroundColor: 'green',
        right: 10,
        top: 5
    }
})

class AlertsButton extends React.Component {
    render() {
        let { classes } = this.props

        return (
            <Badge classes={{ badge: classes.boxBadge }} badgeContent={this.props.count || 0} color='primary'>
                <IconButton onClick={this.props.toggleAlerts}>
                    {this.props.active ? <NotificationsActive className={classes.notification} /> : <NotificationsNoneOutlined className={classes.notification} />}
                </IconButton>
            </Badge>
        )
    }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
    return {
        toggleAlerts: () => dispatch(ALERT.toggle())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AlertsButton))