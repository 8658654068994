import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { withStyles, TableCell, TableRow } from '@material-ui/core'

const styles = theme => ({

})

class TraceList extends Component {

    render() {

        return (
            <TableRow onClick={() => this.props.onSelect(this.props.item.stockDataId)}>
                <TableCell>{this.props.item.stockDataId}</TableCell>
                <TableCell>{this.props.item.stockData.carBrand.name} {this.props.item.stockData.carModel.name}</TableCell>
                <TableCell>{this.props.item.author.fullName}</TableCell>
                <TableCell>{this.props.item.action}</TableCell>
                <TableCell>{moment(this.props.item.createdAt).format('ll')}</TableCell>
            </TableRow>
        )
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails
})

const mapDispatchToProps = dispatch => ({

})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TraceList))