import * as TYPES from '../actionTypes'

const initialState = {
    isOpen: false,
    alerts: []
}

function alertsReducer(state = initialState, action) {
    switch (action.type) {
        case TYPES.TOGGLE_ALERTS:
            return { isOpen: action.payload.isOpen, alerts: action.payload.alerts }
        default:
            return state
    }
}

export default alertsReducer