import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { ExpandMore as Expand } from '@material-ui/icons'

import SimpleList from '../../common/SimpleList'
import ItemDetails from '../../common/ItemDetails'
import * as CONSTANTS from '../../../utils/constants'
import * as OFFER from '../../../redux/actions/offers'

const styles = theme => ({
    subtitle: {
        fontSize: '35px',
        paddingTop: '20px',
        color: theme.palette.primaryColor,
        display: 'block',
        width: '350px'
    },
    legend: {
        width: 'auto',
        marginLeft: 'auto',
        marginTop: '15px',
        color: 'rgba(0, 0, 0, 0.87)',
        '& span':{
            width: '15px',
            height: '15px',
            display: 'inline-block',
            marginLeft: '20px',
            marginRight: '5px',
            position: 'relative',
            bottom: '-1px',
            borderRadius: '50%'
        }
    }
})

class OfferTab extends Component {

    state = {
        offers: [],
        count: 0,
        from: 0,
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        search: '',
        selectedOffer: null,
        selectedOfferStock: null,
        openDetailsModal: false,
        openOfferModal: false,
        renderPage: false,
        activeTab: 0
    }

    componentDidMount() {
        this.getOffers()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.searchText !== this.props.searchText) {
            this.setState({ search: this.props.searchText }, () => this.getOffers())
        }
    }

    getOffers = () => {
        this.props.getOffers({ search: this.state.search })
            .then(result => {
                this.setState({ offers: result.offers, renderPage: true, countOffers: result.count })
            })
    }

    onItemDetailsCancelHandler = () => {
        this.props.getOffers({ search: this.state.search })
            .then(result => {
                this.setState({ offers: result.offers, renderPage: true, selectedOffer: null, openDetailsModal: false, selectedOfferStock: null })
            })

    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, () => this.getOffers())
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getOffers())
    }

    getOffer = offerId => {
        this.props.getOfferById(offerId).then(offer => {
            this.setState({ selectedOfferStock: offer.stock, openDetailsModal: true })
        })
    }

    render() {
        return this.state.renderPage ? <>
            {(this.state.selectedOffer || this.state.selectedOfferStock) && <ItemDetails
                open={this.state.openDetailsModal}
                item={this.state.selectedOffer ? this.state.selectedOffer.stock : this.state.selectedOfferStock}
                fullStock={this.state.selectedOfferStock}
                offer={this.state.selectedOffer}
                maxWidth={'md'}
                noHeaders={true}
                onCancel={this.onItemDetailsCancelHandler} />}
            <SimpleList
                noHeader
                headerFields={this.props.headerFields}
                items={this.state.offers}
                actionsTitle={this.props.language.simpleTable.actionsTitle}
                disableBorders
                selectedOption={CONSTANTS.OFFER_OPTIONS.SUMAR_OFFER}
                onSelect={offer => this.setState({ selectedOffer: offer, openDetailsModal: true })}
                actions={[
                    {
                        tooltip: this.props.language.simpleTable.actions.expandOffer,
                        onClick: (_, row) => this.setState({ selectedOffer: row, openDetailsModal: true }),
                        icon: <Expand />
                    },
                    {
                        tooltip: this.props.language.simpleTable.actions.expandStock,
                        onClick: (_, row) => this.getOffer(row.id),
                        icon: <Expand />
                    },
                    {
                        tooltip: this.props.language.simpleTable.actions.downloadOffer,
                        onClick: (_, row) => window.open(`${CONSTANTS.BACKEND_URL}offers/${row.id}/download`, "_blank"),
                        icon: <Expand />
                    }
                ]}
                search={{
                    onSearch: this.onSearch
                }}
                rowsPerPage={this.state.offersRowsPerPage}
                count={this.state.countOffers}
                changeRowsPerPage={this.changeRowsPerPageHandler}
                changePageHandler={this.changePageHandler}
                legend={
                    <div className={this.props.classes.legend}>
                        <span style={{ backgroundColor: 'rgb(52, 170, 68)' }}></span>Vanduta
                        <span style={{ backgroundColor: 'rgb(17, 82, 147)' }}></span>Rezervata cu avans
                        <span style={{ backgroundColor: 'rgb(227, 51, 113)' }}></span>Rezervata fara avans
                    </div>
                }
            />
        </> : null
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.offer,
    headerFields: reducers.languageReducer.i18n.itemDetails.offer.offerTabHeaderFields
})

const mapDispatchToProps = dispatch => ({
    getOffers: queryParams => dispatch(OFFER.get(queryParams)),
    getOfferById: offerId => dispatch(OFFER.getById(offerId))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OfferTab))