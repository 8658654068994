import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles} from '@material-ui/core'

import SimpleList from '../common/SimpleList'
import ConfirmationDialog from '../common/ConfirmationDialog'
import * as CONSTANTS from '../../utils/constants'
import * as MONITORING from '../../redux/actions/monitoring'
import * as FINANCING_COST from '../../redux/actions/financingCosts'
import * as STOCK from '../../redux/actions/stocks'
import * as NOTIFICATION from '../../utils/notification'

const styles = theme => ({
    subtitle: {
        fontSize: '35px',
        paddingTop: '20px',
        color: theme.palette.primaryColor,
        display: 'block',
        width: '350px'
    }
})

class MonitoringList extends Component {

    state = {
        offers: [],
        count: 0,
        from: 0,
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        search: '',
        selectedOffer: null,
        openDetailsModal: false,
        openOfferModal: false,
        renderPage: false,
        activeTab: 0,
        carConfirm: '',
        openConfirmationModal: false,
        confirmationLogistics: false,
    }

    carConfirmId = null
    carConfirmValue = null

    componentDidMount() {
        this.getMonitoring()
    }

    componentDidUpdate(prevProps) {  
        if(this.props.resetFilter){
            this.getMonitoring()
        } 
        if (prevProps.searchText !== this.props.searchText) {
            this.setState({ search: this.props.searchText}, () => this.getMonitoring())
        }
    }
    
    getMonitoring = () => {
        this.props.getMonitoring({
            search: this.state.search,
            sold:true,
            filterData: JSON.stringify(this.props.filterData)
        }).then(result => {
            this.setState({ stocks: result.stocks, renderPage: true, countStocks: result.count, monitoring: result.monitoring })
        }) 
    }   

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, () => this.getMonitoring())
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getMonitoring())
    }

    onChangeLogisticsConfirmation = (id, value, carBrand, carModel) => {
        this.carConfirmId = id
        this.carConfirmValue = value
        this.setState({ openConfirmationModal: true, carConfirm: `${carBrand} ${carModel}`, confirmationLogistics: true })
    }

    onChangeLogistics = () => {
        this.props.editStock(this.carConfirmId, { stopLogistics: this.carConfirmValue })
            .then(() => {
                if(this.props.filterData && Object.keys(this.props.filterData).length)
                    this.props.filterMonitoring()
                else this.getMonitoring()
                NOTIFICATION.success(this.props.monitoringLanguage.logisticsStopped)
            }).catch(() => {
                NOTIFICATION.error(this.props.monitoringLanguage.logitsticsStopError)
            })
        this.setState({ openConfirmationModal: false})
    }

    onChangeRefoundConfirmation = (id, value, carBrand, carModel) => {
        this.carConfirmId = id
        this.carConfirmValue = value
        this.setState({ openConfirmationModal: true, carConfirm: `${carBrand} ${carModel}`, confirmationLogistics: false})
    }
    onChangeRefound = () => {
        this.props.editFinancingCost(this.carConfirmId, { refoundInterest: this.carConfirmValue })
            .then(() => {
                if(this.props.filterData && Object.keys(this.props.filterData).length)
                    this.props.filterMonitoring()
                else this.getMonitoring()
                NOTIFICATION.success(this.props.monitoringLanguage.refoundInterestEdited)
            }).catch(() => {
                NOTIFICATION.error(this.props.monitoringLanguage.refoundInterestError)
            })
        this.setState({ openConfirmationModal: false})
    }


    render() {
        return this.state.renderPage ? <>
            {/* {this.state.selectedOffer && <ItemDetails
                open={this.state.detailsModal}
                item={this.state.selectedOffer.stock}
                offer={this.state.selectedOffer}
                noHeaders={true}
                onCancel={this.onItemDetailsCancelHandler} />} */}
            <ConfirmationDialog
                titleText="Confirmare"
                open={this.state.openConfirmationModal}
                text={this.state.confirmationLogistics ?
                    this.props.monitoringLanguage.logisticsConfirmation.replace("$replace", this.state.carConfirm) :
                    this.props.monitoringLanguage.refoundConfirmation.replace("$replace", this.state.carConfirm)}
                acceptButtonText={this.props.monitoringLanguage.confirm}
                cancelButtonText={this.props.monitoringLanguage.cancel}
                onAccept={ this.state.confirmationLogistics ? this.onChangeLogistics : this.onChangeRefound }
                onCancel={() => this.setState({ openConfirmationModal: false })}
                onClose={() => this.setState({ openConfirmationModal: false })}
            />
            <SimpleList
                noHeader
                headerFields={this.props.headerFields}
                items={ this.props.filterData && Object.keys(this.props.filterData).length && this.props.monitoring ? this.props.monitoring : this.state.stocks }
                actionsTitle={this.props.language.simpleTable.actionsTitle}
                disableBorders
                selectedOption={CONSTANTS.MONITORING}
                onSelect={ offer => this.setState({ selectedOffer: offer, detailsModal: true })}
                search={{ onSearch: this.onSearch }}
                rowsPerPage={this.state.offersRowsPerPage}
                count={this.state.countStocks}
                changeRowsPerPage={this.changeRowsPerPageHandler}
                changePageHandler={this.changePageHandler}
                onChangeLogistics={this.onChangeLogisticsConfirmation}
                onChangeRefound={this.onChangeRefoundConfirmation}
            />
        </> : null
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.offer,
    headerFields: reducers.languageReducer.i18n.monitoring.simpleTable.header,
    monitoringLanguage: reducers.languageReducer.i18n.monitoring
})

const mapDispatchToProps = dispatch => ({
    getMonitoring: queryParams => dispatch(MONITORING.get(queryParams)),
    editFinancingCost: (id, cost) => dispatch(FINANCING_COST.edit(id, cost)),
    editStock: (id, stock) => dispatch(STOCK.edit(id, stock)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MonitoringList))