import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography, Button, withStyles } from '@material-ui/core'
import { AddCircleOutline as AddIcon } from '@material-ui/icons'
//import moment from 'moment'

//import { validations, addErrAndTouched, validateForm } from '../../utils/validationUtils'
import * as CONSTANTS from '../../utils/constants'
import * as STOCKS from '../../redux/actions/stocks'
import * as CARS from '../../redux/actions/cars'
import * as PROVIDERS from '../../redux/actions/providers'
import * as NOTIFICATION from '../../utils/notification'
import * as ROLES from '../../redux/actions/roles'
import ConfirmationModal from '../common/ConfirmationDialog'
//import NoOptions from '../common/subComponents/AutoCompleteInput/NoOptions'
//import SimpleForm from '../common/SimpleFormGenerator'
import StockModal from './StockModal'
//import SquareRender from '../common/SquareRender'
import SimpleList from '../common/SimpleList'
import ItemDetails from '../common/ItemDetails'
import FinancingCostModal from '../costs/financingCost/FinancingCostModal'
import SearchableHeader from '../../components/common/SearchableHeader'

import { roleInArray } from '../../utils/helpers'

const styles = theme => ({
    listContainer: {
        padding: 24,
        overflow: 'auto'
    },
    addButton: {
        padding: '8px 20px',
        fontSize: '15px',
        width: '100px',
        marginTop: '15px',
        display: 'flex'
    },
    subtitle: {
        fontSize: '36px',
        fontWeight: '500',
        paddingTop: '20px',
        color: theme.palette.primaryColor,
        display: 'block',
        width: '350px'
    },
    table: {
        marginTop: '20px'
    },
    optionButton: {
        padding: 3,
    },
    firstOptionButton: {
        padding: 3,
        marginRight: 10
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    addIconStyle: {
        paddingRight: 5
    },
    addTextStyle: {
        paddingTop: 3
    },
    square: {
        flex: 1,
        marginRight: 12,
        backgroundColor: '#e6492d',
        height: 200,
        borderRadius: 4,
    },
    withoutSidebar: {
        padding: '24px 36px 24px 0px'
    },
    titleDetailsText:{
        fontSize: '16px'
    }
})

class Stock extends Component {

    stockToDelete = {}
    stockToEdit = {}
    createdStock = {}

    state = {
        users: [],
        openFormModal: false,
        detailsModal: false,
        openConfirmationModal: false,
        renderPage: false,
        financingCostModal: false,
        modalType: CONSTANTS.CREATE,
        providers: [],
        carModels: [],
        selectedRow: {},
        carBrands: [],
        stocks: [],
        unmappedStocks: [],
        from: 0,
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        search: '',
        canSubmit: false,
        options: [
            { name: CONSTANTS.OPTIONS.CARDS, clicked: false },
            { name: CONSTANTS.OPTIONS.TABLE, clicked: true }
        ],
        stockOptions: [
            { name: CONSTANTS.STOCK_OPTIONS.SUMAR, clicked: false },
            { name: CONSTANTS.STOCK_OPTIONS.FINANCIAL, clicked: false },
            { name: CONSTANTS.STOCK_OPTIONS.COST, clicked: false }
        ],
        stockToView: null,
        financingToEdit: null,
        retentionNumber: 0,
        selectedOption: CONSTANTS.STOCK_OPTIONS.SUMAR,
        openForEdit: false,
        rectangle: {
            total: 0,
            financed: 0,
            withoutOffer: 0,
            withOffer: 0
        },
        headerFields: this.props.language.simpleTable.headerStock,
        withFinancing: false,
        filterOptions: CONSTANTS.STOCK_FILTERS.map((filter, index) => ({
            name: filter.name,
            label: this.props.language.filterOptions[filter.name],
            value: false
        })),
        onlyIPMG: false
    }

    onFilterOptionChange = items => {
        console.log(items)
        this.setState({
            withFinancing: items[0].value,
            onlyIPMG: items[1].value
        }, this.getStocks)
    }

    selectedCarBrandId = null

    getStocks = () => {
        let params = {
            search: this.state.search,
            withFinancing: this.state.withFinancing,
            onlyIPMG: this.state.onlyIPMG
        }

        let search = this.state.search

        if (search.includes('>') || search.includes('<')) {
            params.search = ''
            //Split in case if there are > and < 
            let splittedSearch = search.split(/\s/).filter(token => token.length)

            splittedSearch.forEach(searchParam => {
                let less = searchParam.includes('<') ? true : false
                let percent = searchParam.includes('%') ? true : false

                if (less && percent) {
                    params.discountLessThan = searchParam.replace(/[<\s%]/g, '')
                } else if (!less && percent) {
                    params.discountGreaterThan = searchParam.replace(/[>\s%]/g, '')
                } else if (less && !percent) {
                    params.listPriceLessThan = searchParam.replace(/[<\s]/g, '')
                } else if (!less && !percent) {
                    params.listPriceGreaterThan = searchParam.replace(/[>\s%]/g, '')
                }
            })
        }

        if (!roleInArray(this.props.login.roles, [CONSTANTS.ROLES.ADMIN]) && this.state.selectedOption === CONSTANTS.STOCK_OPTIONS.SUMAR) {
            this.changeStockOptions({ name: CONSTANTS.STOCK_OPTIONS.FINANCIAL })
        }
        if (!this.props.login.isIPMG) {
            this.changeStockOptions({ name: CONSTANTS.STOCK_OPTIONS.SUMAR })
        }


        return this.props.getStocks({
            ...params
        }).then(stocksResponse => {
            this.setState({
                unmappedStocks: stocksResponse.stocks,
                stocks: stocksResponse.stocks.map((stock, index) => ({
                    _id: stock.id,
                    index: index + 1,
                    provider: stock.provider.name,
                    brand: stock.carBrand.name,
                    model: stock.carModel.name,
                    vinNumber: stock.vinNumber,
                    orderNumber: stock.orderNumber,
                    price: stock.price,
                    discountPercent: stock.discountPercent,
                    accessoriesPrice: stock.accessoriesPrice
                })),
                count: stocksResponse.count,
                renderPage: true,
                openFormModal: false,
                modalType: CONSTANTS.CREATE
            })
            return Promise.resolve()
        })
    }

    componentDidMount() {
        this.getStocks().then(() => {
            if (this.props.match.params.STOCKID) {
                this.openStockDetailsModal({ id: this.props.match.params.STOCKID })
            }
        })
        //this.getStocksForRectangle()
    }

    componentWillReceiveProps(props) {
        if (props.match.params.STOCKID !== this.props.match.params.STOCKID && props.match.params.STOCKID)
            this.openStockDetailsModal({ id: props.match.params.STOCKID })
    }

    onSubmitHandler = () => {
        this.setState({ openFormModal: false })
    }

    deleteStockHandler = () => {
        this.props.delete(this.state.selectedRow.id)
            .then(() => {
                this.getStocks()
                this.props.history.push(`/stock`)
                //this.getStocksForRectangle()
                NOTIFICATION.success(this.props.language.delete.success)
                this.setState({ openConfirmationModal: false, detailsModal: false })
            })
            .catch((err) => {
                this.setState({ openConfirmationModal: false })
                NOTIFICATION.error(this.props.language.delete.failure)
            })
    }

    closeFormModalHandler = () => {
        if (this.state.modalType === CONSTANTS.EDIT)
            this.props.unblockStock(this.stockToEdit.id)
        this.setState({ openFormModal: false, retentionNumber: 0, modalType: CONSTANTS.CREATE })
    }

    openConfirmationModalHandler = () => this.setState({ openConfirmationModal: true })

    closeConfirmationModalHandler = () => this.setState({ openConfirmationModal: false })

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, () => this.getStocks())
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getStocks())
    }

    changeStockOptions = option => {

        let optionsCopy = [...this.state.stockOptions]
        let optionIndex = optionsCopy.findIndex(idx => idx.name === option.name)
        if (optionIndex > -1) {
            optionsCopy = optionsCopy.map(option => {
                return ({
                    name: option.name,
                    clicked: false
                })
            })
            optionsCopy[optionIndex].clicked = true
        }

        let headerFields = []

        switch (option.name) {
            case CONSTANTS.STOCK_OPTIONS.FINANCIAL:
                headerFields = this.props.language.simpleTable.headerFCost
                break;
            case CONSTANTS.STOCK_OPTIONS.COST:
                headerFields = this.props.language.simpleTable.headerCost
                break
            default:
                headerFields = this.props.language.simpleTable.headerStock

        }

        this.setState({
            stockOptions: optionsCopy,
            selectedOption: option.name,
            headerFields
        })
    }

    checkOption = option => {
        let optionIndex = this.state.options.findIndex(idx => idx.name === option)

        if (optionIndex > -1) return this.state.options[optionIndex].clicked
    }

    onSearch = search => this.setState({ search }, this.getStocks)


    onItemDetailsCancelHandler = () => {
        this.getStocks()

        this.setState({ detailsModal: false, stockToView: null })
        this.props.history.push('/stock')
    }

    openStockDetailsModal = row => {
        this.props.getById(row.id).then(res => {
            this.stockToEdit = row
            this.setState({ detailsModal: true, stockToView: res })
        })
        if (!this.props.match.params.STOCKID)
            this.props.history.push(`/stock/${row.id}`)
    }

    openFinancingCostModal = stock => {
        this.stockToEdit = stock
        this.setState({
            financingCostModal: true
        })
    }

    reloadCurrentStock = () => {
        this.props.getById(this.state.stockToView.id).then(res => {
            this.setState({ stockToView: res })
        })
    }

    onSelectHandler = stock => {
        if (this.state.selectedOption === CONSTANTS.STOCK_OPTIONS.SUMAR) {
            this.stockToEdit = stock
            this.setState({
                stockToView: stock,
                openFormModal: true,
                modalType: CONSTANTS.EDIT
            })
        }

        else if (this.state.selectedOption === CONSTANTS.STOCK_OPTIONS.FINANCIAL) {
            this.stockToEdit = stock
            this.setState({
                financingCostModal: true
            })
        }

        else if (this.state.selectedOption === CONSTANTS.STOCK_OPTIONS.COST) {
            this.openStockDetailsModal(stock)
        }
    }

    checkClickedTab = name => {
        const nameIndex = this.state.stockOptions.findIndex(index => index.name === name)
        if (nameIndex > -1) {
            return this.state.stockOptions[nameIndex].clicked
        }
    }

    render() {
        const { classes } = this.props

        const detailsNavbarOptions = [
            // { value: CONSTANTS.NAVBAR.STOCK, role: CONSTANTS.ROLES.STOCK },
            //{ value: CONSTANTS.NAVBAR.FINANCING_COST, role: CONSTANTS.ROLES.FINANCING_COST },
            { value: CONSTANTS.NAVBAR.COSTS, role: [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING] }
            //{ value: CONSTANTS.NAVBAR.OFFERS, role: CONSTANTS.ROLES.OFFER }
        ]
            .filter(option => this.props.checkRights(option.role, CONSTANTS.OR))
            .map(option => option.value)

        const viewOptions = this.props.login.isIPMG ? [
            {
                role: [CONSTANTS.ROLES.ADMIN],
                name: CONSTANTS.STOCK_OPTIONS.SUMAR,
                clicked: this.checkClickedTab(CONSTANTS.STOCK_OPTIONS.SUMAR),
                onClick: option => this.changeStockOptions(option)
            },
            {
                role: [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING],
                name: CONSTANTS.STOCK_OPTIONS.FINANCIAL,
                clicked: this.checkClickedTab(CONSTANTS.STOCK_OPTIONS.FINANCIAL),
                onClick: option => this.changeStockOptions(option)
            },
            {
                role: [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING],
                name: CONSTANTS.STOCK_OPTIONS.COST,
                clicked: this.checkClickedTab(CONSTANTS.STOCK_OPTIONS.COST),
                onClick: option => this.changeStockOptions(option)
            }
        ]
            .filter(option => this.props.checkRights(option.role, CONSTANTS.OR)) :
            [
                {
                    role: [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.EXTERNAL_SELLER],
                    name: CONSTANTS.STOCK_OPTIONS.SUMAR,
                    clicked: this.checkClickedTab(CONSTANTS.STOCK_OPTIONS.SUMAR),
                    onClick: option => this.changeStockOptions(option)
                }
            ]
                .filter(option => this.props.checkRights(option.role, CONSTANTS.OR))


        return (this.state.renderPage && <>
            {this.state.stockToView && <ItemDetails
                footer={CONSTANTS.STOCK_FOOTER}
                title={<span>{this.props.language.detailsTitle} <span className={classes.titleDetailsText}>{`${this.state.stockToView.vinNumber} - ${this.state.stockToView.comment}`}</span></span>}
                editStock={() => this.setState({ modalType: CONSTANTS.EDIT })}
                getStocks={() => this.getStocks()}
                reloadCurrentStock={() => this.reloadCurrentStock()}
                open={this.state.detailsModal}
                navbarOptions={detailsNavbarOptions}
                item={this.state.stockToView}
                defaultTab={this.props.match.params.CURRENT_TAB || null}
                onChangeTab={name => {
                    this.props.history.push(`/stock/${this.state.stockToView.id}/${name.toLowerCase()}`)
                }}
                onCancel={this.onItemDetailsCancelHandler}
                onDelete={() => {
                    this.stockToDelete = this.state.stockToView
                    this.setState({ openConfirmationModal: true, selectedRow: this.state.stockToView })
                }}
            />
            }
            <FinancingCostModal
                open={this.state.financingCostModal}
                onCancel={() => {
                    this.props.unblockStock(this.stockToEdit.id)
                    this.setState({ financingCostModal: false })
                }}
                stock={this.stockToEdit}
                type={CONSTANTS.EDIT}
                onSave={() => {
                    this.getStocks()
                    this.setState({ financingCostModal: false })
                }}
            />
            <StockModal
                getStocks={() => this.getStocks()}
                open={this.state.openFormModal}
                modalType={this.state.modalType}
                openFinancingCostModal={this.openFinancingCostModal}
                onDelete={() => {
                    this.stockToDelete = this.state.stockToView
                    this.setState({ openConfirmationModal: true, selectedRow: this.state.stockToView })
                }}
                stockIdToEdit={this.stockToEdit}
                onClose={this.closeFormModalHandler}
            />
            <ConfirmationModal
                text={`${this.props.language.confirmationDialog.text} ${this.stockToDelete.orderNumber}?`}
                cancelButtonText={this.props.language.confirmationDialog.cancelButtonText}
                acceptButtonText={this.props.language.confirmationDialog.acceptButtonText}
                open={this.state.openConfirmationModal}
                onClose={this.closeConfirmationModalHandler}
                onCancel={this.closeConfirmationModalHandler}
                onAccept={() => this.deleteStockHandler()}>
            </ConfirmationModal>
            <div className={classes.titleContainer}>
                <SearchableHeader
                    hasFilter
                    filterItems={this.state.filterOptions}
                    onChangeFilterItems={this.onFilterOptionChange}
                    filterText={this.props.language.headerFilterText}
                    placeholder={this.props.placeholderLanguage}
                    title={this.props.language.simpleTable.title}
                    onSearch={this.onSearch}
                />
            </div>
            <div className={`${!this.props.sidebarReducer.displaySidebar ? this.props.classes.withoutSidebar : ""} ${this.props.classes.listContainer}`}>
                {this.checkOption(CONSTANTS.OPTIONS.TABLE) && <SimpleList
                    headerFields={this.state.headerFields}
                    getStocks={this.getStocks}
                    items={this.state.unmappedStocks}
                    countTitle={this.props.language.carsInStock}
                    title={<Typography className={classes.subtitle} component='span'>{this.props.language.simpleTable.title}</Typography>}
                    actionsTitle={this.props.language.simpleTable.actionsTitle}
                    disableBorders
                    selectedOption={this.state.selectedOption}
                    onSelect={stock => this.onSelectHandler(stock)}
                    headerButton={{
                        tooltip: this.props.language.simpleTable.headerButton.text,
                        icon: <Button onClick={() => this.setState({ openFormModal: true, modalType: CONSTANTS.CREATE })} className={classes.addButton} variant='contained' color='primary'>
                            <AddIcon className={this.props.classes.addIconStyle} />
                            <span className={this.props.classes.addTextStyle}>{this.props.language.buttons.add}</span>
                        </Button>,
                        onClick: () => { }
                    }}
                    headerFirstOptions={viewOptions}
                    search={{
                        onSearch: this.onSearch
                    }}
                    rowsPerPage={this.state.rowsPerPage}
                    count={this.state.count}
                    changeRowsPerPage={this.changeRowsPerPageHandler}
                    changePageHandler={this.changePageHandler}
                />}
            </div>
        </>)
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.stock,
    login: state.loginReducer,
    sidebarReducer: state.sidebarReducer,
    placeholderLanguage: state.languageReducer.i18n.car.searchPlaceholder
})

const mapDispatchToProps = dispatch => ({
    getStocks: queryParams => dispatch(STOCKS.get(queryParams)),
    getProviders: queryParams => dispatch(PROVIDERS.get(queryParams)),
    getCarModels: (brandId, queryParams) => dispatch(CARS.model.get(brandId, queryParams)),
    getCarBrands: queryParams => dispatch(CARS.brand.get(queryParams)),
    getById: (stockId, blocked) => dispatch(STOCKS.getById(stockId, blocked)),
    unblockStock: stockId => dispatch(STOCKS.unblockStock(stockId)),
    getByIdPopulate: stockId => dispatch(STOCKS.getByIdPopulate(stockId)),
    create: stock => dispatch(STOCKS.create(stock)),
    delete: stockId => dispatch(STOCKS.del(stockId)),
    edit: (stockId, stock) => dispatch(STOCKS.edit(stockId, stock)),
    checkRights: (rights, option) => dispatch(ROLES.checkRights(rights, option))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Stock))