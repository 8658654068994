import queryString from 'query-string'
import axios from '../../utils/axios'
import { errorHandler } from '../../utils/apiFunctions'

const brandRoute = '/cars/brands'
const modelRoute = '/cars/models'

export const brand = {
    get: queryParams => dispatch =>
        axios.get(brandRoute + `?${queryString.stringify(queryParams)}`)
            .then(response => Promise.resolve(response.data))
            .catch(errorHandler),
    getById: brandId => dispatch =>
        axios.get(`${brandRoute}/${brandId}`)
            .then(response => Promise.resolve(response.data))
            .catch(errorHandler),
    create: brand => dispatch =>
        axios.post(brandRoute, { carBrand: brand })
            .then(response => Promise.resolve(response.data))
            .catch(errorHandler),
    edit: (brandId, brand) => dispatch =>
        axios.put(`${brandRoute}/${brandId}`, { carBrand: brand })
            .then(response => Promise.resolve(response.data))
            .catch(errorHandler),
    del: brandId => dispatch =>
        axios.delete(`${brandRoute}/${brandId}`)
            .then(response => Promise.resolve(response.data))
            .catch(errorHandler),
    uploadLogo: (brandId, files) => dispatch =>
        axios.post(`${brandRoute}/${brandId}/image`, files)
            .then(response => Promise.resolve(response.data))
            .catch(errorHandler),
    editLogo: (brandId, files) => dispatch =>
        axios.put(`${brandRoute}/${brandId}/image`, files)
            .then(response => Promise.resolve(response.data))
            .catch(errorHandler)

}

export const model = {
    get: (brandId, queryParams) => dispatch =>
        axios.get(`${brandRoute}/${brandId}/models?${queryString.stringify(queryParams)}`)
            .then(response => Promise.resolve(response.data))
            .catch(errorHandler),
    getById: modelId => dispatch =>
        axios.get(`${modelRoute}/${modelId}`)
            .then(response => Promise.resolve(response.data))
            .catch(errorHandler),
    create: (brandId, model) => dispatch =>
        axios.post(`${brandRoute}/${brandId}/models`, { carModel: model })
            .then(response => Promise.resolve(response.data))
            .catch(errorHandler),
    edit: (modelId, model) => dispatch =>
        axios.put(`${modelRoute}/${modelId}`, { carModel: model })
            .then(response => Promise.resolve(response.data))
            .catch(errorHandler),
    del: modelId => dispatch =>
        axios.delete(`${modelRoute}/${modelId}`)
            .then(response => Promise.resolve(response.data))
            .catch(errorHandler)
}