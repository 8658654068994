import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, withStyles } from '@material-ui/core'
import { AddCircleOutlineOutlined as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'

import { validations } from '../../../utils/validationUtils'
import SimpleList from '../../common/SimpleList'
import FormModal from '../../common/FormModal'
import ConfirmationDialog from '../../common/ConfirmationDialog'
import * as CONSTANTS from '../../../utils/constants'
import * as CAR from '../../../redux/actions/cars'
import * as NOTIFICATION from '../../../utils/notification'

const styles = theme => ({
    addButton: {
        padding: '8px 20px',
        fontSize: '15px',
        width: '100px',
        marginTop: '15px',
        display: 'flex'
    },
    subtitle: {
        fontSize: '35px',
        paddingTop: '20px',
        color: theme.palette.primaryColor,
        display: 'block',
        width: '350px'
    },
    iconStyle: {
        paddingRight: 5
    }
})


class CarBrand extends Component {
    brandToDelete = {}
    brandToEdit = {}

    initialFields = [
        { value: '', type: 'text', label: this.props.language.name, name: 'name', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'file', InputLabelProps: { shrink: true }, label: this.props.language.logo, name: 'logo' }
    ]

    state = {
        brands: [],
        count: 0,
        from: 0,
        search: '',
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        openModal: false,
        openConfirmationModal: false,
        modalType: CONSTANTS.CREATE,
        modalFields: this.initialFields,
        selectedRow: null
    }

    componentDidMount() {
        this.getBrands()
    }

    getBrands = () =>
        this.props.get({
            search: this.state.search
        }).then(data => {
            this.setState({
                brands: data.carBrands.map((brand, index) => ({ index: index + 1, name: brand.name, _id: brand.id })),
                count: data.count,
                openModal: false,
                openConfirmationModal: false
            }, () => {
                if (this.state.brands[0]) {
                    let firstBrand = this.state.brands[0]
                    this.selectRowHandler(firstBrand)
                }
            })
        })


    selectRowHandler = row => {
        this.setState({
            selectedRow: row._id
        })
        this.props.onChangeBrand(row._id, row.name)
    }

    onCreateHandler = fields => {
        this.props.create(fields).then(createdBrand => {
            //now upload file
            if (fields.logo.files && fields.logo.files.length) {
                let files = Array.from(fields.logo.files)
                const formData = new FormData()
                formData.append('file', files[0])
                return this.props.uploadLogo(createdBrand.id, formData).then(() => {
                    this.getBrands()
                    NOTIFICATION.success(this.props.language.create.success)
                })
            } else {
                this.getBrands()
                NOTIFICATION.success(this.props.language.create.success)
            }
        }).catch(() => {
            NOTIFICATION.error(this.props.language.create.failure)
        })
    }

    onEditHandler = fields => {
        this.props.edit(this.brandToEdit._id, fields).then(() => {
            //now edit file if changed

            if (fields.logo.files && fields.logo.files.length) {
                let files = Array.from(fields.logo.files)
                const formData = new FormData()
                formData.append('file', files[0])
                return this.props.editLogo(this.brandToEdit._id, formData).then(() => {
                    this.getBrands()
                    NOTIFICATION.success(this.props.language.edit.success)
                })
            } else {
                this.getBrands()
                NOTIFICATION.success(this.props.language.edit.success)
            }
        }).catch(() => {
            NOTIFICATION.success(this.props.language.edit.failure)
        })
    }

    onDeleteHandler = () => {
        this.props.delete(this.brandToDelete._id).then(() => {
            this.getBrands()
            NOTIFICATION.success(this.props.language.delete.success)
        }).catch(() => {
            NOTIFICATION.success(this.props.language.delete.failure)
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.onSearch(this.props.search)
        }
    }

    toggleEditModal = brand => {
        this.brandToEdit = brand
        this.props.getById(brand._id).then(brandResponse => {
            this.setState({
                modalFields: this.initialFields.map(field => ({ ...field, value: brandResponse[field.name] })),
                openModal: true,
                modalType: CONSTANTS.EDIT
            })
        }).catch(() => NOTIFICATION.error(this.props.language.get.notFound))
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, () => this.getBrands())
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getBrands())
    }

    onSearch = search => this.setState({ search }, this.getBrands)

    render() {
        const { classes } = this.props
        return <>
            <ConfirmationDialog
                open={this.state.openConfirmationModal}
                cancelButtonText={this.props.language.confirmationDialog.cancelButtonText}
                acceptButtonText={this.props.language.confirmationDialog.acceptButtonText}
                text={`${this.props.language.confirmationDialog.text} ${this.brandToDelete.name}?`}
                onCancel={() => this.setState({ openConfirmationModal: false })}
                onClose={() => this.setState({ openConfirmationModal: false })}
                onAccept={this.onDeleteHandler}
            />
            <FormModal
                fields={this.state.modalFields}
                key={this.state.openModal}
                open={this.state.openModal}
                onCancel={() => this.setState({ openModal: false })}
                onSave={data => this.state.modalType === CONSTANTS.CREATE ? this.onCreateHandler(data.fields) : this.onEditHandler(data.fields)}
                dialogTitle={this.state.modalType === CONSTANTS.CREATE ? this.props.language.formModal.addTitle : this.props.language.formModal.editTitle}
                cancelButtonText={this.props.language.confirmationDialog.cancelButtonText}
                submitButtonText={this.state.modalType === CONSTANTS.CREATE ? this.props.language.formModal.add : this.props.language.formModal.edit}
                type={this.state.modalType}
                validate={true}
                maxWidth='xs'
            />
            <SimpleList
                selectedOption={CONSTANTS.CAR_OPTIONS.BRAND}
                headerFields={this.props.language.brandHeaderFields}
                items={this.state.brands}
                onClickRow={this.selectRowHandler}
                actionsTitle={this.props.language.simpleTable.actionsTitle}
                selectedRow={this.state.selectedRow}
                disableBorders
                actions={[
                    // {
                    //     tooltip: this.props.language.simpleTable.actions.delete,
                    //     onClick: (event, row) => {
                    //         this.brandToDelete = row
                    //         this.setState({ openConfirmationModal: true })
                    //     },
                    //     icon: <DeleteIcon />
                    // },
                    {
                        tooltip: this.props.language.simpleTable.actions.edit,
                        onClick: (event, row) => this.toggleEditModal(row),
                        icon: <EditIcon />
                    }
                ]}
                onSelect={this.selectRowHandler}
                headerButton={{
                    tooltip: this.props.language.simpleTable.headerButton.text,
                    icon: <Button onClick={() => this.setState({ openModal: true, modalFields: this.initialFields })} className={classes.addButton} variant='contained' color='primary'>
                        <AddIcon className={classes.iconStyle} />
                        {this.props.language.simpleTable.headerButton.buttonText}
                    </Button>,
                    onClick: () => this.setState({ openModal: true, modalType: CONSTANTS.CREATE })
                }}
                rowsPerPage={this.state.rowsPerPage}
                count={this.state.count}
                countTitle={this.props.language.brands}
                changeRowsPerPage={this.changeRowsPerPageHandler}
                changePageHandler={this.changePageHandler}
            />
        </>
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.car.brands
})

const mapDispatchToProps = dispatch => ({
    get: queryParams => dispatch(CAR.brand.get(queryParams)),
    getById: brandId => dispatch(CAR.brand.getById(brandId)),
    create: brand => dispatch(CAR.brand.create(brand)),
    delete: brandId => dispatch(CAR.brand.del(brandId)),
    uploadLogo: (brandId, form) => dispatch(CAR.brand.uploadLogo(brandId, form)),
    editLogo: (brandId, form) => dispatch(CAR.brand.editLogo(brandId, form)),
    edit: (brandId, brand) => dispatch(CAR.brand.edit(brandId, brand))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CarBrand))