import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table, TableCell, TableHead, TableRow, TableBody, Typography, InputAdornment, Tooltip, IconButton, Paper, Toolbar, TextField, TablePagination, withStyles } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import PropTypes from 'prop-types'

import * as CONSTANTS from '../../utils/constants'

const style = theme => ({
    tableBackground: {
        backgroundColor: theme.palette.common.white
    },
    tableContainer: {
        marginTop: 50
    },
    spacer: {
        flex: '1 1 100%'
    },
    searchIcon: {
        fontSize: '21px'
    },
    searchInput: {
        fontSize: '',
        width: '350px',
        marginRight: '30px'
    },
    searchInputUnderline: {
        '&:after': {
            borderBottom: '2px solid ' + theme.palette.redColor,
        }
    },
    tableTh: {
        padding: '4px 0px 4px 21px',
        '&:nth-of-type(1)': {
            width: '20px',
            padding: '4px 0px 0px 25px'
        }
    },
    tableTd: {
        padding: '4px 10px 4px 15px',
        '&:nth-of-type(1)': {
            width: '20px',
            padding: '4px 0px 0px 25px'
        }
    },
    tableActionsTh: {
        textAlign: 'center',
        width: '100px'
    },
    tableActionsTd: {
        margin: 'auto'
    },
    tableWrapperFullWidth: {
        width: '100%',
        minHeight: '100%'
    },
    disableBorders: {
        boxShadow: 'none'
    },
    disableToolbarPadding: {
        paddingLeft: '0px'
    },
    clickableRow: {
        cursor: 'pointer'
    },
    tablePaper: {
        minHeight: '100%'
    },
    titleContainer: {
        width: '350px',
    },
    actionTableCell: {
        width: 'auto',
        position: 'relative',
        margin: 'auto',
        height: '50px',
        textAlign: 'center'
    }
})

class SimpleTable extends Component {
    state = {
        searchInput: '',
        page: 0,
        rowsPerPage: this.props.rowsPerPage
    }


    changeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value }, () => this.props.changeRowsPerPage(this.state.rowsPerPage))
    }

    changePage = (event, page) => {
        let currentFrom = page * this.state.rowsPerPage
        this.props.changePageHandler(currentFrom)

        this.setState({ page })
    }

    render() {
        return (
            <div className={this.props.classes.tableWrapperFullWidth}>
                <Paper className={`${this.props.tableClass}  ${this.props.disableBorders ? this.props.classes.disableBorders : ''} ${this.props.classes.tablePaper}`}>
                    <Toolbar className={`${this.props.classes.tableToolbar} ${this.props.toolbarClass} ${this.props.disableToolbarPadding ? this.props.classes.disableToolbarPadding : ''}`}>
                        {this.props.title && <Typography variant="div" className={this.props.classes.titleContainer} id="tableTitle">
                            {this.props.title}
                        </Typography>}
                        <div className={this.props.classes.spacer} />
                        {this.props.search &&
                            <Tooltip title={this.props.language.search.searchTitle}>
                                <TextField
                                    className={this.props.classes.searchInput}
                                    value={this.state.searchInput}
                                    onKeyPress={event => event.key === 'Enter' ? this.props.search.onSearch(this.state.searchInput) : () => { }}
                                    onChange={event => this.setState({ searchInput: event.target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon className={this.props.classes.searchIcon} color="secondary" />
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            underline: this.props.classes.searchInputUnderline
                                        }
                                    }}
                                />
                            </Tooltip>}
                        <div className={this.props.classes.actions}>
                            {this.props.headerButton &&
                                <Tooltip title={this.props.headerButton.tooltip}>
                                    <div onClick={this.props.headerButton.onClick} color={this.props.headerButton.color || 'secondary'} aria-label={this.props.headerButton.tooltip}>
                                        {this.props.headerButton.icon}
                                    </div>
                                </Tooltip>}
                        </div>
                    </Toolbar>
                    <Table className={this.props.disableTableContainer ? null : this.props.classes.tableContainer}>
                        <TableHead>
                            <TableRow>
                                {this.props.header.map(value => <TableCell key={value} style={typeof value === 'object' ? value.style : {}} className={this.props.classes.tableTh}>{typeof value === 'object' ? value.val : value}</TableCell>)}
                                {this.props.actions && <TableCell className={`${this.props.classes.tableTh} ${this.props.classes.tableActionsTh}`}> {this.props.actionsTitle} </TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.rows.map(row => {
                                let values = Object.values(row)
                                let keys = Object.keys(row)
                                return (
                                    <TableRow
                                        selected={this.props.selectedRow && this.props.selectedRow === row._id ? true : false}
                                        hover={this.props.onClickRow ? true : false}
                                        className={this.props.onClickRow ? this.props.classes.clickableRow : ''}
                                        onClick={event => {
                                            if (event.target.tagName.toLowerCase() !== 'button' && event.target.tagName !== 'path' && event.target.tagName !== 'svg' && typeof this.props.onClickRow === 'function')
                                                this.props.onClickRow(row)
                                        }} key={row.index}>
                                        {values.map((value, index) => {
                                            if (keys[index].substr(0, 1) !== '_') {
                                                if (value && typeof value === 'object')
                                                    return <TableCell key={index} className={this.props.classes.tableTd} style={value.style}>{value.val}</TableCell>
                                                return <TableCell key={index} className={this.props.classes.tableTd}>{value}</TableCell>
                                            } else return null
                                        })}
                                        {this.props.actions && <TableCell style={{ width: this.props.actionsWidth || this.props.actionTableCell }}>
                                            <div className={this.props.classes.tableActionsTd}>
                                                {this.props.actions.map(action => {
                                                    if ((action.deleteIf && !action.deleteIf(row)) || !action.deleteIf) {
                                                        return <Tooltip key={action.tooltip} title={action.tooltip} placement='top'>
                                                            <IconButton
                                                                onClick={event => action.onClick ? action.onClick(event, row) : () => { }}
                                                                color={action.color || 'primary'}
                                                                aria-label={action.tooltip || ''}>
                                                                {action.icon}
                                                            </IconButton>
                                                        </Tooltip>
                                                    } else return null
                                                })}
                                            </div>
                                        </TableCell>}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    {this.props.changePageHandler && <TablePagination
                        rowsPerPageOptions={CONSTANTS.DEFAULT_ROWS_PER_PAGE}
                        component="div"
                        count={this.props.count}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page'
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page'
                        }}
                        onChangePage={this.changePage}
                        onChangeRowsPerPage={this.changeRowsPerPage}
                    />}
                </Paper>
            </div>
        )
    }
}

SimpleTable.propTypes = {
    rows: PropTypes.array.isRequired,
    header: PropTypes.array.isRequired,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    actionsTitle: PropTypes.string,
    actions: PropTypes.array,
    headerButton: PropTypes.object.isRequired,
    search: PropTypes.object
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n,
})

const mapDispatchToProps = dispatch => ({
})

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(SimpleTable))