import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, withStyles } from '@material-ui/core'
import { Delete as DeleteIcon, Edit as EditIcon, AddCircleOutline as AddIcon, InsertDriveFile as DocumentIcon, LiveTvRounded } from '@material-ui/icons'
import moment from 'moment'

import { validations } from '../../../utils/validationUtils'
import SimpleList from '../../common/SimpleList'
import FormModal from '../../common/FormModal'
import ConfirmationDialog from '../../common/ConfirmationDialog'
import * as NOTIFICATION from '../../../utils/notification'
import * as CONSTANTS from '../../../utils/constants'
import Header from '../../common/SearchableHeader'
import * as CLIENT from '../../../redux/actions/clients'
import * as LEASING from '../../../redux/actions/leasing'

const styles = theme => ({
    addButton: {
        padding: '8px 20px',
        fontSize: '15px',
        width: '150px',
        marginTop: '15px',
        display: 'flex'
    },
    subtitle: {
        fontSize: '35px',
        paddingTop: '20px',
        color: theme.palette.primaryColor,
        display: 'block',
        width: '350px'
    },
    header: {
        width: '100%',
        height: 70
    },
    listContainer: {
        padding: 27,
        overflow: 'auto'
    },
    addIcon: {
        paddingRight: 5
    },
    withoutSidebar: {
        padding: '24px 36px 24px 0px'
    }
})

class Leasing extends Component {
    initialFields = [
        { value: '', type: 'text', label: 'Cui', name: 'cui', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: 'Nume', name: 'name', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: 'Tara', name: 'country', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: 'Oras', name: 'city', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: 'Adresa', name: 'address', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: 'Nume persoana contact', name: 'contactName', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: 'Email persoana contact', name: 'contactEmail', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: 'Numar telefon persoana contact', name: 'contactPhoneNumber', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: 'Iban', name: 'iban', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: 'Banca', name: 'bank', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'text', label: 'Registrul comertului', name: 'tradeRegister', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'file', InputLabelProps: { shrink: true }, label: 'Document', name: 'document' }
    ]

    leasingToEdit = {}
    leasingToDelete = {}

    _deepClone = arr => [...arr].map(el => ({ ...el }))

    state = {
        leasings: [],
        count: 0,
        from: 0,
        search: '',
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        openModal: false,
        openConfirmationModal: false,
        modalFields: this._deepClone(this.initialFields),
        modalType: CONSTANTS.CREATE,
        showAnafButton: true
    }

    componentDidMount() {
        this.getLeasings()
    }

    getLeasings = () =>
        this.props.getLeasing({
            search: this.state.search
        }).then(data => {
            this.setState({
                leasings: data.rows.map((leasing, index) => ({
                    index: index + 1,
                    _id: leasing.id,
                    name: leasing.name || '',
                    createdAt: moment(leasing.createdAt).format(CONSTANTS.DEFAULT_DATE_TIME_FORMAT),
                    updatedAt: moment(leasing.updatedAt).format(CONSTANTS.DEFAULT_DATE_TIME_FORMAT)
                })),
                count: data.count,
                openModal: false,
                openConfirmationModal: false
            })
            return Promise.resolve()
        })

    uploadDocument = (id, files) => {
        const formData = new FormData()
        formData.append('file', files[0])

        return this.props.uploadDocument(id, formData)
    }

    onCreateHandler = model => {
        if(!(model.document && model.document.files && model.document.files.length)){
            return NOTIFICATION.error(this.props.language.withoutDocument)
        }
        this.props.createLeasing(model).then(async data => {
            if (model.document && model.document.files && model.document.files.length) {
                await this.uploadDocument(data.id, model.document.files)
            }
            this._clearForm()
            this.getLeasings()
            NOTIFICATION.success(this.props.language.create.success)
        }).catch(() => NOTIFICATION.error(this.props.language.create.failure))
    }

    onEditHandler = model => {
        this.props.editLeasing(this.leasingToEdit._id, model).then(async () => {

            if (model && model.document && model.document.files && model.document.files.length) {
                await this.uploadDocument(this.leasingToEdit._id, model.document.files)
            }
            this.getLeasings()
            this._clearForm()
            NOTIFICATION.success(this.props.language.edit.success)
        }).catch(() => NOTIFICATION.error(this.props.language.edit.failure))
    }

    onDeleteHandler = () => {
        this.props.deleteLeasing(this.leasingToDelete._id).then(() => {
            NOTIFICATION.success(this.props.language.delete.success)
            this.getLeasings()
        }).catch(() => NOTIFICATION.error(this.props.language.delete.failure))
    }

    toggleEditModal = provider => {
        this.leasingToEdit = provider
        this.props.getByIdLeasing(provider._id).then(providerResponse => {

            let fields = this._deepClone(this.initialFields)
                .map(field => ({ ...field, value: providerResponse[field.name] }))


            this.setState({
                modalFields: fields,
                openModal: true,
                modalType: CONSTANTS.EDIT,
                showAnafButton: false
            })
        }).catch(() => NOTIFICATION.error(this.props.language.get.notFound))
    }

    _clearForm = () => {
        this.setState({ modalFields: this._deepClone(this.initialFields), showAnafButton: true })
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, () => this.getLeasings())
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getLeasings())
    }

    onSearch = search => this.setState({ search }, this.getLeasings)

    onChangeHandler = event => {
        let fieldsCopy = this._deepClone(this.state.modalFields)

        let fieldIndex = fieldsCopy.findIndex(field => field.name === event.target.name)

        if (fieldIndex < 0) return

        fieldsCopy[fieldIndex].value = event.target.value
        this.setState({
            modalFields: fieldsCopy
        })
    }

    render() {
        const { classes } = this.props
        return <>
            <ConfirmationDialog
                open={this.state.openConfirmationModal}
                cancelButtonText={this.props.language.confirmationDialog.cancelButtonText}
                acceptButtonText={this.props.language.confirmationDialog.acceptButtonText}
                text={`${this.props.language.confirmationDialog.text} ${this.leasingToDelete.name}?`}
                onCancel={() => this.setState({ openConfirmationModal: false })}
                onClose={() => this.setState({ openConfirmationModal: false })}
                onAccept={this.onDeleteHandler}
            />

            <FormModal
                canSubmit
                fields={[...this.state.modalFields]}
                key={this.state.openModal}
                open={this.state.openModal}
                onCancel={() => this.setState({ openModal: false }, this._clearForm)}
                onSave={data => this.state.modalType === CONSTANTS.CREATE ? this.onCreateHandler(data.fields) : this.onEditHandler(data.fields)}
                dialogTitle={this.state.modalType === CONSTANTS.CREATE ? this.props.language.formModal.add : this.props.language.formModal.title}
                cancelButtonText={this.props.language.confirmationDialog.cancelButtonText}
                submitButtonText={this.state.modalType === CONSTANTS.CREATE ? this.props.language.formModal.add : this.props.language.formModal.edit}
                type={this.state.modalType}
                validate={true}
                maxWidth='md'
                onChange={this.onChangeHandler}
                fetchAnaf
            />

            <div className={classes.header}>
                <Header
                    title={this.props.language.leasing}
                    placeholder={this.props.language.leasing.search}
                    onSearch={this.onSearch}
                />
            </div>
            <div className={`${!this.props.sidebarReducer.displaySidebar ? this.props.classes.withoutSidebar : ""} ${this.props.classes.listContainer}`}>
                <SimpleList
                    items={this.state.leasings}
                    headerFields={this.props.language.simpleTable.header}
                    selectedOption={CONSTANTS.PROVIDER_OPTIONS}
                    actionsTitle={this.props.language.simpleTable.actionsTitle}
                    disableBorders
                    actions={[
                        {
                            tooltip: this.props.language.simpleTable.actions.delete,
                            onClick: (event, row) => {
                                this.leasingToDelete = row
                                this.setState({ openConfirmationModal: true })
                            },
                            icon: <DeleteIcon />
                        },
                        {
                            tooltip: this.props.language.simpleTable.actions.edit,
                            onClick: (event, row) => this.toggleEditModal(row),
                            icon: <EditIcon />
                        },
                        {
                            tooltip: this.props.language.simpleTable.actions.preview,
                            onClick: (event, row) => this.props.getByIdLeasing(row._id)
                                .then(result => window.open(`${CONSTANTS.BACKEND_URL}static/leasings/${result.documentPath}`, "_blank"))
                            ,
                            icon: <DocumentIcon />
                        }
                    ]}
                    onSelect={provider => this.toggleEditModal(provider)}
                    headerButton={{
                        tooltip: this.props.language.simpleTable.headerButton.text,
                        icon: <Button onClick={() => this.setState({ openModal: true })} className={classes.addButton} variant='contained' color='primary'>
                            <AddIcon className={classes.addIcon} />
                            {this.props.language.simpleTable.headerButton.buttonText}
                        </Button>,
                        onClick: () => this.setState({ openModal: true, modalType: CONSTANTS.CREATE })
                    }}
                    search={{
                        onSearch: this.onSearch
                    }}
                    rowsPerPage={this.state.rowsPerPage}
                    count={this.state.count}
                    countTitle={this.props.language.leasingsCount}
                    changeRowsPerPage={this.changeRowsPerPageHandler}
                    changePageHandler={this.changePageHandler}
                />
            </div>
        </>
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.leasing,
    sidebarReducer: reducers.sidebarReducer
})

const mapDispatchToProps = dispatch => ({
    getLeasing: queryParams => dispatch(LEASING.get({ ...queryParams })),
    createLeasing: leasing => dispatch(LEASING.create(leasing)),
    getByIdLeasing: id => dispatch(LEASING.getById(id)),
    editLeasing: (id, leasing) => dispatch(LEASING.edit(id, leasing)),
    deleteLeasing: id => dispatch(LEASING.deleteRow(id)),
    uploadDocument: (id, files) => dispatch(LEASING.uploadDocument(id, files)),
    getDocument: id => dispatch(LEASING.getDocument(id)),
    getAnafData: cui => dispatch(CLIENT.getFromAnaf(cui))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Leasing))