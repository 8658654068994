import { combineReducers } from 'redux'
import languageReducer from './language'
import loginReducer from './login'
import alerts from './alerts'
import sidebarReducer from './sidebar'

export default combineReducers({
    languageReducer,
    loginReducer,
    sidebarReducer,
    alerts
})