import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, TableRow, TableCell, Tooltip } from '@material-ui/core'
import moment from 'moment'

import ListAvatarAndCarBrand from '../common/subComponents/ListAvatarAndCarBrand'
import DropdownMenu from '../common/DropdownMenu'

import * as NOTIFICATION from '../../utils/notification'
import * as CONSTANTS from '../../utils/constants'
import * as STOCK from '../../redux/actions/stocks'
import { formatNumber, ClickWrapper } from '../../utils/helpers'
import config from '../../config/local.json'

const styles = theme => ({
    fieldText: {
        fontWeight: 'normal',
        fontSize: 14,
        paddingTop: 4
    },
    title: {
        width: 350
    },
    clickedButton: {
        color: 'green'
    },
    listWrapper: {
        '& span:not(:first-child)': {
            display: 'inline-block',
            marginLeft: '5px'
        }
    },
    hrefStyle: {
        color: 'black',
        paddingRight: 8
    },
    iconStyle: {
        fontSize: 24
    },
    expireStyle: {
        color: 'red',
        fontWeight: 500
    }
})

class RentList extends Component {

    render() {
        const logoPath = `${config.baseUrl}/cars/brands/${this.props.item.stock.carBrandId}/image`
        let fieldsToRenderInPrimaryText = [
            { field: CONSTANTS.RENT_FIELDS.PROVIDER, name: this.props.language.stock.provider },
            { field: CONSTANTS.RENT_FIELDS.CLIENT, name: this.props.language.stock.client },
            { field: CONSTANTS.RENT_FIELDS.USER, name: this.props.language.stock.username },
            { field: CONSTANTS.RENT_FIELDS.CONTRACT_NUMBER, name: this.props.language.offer.rent.contractNumber },
            { field: CONSTANTS.RENT_FIELDS.CONTRACT_DATE, name: this.props.language.offer.rent.contractDate },
            { field: CONSTANTS.RENT_FIELDS.END_DATE, name: this.props.language.offer.rent.endDate },
            { field: CONSTANTS.RENT_FIELDS.PERIOD_DAYS, name: this.props.language.offer.rent.period },
            { field: CONSTANTS.RENT_FIELDS.PRICE, name: this.props.language.offer.rent.price, symbol: CONSTANTS.EURO_SYMBOL },
        ]

        return (
            <Tooltip placement="top" title={this.props.item.stock.comment}>
                <TableRow>
                    <ClickWrapper onClick={() => { }}>
                        <TableCell>
                            <ListAvatarAndCarBrand
                                carBrandName={this.props.item.stock.carBrand.name}
                                carModelName={this.props.item.stock.carModel.name}
                                logoPath={logoPath}
                            />
                        </TableCell>
                        {fieldsToRenderInPrimaryText.map((fieldObj, index) => {
                            if (fieldObj.field === CONSTANTS.RENT_FIELDS.PROVIDER) {
                                return (
                                    <TableCell key={index} >
                                        {this.props.item.stock[fieldObj.field] ? <span className={this.props.classes.fieldText}>{`${this.props.item.stock[fieldObj.field].name}`}</span> : null}
                                    </TableCell>
                                )
                            }
                            if (fieldObj.field === CONSTANTS.RENT_FIELDS.CLIENT) {
                                return (
                                    <TableCell key={index} >
                                        {this.props.item.client[fieldObj.field] ? <span className={this.props.classes.fieldText}>{`${this.props.item.client[fieldObj.field]}`}</span> : <span className={this.props.classes.fieldText}>{this.props.item.client['contactName'] || '-'}</span>}
                                    </TableCell>
                                )
                            }

                            if (fieldObj.field === CONSTANTS.RENT_FIELDS.USER) {
                                return (
                                    <TableCell key={index} >
                                        {this.props.item[fieldObj.field] ? <span className={this.props.classes.fieldText}>{`${this.props.item[fieldObj.field].fullName}`}</span> : null}
                                    </TableCell>
                                )
                            }
                            if (fieldObj.field === CONSTANTS.RENT_FIELDS.END_DATE) {
                                let expiresInDay = null
                                // If extend rents then display last end date of array
                                if (this.props.item.extendRents && this.props.item.extendRents.length) {
                                    expiresInDay = moment(this.props.item.extendRents[this.props.item.extendRents.length - 1].to).diff(moment(), 'days')
                                
                                    return (
                                        <TableCell size='small' key={index} >
                                            {this.props.item[fieldObj.field] ? <span className={`${this.props.classes.fieldText} ${expiresInDay <= CONSTANTS.END_DATE_EXPIRATION_DAYS ? this.props.classes.expireStyle : ''}`}>{`${moment(this.props.item.extendRents[this.props.item.extendRents.length - 1].to).format(CONSTANTS.DEFAULT_DATE_FORMAT)}`} </span> : <span className={this.props.classes.fieldText}>-</span>}
                                        </TableCell>
                                    )
                                }
                             
                                expiresInDay = moment(this.props.item[fieldObj.field]).diff(moment(), 'days')
                                return (
                                    <TableCell size='small' key={index} >
                                        {this.props.item[fieldObj.field] ? <span className={`${this.props.classes.fieldText} ${expiresInDay <= CONSTANTS.END_DATE_EXPIRATION_DAYS ? this.props.classes.expireStyle : ''}`}>{`${moment(this.props.item[fieldObj.field]).format(CONSTANTS.DEFAULT_DATE_FORMAT)}`} </span> : <span className={this.props.classes.fieldText}>-</span>}
                                    </TableCell>
                                )

                            }

                            if (fieldObj.field === CONSTANTS.RENT_FIELDS.CONTRACT_DATE) {
                                return (
                                    <TableCell size='small' key={index} >
                                        {this.props.item[fieldObj.field] ? <span className={this.props.classes.fieldText}>{`${moment(this.props.item[fieldObj.field]).format(CONSTANTS.DEFAULT_DATE_FORMAT)}`} </span> : <span className={this.props.classes.fieldText}>-</span>}
                                    </TableCell>
                                )
                            }

                            // If extend rent hen display sum of days
                            if (fieldObj.field === CONSTANTS.RENT_FIELDS.PERIOD_DAYS && this.props.item.extendRents && this.props.item.extendRents.length) {
                                const periodDaysSum = this.props.item.extendRents.map(item => item.days).reduce((sum, currValue) => sum + currValue)
                                return (
                                    <TableCell key={index} >
                                        {this.props.item[fieldObj.field] ?
                                            <span className={this.props.classes.fieldText}>
                                                {`${formatNumber(this.props.item[fieldObj.field] + periodDaysSum)} ${fieldObj.symbol ? fieldObj.symbol : ""}`} </span>
                                            : <span className={this.props.classes.fieldText}>{`0 ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span>}
                                    </TableCell>
                                )
                            }

                            if (fieldObj.field === CONSTANTS.RENT_FIELDS.CONTRACT_NUMBER) {
                                return (
                                    <TableCell key={index} >
                                        {this.props.item[fieldObj.field] ?
                                            <span className={this.props.classes.fieldText}>
                                                {`${this.props.item[fieldObj.field]} ${fieldObj.symbol ? fieldObj.symbol : ""}`} </span>
                                            : <span className={this.props.classes.fieldText}>{`0 ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span>}
                                    </TableCell>
                                )
                            }

                            if (fieldObj.field === CONSTANTS.RENT_FIELDS.PRICE) {
                                let price = this.props.item.price
                                const lastExtend = this.props.item.extendRents.length ? this.props.item.extendRents[this.props.item.extendRents.length -1] : {}
                                if (lastExtend.price) {
                                    price = lastExtend.price
                                }
                                return (
                                    <TableCell key={index} >
                                        {price ?
                                            <span className={this.props.classes.fieldText}>
                                                {`${price} ${fieldObj.symbol ? fieldObj.symbol : ""}`} </span>
                                            : <span className={this.props.classes.fieldText}>{`0 ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span>}
                                    </TableCell>
                                )
                            }

                            return (
                                <TableCell key={index} >
                                    {this.props.item[fieldObj.field] ?
                                        <span className={this.props.classes.fieldText}>
                                            {`${formatNumber(this.props.item[fieldObj.field])} ${fieldObj.symbol ? fieldObj.symbol : ""}`} </span>
                                        : <span className={this.props.classes.fieldText}>{`0 ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span>}
                                </TableCell>
                            )
                        })}
                    </ClickWrapper>
                    {this.props.actions ? <TableCell>
                        <DropdownMenu type={this.props.language.stock.actions} item={this.props.item} items={[
                            {
                                tooltip: this.props.language.stock.getContract,
                                onClick: (_, row) => window.open(`${CONSTANTS.BACKEND_URL}rents/${this.props.item.id}/contract`, "_blank"),
                                withoutBorder: true
                            },
                            {
                                tooltip: this.props.language.stock.sendContract,
                                onClick: (_, row) => {
                                    if (window.confirm(this.props.language.rentConfirmations.sendRent)) {
                                        this.props.sendRentContract(this.props.item.id).then(() => {
                                            NOTIFICATION.success(this.props.language.stock.mailSent)
                                        })
                                            .catch(() => NOTIFICATION.error(this.props.language.stock.mailFail))
                                    }
                                }
                            },
                            {
                                tooltip: this.props.language.stock.extension,
                                onClick: (_, row) => this.props.setRentExtendModal(row),
                                withoutBorder: true

                            },
                            {
                                tooltip: this.props.language.stock.downloadExtension,
                                onClick: (_, row) => {
                                    let lastExtend
                                    if (row.extendRents && row.extendRents.length) {
                                        lastExtend = row.extendRents[row.extendRents.length - 1]
                                    }
                                    window.open(`${CONSTANTS.BACKEND_URL}rents/${row.id}/extendContract?from=${lastExtend ? lastExtend.from : null}&to=${lastExtend ? lastExtend.to : null}`, "_blank")
                                },
                                withoutBorder: true

                            },
                            {
                                tooltip: this.props.language.stock.sendExtendContract,
                                onClick: (_, row) => {
                                    if (window.confirm(this.props.language.rentConfirmations.sendRentExtend)) {
                                        this.props.sendExtendRentContract(this.props.item.id).then(() => {
                                            NOTIFICATION.success(this.props.language.stock.mailSent)
                                        })
                                            .catch(() => NOTIFICATION.error(this.props.language.stock.mailFail))
                                    }
                                }
                            },
                            {
                                tooltip: this.props.language.stock.cancel,
                                onClick: (_, row) => {
                                    window.open(`${CONSTANTS.BACKEND_URL}rents/${this.props.item.id}/endContract`, "_blank")
                                },
                                withoutBorder: true
                            },
                            {
                                tooltip: this.props.language.stock.sendCloseContract,
                                onClick: (_, row) => {
                                    if (window.confirm(this.props.language.rentConfirmations.sendRentClose)) {
                                        this.props.sendCancelRentContract(this.props.item.id).then(() => {
                                            NOTIFICATION.success(this.props.language.stock.mailSent)
                                        })
                                            .catch(() => NOTIFICATION.error(this.props.language.stock.mailFail))
                                    }
                                },
                                withoutBorder: true
                            },
                            {
                                tooltip: this.props.language.stock.closeRent,
                                onClick: (_, row) => {
                                    if (window.confirm(this.props.language.rentConfirmations.close)) {
                                        this.props.closeRent(this.props.item.id).then(() => {
                                            this.props.getRents()
                                        })
                                    }
                                }
                            },
                            {
                                tooltip: this.props.language.stock.pvpp,
                                onClick: (_, row) => this.props.setRentPVPPModal(row),
                                withoutBorder: true
                            },
                            {
                                tooltip: this.props.language.stock.sendPVPP,
                                onClick: (_, row) => {
                                    if (window.confirm(this.props.language.rentConfirmations.sendRentPVPP)) {
                                        this.props.sendPVPP(this.props.item.id).then(() => {
                                            NOTIFICATION.success(this.props.language.stock.mailSent)
                                        })
                                            .catch(() => NOTIFICATION.error(this.props.language.stock.mailFail))
                                    }
                                }
                            }
                        ]} />
                    </TableCell> : null}
                </TableRow >
            </Tooltip>
        )
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails
})

const mapDispatchToProps = dispatch => ({
    getExtendRentContract: (rentId, from, to) => dispatch(STOCK.getRentExtend(rentId, from, to)),
    sendRentContract: rentId => dispatch(STOCK.sendRentContract(rentId)),
    sendExtendRentContract: rentId => dispatch(STOCK.sendExtendRentContract(rentId)),
    sendCancelRentContract: rentId => dispatch(STOCK.sendCancelRentContract(rentId)),
    closeRent: rentId => dispatch(STOCK.closeRent(rentId)),
    sendPVPP: rentId => dispatch(STOCK.sendPVPP(rentId))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(RentList))