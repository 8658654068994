import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, TableCell, TableRow, Tooltip } from '@material-ui/core'

import ListAvatarAndCarBrand from '../../common/subComponents/ListAvatarAndCarBrand'
import * as CONSTANTS from '../../../utils/constants'
import { ClickWrapper } from '../../../utils/helpers'
import config from '../../../config/local.json'


const styles = theme => ({
    fieldText: {
        fontWeight: 'normal',
        fontSize: 14,
        paddingTop: 4
    },
    title: {
        width: 350
    },
    clickedButton: {
        color: 'green'
    }
})

class CostsStockList extends Component {

    render() {
        const logoPath = `${config.baseUrl}/cars/brands/${this.props.item.carBrandId}/image`
        let fieldsToRenderInPrimaryText = [
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.ORDERNUMBER, name: this.props.language.stock.orderNumber },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.VINNUMBER, name: this.props.language.stock.vinNumber },
            { field: CONSTANTS.LEASING_COST_DETAILS_FIELDS.TOTAL_COSTS, name: this.props.language.lCost.totalCosts, symbol: CONSTANTS.RON_SYMBOL },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.TOTAL, name: this.props.language.fCost.total, symbol: CONSTANTS.RON_SYMBOL },
            { field: CONSTANTS.LEASING_COST_DETAILS_FIELDS.TOTAL, name: this.props.language.lCost.total, symbol: CONSTANTS.RON_SYMBOL },
        ]

        return (
            <Tooltip placement="top" title={this.props.item.comment}>
                <TableRow>
                    <ClickWrapper onClick={() => this.props.onSelect(this.props.item)}>
                        <TableCell>
                            <ListAvatarAndCarBrand
                                carBrandName={this.props.item.carBrand.name}
                                carModelName={this.props.item.carModel.name}
                                logoPath={logoPath}
                            />
                        </TableCell>
                        {fieldsToRenderInPrimaryText.map((fieldObj, index) => {
                            if (fieldObj.field === CONSTANTS.FINANCING_COST_DETAILS_FIELDS.ORDERNUMBER || fieldObj.field === CONSTANTS.FINANCING_COST_DETAILS_FIELDS.VINNUMBER) {
                                return (<TableCell key={index} className={this.props.classes.primaryTextContainer}>
                                    {this.props.item[fieldObj.field] ?
                                        <span className={this.props.classes.fieldText}>{this.props.item[fieldObj.field]}</span> :
                                        <span className={this.props.classes.fieldText}>-</span>
                                    }
                                </TableCell>)
                            }

                            if (fieldObj.field === CONSTANTS.FINANCING_COST_DETAILS_FIELDS.TOTAL) {
                                return (<TableCell key={index}>
                                    {<span className={this.props.classes.fieldText}>{`${new Intl.NumberFormat('ro-RO').format(this.props.item.financingCost ? this.props.item.financingCost[CONSTANTS.FINANCING_COST_DETAILS_FIELDS.TOTAL] : 0)} ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span>}
                                </TableCell>)
                            }

                            if (fieldObj.field === CONSTANTS.LEASING_COST_DETAILS_FIELDS.TOTAL) {
                                return (<TableCell key={index}>
                                    {<span className={this.props.classes.fieldText}>{`${new Intl.NumberFormat('ro-RO').format(this.props.item.cost[CONSTANTS.LEASING_COST_DETAILS_FIELDS.TOTAL])} ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span>}
                                </TableCell>)
                            }

                            return (
                                <TableCell key={index}>
                                    {this.props.item.cost && this.props.item.cost[fieldObj.field] ? <span className={this.props.classes.fieldText}>{`${new Intl.NumberFormat('ro-RO').format(this.props.item.cost[fieldObj.field])} ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span> : <span className={this.props.classes.fieldText}>{`0 ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span>}
                                </TableCell>
                            )
                        })}
                    </ClickWrapper>
                </TableRow>
            </Tooltip>)
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails,
})

const mapDispatchToProps = dispatch => ({

})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CostsStockList))