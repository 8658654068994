import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as RENT from '../../../redux/actions/stocks'
import * as CONSTANTS from '../../../utils/constants'
import SimpleList from '../../common/SimpleList'


class RentsListSold extends Component {
    state = {
        rents: [],
        count: 0,
        rentsRowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        from: 0
    }

    componentDidMount() {
        this.getRents()
    }


    getRents = () => {
        this.props.get(this.props.stockId, { from: this.state.from, limit: this.state.rowsPerPage })
            .then(result => {
                this.setState({ rents: result.rows, count: result.count })
            })
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getRents())
    }

    render() {
        return <SimpleList
            noHeader
            headerFields={this.props.language.simpleTable.headerRent}
            items={this.state.rents}
            disableBorders
            onSelect={() => { }}
            selectedOption={CONSTANTS.RENT_OPTION}
            rowsPerPage={this.state.rentsRowsPerPage}
            count={this.state.count}
            changePageHandler={this.changePageHandler} />
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.stock,
    headerFields: state.languageReducer.i18n.itemDetails.offer.offerTabHeaderFields
})

const mapDispatchToProps = dispatch => ({
    get: (stockId, options) => dispatch(RENT.getRentedByStockId(stockId, options))
})

export default connect(mapStateToProps, mapDispatchToProps)(RentsListSold)