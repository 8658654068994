import React, { Component } from 'react'
import { connect } from 'react-redux'
import { generateOptionalsHtml } from '../../utils/helpers'
import { Typography, withStyles } from '@material-ui/core'
import moment from 'moment'

import * as CONSTANTS from '../../utils/constants'
import * as STOCKS from '../../redux/actions/stocks'
import { formatNumber } from '../../utils/helpers'

import MiniGallery from '../common/MiniGallery'

const styles = theme => ({
    ovalYellow: {
        width: 14,
        height: 14,
        borderRadius: 7,
        backgroundColor: '#f8ac14',
        marginRight: 10
    },
    displayInline: {
        display: 'inline-block'
    },
    ovalBlue: {
        width: 14,
        height: 14,
        borderRadius: 7,
        backgroundColor: '#86afec',
        marginRight: 10
    },
    modifiyContainer: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        top: '38%',
        paddingRight: 8
    },
    modifiyButton: {
        marginLeft: 'auto'
    },
    detailsContainer: {
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'row'
    },
    details: {
        flex: 1,
        padding: '33px 16px',
        '& tr > td:nth-of-type(2n)': {
            paddingLeft: '10px'
        },
        backgroundColor: '#FBFBFA'
    },
    detailText: {
        paddingBottom: 0,
        fontWeight: 500,
        color: '#3e3f42'
    },
    commentText: {
        textAlign: "left"
    },
    commentBox: {
        verticalAlign: "top"
    },
    detailFirstText: {
        color: '#9ea0a5',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        paddingBottom: 0,
    },
    detailTextContainer: {
        paddingLeft: 24
    },
    status: {
        padding: '18px 16px',
        marginLeft: 'auto',
        display: 'block'
    },
    statusText: {
        padding: 0,
        paddingRight: 15,
        paddingTop: 4,
        fontSize: 14,
        color: '#3e3f42',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal'
    },
    stockDetailsText: {
        paddingLeft: 25,
        fontWeight: 500
    },
    noCosts: {
        color: 'red'
    },
    addPhoto: {

    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    uploadLabel: {
        textAlign: 'center'
    },
    displayNone: {
        display: 'none'
    },
    imgWrapper: {
        width: '300px',
        textAlign: 'center',
        borderRight: '1px solid rgba(0,0,0,0.1)',
        margin: '12px 0px',
        padding: 8
    },
    primaryImage: {
        height: 200,
        maxWidth: 250,
        objectFit: 'cover'
    },
    detailsTable: {
        overflow: 'auto',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    optionals: {
        flex: 2,
        padding: '20px',
        '& table': {
            width: '100%',
            '& td': {
                padding: '5px',
                borderBottom: '1px solid #e0e0e0',
                '&:nth-of-type(2n+1)': {
                    color: '#3e3f42',
                    fontWeight: '500',
                    fontSize: '14px',
                    minWidth: '50px'
                },
                '&:nth-of-type(2n)': {
                    color: '#84868c',
                    fontSize: '14px'
                }
            }
        }
    },
    optionalsTitle: {
        fontSize: '17px',
        paddingBottom: '15px',
        color: '#000000',
        width: '100%',
        borderBottom: '1px solid #e0e0e0'
    }
})

class StockTabDetails extends Component {

    state = {
        mappedStock: null,
        selectedImagePath: ''
    }

    componentDidMount() {
        if (this.props.fullStock.images && this.props.fullStock.images.length) {
            this.setState({
                selectedImagePath: this.props.fullStock.images[0].fileName
            })
        }
    }

    // uploadFile = event => {
    //     let files = Array.from(event.target.files)
    //     if (!files.length) return;

    //     const formData = new FormData()
    //     files.forEach((file, i) => {
    //         formData.append('file', file)
    //     })

    //     this.props.uploadFileToStock(this.props.fullStock.id, formData).then(() => {
    //         this.props.reloadCurrentStock()
    //         NOTIFICATION.success(this.props.language.stock.image.success)
    //     }).catch(() => {
    //         NOTIFICATION.error(this.props.language.stock.image.failure)
    //     })
    // }

    orderImages = images => images.sort((a, b) => a.order - b.order)

    render() {
        const { classes } = this.props

        let fieldsToDisplay = [
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.MILEAGE, name: this.props.language.itemDetails.stock.km },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.LIST_PRICE, name: this.props.language.itemDetails.stock.price, symbol: CONSTANTS.EURO_SYMBOL },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.ACCESSORIES_PRICE, name: this.props.language.itemDetails.stock.accessoriesPrice, symbol: CONSTANTS.EURO_SYMBOL },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.ORDERNUMBER, name: this.props.language.itemDetails.stock.orderNumber },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.VINNUMBER, name: this.props.language.itemDetails.stock.vinNumber },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.DELIVERY_DATE, name: this.props.language.itemDetails.stock.deliveryDate },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.REGISTRATIONDATE, name: this.props.language.itemDetails.stock.registrationProductionDate },
        ]

        let carFieldsToDisplay = [
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.INTERIOR_COLOR, name: this.props.language.car.models.form.fields.interiorColor },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.EXTERIOR_COLOR, name: this.props.language.car.models.form.fields.exteriorColor },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.FUEL, name: this.props.language.car.models.form.fields.fuel },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.GEARBOX, name: this.props.language.car.models.form.fields.gearbox },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.TRANSMISSION, name: this.props.language.car.models.form.fields.transmission },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.CYLINDER_CAPACITY, name: this.props.language.car.models.form.fields.cylinderCapacity },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.CP, name: this.props.language.car.models.form.fields.cp },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.MAX_MASS, name: this.props.language.car.models.form.fields.maximumMass },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.URBAN_CONSUMPTION, name: this.props.language.car.models.form.fields.urbanConsumption },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.MIXT_CONSUMPTION, name: this.props.language.car.models.form.fields.mixtConsumption },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.EXTRA_URBAN_CONSUMPTION, name: this.props.language.car.models.form.fields.extraUrbanConsumption },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.EMISSIONS, name: this.props.language.car.models.form.fields.emissions },
        ]
        return (
            <div className={classes.detailsContainer}>
                <div className={classes.imgWrapper}>
                    <MiniGallery
                        displayMainPhoto={true}
                        isForOffer={this.props.isForOffer}
                        onImageClick={imagePath => this.setState({ selectedImagePath: imagePath })}
                        images={this.orderImages(this.props.fullStock.images)}
                        stockId={this.props.fullStock.id}
                        reloadImages={() => this.props.reloadCurrentStock()}
                    />
                    <table className={classes.details}>
                        <tbody>
                        <tr key={this.props.fullStock.year}>
                            <td className={classes.commentBox}><Typography key={this.props.fullStock.year} className={classes.detailFirstText} component="p">{this.props.language.itemDetails.stock.comment}</Typography></td>
                            <td> 
                                <Typography key={this.props.fullStock.year} className={classes.commentText} component="p">
                                    {this.props.fullStock.comment}
                                </Typography>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {/* <div className={classes.addPhotoButton}>
                            <input
                                className={classes.displayNone}
                                onChange={event => this.uploadFile(event)}
                                id="upload-file"
                                multiple
                                type="file"
                            />
                            <label htmlFor="upload-file" className={classes.uploadLabel}>
                                <Button variant="contained" component="span">
                                    {this.props.language.upload}
                                </Button>
                            </label>
                        </div> */}
                </div>
                <div className={classes.detailsTable}>

                    <table className={classes.details}>
                        <tbody>
                            {
                                fieldsToDisplay.map((field, index) => {
                                    if (field.field === CONSTANTS.STOCK_DETAILS_FIELDS.LIST_PRICE && (this.props.fullStock.type === 'external' || this.props.fullStock.type === 'sh')) {
                                        return <tr key={index}>
                                            <td><Typography key={index} className={classes.detailFirstText} component="p">{this.props.language.itemDetails.stock.sellingPrice}</Typography></td>
                                            <td> 
                                                <Typography key={index} className={classes.detailText} component="p">
                                                    {typeof (this.props.fullStock[field.field]) == 'number' ? `${formatNumber(this.props.fullStock['purchasePrice'])} ${field.symbol ? field.symbol : ""}` : this.props.fullStock[field.field]}
                                                </Typography>
                                            </td>
                                        </tr>
                                    }
                                    return (<tr key={index}>
                                        <td><Typography key={index} className={classes.detailFirstText} component="p">{field.name}</Typography></td>
                                        <td> {(field.field === CONSTANTS.STOCK_DETAILS_FIELDS.DELIVERY_DATE || field.field === CONSTANTS.STOCK_DETAILS_FIELDS.REGISTRATIONDATE) ?
                                            <Typography key={index} className={classes.detailText} component="p">{!this.props.fullStock[field.field] ? <span>-</span> : moment(this.props.fullStock[field.field]).format(CONSTANTS.DEFAULT_DATE_FORMAT)}</Typography>
                                            :
                                            <Typography key={index} className={classes.detailText} component="p">
                                                {typeof (this.props.fullStock[field.field]) == 'number' ? `${formatNumber(this.props.fullStock[field.field])} ${field.symbol ? field.symbol : ""}` : this.props.fullStock[field.field]}
                                            </Typography>}
                                        </td>
                                    </tr>)
                                })
                            }
                            <tr>
                                <td><Typography className={classes.detailFirstText} component="p">{this.props.language.itemDetails.stock.manufactureYear}</Typography></td>
                                <td><Typography className={classes.detailText} component="p">{this.props.fullStock.manufactureYear}</Typography></td>
                            </tr>
                            {
                                
                                carFieldsToDisplay.map((field, index) => {
                                    if (field.field === 'fuel') {
                                        return (
                                            <tr key={index}>
                                                <td><Typography key={index} className={classes.detailFirstText} component="p">{field.name}</Typography></td>
                                                <td><Typography key={index} className={classes.detailText} component="p">
                                                    {this.props.language.car.models.form.enums['FUEL'][this.props.fullStock.carModel['fuel']]}
                                                </Typography></td>
                                            </tr>
                                        )
                                    }

                                    if (field.field === 'interiorColor') {
                                        return (
                                            <tr key={index}>
                                                <td><Typography key={index} className={classes.detailFirstText} component="p">{field.name}</Typography></td>
                                                <td><Typography key={index} className={classes.detailText} component="p">
                                                    {this.props.fullStock.interiorColor}
                                                </Typography></td>
                                            </tr>
                                        )
                                    }

                                    if (field.field === 'exteriorColor') {
                                        return (
                                            <tr key={index}>
                                                <td><Typography key={index} className={classes.detailFirstText} component="p">{field.name}</Typography></td>
                                                <td><Typography key={index} className={classes.detailText} component="p">
                                                    {this.props.fullStock.exteriorColor}
                                                </Typography></td>
                                            </tr>
                                        )
                                    }

                                    if (field.field === 'gearbox') {
                                        return (
                                            <tr key={index}>
                                                <td><Typography key={index} className={classes.detailFirstText} component="p">{field.name}</Typography></td>
                                                <td><Typography key={index} className={classes.detailText} component="p">
                                                    {this.props.language.car.models.form.enums['GEARBOX'][this.props.fullStock.carModel['gearbox']]}
                                                </Typography></td>
                                            </tr>
                                        )
                                    }

                                    if (field.field === 'transmission') {
                                        return (
                                            <tr key={index}>
                                                <td><Typography key={index} className={classes.detailFirstText} component="p">{field.name}</Typography></td>
                                                <td><Typography key={index} className={classes.detailText} component="p">
                                                    {this.props.language.car.models.form.enums['TRANSMISSION'][this.props.fullStock.carModel['transmission']]}
                                                </Typography></td>
                                            </tr>
                                        )
                                    }

                                    return (
                                        <tr key={index}>
                                            <td><Typography key={index} className={classes.detailFirstText} component="p">{field.name}</Typography></td>
                                            <td><Typography key={index} className={classes.detailText} component="p">
                                                {this.props.fullStock.carModel[field.field]}
                                            </Typography>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    <div className={classes.optionals}>
                        <Typography className={classes.optionalsTitle} component="p">{this.props.language.itemDetails.stock.optionals}</Typography>
                        <table>
                            <tbody dangerouslySetInnerHTML={{ __html: generateOptionalsHtml(this.props.fullStock.optionalsList) }}>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* <div className={classes.status}>
                        <div className={classes.displayInline}>
                            {
                                this.props.fullStock.offers.length ?
                                    <div className={this.props.classes.ovalBlue}>
                                    </div> :
                                    <div className={this.props.classes.ovalYellow}>
                                    </div>
                            }
                        </div>
                        <div className={classes.displayInline}>
                            {this.props.fullStock.offers.length ? <span className={this.props.classes.statusText}>{this.props.language.with}</span> : <span className={this.props.classes.statusText}>{this.props.language.without}</span>}
                        </div>
                    </div> */}
            </div>
        )


    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    uploadFileToStock: (stockId, files) => dispatch(STOCKS.uploadFileToStock(stockId, files)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(StockTabDetails))