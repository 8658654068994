import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Typography } from '@material-ui/core'
import { ExpandMore as Expand } from '@material-ui/icons'

import ItemDetails from '../../common/ItemDetails'
import SimpleList from '../../common/SimpleList'
import * as CONSTANTS from '../../../utils/constants'
import * as OFFER from '../../../redux/actions/offers'

const styles = theme => ({
    offersList: {
        flex: 2,
        padding: 26
    },
    clientInfo: {
        flex: 1,
        padding: "2px 26px 0px 26px",
        display: 'flex',
        flexDirection: 'row'
    },
    secondColumnClient: {
        paddingLeft: 32
    },
    clientDetail: {
        paddingBottom: 12
    },
    firstClientDetail: {
        fontSize: 14,
        fontWeight: 'normal',
        color: '#9ea0a5',
        paddingTop: 12
    },
    secondClientDetail: {
        fontSize: 14,
        fontWeight: 500,
        color: '#3e3f42',
        paddingTop: 12
    },
    modalContainer: {
        display: 'flex',
        flexDirection: 'column'
    }
})

class ClientOfferList extends Component {

    state = {
        offers: [],
        count: 0,
        from: 0,
        rowsPerPage: CONSTANTS.DEFAULT_MINIMODAL_ROWS_PER_PAGE_VALUE,
        selectedOffer: null,
        openDetailsModal: false,
        openOfferModal: false,
        renderPage: false,
        activeTab: 0
    }

    componentDidMount() {
        this.getOffers()
    }

    getOffers = () => {
        this.props.getOffers({ search: this.props.client.name, from: this.state.from, limit: this.state.rowsPerPage })
            .then(result => {
                this.setState({ offers: result.offers, renderPage: true, detailsModal: false, count: result.count })
            })
    }

    onItemDetailsCancelHandler = () => {
        this.getOffers()
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, () => this.getOffers())
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getOffers())
    }

    render() {

        let fieldsToDisplay = [
            { field: CONSTANTS.OFFER_DETAILS_FIELDS.CUI, label: this.props.itemDetailsOffer.cui },
            { field: CONSTANTS.OFFER_DETAILS_FIELDS.name, label: this.props.itemDetailsOffer.companyName },
            { field: CONSTANTS.OFFER_DETAILS_FIELDS.phoneNumber, label: this.props.itemDetailsOffer.phoneNumber },
            { field: CONSTANTS.OFFER_DETAILS_FIELDS.email, label: this.props.itemDetailsOffer.email }
        ]

        const { classes } = this.props
        if (this.state.renderPage) {
            return <>
                {this.state.selectedOffer && <ItemDetails
                    open={this.state.detailsModal}
                    item={this.state.selectedOffer.stock}
                    offer={this.state.selectedOffer}
                    noHeaders={true}
                    onCancel={this.onItemDetailsCancelHandler} />}
                <div className={classes.modalContainer}>
                    <div className={classes.clientInfo}>
                        <div className={classes.firstColumnClient}>
                            {fieldsToDisplay.map((field, index) => {
                                return (
                                    <div key={index}>
                                        <Typography key={index} className={classes.firstClientDetail} component="p">{field.label}</Typography>
                                    </div>
                                )
                            })}
                        </div>
                        <div className={classes.secondColumnClient}>
                            {fieldsToDisplay.map((field, index) => {
                                return (
                                    <div key={index}>
                                        <Typography key={index} className={classes.secondClientDetail} component="p">{this.props.client[field.field] || '-'}</Typography>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={classes.offersList}>
                        <SimpleList
                            noHeader={true}
                            items={this.state.offers}
                            headerFields={this.props.itemDetailsOffer.offerTabHeaderFields}
                            disableBorders
                            selectedOption={CONSTANTS.OFFER_OPTIONS.SUMAR_OFFER}
                            onSelect={offer => this.setState({ selectedOffer: offer, detailsModal: true })}
                            actions={[
                                {
                                    tooltip: this.props.language.simpleTable.actions.expand,
                                    onClick: (_, row) => this.setState({ selectedOffer: row, detailsModal: true }),
                                    icon: <Expand />
                                }
                            ]}
                            count={this.state.count}
                            rowsPerPage={CONSTANTS.DEFAULT_MINIMODAL_ROWS_PER_PAGE_VALUE}
                            changeRowsPerPage={this.changeRowsPerPageHandler}
                            changePageHandler={this.changePageHandler}
                        />
                    </div>
                </div>
            </>
        }
        else return null
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.offer,
    itemDetailsOffer: reducers.languageReducer.i18n.itemDetails.offer,
})

const mapDispatchToProps = dispatch => ({
    getOffers: queryParams => dispatch(OFFER.get(queryParams))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ClientOfferList))