import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'

import InputGenerator from '../../common/subComponents/InputGenerator'
import SimpleModal from '../../common/SimpleModal'
import * as NOTIFICATION from '../../../utils/notification'
import { isValidField } from '../../../utils/validationUtils'

const style = theme => ({
    title: {
        padding: '24px 0px 20px 19px',
        borderBottom: '1px solid #eaedf3'
    },
    cancelStyle: {
        marginLeft: 3,
        marginRight: 'auto'
    },
    acceptStyle: {
        marginRight: 7
    },
    contentStyle: {
        paddingLeft: 18,
        paddingRight: 12,
        paddingTop: 12
    }
})

class UserFormModal extends Component {

    createCopy = array => [...array].map(obj => ({ ...obj }))

    state = {
        open: false,
        fields: [...this.props.fields.map(field => ({ ...field, error: false }))],
        canSubmit: false,
        type: this.props.type
    }

    saveChanges() {
        let output = {}

        if (!this.validate()) return NOTIFICATION.error(this.props.validationLanguage.uncomplete)

        this.props.fields.forEach((element, index) => {
            output[element.name] = this.state.fields[index].value
        })

        this.props.onSave({ type: this.props.type, fields: output })
    }

    validate = () => {
        let newFields = [...this.state.fields]
        let isValid = true

        newFields.forEach((field, index) => {
            if (!isValidField(field)) {
                isValid = false
                newFields[index].error = true
            } else {
                newFields[index].error = false
            }
        })

        this.setState({ fields: [...newFields] })
        return isValid
    }

    onChange = event => {
        if (event.target.name === 'roles') return this.onChangeRoles(event)
        const updatedFields = this.createCopy(this.state.fields)

        const nameIndex = updatedFields.findIndex(f => f.name === event.target.name)

        if (nameIndex > -1) {
            updatedFields[nameIndex].value = event.target.value
        }

        this.setState({ fields: updatedFields })
    }

    onChangeRoles = event => {
        let fieldsCopy = this.createCopy(this.state.fields)
        let nameIndex = fieldsCopy.findIndex(field => field.name === 'roles')
        if (nameIndex > -1) {
            fieldsCopy[nameIndex].value = event.target.value
            this.setState({ fields: fieldsCopy })
        }
    }

    renderFields() {
        return this.state.fields.map((field, index) => {
            return <InputGenerator
                key={index}
                fullWidth={true}
                margin='dense'
                {...field}
                onChange={event => this.onChange(event)}
            />
        })
    }

    render() {
        return (
            <div>
                <SimpleModal
                    open={this.props.open}
                    aria-labelledby="form-dialog-title"
                    title={this.props.title}
                    onCancel={() => this.props.onCancel()}
                    onAccept={() => this.saveChanges()}
                    acceptButtonText={this.props.submitButtonText}
                    cancelButtonText={this.props.language.confirmationDialog.cancelButtonText}
                    canSubmitForm={this.state.canSubmit}
                    maxWidth={this.props.maxWidth}
                    styles={this.props.classes}
                >
                    {this.renderFields()}
                </SimpleModal>
            </div>
        )
    }
}

const mapStateToProps = reducers => ({
    validationLanguage: reducers.languageReducer.i18n.validation
})

const mapDispatchToProps = dispatch => ({})

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(UserFormModal))