import queryString from 'query-string'

import axios from '../../utils/axios'
import { errorHandler } from '../../utils/apiFunctions'

const baseRoute = '/leasing'

export const get = queryParams => dispatch =>
    axios.get(baseRoute + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getById = id => dispatch =>
    axios.get(`${baseRoute}/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const edit = (id, leasing) => dispatch =>
    axios.put(`${baseRoute}/${id}`, { leasing })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const create = leasing => dispatch =>
    axios.post(baseRoute, { leasing })
        .then(response => response.data)
        .catch(errorHandler)

export const deleteRow = id => dispatch =>
    axios.delete(`${baseRoute}/${id}`)
        .then(response => response.data)
        .catch(errorHandler)

export const uploadDocument = (id, files) => dispatch =>
    axios.put(`${baseRoute}/${id}/document`, files)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getDocument = id => dispatch => 
    axios.get(`${baseRoute}/${id}/document`)
        .then(response => response.data)
        .catch(errorHandler)