import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogActions, Button, DialogContentText, DialogTitle, withStyles, Checkbox, Tooltip, FormControlLabel } from '@material-ui/core'
import { Close } from '@material-ui/icons'

import MultiSelector from './MultipleSelector'
import * as OFFERS from '../../redux/actions/offers'

const styles = theme => ({
    dialogTitle: {
        borderBottom: '1px solid #eaedf3',
        marginBottom: 15
    },
    topCancelButton: {
        float: 'right',
        margin: '5px 0px 0px 0px',
        cursor: 'pointer'
    },
    dialogContent: {
        padding: '2px 20px'
    },
    dialogActions: {
        borderTop: '1px solid #eaedf3',
        marginTop: 10,
        paddingTop: 25
    },
    cancelButton: {
        marginRight: 'auto',
        marginLeft: 18
    },
    checkboxesContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
})

class CheckListModal extends Component {
    state = {
        steps: [
            {
                name: 'personalBelonging',
                checked: false,
                label: this.props.language.personalBelonging.label,
                tooltip: this.props.language.personalBelonging.tooltip
            },
            {
                name: 'accessories',
                checked: false,
                label: this.props.language.accessories.label,
                tooltip: this.props.language.accessories.tooltip
            },
            {
                name: 'wash',
                checked: false,
                label: this.props.language.wash.label,
                tooltip: this.props.language.wash.tooltip
            },
            {
                name: 'kit',
                checked: false,
                label: this.props.language.kit.label,
                tooltip: this.props.language.kit.tooltip
            },
            {
                name: 'vigniette',
                checked: false,
                label: this.props.language.vigniette.label,
                tooltip: this.props.language.vigniette.tooltip
            },
            {
                name: 'keys',
                checked: false,
                label: this.props.language.keys.label,
                tooltip: this.props.language.keys.tooltip
            },
            {
                name: 'instructions',
                checked: false,
                label: this.props.language.instructions.label,
                tooltip: this.props.language.instructions.tooltip
            },

            {
                name: 'bordComputer',
                checked: false,
                label: this.props.language.bordComputer.label,
                tooltip: this.props.language.bordComputer.tooltip
            },
            {
                name: 'washVigniette',
                checked: false,
                label: this.props.language.washVigniette.label,
                tooltip: this.props.language.washVigniette.tooltip
            },
            {
                name: 'verifiedAccessoriesAfterWash',
                checked: false,
                label: this.props.language.verifieAccessoriesAfterWash.label,
                tooltip: this.props.language.verifieAccessoriesAfterWash.tooltip
            },
            {
                name: 'photos',
                checked: false,
                label: this.props.language.photos.label,
                tooltip: this.props.language.photos.tooltip
            },
            {
                name: 'pvpp',
                checked: false,
                label: this.props.language.pvpp.label,
                tooltip: this.props.language.pvpp.tooltip
            },
            {
                name: 'originalDocuments',
                checked: false,
                label: this.props.language.originalDocuments.label,
                tooltip: this.props.language.originalDocuments.tooltip
            }]
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open && this.props.open == true) {
            this.props.getInWork(this.props.inWorkId)
                .then(work => {
                    let newSteps = [...this.state.steps]

                    newSteps.forEach(step => step.checked = work[step.name + 'Verification'])

                    this.setState({ steps: newSteps })
                })
        }
    }

    onCheckboxChangeHandler = event => {
        let checkboxIndex = this.state.steps.findIndex(step => step.name === event.target.value)

        if (checkboxIndex < 0) return alert('Eroare')

        let newCheckboxes = [...this.state.steps]
        newCheckboxes[checkboxIndex].checked = !this.state.steps[checkboxIndex].checked

        let modifiedFieldName = `${newCheckboxes[checkboxIndex].name}Verification`

        // Aici trimite la backend
        this.props.check(this.props.inWorkId, {
            [modifiedFieldName]: newCheckboxes[checkboxIndex].checked
        }).then(() => this.setState({ steps: newCheckboxes }))
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                fullWidth={true}
                onClose={() => this.props.onClose()}
                maxWidth={'xs'}
            >
                <DialogTitle className={this.props.classes.dialogTitle}>
                    {this.props.titleText ? this.props.titleText : 'Stergere'}
                    <Close onClick={this.props.onCancel} className={this.props.classes.topCancelButton} />
                </DialogTitle>
                <DialogContent className={this.props.classes.dialogContent}>
                    <DialogContentText>
                        <div className={this.props.classes.checkboxesContainer}>
                            {this.state.steps.map((step, index) =>
                                <Tooltip key={index} title={step.tooltip}>
                                    <FormControlLabel
                                        key={index}
                                        label={step.label}
                                        control={<Checkbox
                                            name={step.name}
                                            label={step.label}
                                            checked={step.checked}
                                            onChange={this.onCheckboxChangeHandler}
                                            value={step.name} />}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={this.props.classes.dialogActions}>
                    <Button className={this.props.classes.cancelButton} onClick={() => this.props.onCancel()} color="secondary">
                        {this.props.cancelButtonText}
                    </Button>
                    <Button onClick={() => this.props.onAccept()} color="primary">
                        {this.props.acceptButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

CheckListModal.propTypes = {
    open: PropTypes.bool.isRequired,
    cancelButtonText: PropTypes.string.isRequired,
    acceptButtonText: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.checkboxList,
})

const mapDispatchToProps = dispatch => ({
    check: (workId, verifications) => dispatch(OFFERS.checkVerification(workId, verifications)),
    getInWork: workId => dispatch(OFFERS.getInWorkById(workId))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CheckListModal))