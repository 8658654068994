import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    formGroup: {
        display: 'inline'
    },
    radioGroup: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        paddingTop: 12
    },
    formLabelContainer: {
        marginLeft: -8,
        fontSize: 12,
        fontWeight: 500,
        color: '#9ea0a5',
        textTransform: 'uppercase',
        letterSpacing: 'normal'
    }
});

class RadioSelector extends React.Component {

    handleChange = event => {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: event.target.value
            }
        })
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={this.props.classes.formLabelContainer} component="legend">{this.props.utils}</FormLabel>
                    <RadioGroup className={classes.radioGroup} aria-label="gender" name="gender1" value={this.props.value} disabled={this.props.disabled} onChange={event => this.handleChange(event)}>
                        {this.props.options.map((op, index) => {
                            return (
                                <FormControlLabel disabled={this.props.disabled} key={index} value={op.label} control={<Radio />} label={op.label} />
                            )
                        })}
                    </RadioGroup>
                </FormControl>
            </div>
        );
    }
}


const mapStateToProps = reducers => ({
    languageReducer: reducers.languageReducer.i18n.selector
})

export default withStyles(styles)(connect(mapStateToProps)(RadioSelector))