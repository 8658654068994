import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, TableRow, TableCell, Tooltip, CircularProgress } from '@material-ui/core'
import moment from 'moment'

import ListAvatarAndCarBrand from '../common/subComponents/ListAvatarAndCarBrand'
import DropdownMenu from '../common/DropdownMenu'
import ConfirmationDialog from '../common/ConfirmationDialog'
import ChecklistModal from '../common/CheckListModal'
import InWorkDetails from './InWorkDetails'
import LeasingModal from '../offer/offerTab/LeasingModal'

import * as CONSTANTS from '../../utils/constants'
import * as SALES from '../../redux/actions/sales'
import * as OFFERS from '../../redux/actions/offers'
import * as IN_WORKS from '../../redux/actions/inWorks'
import * as NOTIFICATIONS from '../../utils/notification'

import { formatNumber, ClickWrapper } from '../../utils/helpers'
import config from '../../config/local.json'

const styles = theme => ({
    fieldText: {
        fontWeight: 'normal',
        fontSize: 14,
        paddingTop: 4
    },
    title: {
        width: 350
    },
    clickedButton: {
        color: 'green'
    },
    listWrapper: {
        '& span:not(:first-child)': {
            display: 'inline-block',
            marginLeft: '5px'
        }
    },
    hrefStyle: {
        color: 'black',
        paddingRight: 8
    },
    iconStyle: {
        fontSize: 24
    },
    spinnerStyle: {
        color: '#3e3f42',
        height: '25px !important',
        width: '25px !important'
    },
    colorWhite: {
        color: 'white'
    }
})

class InWorkList extends Component {

    state = {
        displaySpinner: false,
        openConfirmationModal: false,
        selectedRow: null,
        confirmationType: '',
        showChecklistModal: false,
        inWorkId: null,
        openDetailsModal: false,
        inWorkForDetails: null,
        openLeasingSelector: false,
        downloadLeasing: false
    }

    sendToSale = () => {
        this.props.sendToSale({ offerId: this.state.selectedRow.offer.id }).then(() => {
            this.setState({ openConfirmationModal: false })
            this.props.getInWork()
            NOTIFICATIONS.success(this.props.language.successSold)
        })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.message && err.response.data.message === 'selling_exchange_rate')
                    NOTIFICATIONS.error(this.props.language.failSoldExchangeRate)
                else
                    NOTIFICATIONS.error(this.props.language.failSoldCoseFinancing)
            })
    }

    sendAnalysis = leasingId => {
        if (this.state.downloadLeasing) {
            window.open(`${CONSTANTS.BACKEND_URL}offers/${this.props.item.offer.id}/invoice/proform/leasing/${leasingId}/download`, "_blank")
            window.open(`${CONSTANTS.BACKEND_URL}offers/${this.props.item.offer.id}/analyzeLeasing/${leasingId}/download`, "_blank")
            this.setState({ openConfirmationModal: false })
            return;
        }

        this.setState({ displaySpinner: true }, () => {
            this.props.sendAnalysis(this.props.item.offer.id, leasingId).then(() => {
                this.setState({ openConfirmationModal: false, displaySpinner: false, openLeasingSelector: false })
                NOTIFICATIONS.success(this.props.language.offer.analysisSent)
            })
                .catch(err => {
                    this.setState({ displaySpinner: false })
                    NOTIFICATIONS.error(this.props.language.offer.analysisFail)
                })
        })
    }

    render() {
        const logoPath = `${config.baseUrl}/cars/brands/${this.props.item.offer.stock.carBrand.id}/image`
        let fieldsToRenderInPrimaryText = [
            { field: CONSTANTS.IN_WORK_FIELDS.PROVIDER, name: this.props.language.offer.provider },
            { field: CONSTANTS.IN_WORK_FIELDS.NAME, name: this.props.language.offer.clientName, populate: 'client' },
            { field: CONSTANTS.IN_WORK_FIELDS.USER, name: this.props.language.offer.username, populate: 'user' },
            { field: CONSTANTS.IN_WORK_FIELDS.ORDERNUMBER, name: this.props.language.offer.orderNumber, populate: 'stock' },
            { field: CONSTANTS.IN_WORK_FIELDS.VINNUMBER, name: this.props.language.offer.vinNumber, populate: 'stock' },
            { field: CONSTANTS.IN_WORK_FIELDS.REGISTRATIONDATE, name: this.props.language.offer.registrationDate, populate: 'stock' },
            { field: CONSTANTS.IN_WORK_FIELDS.LIST_PRICE, name: this.props.language.offer.listPrice, populate: 'stock', symbol: CONSTANTS.EURO_SYMBOL },
            { field: CONSTANTS.IN_WORK_FIELDS.DISCOUNTPERCENT, name: this.props.language.offer.discountPercent, symbol: '%' },
            { field: CONSTANTS.IN_WORK_FIELDS.DISCOUNTEUR, name: this.props.language.offer.discountEUR, symbol: CONSTANTS.EURO_SYMBOL },
            { field: CONSTANTS.IN_WORK_FIELDS.SELLING_PRICE_EU, name: this.props.language.offer.sellingPriceEUR, symbol: CONSTANTS.EURO_SYMBOL },
            { field: CONSTANTS.IN_WORK_FIELDS.CAR_PROFIT_EUR, name: this.props.language.offer.carProfitEUR, symbol: CONSTANTS.EURO_SYMBOL }
        ]
        return (
            <>
                <LeasingModal
                    displaySpinner={this.state.displaySpinner}
                    leasingId={this.state.selectedRow ? this.state.selectedRow.offer.leasingId : null}
                    sendAnalysis={leasingId => this.sendAnalysis(leasingId)}
                    onClose={() => this.setState({ openLeasingSelector: false })}
                    open={this.state.openLeasingSelector} />
                <InWorkDetails
                    open={this.state.openDetailsModal}
                    inWork={this.state.inWorkForDetails}
                    onClose={() => this.setState({ openDetailsModal: false })}
                />
                <ConfirmationDialog
                    open={this.state.openConfirmationModal}
                    titleText={this.state.confirmationType === CONSTANTS.SELL_OPTION ? "Vinde" : "Analiza leasing"}
                    cancelButtonText={this.props.confirmationDialogLanguage.cancelButtonText}
                    acceptButtonText={this.state.confirmationType === CONSTANTS.SELL_OPTION ? this.props.confirmationDialogLanguage.acceptButtonText : this.state.displaySpinner ?
                        <><span className={this.props.classes.colorWhite}>{this.props.offerLanguage.sendingAnalysis}</span>&nbsp;<CircularProgress className={`${this.props.classes.spinnerStyle} ${this.props.classes.colorWhite}`} /></> :
                        this.props.confirmationDialogLanguage.sendButtonText}
                    text={this.state.confirmationType === CONSTANTS.SELL_OPTION ? `${this.props.confirmationDialogLanguage.text}` : this.props.confirmationDialogLanguage.textSend}
                    onCancel={() => this.setState({ openConfirmationModal: false })}
                    onClose={() => this.setState({ openConfirmationModal: false })}
                    onAccept={() => this.state.confirmationType === CONSTANTS.SELL_OPTION ? this.sendToSale() : this.sendAnalysis()}
                />
                <ChecklistModal
                    open={this.state.showChecklistModal}
                    cancelButtonText={this.props.confirmationDialogLanguage.cancelButtonText}
                    acceptButtonText={'Finalizare'}
                    inWorkId={this.state.inWorkId}
                    titleText={this.props.language.stock.verifications}
                    onCancel={() => this.setState({ showChecklistModal: false })}
                    onClose={() => this.setState({ showChecklistModal: false })}
                    onAccept={() => this.setState({ showChecklistModal: false })}
                />
                <Tooltip placement="top" title={this.props.item.offer.stock.comment}>
                    <TableRow>
                        <ClickWrapper onClick={() => { this.props.onSelect(this.props.item) }}>
                            <TableCell>
                                <ListAvatarAndCarBrand
                                    carBrandName={this.props.item.offer.stock.carBrand.name}
                                    carModelName={this.props.item.offer.stock.carModel.name}
                                    logoPath={logoPath}
                                />
                            </TableCell>
                            {fieldsToRenderInPrimaryText.map((fieldObj, index) => {
                                if (fieldObj.populate) {
                                    if (fieldObj.field === CONSTANTS.IN_WORK_FIELDS.VINNUMBER) {
                                        return (
                                            <TableCell key={index} >
                                                <Tooltip placement="top" title={this.props.item.offer[fieldObj.populate][fieldObj.field]}>{this.props.item.offer[fieldObj.populate][fieldObj.field] ? <span className={this.props.classes.fieldText}>{`${this.props.item.offer[fieldObj.populate][fieldObj.field].substr(-1 * CONSTANTS.VIN_SUBSTR_LENGTH)}`}</span> : '-'}</Tooltip>
                                            </TableCell>
                                        )
                                    }
                                    if (fieldObj.field === CONSTANTS.IN_WORK_FIELDS.USER) {
                                        return (
                                            <TableCell key={index} >
                                                <Tooltip placement="top" title={this.props.item[fieldObj.populate][fieldObj.field]}>{this.props.item[fieldObj.populate][fieldObj.field] ? <span className={this.props.classes.fieldText}>{`${this.props.item[fieldObj.populate][fieldObj.field]}`}</span> : '-'}</Tooltip>
                                            </TableCell>
                                        )
                                    }
                                    if (fieldObj.field === CONSTANTS.IN_WORK_FIELDS.REGISTRATIONDATE) {
                                        return (
                                            <TableCell key={index} >
                                                {this.props.item.offer[fieldObj.populate][fieldObj.field] ? <span className={this.props.classes.fieldText}>{`${moment(this.props.item.offer[fieldObj.populate][fieldObj.field]).format(CONSTANTS.DEFAULT_DATE_FORMAT)}`}</span> : '-'}
                                            </TableCell>
                                        )
                                    }
                                    if (typeof this.props.item.offer[fieldObj.populate][fieldObj.field] === 'number') {
                                        return (<TableCell key={index} >{
                                            this.props.item.offer[fieldObj.populate][fieldObj.field] ?
                                                <span className={this.props.classes.fieldText}>
                                                    {`${formatNumber(this.props.item.offer[fieldObj.populate][fieldObj.field])} ${fieldObj.symbol ? fieldObj.symbol : ""}`} </span>
                                                : <span className={this.props.classes.fieldText}>{`0 ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span>
                                        }</TableCell>)
                                    }
                                    return (
                                        <TableCell key={index} >
                                            {this.props.item.offer[fieldObj.populate][fieldObj.field] ? <span className={this.props.classes.fieldText}>{`${this.props.item.offer[fieldObj.populate][fieldObj.field]}`}</span> : '-'}
                                        </TableCell>
                                    )
                                }
                                if (fieldObj.field === CONSTANTS.RENT_FIELDS.PROVIDER) {
                                    return (
                                        <TableCell key={index} >
                                            {this.props.item.offer.stock[fieldObj.field] ? <span className={this.props.classes.fieldText}>{`${this.props.item.offer.stock[fieldObj.field].name}`}</span> : '-'}
                                        </TableCell>
                                    )
                                }
                                return (
                                    <TableCell key={index} >
                                        {this.props.item.offer[fieldObj.field] ?
                                            <span className={this.props.classes.fieldText}>
                                                {`${formatNumber(this.props.item.offer[fieldObj.field])} ${fieldObj.symbol ? fieldObj.symbol : ""}`} </span>
                                            : <span className={this.props.classes.fieldText}>{`0 ${fieldObj.symbol ? fieldObj.symbol : ""}`}</span>}
                                    </TableCell>
                                )
                            })}
                        </ClickWrapper>
                        <TableCell>
                            <span className={this.props.classes.fieldText}>{moment().diff(moment(this.props.item.createdAt), 'days') + 1}</span>
                        </TableCell>
                        <TableCell>
                            <DropdownMenu type={this.props.language.stock.actions} item={this.props.item} items={[
                                {
                                    tooltip: this.props.language.stock.details,
                                    onClick: (_, row) => {
                                        this.setState({ openDetailsModal: true, inWorkForDetails: row })
                                    }
                                },
                                {
                                    tooltip: this.props.language.stock.getSaleContract,
                                    onClick: (_, row) => this.props.generateSaleContract(row),
                                    withoutBorder: true
                                },
                                {
                                    tooltip: this.props.language.stock.sendSaleContract,
                                    onClick: (_, row) => {
                                        if (window.confirm(this.props.language.inWorkConfirmations.sendContract)) {
                                            this.props.sendSaleContractViaMail(this.props.item.id)
                                                .then(() => {
                                                    NOTIFICATIONS.success(this.props.language.stock.mailSent)
                                                })
                                                .catch(() => NOTIFICATIONS.error(this.props.language.stock.mailFail))
                                        }
                                    }

                                },
                                // {
                                //     tooltip: this.props.language.stock.getInvoice,
                                //     onClick: (_, row) => window.open(`${CONSTANTS.BACKEND_URL}inWorks/${this.props.item.id}/invoice`, "_blank"),
                                //     withoutBorder: true
                                // },
                                // {
                                //     tooltip: this.props.language.stock.sendInvoice,
                                //     onClick: (_, row) => this.props.sendInvoiceViaMail(this.props.item.id)
                                //         .then(() => {
                                //             NOTIFICATIONS.success(this.props.language.stock.mailSent)
                                //         })
                                //         .catch(() => NOTIFICATIONS.error(this.props.language.stock.mailFail))

                                // },
                                {
                                    tooltip: this.props.language.stock.leasingAnalysis,
                                    onClick: (_, row) => { this.setState({ selectedRow: row, openLeasingSelector: true, downloadLeasing: false }) },
                                },
                                {
                                    tooltip: 'Descarca documente analiza leasing',
                                    onClick: (_, row) => { this.setState({ selectedRow: row, openLeasingSelector: true, downloadLeasing: true }) },
                                },

                                // {
                                //     tooltip: this.props.language.stock.verifications,
                                //     onClick: (_, row) => this.setState({ showChecklistModal: true, inWorkId: this.props.item.id }),

                                // },
                                {
                                    tooltip: this.props.language.stock.pvpp,
                                    onClick: (_, row) => this.props.generatePVPPContract(row),
                                    withoutBorder: true
                                },
                                // {
                                //     tooltip: this.props.language.stock.sendPVPP,
                                //     onClick: (_, row) => {
                                //         if (window.confirm(this.props.language.inWorkConfirmations.sendPVPP)) {
                                //             this.props.sendPVPP(this.props.item.id).then(() => {
                                //                 NOTIFICATIONS.success(this.props.language.stock.mailSent)
                                //             })
                                //                 .catch(() => NOTIFICATIONS.error(this.props.language.stock.mailFail))
                                //         }
                                //     },
                                // },
                                {
                                    tooltip: this.props.language.stock.delete,
                                    onClick: (_, row) => {
                                        if (window.confirm(this.props.confirmationDialogLanguage.cancelInWork)) {
                                            this.props.deleteInWork(row.id).then(() => {
                                                NOTIFICATIONS.success(this.props.language.stock.message.deleteSuccess)
                                                this.props.getInWork()
                                            })
                                                .catch(() => NOTIFICATIONS.error(this.props.language.stock.message.deleteFail))
                                        }
                                    },

                                },
                                {
                                    tooltip: <strong>{this.props.language.stock.sell}</strong>,
                                    onClick: (_, row) => { this.setState({ selectedRow: row, openConfirmationModal: true, confirmationType: CONSTANTS.SELL_OPTION }) },
                                },
                            ]} />
                        </TableCell>
                        {/* <TableCell>
                        <Tooltip placement={"top"} title={this.props.language.stock.getProformaInvoice}>
                            <a className={this.props.classes.hrefStyle} href={`${CONSTANTS.BACKEND_URL}offers/${this.props.item.id}/inWork/invoice`}><ProformaInvoice className={this.props.classes.iconStyle} /></a>
                        </Tooltip>
                        <Tooltip placement={"top"} title={this.props.language.stock.getSaleContract}>
                            <a className={this.props.classes.hrefStyle} href={`${CONSTANTS.BACKEND_URL}offers/${this.props.item.id}/inWork/saleContract`}><SaleContract className={this.props.classes.iconStyle} /></a>
                        </Tooltip>
                    </TableCell> */}
                    </TableRow >
                </Tooltip>
            </>
        )
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails,
    confirmationDialogLanguage: state.languageReducer.i18n.confirmationDialog,
    offerLanguage: state.languageReducer.i18n.offer
})

const mapDispatchToProps = dispatch => ({
    sendInvoiceViaMail: workId => dispatch(IN_WORKS.sendInvoiceViaMail(workId)),
    sendSaleContractViaMail: workId => dispatch(IN_WORKS.sendSaleViaMail(workId)),
    sendToSale: sale => dispatch(SALES.create(sale)),
    sendAnalysis: (offerId, leasingId) => dispatch(OFFERS.sendAnalysis(offerId, leasingId)),
    deleteInWork: inWorkId => dispatch(OFFERS.deleteInWork(inWorkId)),
    sendPVPP: inWorkId => dispatch(OFFERS.sendPVPP(inWorkId))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InWorkList))