import React, { Component } from 'react'
import { List, ListItem, ListItemText, TextField, Tooltip, withStyles } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

const styles = {
    deleteIcon: {
        transition: '0.4s ease',
        color: 'rgba(0, 0, 0, 0.42)',
        cursor: 'pointer',
        "&:hover": {
            color: 'rgba(0, 0, 0, 0.87)'
        }
    }
}

class AddList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            options: this.props.options || [],
            paramsField: ''
        }
    }

    removeItem(index) {
        let optionsCopy = [...this.state.options]
        optionsCopy.splice(index, 1)
        this.setState({ options: optionsCopy })
    }

    addItem() {
        let optionsCopy = [...this.state.options]
        optionsCopy.push(this.state.paramsField)
        this.setState({ paramsField: '', options: optionsCopy })
        this.props.onChange(this.state.options)
    }

    renderItems() {
        return this.state.options.map((element, index) =>
            <ListItem key={index} button>
                <ListItemText primary={element} />
                <Tooltip title="Delete" placement="left">
                    <DeleteIcon className={this.props.classes.deleteIcon} color='inherit' onClick={() => this.removeItem(index)} />
                </Tooltip>
            </ListItem>
        )
    }

    render() {
        return (
            <List component="nav" dense={true} disablePadding={true}>
                <div>
                    <Tooltip title="Press ENTER to add" placement="bottom">
                        <TextField
                            id="standard-name"
                            label="Add Parameters"
                            fullWidth={true}
                            value={this.state.paramsField}
                            onChange={event => this.setState({ paramsField: event.target.value })}
                            onKeyPress={event => event.key === 'Enter' ? this.addItem() : null}
                            margin="dense"
                        />
                    </Tooltip>
                </div>
                {this.renderItems()}
            </List>
        )
    }
}

export default withStyles(styles)(AddList)
