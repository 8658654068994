import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { withStyles } from '@material-ui/core'

import SimpleModal from '../../common/SimpleModal'
import FileList from './FilesList'

import * as OFFERS from '../../../redux/actions/offers'
import * as CONSTANTS from '../../../utils/constants'
import * as NOTIFICATIONS from '../../../utils/notification'

const styles = theme => ({
    
})

class FilesModal extends Component {

    state = {
        openModal: false,
        renderPage: false
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.open && nextProps.open) {
            this.setState({ openModal: true, renderPage: true })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open && !this.props.open) {
            this.setState({
                openModal: this.props.open
            })
        }
    }


    onCloseHandler = () => {
        this.props.onClose()
        this.setState({ openModal: false })
    }

    render() {
        const { language } = this.props

        return this.state.renderPage ? <SimpleModal
            styles={this.props.classes}
            open={this.state.openModal}
            onClose={() => this.onCloseHandler()}
            onCancel={() => this.onCloseHandler()}
            onAccept={() => { }}
            withCancelButton={false}
            cancelButtonText={language.offer.createModal.cancel}
            canSubmitForm={true}
            title={language.offer.createModal.filesTitle}
            maxWidth={'md'}
        >
            <FileList getFiles={() => this.props.getFiles(this.props.offerId)} offerId={this.props.offerId} />
        </SimpleModal> : null
    }
}
const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FilesModal))