import * as TYPES from '../actionTypes'

const initialState = {
    displaySidebar: true
}

function sidebarReducer(state = initialState, action) {
    switch (action.type) {
        case TYPES.SET_DISPLAY_SIDEBAR:
            return { ...state, displaySidebar: action.payload }
        default:
            return state
    }
}

export default sidebarReducer