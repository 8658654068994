import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'

import { Tooltip } from '@material-ui/core'
import { TurnedIn as TurnedIcon } from '@material-ui/icons'
import { validations } from '../../../utils/validationUtils'
import SimpleMenu from '../../common/SimpleMenu'
import ConfirmationDialog from '../../common/ConfirmationDialog'
import * as CONSTANTS from '../../../utils/constants'
import * as FINANCING from '../../../redux/actions/financingCosts'
import * as NOTIFICATION from '../../../utils/notification'

import SimpleForm from '../../common/SimpleFormGenerator'

const todayValue = moment().format(CONSTANTS.INPUT_TYPE_DATE_FORMAT)

class FinancingModal extends Component {

    initialFields = [
        { value: '', type: 'text', label: this.props.language.itemDetails.fCost.contractNumber, name: 'contractNumber', validation: { checks: [validations.notEmpty] } },
        { value: todayValue, type: 'date', InputLabelProps: { shrink: true }, label: this.props.language.itemDetails.fCost.contractDate, name: 'contractDate', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.initial, name: 'initialContribution', validation: { checks: [validations.notEmpty] }, breakline: true },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.a1, name: 'analysisTax1', validation: { checks: [validations.notEmpty] }, breakline: true },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.interestRateB1, name: 'interestRateB1', validation: { checks: [validations.notEmpty] }, breakline: true },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.interestRateB2, name: 'interestRateB2', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.interestRateB3, name: 'interestRateB3', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.interestRateB4, name: 'interestRateB4', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.interestRateB5, name: 'interestRateB5', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.a2, name: 'analysisTax2', validation: { checks: [validations.notEmpty] }, breakline: true },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.interestRateA1, name: 'interestRateA1', validation: { checks: [validations.notEmpty] }, breakline: true },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.contribution1, name: 'contribution1', validation: { checks: [validations.notEmpty] } },
        { value: todayValue, type: 'date', label: this.props.language.itemDetails.fCost.date1, name: 'contribution1Date', validation: { checks: [validations.notEmpty] } },

        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.interestRateA2, name: 'interestRateA2', validation: { checks: [validations.notEmpty] }, breakline: true },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.contribution2, name: 'contribution2', validation: { checks: [validations.notEmpty] } },
        { value: todayValue, type: 'date', label: this.props.language.itemDetails.fCost.date2, name: 'contribution2Date', validation: { checks: [validations.notEmpty] } },

        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.interestRateA3, name: 'interestRateA3', validation: { checks: [validations.notEmpty] }, breakline: true },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.contribution3, name: 'contribution3', validation: { checks: [validations.notEmpty] } },
        { value: todayValue, type: 'date', label: this.props.language.itemDetails.fCost.date3, name: 'contribution3Date', validation: { checks: [validations.notEmpty] } },

        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.interestRateA4, name: 'interestRateA4', validation: { checks: [validations.notEmpty] }, breakline: true },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.contribution4, name: 'contribution4', validation: { checks: [validations.notEmpty] } },
        { value: todayValue, type: 'date', label: this.props.language.itemDetails.fCost.date4, name: 'contribution4Date', validation: { checks: [validations.notEmpty] } },

        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.interestRateA5, name: 'interestRateA5', validation: { checks: [validations.notEmpty] }, breakline: true },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.fCost.contribution5, name: 'contribution5', validation: { checks: [validations.notEmpty] } },
        { value: todayValue, type: 'date', label: this.props.language.itemDetails.fCost.date5, name: 'contribution5Date', validation: { checks: [validations.notEmpty] } },
        { value: '', type: 'numberFormat', disabled: true, label: this.props.language.itemDetails.fCost.financedValue, name: 'financedValue', endAdornment: <Tooltip title='Formula'><TurnedIcon /></Tooltip>, breakline: true },
        { value: '', type: 'numberFormat', disabled: true, label: this.props.language.itemDetails.fCost.remainedValue, name: 'remainedValue', endAdornment: <Tooltip title='Formula'><TurnedIcon /></Tooltip> },
        { value: '', type: 'numberFormat', disabled: true, label: this.props.language.itemDetails.fCost.interest, name: 'interestRestitution', endAdornment: <Tooltip title='Formula'><TurnedIcon /></Tooltip> },
        { value: '', type: 'numberFormat', disabled: true, label: this.props.language.itemDetails.fCost.total, name: 'total', endAdornment: <Tooltip title='Formula'><TurnedIcon /></Tooltip> }
    ]

    state = {
        modalFields: this.initialFields,
        editModal: false,
        closeFinancingState: null,
        openFinancingConfirmation: false
    }

    componentDidUpdate(prevProps) {
        if (prevProps.type === CONSTANTS.EDIT && (this.props.open && !prevProps.open)) {
            this.toggleEditModal()
        }
        if (prevProps.type === CONSTANTS.CREATE && (this.props.open && !prevProps.open)) {
            this.setState({ modalFields: this.initialFields, editModal: false })
        }
    }

    componentDidMount() {
        if (this.props.type === CONSTANTS.EDIT) {
            this.toggleEditModal()
        }
        if (this.props.type === CONSTANTS.CREATE) {
            this.setState({ modalFields: this.initialFields })
        }
    }

    onChangeHandler = event => {
        let currentIndex = this.state.modalFields.findIndex(field => field.name === event.target.name)
        if (currentIndex > -1) {
            let modalFieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))

            modalFieldsCopy[currentIndex].value = event.target.value
            modalFieldsCopy[currentIndex].touched = true

            let totalPriceFields = ['interestRateA1', 'interestRateA2', 'interestRateA3', 'interestRateA4', 'interestRateA5',
                'interestRateB1', 'interestRateB2', 'interestRateB3', 'interestRateB4', 'interestRateB5', 'analysisTax2', 'analysisTax1']
            let totalPriceValues = totalPriceFields.map(field => {
                let fieldIndex = modalFieldsCopy.findIndex(idx => idx.name === field)
                return Number(modalFieldsCopy[fieldIndex].value)
            })

            let interestRestitutionFields = ['interestRateA1', 'interestRateA2', 'interestRateA3', 'interestRateA4', 'interestRateA5',
                'interestRateB1', 'interestRateB2', 'interestRateB3', 'interestRateB4', 'interestRateB5']
            let interestRestitutionValues = interestRestitutionFields.map(field => {
                let fieldIndex = modalFieldsCopy.findIndex(idx => idx.name === field)
                return Number(modalFieldsCopy[fieldIndex].value)
            })

            //formulas
            let totalPriceIndex = modalFieldsCopy.findIndex(idx => idx.name === 'total')
            let interestRestitutionIndex = modalFieldsCopy.findIndex(idx => idx.name === 'interestRestitution')
            let remainedValueIndex = modalFieldsCopy.findIndex(idx => idx.name === 'remainedValue')
            let financedValueIndex = modalFieldsCopy.findIndex(idx => idx.name === 'financedValue')

            if (totalPriceIndex > -1 && interestRestitutionIndex > -1 && remainedValueIndex > -1 && financedValueIndex > -1) {

                let initialContributionIndex = modalFieldsCopy.findIndex(idx => idx.name === 'initialContribution')

                if (!this.state.hasFinancing && this.props.type === CONSTANTS.EDIT) {
                    modalFieldsCopy[financedValueIndex].value = 0
                    modalFieldsCopy[remainedValueIndex].value = 0
                }
                else {
                    if (initialContributionIndex > -1) {
                        if (this.props.stock.type === 'sh' && this.props.stock.ShPurchasePrice)
                            modalFieldsCopy[financedValueIndex].value = Number(Number((this.props.stock.ShPurchasePrice * this.props.stock.purchaseExchangeRate) * 1.19 - Number(modalFieldsCopy[initialContributionIndex].value)))
                        else modalFieldsCopy[financedValueIndex].value = Number(Number((this.props.stock.purchasePrice * this.props.stock.purchaseExchangeRate) * 1.19 - Number(modalFieldsCopy[initialContributionIndex].value)))
                    }

                    let remainedValueFields = ['contribution1', 'contribution2', 'contribution3', 'contribution4', 'contribution5']
                    let remainedValueValues = remainedValueFields.map(field => {
                        let fieldIndex = modalFieldsCopy.findIndex(idx => idx.name === field)
                        return Number(modalFieldsCopy[fieldIndex].value)
                    })

                    let reducedValue = Number(remainedValueValues.reduce((total, value) => total - Number(value), Number(modalFieldsCopy[financedValueIndex].value)))
                    modalFieldsCopy[remainedValueIndex].value = reducedValue
                }
                modalFieldsCopy[totalPriceIndex].value = Number(totalPriceValues.reduce((total, value) => total + Number(value)))
                modalFieldsCopy[interestRestitutionIndex].value = Number(interestRestitutionValues.reduce((total, value) => total + Number(value), 0))
            }
            this.setState({ modalFields: modalFieldsCopy }, this.setSubmitForm)
        }
    }

    // onCreateHandler = () => {
    //     let createJson = {}
    //     this.state.modalFields.forEach(field => createJson[field.name] = field.value ? field.value : 0)

    //     createJson.stockId = this.props.stock.id

    //     this.props.createFinancing(createJson).then(() => {
    //         this.props.onCancel()
    //         this.setState({ modalFields: this.initialFields })
    //         NOTIFICATION.success(this.props.language.cost.financing.create.success)
    //     }).catch(() => {
    //         NOTIFICATION.error(this.props.language.cost.financing.create.failure)
    //     })
    // }

    onEditHandler = () => {
        let editJSON = {}
        this.state.modalFields.forEach(field => editJSON[field.name] = field.value ? field.value : 0)

        //Check for contract date from financing to be after contract date from stock
        if (moment(editJSON.contractDate).isBefore(moment(this.props.stock.contractDate))) {
            let contractDateIndex = this.state.modalFields.findIndex(item => item.name === 'contractDate')
            let newModalFields = [...this.state.modalFields].map(obj => ({ ...obj }))
            if (contractDateIndex > -1) {
                newModalFields[contractDateIndex].error = true
                this.setState({ modalFields: newModalFields })
                return;
            }
        }

        this.props.edit(this.props.stock.financingCostId, editJSON).then(() => {
            this.props.onSave()
            NOTIFICATION.success(this.props.language.cost.financing.edit.success)
        }).catch(() => {
            NOTIFICATION.error(this.props.language.cost.financing.edit.failure)
        })
        this.setState({ modalFields: this.initialFields })
    }

    toggleEditModal = () => {
        if (!this.props.stock.financingCostId) return;
        this.props.getById(this.props.stock.financingCostId, true).then(financingCostResult => {
            // if (financingCostResult.blockedBy && financingCostResult.blockedBy.id !== this.props.login.userId) {
            //     this.props.onCancel()
            //     return NOTIFICATION.error(`${this.props.language.stock.blocked} ${financingCostResult.blockedBy.username}`)
            // }
            let hasFinancing = financingCostResult.stocks && financingCostResult.stocks.length && financingCostResult.stocks[0].hasFinancing
            this.setState({
                modalFields: this.initialFields.map(field => {

                    //make financedValue / remainingValue 0
                    if (field.name === CONSTANTS.FINANCING_COST_DETAILS_FIELDS.FINANCEDVALUE || field.name === CONSTANTS.FINANCING_COST_DETAILS_FIELDS.REMAINEDVALUE) {
                        return ({
                            ...field,
                            value: !hasFinancing ? 0 : financingCostResult[field.name] ? financingCostResult[field.name] : '',
                            disabled: financingCostResult.isClosed ? true : field.disabled
                        })
                    }

                    if (field.type === 'date') return ({
                        ...field,
                        disabled: financingCostResult.isClosed ? true : field.disabled,
                        value: moment(financingCostResult[field.name]).format(CONSTANTS.INPUT_TYPE_DATE_FORMAT)
                    })
                    return ({
                        ...field,
                        value: financingCostResult[field.name] ? financingCostResult[field.name] : '',
                        disabled: financingCostResult.isClosed ? true : field.disabled
                    })
                }),
                editModal: true,
                isClosed: financingCostResult.isClosed ? true : false,
                hasFinancing: hasFinancing
            })
        })
    }

    onCancelHandler = () => {
        this.props.onCancel()
    }

    openFinancingConfirmation = value => {
        this.setState({
            openFinancingConfirmation: true,
            closeFinancingState: value
        })
    }

    closeFinancingConfirmation = () => {
        this.setState({
            openFinancingConfirmation: false,
            closeFinancingState: null
        })
    }

    onCloseFinancing = () => {
        if (this.state.closeFinancingState)
            this.props.edit(this.props.stock.financingCostId, {
                isClosed: true,
                closedType: this.state.closeFinancingState
            }).then(() => {
                NOTIFICATION.success(this.props.language.cost.financing.edit.success)
                this.setState({ openFinancingConfirmation: false, closeFinancingState: null })
                this.props.onSave()
            }).catch(() => {
                NOTIFICATION.success(this.props.language.cost.financing.edit.error)
            })
    }

    UtilsButton = () => {
        return (
            <div style={{ marginRight: 10 }}>
                <SimpleMenu
                    disabled={this.state.isClosed}
                    text={this.props.language.cost.financing.close.button}
                    options={[
                        { label: this.props.language.cost.financing.close.menu.withRestitution, value: 'WITH_RESTITUTION' },
                        { label: this.props.language.cost.financing.close.menu.withoutRestitution, value: 'WITHOUT_RESTITUTION' }
                    ]}
                    onClick={this.openFinancingConfirmation}
                />
            </div>
        )
    }

    render() {
        return (
            <>
                <ConfirmationDialog
                    titleText={this.props.language.cost.financing.close.confirmation}
                    text={this.state.closeFinancingState === 'WITH_RESTITUTION' ? this.props.language.cost.financing.close.withRestitution : this.props.language.cost.financing.close.withoutRestitution}
                    open={this.state.openFinancingConfirmation}
                    acceptButtonText={this.props.language.cost.financing.close.confirm}
                    cancelButtonText={this.props.language.cost.financing.close.cancel}
                    onAccept={this.onCloseFinancing}
                    onClose={this.closeFinancingConfirmation}
                    onCancel={this.closeFinancingConfirmation}
                />
                <SimpleForm
                    canSubmit={true}
                    fields={this.state.modalFields}
                    dialogTitle={this.props.type === CONSTANTS.CREATE ? this.props.language.cost.financing.formModal.add : this.props.language.cost.financing.formModal.title}
                    open={this.props.open}
                    onSave={() => this.onEditHandler()}
                    onSubmit={() => this.props.onSubmit()}
                    onCancel={this.state.editModal ? () => this.props.onCancel() : () => this.onCancelHandler()}
                    onChange={this.onChangeHandler}
                    maxWidth='sm'
                    utilsButton={this.UtilsButton}
                    cancelButtonText={this.props.language.cost.financing.confirmationDialog.cancelButtonText}
                    submitButtonText={this.props.type === CONSTANTS.CREATE ? this.props.language.cost.financing.confirmationDialog.submitButtonText : this.props.language.cost.financing.formModal.edit}>
                </SimpleForm>
            </>
        )
    }
}


const mapStateToProps = state => ({
    language: state.languageReducer.i18n,
    login: state.loginReducer
})

const mapDispatchToProps = dispatch => ({
    createFinancing: financingCost => dispatch(FINANCING.create(financingCost)),
    getById: (financingCostId, blocked) => dispatch(FINANCING.getById(financingCostId, blocked)),
    unblockStock: financingCostId => dispatch(FINANCING.unblockStock(financingCostId)),
    edit: (financingCostId, financingCost) => dispatch(FINANCING.edit(financingCostId, financingCost))
})

export default connect(mapStateToProps, mapDispatchToProps)(FinancingModal)