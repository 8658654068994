import React, { Component } from 'react'
import { Paper, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import Header from '../../common/SearchableHeader'
import CarBrand from './CarBrand'
import CarModal from './CarModel'

const styles = theme => ({
    brand: {
        borderRight: '1px solid rgba(0,0,0,0.1)',
        backgroundColor: '#FBFBFA',
        width: '50%',
        padding: 24
    },
    model: {
        width: '50%',
        padding: 24,
        backgroundColor: '#FBFBFA'
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        borderBottom: 'none',
        borderRadius: 'unset',
        boxShadow: 'none',
        minHeight: '100%',
        alignItems: 'stretch'
    },
    header: {
        width: '100%',
        height: 70

    },
    listContainer: {
        padding: 2,
        height: '100%',
        overflow: 'auto'
    },
    withoutSidebar: {
        padding: '24px 36px 24px 0px'
    }
})

class Car extends Component {

    state = {
        brandId: null,
        brandName: '',
        search: ''
    }

    changeBrandHandler = (brandId, brandName) => {
        this.setState({ brandId, brandName })
    }

    render() {
        const { classes } = this.props
        return (
            <>
                <div className={classes.header}>
                    <Header
                        title={this.props.language.title}
                        placeholder={this.props.language.searchPlaceholder}
                        onSearch={input => this.setState({ search: input })}
                    />
                </div>
                <div className={classes.listContainer}>
                    <Paper className={`${classes.content}`}>
                    <div className={`${!this.props.sidebarReducer.displaySidebar ? this.props.classes.withoutSidebar : ""} ${this.props.classes.brand}`}>
                            <CarBrand onChangeBrand={this.changeBrandHandler} search={this.state.search} />
                        </div>
                        <div className={`${!this.props.sidebarReducer.displaySidebar ? this.props.classes.withoutSidebar : ""} ${this.props.classes.model}`}>
                            <CarModal brandName={this.state.brandName} brandId={this.state.brandId} />
                        </div>
                    </Paper>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    language: state.languageReducer.i18n.car,
    sidebarReducer: state.sidebarReducer
})

const mapDispatchToProps = dispatch => ({
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Car))