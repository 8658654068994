import React, { Component } from 'react'
import { connect } from 'react-redux'
import Sidebar from '../common/Sidebar'
import * as USER from '../../redux/actions/users'

class RootSidebar extends Component {

   

    render() {
        return (<>
            <Sidebar
                roles={this.props.roles}
                displaySidebarHandler={this.props.displaySidebarHandler}
                header={this.props.rootText.sidebar.header}
                onSelectTooltipItem={this.onSelectTooltipItemHandler}
                listItems={this.props.items}
                onClickButton={this.props.onLogout}
                buttonText={this.props.rootText.sidebar.logout} />
        </>)
    }
}

const mapStateToProps = reducers => ({
    username: reducers.loginReducer.username,
    rootText: reducers.languageReducer.i18n.root
})

const mapDispatchToProps = dispatch => ({
    getMe: () => dispatch(USER.getMe()),
    updateMe: user => dispatch(USER.updateMe(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(RootSidebar)