import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, TableRow, TableCell } from '@material-ui/core'
import { ClickWrapper } from '../../../utils/helpers'

import DropdownMenu from '../../common/DropdownMenu'

const styles = theme => ({

})

class CarBrandList extends Component {

    render() {
        return (
            <TableRow className={`${this.props.selectedItem === this.props.item._id ? 'selected' : ''}`}>
                <ClickWrapper onClick={() => this.props.onSelect(this.props.item)} >
                    <TableCell>{this.props.item.name}</TableCell>
                </ClickWrapper>
                <TableCell>
                    {this.props.actions ? <DropdownMenu type={'ACTIONS'} item={this.props.item} items={this.props.actions} />
                        : null}
                </TableCell>
            </TableRow>
        )
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails
})

const mapDispatchToProps = dispatch => ({

})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CarBrandList))