import React from 'react'
import { connect } from 'react-redux'
import { withStyles, Tooltip, Menu, MenuItem, Card, CardHeader, CardContent, CardActions, Avatar, IconButton } from '@material-ui/core'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'

import * as CONSTANTS from '../../../utils/constants'

const styles = theme => ({
    card: {
        maxWidth: 345,
        marginRight: 12,
        marginTop: 12
    },
    media: {
        height: 0,
        paddingTop: '90%'
    },
    avatar: {
        backgroundColor: 'green',
    },
    avatarContainer: {
        width: 25,
        height: 25,
        color: 'black'
    },
    fieldTextAvatar: {
        fontWeight: 500,
        paddingLeft: 3,
        color: 'black'
    },
    fieldText: {
        fontWeight: 500,
        paddingTop: 4
    },
    menu: {
        maxHeight: 48 * 4.5,
        width: 200,
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    infoDetails: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column'
    },
    mainContent: {
        paddingBottom: 16
    },
    secondaryContent: {
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box'
    },
    secondaryTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 6
    },
    primaryTextContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        color: 'black'
    },
    missing: {
        color: 'red',
        fontWeight: 500,
        paddingRight: 8
    },
    accepted: {
        color: 'green',
        fontWeight: 500,
        paddingRight: 8
    },
    menuContainer: {
        maxHeight: 48 * 4.5,
        width: 200,
    }
})

class FinancingCard extends React.Component {
    state = {
        expandSettings: false,
        anchorEl: null
    }

    onSettingsClickedHandler = event => {
        this.setState({ expandSettings: true, anchorEl: event.currentTarget })
    }

    onOptionClickedHandler = (event, action) => {
        if (action.onClick) {
            action.onClick(event, this.props.item)
        }
        this.setState({ expandSettings: false, anchorEl: null })
    }

    render() {

        let fieldsToRenderInPrimaryText = [
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.REMAINEDVALUE, name: this.props.language.fCost.remainedValue },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.FINANCEDVALUE, name: this.props.language.fCost.financedValue },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.CONTRACTDATE, name: this.props.language.fCost.contractDate },
            { field: CONSTANTS.FINANCING_COST_DETAILS_FIELDS.CONTRACTNUMBER, name: this.props.language.fCost.contractNumber },
        ]


        const { classes } = this.props
        return (
            <Card className={classes.card}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            {!this.props.item.carBrand ? "" : !this.props.item.carBrand.name ? "" : this.props.item.carBrand.name[0]}
                        </Avatar>
                    }
                    action={
                        this.props.actions && <div>
                            <IconButton onClick={event => this.onSettingsClickedHandler(event)} aria-label="settings" aria-controls="settings-menu">
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="settings-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={this.state.expandSettings}
                                onClose={() => this.setState({ expandSettings: false, anchorEl: null })}
                            >
                                {
                                    this.props.actions.map(act => {
                                        return (
                                            <MenuItem key={act.tooltip} onClick={event => this.onOptionClickedHandler(event, act)}>
                                                {act.tooltip}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Menu>
                        </div>
                    }
                    title={this.props.title}
                    subheader={this.props.subtitle}
                />
                {/* {this.props.cardImage && <CardMedia
                    className={classes.media}
                    image={require('../../../Assets/win.png')}
                    title=""
                />} */}
                <CardContent>
                    <div className={classes.infoContainer}>
                        <div className={classes.infoDetails}>
                            {fieldsToRenderInPrimaryText.map(fieldObj => (
                                <div className={this.props.classes.primaryTextContainer}>
                                    {this.props.item[fieldObj.field] ? <span className={this.props.classes.fieldText}>{fieldObj.name}: {parseFloat(this.props.item[fieldObj.field]).toFixed(2)} </span> : <span className={this.props.classes.fieldText}>{fieldObj.name}: 0 </span>}
                                </div>
                            ))}
                        </div>

                    </div>
                </CardContent>
                {this.props.actions && <CardActions >
                    {this.props.actions.map(action => {
                        if ((action.deleteIf && !action.deleteIf(this.props.item)) || !action.deleteIf) {
                            return <Tooltip key={action.tooltip} title={action.tooltip} placement='top'>
                                <IconButton
                                    onClick={event => action.onClick ? action.onClick(event, this.props.item) : () => { }}
                                    color={action.color || 'primary'}
                                    aria-label={action.tooltip || ''}>
                                    {action.icon}
                                </IconButton>
                            </Tooltip>
                        } else return null
                    })}

                </CardActions>}
            </Card>
        );
    }

}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails,
})

const mapDispatchToProps = dispatch => ({

})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FinancingCard))