import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography, withStyles } from '@material-ui/core'
import moment from 'moment'

import * as CONSTANTS from '../../../utils/constants'
import MiniGallery from '../../common/MiniGallery'

const styles = theme => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    photoGalery: {
        paddingLeft: 24,
        marginTop: -34
    },
    detailsWrapper: {
        flex: 3,
        display: 'flex',
        flexDirection: 'column'
    },
    equalFlex: {
        flex: 1
    },
    history: {
        position: 'relative',
        top: '45px',
        paddingLeft: '25px',
        cursor: 'pointer',
        color: '#6b6c6f',
        fontSize: 12
    },
    generalDetails: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1
    },
    detailsContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    accessoriesDetails: {
        flex: 1,
        padding: '0px 25px',
        color: '#3e3f42',
        flexDirection: 'column',
        flexWrap: 'wrap'
    },
    accessories: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    accessoriesTitle: {
        color: '#3e3f42',
        fontWeight: 500,
        fontSize: 16
    },
    accessoriesText: {
        color: '#6b6c6f',
        fontWeight: 'normal',
        fontSize: 12
    },
    accessoriesListWrap: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        height: 100,
        flex: 1
    },
    details: {
        padding: '33px 25px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    detailText: {
        paddingBottom: 12,
        fontWeight: 500,
        color: '#3e3f42'
    },
    detailFirstText: {
        color: '#9ea0a5',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        paddingBottom: 12,
    },
    detailTextContainer: {
        paddingLeft: 38
    },
    imgWrapper: {
        width: '300px'
    }
})

class OfferStockTabDetails extends Component {

    state = {
        mappedStock: null
    }

    render() {

        let accessoriesList = [
            { name: 'Culoare', value: 'Red' },
            { name: 'Motor', value: '3000' },
            { name: 'Faruri', value: 'Angel eyes' },
            { name: 'Inchidere centralizata', value: 'Da' },
            { name: 'Scaune', value: 'Ancantara' },
            { name: 'Incalzire volan', value: 'Da' },
            { name: 'An', value: '2018' },
            { name: 'Unic proprietar', value: 'Da' },
            { name: 'Incalzire', value: 'Da' },
            { name: 'ABS', value: 'Da' },
            { name: 'Senzori parcare', value: 'Fata si spate' },
            { name: 'Camera parcare', value: 'Da' },
            { name: 'Incalzire oglinzi', value: 'Da' },
            { name: 'Pilot automat', value: 'Da' },
            { name: 'Geamuri electrice', value: 'Da' },
            { name: 'Jante aliaj', value: 'Da' },
            { name: 'Automata', value: 'Da' },
            { name: 'Comenzi volan', value: 'Da' },
            { name: 'Computer bord', value: 'Da' },
            { name: 'Navigate', value: 'Da' },
        ]

        const { classes } = this.props

        let fieldsToDisplayFirstColumn = [
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.MILEAGE, name: this.props.language.stock.km },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.LIST_PRICE, name: this.props.language.stock.price, symbol: CONSTANTS.EURO_SYMBOL },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.DISCOUNTPERCENT, name: this.props.language.stock.discountPercent, symbol: CONSTANTS.PERCENT_SYMBOL },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.DISCOUNTEUR, name: this.props.language.stock.discountEUR, symbol: CONSTANTS.EURO_SYMBOL }
        ]

        let fieldsToDisplaySecondColumn = [
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.ORDERNUMBER, name: this.props.language.stock.orderNumber },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.VINNUMBER, name: this.props.language.stock.vinNumber },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.REGISTRATIONDATE, name: this.props.language.stock.createdAt },
            { field: CONSTANTS.STOCK_DETAILS_FIELDS.PURCHASEPRICE, name: this.props.language.stock.purchasePrice, symbol: CONSTANTS.EURO_SYMBOL }
        ]
        return (
            <>
                <div className={classes.detailsContainer}>
                    <div className={classes.mainContainer}>
                        <div className={classes.imgWrapper}>
                            <MiniGallery
                                images={this.props.fullStock.images}
                            />
                        </div>
                    </div>
                    <div className={classes.detailsWrapper}>
                        <div className={classes.generalDetails}>
                            <div className={classes.details}>
                                <div>
                                    {
                                        fieldsToDisplayFirstColumn.map((field, index) => {
                                            if (field.field === CONSTANTS.STOCK_DETAILS_FIELDS.REGISTRATIONDATE) {
                                                return (
                                                    <Typography key={index} className={classes.detailFirstText} component="p">{field.name}</Typography>
                                                )
                                            }
                                            return (
                                                <div>
                                                    <Typography key={index} className={classes.detailFirstText} component="p">{field.name}</Typography>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className={classes.detailTextContainer}>
                                    {
                                        fieldsToDisplayFirstColumn.map((field, index) => {
                                            if (field.field === CONSTANTS.STOCK_DETAILS_FIELDS.REGISTRATIONDATE) {
                                                return (
                                                    <Typography key={index} className={classes.detailText} component="p">{moment(this.props.fullStock[field.field]).format('l')}</Typography>
                                                )
                                            }
                                            return (
                                                <div>
                                                    <Typography key={index} className={classes.detailText} component="p">{typeof (this.props.fullStock[field.field]) == 'number' ? `${parseFloat(this.props.fullStock[field.field]).toFixed(2)} ${field.symbol ? field.symbol : ""}` : this.props.fullStock[field.field]}</Typography>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className={classes.details}>
                                <div>
                                    {
                                        fieldsToDisplaySecondColumn.map((field, index) => {
                                            if (field.field === CONSTANTS.STOCK_DETAILS_FIELDS.REGISTRATIONDATE) {
                                                return (
                                                    <Typography key={index} className={classes.detailFirstText} component="p">{field.name}</Typography>
                                                )
                                            }
                                            return (
                                                <div>
                                                    <Typography key={index} className={classes.detailFirstText} component="p">{field.name}</Typography>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className={classes.detailTextContainer}>
                                    {
                                        fieldsToDisplaySecondColumn.map((field, index) => {
                                            if (field.field === CONSTANTS.STOCK_DETAILS_FIELDS.REGISTRATIONDATE) {
                                                return (
                                                    <Typography key={index} className={classes.detailText} component="p">{moment(this.props.fullStock[field.field]).format('l')}</Typography>
                                                )
                                            }
                                            return (
                                                <div>
                                                    <Typography key={index} className={classes.detailText} component="p">{typeof (this.props.fullStock[field.field]) == 'number' ? `${parseFloat(this.props.fullStock[field.field]).toFixed(2)} ${field.symbol ? field.symbol : ""}` : this.props.fullStock[field.field]}</Typography>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={classes.accessoriesDetails}>
                            <span className={classes.accessoriesTitle}>{this.props.language.accessoriesTitle}</span>
                            <div className={classes.accessories}>
                                <div className={classes.accessoriesListWrap}>
                                    {accessoriesList.map(item => {
                                        return (
                                            <div className={classes.accItem}>
                                                <span className={classes.accessoriesText}>{item.name}: {item.value}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className={classes.equalFlex}>
                            <span className={classes.history} onClick={this.props.setOfferOption}>{this.props.language.history}</span>
                        </div>
                    </div>
                </div>
            </>)
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails
})

const mapDispatchToProps = dispatch => ({
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OfferStockTabDetails))