import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { IconButton, withStyles } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import * as CONSTANTS from '../../utils/constants'
import { formatNumber } from '../../utils/helpers'

const styles = theme => ({
    timelineWrapper: {
        listStyle: 'none',
        paddingLeft: '5px',
        overflow: 'auto',
        height: 'calc(100% - 170px)',
        '& li:nth-of-type(1)': {
            marginTop: '0px',
            paddingTop: '0px'
        }
    },
    item: {
        paddingTop: '30px',
        paddingLeft: '22px',
        borderLeft: '1px solid #e0e0e0',
        position: 'relative',
        '& > span': {
            display: 'inline-block',
            width: 12,
            height: 12,
            borderRadius: '50%',
            position: 'absolute',
            marginLeft: '-28px',
            bottom: '16px',
            backgroundColor: '#34aa44'
        },
        '& > span.withNote': {
            bottom: '26px'
        },
        '& > span.deleted': {
            backgroundColor: '#e6492d'
        }
    },
    text: {
        padding: '0px 0px 10px 0px',
        fontSize: '16px',
        '& > span.author': {
            fontWeight: 500
        },
        '& > span.amount': {
            color: '#34aa44'
        },
        '& > span.amount.deleted': {
            color: '#e6492d'
        },
        '& > span.category': {
            color: '#34aa44'
        }
    },
    date: {
        color: '#6b6c6f',
        fontSize: '14px'
    },
    note: {
        color: '#6b6c6f',
        fontSize: '14px',
        paddingBottom: '10px'
    },
    boldText: {
        fontWeight: 500
    },
    deleteIcon: {
        float: 'right',
        position: 'relative',
        marginRight: '10px',
        bottom: '-10px'
    }
})

function renderItemOffer({ item, classes, offerLanguage }) {
    return <>
        <div className={classes.text}>
            <span className='author'>{item.author} </span>
            {offerLanguage.created} {item.clientName}. <span className={classes.boldText}>{offerLanguage.discount}</span>: {item.discount}% - <span className={classes.boldText}>{offerLanguage.finalPrice}</span>: {item.sellingPriceEUR} EUR
        </div>
        <div className={classes.date}><span className={classes.boldText}>{moment(item.createdAt).format(CONSTANTS.DEFAULT_DATE_TIME_FORMAT)}</span></div>
    </>
}

function renderItemCost({ item, classes, costLanguage, onDelete }) {
    switch (item.type) {
        case 'cascos':
        case 'vignettes':
        case 'rcas':
        case 'prts':
            return <>
                <div className={classes.text}><span className='author'>{item.author}</span> {costLanguage.added} <span className={`amount ${!item.add ? 'deleted' : ''}`}>{formatNumber(item.value)}</span> {costLanguage.ronInCategory} <span className='category'>{item.category}</span>
                    {onDelete && <IconButton onClick={() => onDelete(item)} className={classes.deleteIcon} aria-label="delete" color="primary">
                        <DeleteIcon />
                    </IconButton>}
                </div>
                <div className={classes.date}>Din {moment(item.validFromDate).format(CONSTANTS.DEFAULT_DATE_FORMAT)} pana la <span className={classes.boldText}>{moment(item.expirationDate).format(CONSTANTS.DEFAULT_DATE_FORMAT)}</span></div>
            </>
        default: return <>
            <div className={classes.text}><span className='author'>{item.author}</span> {costLanguage.added} <span className={`amount ${!item.add ? 'deleted' : ''}`}>{item.value}</span> {costLanguage.ronInCategory} <span className='category'>{item.category}</span>
                {onDelete && <IconButton onClick={() => onDelete(item)} className={classes.deleteIcon} aria-label="delete" color="primary">
                    <DeleteIcon />
                </IconButton>}
            </div>
            <div className={classes.note}>{item.note}</div>
            <div className={classes.date}><span className={classes.boldText}>{moment(item.createdAt).format(CONSTANTS.DEFAULT_DATE_FORMAT)}</span></div>
        </>
    }
}

function Timeline(props) {
    const { classes, offerLanguage } = props

    return <ul className={classes.timelineWrapper}>
        {props.items.map((item, index) => <li key={index} className={classes.item}>
            <span key={index} className={`${item.note ? 'withNote' : ''}`}></span>
            {props.isCost ? renderItemCost({ ...props, item: item }) : renderItemOffer({ item, classes, offerLanguage })}
        </li>)}
    </ul>
}

const mapStateToProps = state => {
    return {
        costLanguage: state.languageReducer.i18n.itemDetails.lCost,
        offerLanguage: state.languageReducer.i18n.itemDetails.offer
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Timeline))