import { createMuiTheme } from '@material-ui/core/styles'

const primaryColor = '#3e3f42'
const primaryColorHover = '#6b6c6f'
const secondaryColor = '#1665d8'
const whiteColor = '#ffffff'
const mainTextColor = '#ffffff'
const blueColor = '#1665d8'
const greenColor = '#34aa44'
const redColor = '#e6492d'

const primaryButtonColor = '#34aa44'
const secondarButtonColor = '#f6f7f9'

const MuiTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primaryColor,
        whiteColor,
        secondaryColor,
        blueColor,
        greenColor,
        redColor,
        firstColor: "#d1d1d1",
        secondColor: "#FFFFFF"
    },
    overrides: {
        MuiTableCell: {
            root: {
                padding: '4px 10px 4px 15px',
                fontWeight: 500,
                color: '#9ea0a5',
                fontSize: 13,
                paddingTop: 4,
                '&:nth-of-type(1)': {
                    padding: '4px 0px 0px 25px'
                },
                '&:last-child': {
                    width: '85px'
                },
            },
            head: {
                color: '#9ea0a5',
                fontSize: '13px',
                fontWeight: '500',
                paddingTop: '4px'
            }
        },
        MuiTableRow: {
            root: {
                height: '60px',
                borderLeft: '5px solid transparent',
                cursor: 'pointer',
                '&:hover': {
                    borderLeft: '5px solid rgba(0,0,0,0.3)'
                },
                '&.selected': {
                    borderLeft: '5px solid rgba(0,0,0,0.3)'
                }
            }
        },
        MuiInput: {
            underline: {

            },
            disabled: {
                color: '#777777'
            },
            root: {
                height: 45,
                paddingLeft: 12,
                paddingRight: 12
            }
        },
        MuiFormControl: {
            marginNormal: {
                marginTop: 8
            }
        },
        MuiFormLabel: {
            root: {
                "&$focused": {
                    color: primaryColor
                },
            }
        },
        MuiTooltip: {
            tooltipPlacementTop: {
                fontSize: 14
            }
        },
        MuiInputBase: {
            root: {
                boxSizing: 'border-box'
            }
        },
        MuiOutlinedInput: {
            root: {
                '& $notchedOutline': {
                    borderColor: '#e2e5ed'
                },
                '&$focused $notchedOutline': {
                    borderColor: 'green',
                },
                '&$disabled': {
                    '& $notchedOutline': {
                        borderColor: '#e2e5ed'
                    }
                }
            },
            adornedEnd: {
                height: 45
            },
        },
        MuiButton: {
            contained: {
                boxShadow: 'unset'
            },
            containedPrimary: {
                color: whiteColor,
                backgroundColor: primaryButtonColor,
                '&:hover': {
                    backgroundColor: greenColor,
                    opacity: 0.50
                }
            },
            flatSecondary: {
                color: '#3e3f42',
                backgroundColor: secondarButtonColor,
                boxShadow: '0 0.5px 0.5px 0 rgba(22, 29, 37, 0.05), inset 0 1px 0 0 rgba(255, 255, 255, 0.05)',
                border: 'solid 0.5px #d8dce6',
                padding: 9,
                fontSize: 12,
                '&:hover': {
                    backgroundColor: '#d8dce6',
                    opacity: 0.75
                }
            },
            flatPrimary: {
                color: whiteColor,
                backgroundColor: primaryButtonColor,
                padding: 9,
                fontSize: 12,
                '&:hover': {
                    backgroundColor: primaryColor,
                    opacity: 0.50
                },
            },
            label: {
                fontSize: 12
            }
        },
        MuiCheckbox: {
            colorPrimary: {
                '&$checked': {
                    color: primaryColor,
                }
            },
            colorSecondary: {
                '&$checked': {
                    color: 'green',
                }
            }
        },
        MuiTypography: {
            colorPrimary: {
                color: mainTextColor
            },
            body1: {
                fontSize: 16
            }
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: primaryColorHover
            }
        },
        MuiIconButton: {
            root: {
                padding: 5
            },
            colorSecondary: {
                color: secondaryColor
            },
            colorPrimary: {
                color: primaryColor
            }
        },
        MuiSelect: {
            root: {
                height: 45
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: 21
            },
            colorPrimary: {
                color: primaryColor
            },
            colorSecondary: {
                color: secondaryColor
            }
        },
        AutoCompleteInputlist: {
            border: '1px solid red'
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0
            }
        },
        MuiListItemIcon: {
            root: {
                fontSize: 30,
                color: '#ffffff'
            }
        },
        MuiTablePagination: {
            input: {
                paddingTop: 18
            }
        },
        MuiListItem: {
            divider: {
                borderBottom: 'none'
            },
            secondaryAction: {
                paddingRight: 'none'
            },
            root: {
                color: primaryColor,
                borderLeft: '2px solid transparent'
            },
            button: {
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    //borderLeft: '2px solid white'
                },
                '&$selected': {
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    // borderLeft: '2px solid white'
                },
            }
        },
        MuiListSubheader: {
            sticky: {
                borderLeft: '3px solid #ffffff',
                borderBottom: '1px solid #e0e0e0'
            }
        },
        MuiExpansionPanel: {
            root: {
                marginTop: '5px'
            },
            expanded: {
                marginBottom: '0px',
                marginTop: '5px'
            }
        },
        MuiTab: {
            root: {
                minHeight: '63px',
                fontSize: '15px !important',
                paddingTop: '6px',

            }
        },
        MuiDialog: {
            paper: {
                padding: '10px 14px'
            }
        },
        MuiDialogContent: {
            root: {
                padding: '0px 4px'
            }
        },
        MuiCardHeader: {
            root: {
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
            },
            title: {
                fontSize: 14,
                fontWeight: '500'
            },
            subheader: {
                fontSize: 12
            }
        },
        MuiCardContent: {
            root: {
                padding: '16px 16px 16px 16px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                '&:last-child': {
                    paddingBottom: 8
                }
            },
        },
        MuiCardActions: {
            root: {
                justifyContent: 'center',
                padding: '2px 4px'
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 22px 0px rgba(0,0,0,0.14), 1px 2px 1px -1px rgba(0,0,0,0.12)"
            },
        },
        MuiRadio: {
            colorSecondary: {
                '&$checked': {
                    color: 'green'
                }
            }
        }
    }
})
export default MuiTheme