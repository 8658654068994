import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, TableCell, TableRow } from '@material-ui/core'

import DropdownMenu from '../../common/DropdownMenu'
import { ClickWrapper } from '../../../utils/helpers'

const styles = theme => ({

})

class ProviderList extends Component {

    render() {

        return (
            <TableRow>
                <ClickWrapper onClick={() => this.props.onSelect(this.props.item)}>
                    <TableCell>{this.props.item.name}</TableCell>
                    <TableCell>{this.props.item.updatedAt}</TableCell>
                </ClickWrapper>
                <TableCell>{this.props.actions ? <DropdownMenu type={'ACTIONS'} item={this.props.item} items={this.props.actions} />
                    : null}</TableCell>
            </TableRow>
        )
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails
})

const mapDispatchToProps = dispatch => ({

})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProviderList))