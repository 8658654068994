import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { withStyles, TableRow, TableCell, Tooltip } from '@material-ui/core'

import * as CONSTANTS from '../../utils/constants'
import config from '../../config/local.json'

import ListAvatarAndCarBrand from '../common/subComponents/ListAvatarAndCarBrand'
import { formatNumber, ClickWrapper } from '../../utils/helpers'
import DropdownMenu from '../common/DropdownMenu'

const styles = theme => ({

})

let COLORS = {
    BLUE: '#115293',
    ORANGE: '#e33371'
}

class OfferList extends Component {

    handleStatus = () => {
        if (this.props.item.stock.isSold && !this.props.item.isCanceled) {
            return (
                <>
                    <Tooltip title={this.props.language.offer.tooltipTitle.sold} placement={"top"}>
                        <span>{this.props.language.offer.statusAbreviation.sold}</span>
                    </Tooltip>
                </>
            )
        }

        if (this.props.item.isCanceled) {
            return (
                <>
                    <Tooltip title={this.props.language.offer.tooltipTitle.canceled} placement={"top"}>
                        <span>{this.props.language.offer.statusAbreviation.canceled}</span>
                    </Tooltip>
                </>
            )
        }

        if (this.props.item.isInWork) {
            return (
                <>
                    <Tooltip title={this.props.language.offer.tooltipTitle.inWork} placement={"top"}>
                        <span>{this.props.language.offer.statusAbreviation.inWork}</span>
                    </Tooltip>
                </>
            )
        }

        let reservedUntil = this.props.item.reservedUntil ? moment(this.props.item.reservedUntil).format(CONSTANTS.DEFAULT_DATE_FORMAT) : ''

        if (this.props.item.withAdvance) {
            return (
                <>
                    <Tooltip title={this.props.language.offer.tooltipTitle.withAdvance.replace('?', ` - ${reservedUntil}`)} placement={"top"}>
                        <span>{this.props.language.offer.statusAbreviation.withAdvance}</span>
                    </Tooltip>
                </>
            )
        }
        if (!this.props.item.withAdvance && moment(this.props.item.reservedUntil).isAfter(moment())) {
            return (
                <>
                    <Tooltip title={this.props.language.offer.tooltipTitle.withoutAdvance.replace('?', ` - ${reservedUntil}`)} placement={"top"}>
                        <span>{this.props.language.offer.statusAbreviation.withoutAdvance}</span>
                    </Tooltip>
                </>
            )
        }

        return (
            <>
                <Tooltip title={this.props.language.offer.tooltipTitle.noStatus} placement={"top"}>
                    <span>{this.props.language.offer.statusAbreviation.noStatus}</span>
                </Tooltip>
            </>
        )
    }

    render() {
        const logoPath = `${config.baseUrl}/cars/brands/${this.props.item.stock.carBrandId}/image`

        let reservedWithAdvance = this.props.item && this.props.item.reservedUntil && this.props.item.withAdvance
        let reservedWithoutAdvance = this.props.item && this.props.item.reservedUntil && !this.props.item.withAdvance
        let isSold = this.props.item.stock.isSold && !this.props.item.isCanceled
        

        let getBorderLeftStyle = () => {
            if (isSold) return '6px solid #34aa44'
            if (reservedWithoutAdvance) return `6px solid ${COLORS.ORANGE}`
            if (reservedWithAdvance) return `6px solid ${COLORS.BLUE}`
            return ''
        }

        return (
            <TableRow style={{
                opacity: this.props.item.stock.isSold || this.props.item.isCanceled ? 0.6 : null,
                borderLeft: getBorderLeftStyle()
            }}>
                <ClickWrapper onClick={() => this.props.onSelect(this.props.item)}>
                    <TableCell>
                        <ListAvatarAndCarBrand
                            carBrandName={this.props.item.stock.carBrand.name}
                            carModelName={this.props.item.stock.carModel.name}
                            logoPath={logoPath}
                        />
                    </TableCell>
                    <TableCell>{this.props.item.stock.provider.name || '-'}</TableCell>
                    <TableCell>{this.props.item.client.name || '-'}</TableCell>
                    <TableCell>{this.props.item.user.username || '-'}</TableCell>
                    <TableCell> {formatNumber(this.props.item.discountEUR) || '0'} {CONSTANTS.EURO_SYMBOL}</TableCell>
                    <TableCell> {formatNumber(this.props.item.sellingPriceEUR) || '0'} {CONSTANTS.EURO_SYMBOL}</TableCell>
                    <TableCell>{this.handleStatus()}</TableCell>
                    <TableCell>{this.props.item && this.props.item.date ? moment(this.props.item.date).format(CONSTANTS.DEFAULT_DATE_FORMAT) : '-'}</TableCell>
                </ClickWrapper>
                {this.props.actions ? <TableCell> <DropdownMenu type={'ACTIONS'} item={this.props.item} items={this.props.actions} />
                </TableCell> : null}
            </TableRow>)
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails
})

const mapDispatchToProps = dispatch => ({

})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OfferList))