import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography, Button, CircularProgress, withStyles } from '@material-ui/core'
import moment from 'moment'

import InputGenerator from '../../common/subComponents/InputGenerator'
import ConfirmationDialog from '../../common/ConfirmationDialog'
import LeasingModal from './LeasingModal'

import * as CONSTANTS from '../../../utils/constants'
import * as OFFERS from '../../../redux/actions/offers'
import * as STOCKS from '../../../redux/actions/stocks'
import * as SALES from '../../../redux/actions/sales'
import * as NOTIFICATION from '../../../utils/notification'

import { roleInArray } from '../../../utils/helpers'

const styles = theme => ({
    imgStyle: {
        padding: '34px 25px',
        width: 250,
        height: 200,
        borderRadius: 36,
        objectFit: 'fill'
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    photoGalery: {
        paddingLeft: 24,
        marginTop: -34
    },
    detailsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    equalFlex: {
        flex: 1
    },
    history: {
        paddingLeft: '25px',
        cursor: 'pointer',
        color: '#6b6c6f',
        fontSize: 12
    },
    advanceContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 26,
        minWidth: '150px'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    generalDetails: {
        padding: 25,
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    modifiyContainer: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        top: '38%',
        paddingRight: 8
    },
    modifiyButton: {
        marginLeft: 'auto'
    },
    detailsContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    accessoriesDetails: {
        flex: 3,
        padding: '0px 25px',
        color: '#3e3f42',
        flexDirection: 'column',
        flexWrap: 'wrap'
    },
    accessories: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    accItemValue: {
        paddingLeft: 28
    },
    accessoriesTitle: {
        color: '#3e3f42',
        fontWeight: 500,
        fontSize: 16
    },
    accessoriesText: {
        color: '#6b6c6f',
        fontWeight: 'normal',
        fontSize: 12
    },
    accessoriesListWrap: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        height: 100,
        flex: 1
    },
    details: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    detailText: {
        paddingBottom: 4,
        fontWeight: 500,
        color: 'black'
    },
    detailFirstText: {
        color: '#3e3f42',
        fontWeight: 400,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        paddingBottom: 4,
    },
    detailTextContainer: {
        paddingLeft: 38,
        maxWidth: '300px'
    },
    gridList: {
        paddingRight: 24
    },
    status: {
        padding: '18px 8px',
        marginLeft: 'auto'
    },
    statusText: {
        padding: 0,
        paddingRight: 15,
        paddingTop: 4,
        color: '#3e3f42',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal'
    },
    stockDetailsText: {
        paddingLeft: 25,
        fontWeight: 500
    },
    noCosts: {
        color: 'red'
    },
    addPhoto: {

    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    uploadLabel: {
        textAlign: 'center'
    },
    displayNone: {
        display: 'none'
    },
    titleText: {
        padding: 0,
        margin: 0,
        fontSize: 15,
        fontWeight: 500,
        color: '#9ea0a5',
        marginBottom: 8
    },
    optionsTitleText: {
        padding: 0,
        margin: 0,
        fontSize: 15,
        fontWeight: 500,
        color: '#9ea0a5',
        marginBottom: 8,
        marginTop: 24
    },
    optionsButton: {
        margin: '10px 4px',
        padding: 6
    },
    reservationContainer: {
        padding: '25px 6px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    optionsContainer: {
        paddingLeft: 12
    },
    spinnerStyle: {
        color: '#3e3f42',
        height: '25px !important',
        width: '25px !important'
    },
    colorWhite: {
        color: 'white'
    }
})

class OfferDetails extends Component {

    reservationFields = [
        { value: '', type: 'date', label: 'Valabilitate', name: 'reservedUntil', validation: {} },
        { type: 'radioSelector', name: 'reservationType', utils: 'Tipul rezervarii', value: '', options: CONSTANTS.RESERVATION_OPTIONS.map(field => ({ ...field, label: this.props.language.reservations.reservationType[field.name] })) },
        { value: 0, disabled: true, type: 'numberFormat', label: 'Avans procentual', name: 'advancePercentage' },
        { value: 0, type: 'numberFormat', label: 'Avans Valoric', name: 'advanceValue' },
        { value: 0, type: 'numberFormat', decimalSize: CONSTANTS.EXCHANGE_DECIMAL_SIZE, label: 'Curs EURO actual', name: 'invoiceSellingExchangeRate', validation: {} },
        { value: 5, type: 'numberFormat', decimalSize: CONSTANTS.EXCHANGE_DECIMAL_SIZE, label: 'Termen de plata(zile)', name: 'daysUntilPayment' }
    ]

    state = {
        displaySpinner: false,
        mappedStock: null,
        renderReservation: false,
        reservationFields: this.reservationFields,
        showLastColumn: false,
        editType: false,
        openConfirmationModal: false,
        confirmationType: '',
        openLeasingSelector: false,
        displaySpinnerLeasing: false,
        downloadLeasing: false
    }

    componentDidMount() {
        if (moment(this.props.offer.reservedUntil).isAfter(moment()) || this.props.offer.inWork) {
            this.populateFields()
        }
    }

    populateFields = () => {
        const fieldsToPopulate = [...this.state.reservationFields].map(field => field.name)
        const fieldsCopy = this.state.reservationFields.map(field => ({ ...field }))

        // Populate fields from current offer
        fieldsToPopulate.forEach(field => {
            const currentIndex = fieldsCopy.findIndex(index => index.name === field)
            if (currentIndex > -1) {
                fieldsCopy[currentIndex].disabled = true

                // Handle date time populate
                if (field === 'reservedUntil') {
                    fieldsCopy[currentIndex].value = moment(this.props.offer[field]).format(CONSTANTS.INPUT_TYPE_DATE_FORMAT)
                }

                // Handle populate radio selector
                else if (field === 'reservationType') {
                    let reservationTypeLabel
                    if (this.props.offer['withAdvance'] === true) {
                        const currentLabel = fieldsCopy[currentIndex].options.filter(field => field.name === 'withAdvance')
                        if (currentLabel && currentLabel.length) reservationTypeLabel = currentLabel[0].label
                    }
                    else {
                        const currentLabel = fieldsCopy[currentIndex].options.filter(field => field.name === 'withoutAdvance')
                        if (currentLabel && currentLabel.length) reservationTypeLabel = currentLabel[0].label
                    }
                    fieldsCopy[currentIndex].value = reservationTypeLabel
                }

                // Default populate
                else {
                    fieldsCopy[currentIndex].value = this.props.offer[field]
                }
            }
        })
        this.setState({ reservationFields: fieldsCopy, renderReservation: true, showLastColumn: true, editType: true })
    }

    onChange = event => {
        const currentIndex = this.state.reservationFields.findIndex(index => index.name === event.target.name)
        const advancePercentageIndex = this.state.reservationFields.findIndex(index => index.name === 'advancePercentage')
        const advanceValueIndex = this.state.reservationFields.findIndex(index => index.name === 'advanceValue')

        if (currentIndex > -1) {
            let fieldsCopy = this.state.reservationFields.map(field => ({ ...field }))

            fieldsCopy[currentIndex].value = event.target.value

            //Handle formulas
            if (event.target.name === 'advanceValue') {
                fieldsCopy[advancePercentageIndex].value = Number(Number(event.target.value) / Number(this.props.offer.sellingPriceEUR)) * 100
            }

            if (event.target.name === 'reservationType') {
                this.setState({ showLastColumn: true })
            }
            this.setState({ reservationFields: fieldsCopy })
        }
    }

    createReservationHandler = () => {

        let jsonMap = {}

        this.state.reservationFields.forEach(field => {
            jsonMap[field.name] = field
        })

        const reservationJson = {}

        // Create reservation json with input values
        Object.keys(jsonMap).forEach(key => {
            if (key === "reservationType") {
                const selectedOptionIndex = jsonMap[key].options.findIndex(index => index.label.toLowerCase() === jsonMap[key].value.toLowerCase())
                if (selectedOptionIndex > -1) reservationJson["withAdvance"] = jsonMap[key].options[selectedOptionIndex].name === 'withAdvance' ? true : false
            }
            if (key === 'reservedUntil') {
                reservationJson[key] = moment(jsonMap[key].value).toDate()
            }
            else reservationJson[key] = jsonMap[key].value
        })

        if (jsonMap['invoiceSellingExchangeRate'].value === 0 && reservationJson["withAdvance"] === true) {
            jsonMap['invoiceSellingExchangeRate'].error = true
            return this.setState({ reservationFields: [...Object.values(jsonMap)] })
        }

        this.setState({ displaySpinner: reservationJson["withAdvance"] === true ? true : false }, () => {
            this.props.reserve(this.props.offer.id, reservationJson).then(() => {
                NOTIFICATION.success(this.props.language.reservedSuccess)

                if (reservationJson["withAdvance"] === true) {
                    this.setState({ displaySpinner: false })
                    this.props.onCancel()
                    //window.open(`${CONSTANTS.BACKEND_URL}offers/${this.props.offer.id}/invoice`, "_blank")
                }

                this.props.onCancel()
            })
                .catch(() => {
                    this.setState({ displaySpinner: false })
                    NOTIFICATION.error(this.props.language.reservedFail)
                })
        })
    }

    InputWrapper = ({ input, keyProps, shrink }) =>
        <InputGenerator
            key={keyProps}
            InputLabelProps={shrink ? { shrink: true } : {}}
            inputProps={{
                min: moment().add(1, 'days').format(CONSTANTS.INPUT_TYPE_DATE_FORMAT)
            }}
            margin="dense"
            {...input}
            onChange={event => this.onChange(event)}
        />

    sendInWork = () => {
        if (window.confirm(this.props.language.offer.inWorkConfirmation)) {
            this.props.sendInWork(this.props.offer.id).then(() => {
                this.props.onCancel()
                NOTIFICATION.success(this.props.language.offer.sendInWorkSuccess)
            })
                .catch(() => {
                    NOTIFICATION.error(this.props.language.offer.sendInWorkFail)
                })
        }
    }

    sendAnalysis = leasingId => {
        if (this.state.downloadLeasing) {
            window.open(`${CONSTANTS.BACKEND_URL}offers/${this.props.offer.id}/invoice/proform/leasing/${leasingId}/download`, "_blank")
            window.open(`${CONSTANTS.BACKEND_URL}offers/${this.props.offer.id}/analyzeLeasing/${leasingId}/download`, "_blank")
            this.props.onCancel()
            return;
        }
        this.setState({ displaySpinnerLeasing: true, openLeasingSelector: false }, () => {
            this.props.sendAnalysis(this.props.offer.id, leasingId).then(() => {
                this.setState({ displaySpinnerLeasing: false })
                this.props.onCancel()
                NOTIFICATION.success(this.props.language.offer.analysisSent)
            })
                .catch(err => {
                    this.setState({ displaySpinnerLeasing: false })
                    NOTIFICATION.error(this.props.language.offer.analysisFail)
                })
        })
    }

    sendInvoice = () => {
        if (window.confirm(this.props.language.offer.sendInvoiceConfirmation)) {
            this.setState({ displaySpinner: true, }, () => {
                this.props.sendInvoice(this.props.offer.id).then(() => {
                    this.setState({ displaySpinner: false })
                    this.props.onCancel()
                    NOTIFICATION.success(this.props.language.offer.invoiceSent)
                })
                    .catch(err => {
                        this.setState({ displaySpinner: false })
                        NOTIFICATION.error(this.props.language.offer.invoiceFail)
                    })
            })
        }
    }

    renderReservation = () => {

        const { classes } = this.props
        const InputWrapper = this.InputWrapper

        let jsonMap = {}
        this.state.reservationFields.forEach(field => {
            jsonMap[field.name] = field
        })

        // Check reservation type if withAdvance we should render extra fields
        const reservationType = jsonMap["reservationType"].options.filter(type => type.label.toLowerCase() === jsonMap["reservationType"].value.toLowerCase())[0]

        return (
            <>
                <div className={this.props.classes.flexRow}>
                    <div>
                        <p className={`${classes.titleText}`}>{this.props.language.reservationDetails}</p>

                        <InputWrapper input={jsonMap['reservedUntil']} key={'reservedUntil'} shrink={true} />
                        {jsonMap["reservedUntil"].value && <InputWrapper input={jsonMap['reservationType']} key={'reservationType'} />}
                        {!this.state.editType && this.state.showLastColumn ?
                            <Button onClick={() => this.createReservationHandler()} color="secondary">{this.props.language.sendReservation}</Button> : null}
                    </div>
                    <div>
                        {this.state.showLastColumn ?
                            reservationType && reservationType.name === 'withAdvance' ?
                                <div className={this.props.classes.advanceContainer}>
                                    <InputWrapper input={jsonMap['advancePercentage']} key={'advancePercentage'} shrink={true} />
                                    <InputWrapper input={jsonMap['advanceValue']} key={'advanceValue'} shrink={true} />
                                    <InputWrapper input={jsonMap['invoiceSellingExchangeRate']} key={'invoiceSellingExchangeRate'} shrink={true} />
                                    <InputWrapper input={jsonMap['daysUntilPayment']} key={'daysUntilPayment'} shrink={true} />
                                </div> : null
                            : null}
                    </div>
                    {this.state.editType &&
                        !this.props.offer.isInWork &&
                        !this.props.offer.stock.isSold &&
                        !this.props.offer.isCanceled &&
                        roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.FINANCING]) ? <div>
                            <div className={classes.optionsContainer}>
                                <p className={`${classes.titleText}`}>{this.props.language.options}</p>
                                <div className={classes.flexColumn}>
                                    {<Button onClick={() => {
                                        this.sendInWork()
                                    }}
                                        className={classes.optionsButton}
                                        color="secondary">{reservationType.name === 'withAdvance' ? this.props.language.offer.advancePaid : this.props.language.offer.inWork}
                                    </Button>}
                                    {this.props.offer.email ? <Button onClick={() => {
                                        this.setState({ openLeasingSelector: true, downloadLeasing: false })
                                    }} color="secondary">{this.state.displaySpinnerLeasing ? <><span>{this.props.language.offer.sendLeasingAnalysis}</span>&nbsp;<CircularProgress className={this.props.classes.spinnerStyle} /></> : this.props.language.offer.leasingAnalysis}</Button> : null}
                                    <Button onClick={() => {
                                        this.setState({ openLeasingSelector: true, downloadLeasing: true })
                                    }} style={{ marginTop: 10 }} color="secondary">Descarca documente analiza leasing</Button>
                                    {reservationType && reservationType.name === 'withAdvance' && <>
                                        <Button onClick={() => window.open(`${CONSTANTS.BACKEND_URL}offers/${this.props.offer.id}/invoice`, "_blank")} className={classes.optionsButton} color="secondary">{this.props.language.offer.getInvoiceOffer}</Button>
                                        <Button onClick={() => this.sendInvoice()} color="secondary">{this.state.displaySpinner ? <><span>{this.props.offerLanguage.sendingProforma}</span>&nbsp;<CircularProgress className={this.props.classes.spinnerStyle} /></> : this.props.language.offer.sendInvoiceOffer}</Button></>}
                                </div>
                            </div>
                        </div> : null}
                </div>
            </>
        )
    }

    sendToSale = () => {
        this.props.sendToSale({ offerId: this.props.offer.id }).then(() => {
            this.props.onCancel()
            NOTIFICATION.success(this.props.language.successSold)
        })
            .catch(err => {
                NOTIFICATION.error(this.props.language.failSold)
            })
    }

    render() {
        const { classes } = this.props
        let fieldsToDisplayInitialColumn = [
            { field: CONSTANTS.OFFER_DETAILS_FIELDS.id, name: this.props.language.offer.id },
            { field: CONSTANTS.OFFER_DETAILS_FIELDS.createdAt, name: this.props.language.offer.date },
            { field: CONSTANTS.OFFER_DETAILS_FIELDS.CUI, name: this.props.language.offer.cui },
            { field: CONSTANTS.OFFER_DETAILS_FIELDS.name, name: this.props.language.offer.companyName },
            { field: CONSTANTS.OFFER_DETAILS_FIELDS.phoneNumber, name: this.props.language.offer.phoneNumber },
            { field: CONSTANTS.OFFER_DETAILS_FIELDS.email, name: this.props.language.offer.email },
        ]

        const fieldsOffer = [
            { field: CONSTANTS.OFFER_DETAILS_FIELDS.discount, name: this.props.language.offer.discountPercentage, symbol: '%' },
            { field: CONSTANTS.OFFER_DETAILS_FIELDS.discountEUR, name: this.props.language.offer.discountEUR, symbol: CONSTANTS.EURO_SYMBOL },
            { field: CONSTANTS.OFFER_DETAILS_FIELDS.sellingPriceEUR, name: this.props.language.offer.sellingPriceEUR, symbol: CONSTANTS.EURO_SYMBOL },
            { field: CONSTANTS.OFFER_DETAILS_FIELDS.carProfitEUR, name: this.props.language.offer.carProfitEUR, symbol: CONSTANTS.EURO_SYMBOL },
        ]
        return (
            <>

                <div className={classes.detailsContainer}>
                    <div className={classes.detailsWrapper}>
                        <div className={classes.generalDetails}>
                            <p className={classes.titleText}>{this.props.language.offerDetails}</p>
                            <div className={classes.details}>
                                <div>
                                    {
                                        <>
                                            {fieldsToDisplayInitialColumn.map((field, index) => {
                                                if (field.field === CONSTANTS.STOCK_DETAILS_FIELDS.REGISTRATIONDATE) {
                                                    return (
                                                        <Typography
                                                            key={index}
                                                            className={classes.detailFirstText}
                                                            component="p">{field.name}:</Typography>
                                                    )
                                                }
                                                return (
                                                    <div key={index}>
                                                        <Typography
                                                            key={index}
                                                            className={classes.detailFirstText}
                                                            component="p">{field.name}:</Typography>
                                                    </div>
                                                )
                                            })}


                                            {(this.props.offer.isInWork || this.props.offer.stock.isSold) && <Typography
                                                className={classes.detailFirstText}
                                                style={{ paddingTop: 10 }}
                                                component="p">{this.props.language.offer.status}:
                                            </Typography>}

                                            {fieldsOffer.map((field, index) => {
                                                return (
                                                    <div key={index}>
                                                        <Typography
                                                            key={index}
                                                            className={classes.detailFirstText}
                                                            component="p">{field.name}:</Typography>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    }
                                </div>
                                <div className={classes.detailTextContainer}>
                                    {<>
                                        {fieldsToDisplayInitialColumn.map((field, index) => {
                                            if (field.field === CONSTANTS.OFFER_DETAILS_FIELDS.id) {
                                                return (
                                                    <Typography
                                                        key={index}
                                                        className={classes.detailText}
                                                        component="p">{this.props.offer.id}</Typography>
                                                )
                                            }
                                            if (field.field === CONSTANTS.OFFER_DETAILS_FIELDS.createdAt) {
                                                return (
                                                    <Typography
                                                        key={index}
                                                        className={classes.detailText}
                                                        component="p">{moment(this.props.offer[field.field]).format(CONSTANTS.DEFAULT_DATE_FORMAT)}</Typography>
                                                )
                                            }

                                            if (field.field === CONSTANTS.OFFER_DETAILS_FIELDS.email) {
                                                return (
                                                    <Typography
                                                        key={index}
                                                        className={classes.detailText}
                                                        component="p">{this.props.offer['email'] || '-'}</Typography>
                                                )
                                            }

                                            if (field.field === CONSTANTS.OFFER_DETAILS_FIELDS.phoneNumber) {
                                                return (
                                                    <Typography
                                                        key={index}
                                                        className={classes.detailText}
                                                        component="p">{this.props.offer['phoneNumber'] || '-'}</Typography>
                                                )
                                            }

                                            return (
                                                <div key={index}>
                                                    <Typography
                                                        key={index}
                                                        className={classes.detailText}
                                                        component="p">{typeof (this.props.offer.client[field.field]) == 'number' ?
                                                            `${new Intl.NumberFormat('ro-RO').format(this.props.offer.client[field.field])} ${field.symbol ? field.symbol : ""}` :
                                                            this.props.offer.client[field.field] || '-'}
                                                    </Typography>
                                                </div>
                                            )

                                        })}
                                        {(this.props.offer.isInWork || this.props.offer.stock.isSold) && <Typography
                                            className={classes.detailText}
                                            style={{ color: '#f44336', paddingTop: 10 }}
                                            component="p">{this.props.offer.isInWork && !this.props.offer.stock.isSold ? this.props.language.offer.inWork : this.props.language.offer.isSold}
                                        </Typography>}

                                        {fieldsOffer.map((field, index) => {
                                            if (field.field === 'discount') {
                                                return (
                                                    <div key={index}>
                                                        <Typography
                                                            key={index}
                                                            className={classes.detailText}
                                                            component="p">
                                                            {`${new Intl.NumberFormat('ro-RO', { maximumFractionDigits: 4 }).format(this.props.offer[field.field])} ${field.symbol}`}
                                                        </Typography>
                                                    </div>
                                                )
                                            }
                                            return (
                                                <div key={index}>
                                                    <Typography
                                                        key={index}
                                                        className={classes.detailText}
                                                        component="p">{typeof (this.props.offer[field.field]) == 'number' ?
                                                            `${new Intl.NumberFormat('ro-RO').format(this.props.offer[field.field])} ${field.symbol ? field.symbol : ""}` :
                                                            this.props.offer[field.field] || '-'}
                                                    </Typography>
                                                </div>
                                            )

                                        })}
                                    </>
                                    }
                                </div>
                            </div>
                            <LeasingModal
                                leasingId={this.props.offer.leasingId}
                                sendAnalysis={leasingId => this.sendAnalysis(leasingId)}
                                onClose={() => this.setState({ openLeasingSelector: false })}
                                open={this.state.openLeasingSelector} />
                            <ConfirmationDialog
                                open={this.state.openConfirmationModal}
                                titleText={this.state.confirmationType === CONSTANTS.SELL_OPTION ? "Vinde" : "Analiza leasing"}
                                cancelButtonText={this.props.confirmationDialogLanguage.cancelButtonText}
                                acceptButtonText={this.state.confirmationType === CONSTANTS.SELL_OPTION ? this.props.confirmationDialogLanguage.acceptButtonText : this.state.displaySpinner ?
                                    <><span className={this.props.classes.colorWhite}>{this.props.offerLanguage.sendingAnalysis}</span>&nbsp;<CircularProgress className={`${this.props.classes.spinnerStyle} ${this.props.classes.colorWhite}`} /></> :
                                    this.props.confirmationDialogLanguage.sendButtonText}
                                text={this.state.confirmationType === CONSTANTS.SELL_OPTION ? `${this.props.confirmationDialogLanguage.text}` : this.props.confirmationDialogLanguage.textSend}
                                onCancel={() => this.setState({ openConfirmationModal: false })}
                                onClose={() => this.setState({ openConfirmationModal: false })}
                                onAccept={() => this.state.confirmationType === CONSTANTS.SELL_OPTION ? this.sendToSale() : this.sendAnalysis()}
                            />
                            {!this.state.editType && !this.props.offer.stock.isSold && !this.props.offer.isInWork && roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.IPMG_SELLER]) && !this.props.offer.isCanceled ? <div>
                                <p className={classes.optionsTitleText}>{this.props.language.options}</p>
                                <div>
                                    <Button
                                        onClick={() => this.sendInWork()}
                                        className={classes.optionsButton}
                                        color={"primary"}>{this.props.language.offer.inWork}</Button>
                                    {roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.IPMG_SELLER]) && < Button
                                        onClick={() => this.setState({ renderReservation: true })}
                                        className={classes.optionsButton}
                                        color={"primary"}>{this.props.language.reserve}</Button>}
                                </div>
                            </div> : null}
                        </div>
                    </div>

                    {this.state.renderReservation ? <div className={classes.reservationContainer}>
                        {this.renderReservation(this.props.offer.reservedUntil ? true : false)}

                    </div> : null}
                </div>
            </>)

    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails,
    confirmationDialogLanguage: state.languageReducer.i18n.confirmationDialog,
    offerLanguage: state.languageReducer.i18n.offer,
    loginReducer: state.loginReducer
})

const mapDispatchToProps = dispatch => ({
    reserve: (id, newOffer) => dispatch(OFFERS.reserve(id, newOffer)),
    sendInvoice: (id) => dispatch(OFFERS.sendInvoice(id)),
    sendInWork: offerId => dispatch(OFFERS.sendInWork(offerId)),
    sendToSale: sale => dispatch(SALES.create(sale)),
    sendAnalysis: (offerId, leasingId) => dispatch(OFFERS.sendAnalysis(offerId, leasingId))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OfferDetails))