import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, TableRow, TableCell, Tooltip, CircularProgress } from '@material-ui/core'
import { Assignment as ProformaInvoice, Description as SaleContract } from '@material-ui/icons'
import moment from 'moment'

import InputGenerator from '../common/subComponents/InputGenerator'
import SimpleModal from '../common/SimpleModal'

import * as CONSTANTS from '../../utils/constants'
import * as IN_WORKS from '../../redux/actions/inWorks'
import * as NOTIFICATIONS from '../../utils/notification'

const styles = theme => ({
    inputContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    equalFlex: {
        flex: 1
    },
    inputPadding: {
        padding: 10
    }
})

class InWorkDetails extends Component {


    initialFields = [
        { value: '', type: 'numberFormat', decimalSize: CONSTANTS.EXCHANGE_DECIMAL_SIZE, label: 'Curs Vanzare', name: 'sellingExchangeRate' },
        { type: 'dropdownSelector', name: 'leasingPercentage', utils: 'Procent leasing', value: '', options: CONSTANTS.LEASING_PERCENT },
        { type: 'date', name: 'sellingDate', utils: 'Data vanzare', value: ''},
    ]

    state = {
        open: false,
        currentInWork: null,
        modalFields: this.initialFields,
        renderPage: false
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.open && !this.props.open) {
            this.populate(nextProps.inWork)
        }
    }

    populate = (inWork) => {
        this.props.getById(inWork.id).then(res => {
            let modalFieldsCopy = this.state.modalFields.map(field => ({ ...field }))
            modalFieldsCopy.forEach(field => {
                const currentIndex = modalFieldsCopy.findIndex(index => index.name === field.name)
                if (currentIndex > -1) {
                    modalFieldsCopy[currentIndex].value = res[field.name]
                }
            })

            this.setState({ modalFields: modalFieldsCopy, open: true, renderPage: true })
        })
    }

    InputWrapper = ({ input, keyProps, shrink, onChange, value }) => <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding}`}>
        <InputGenerator
            key={keyProps}
            fullWidth={true}
            InputLabelProps={shrink ? { shrink: true } : {}}
            margin="dense"
            {...input}
            onChange={event => onChange(event)}
            value={value ? value : input.value}
        />
    </div>

    onChangeHandler = event => {
        let fieldsCopy = this.state.modalFields.map(field => ({ ...field }))
        let fieldIndex = fieldsCopy.findIndex(field => field.name === event.target.name)
        if (fieldIndex < 0) return

        fieldsCopy[fieldIndex].value = event.target.value

        this.setState({ modalFields: fieldsCopy })
    }

    renderFields = () => {
        const InputWrapper = this.InputWrapper

        let jsonMap = {}
        this.state.modalFields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <div className={this.props.classes.inputContainer}>
                <InputWrapper input={jsonMap['sellingExchangeRate']} key={'sellingExchangeRate'} onChange={this.onChangeHandler} />
                <InputWrapper input={jsonMap['leasingPercentage']} key={'leasingPercentage'} onChange={this.onChangeHandler} />
            </div>
        )
    }

    onSave = () => {
        let jsonMap = {}
        this.state.modalFields.forEach(field => {
            jsonMap[field.name] = field.value
        })

        this.props.edit(this.props.inWork.id, jsonMap).then(() => {
            NOTIFICATIONS.success(this.props.language.successEdit)
            this.onClose()
        })
            .catch(() => {
                NOTIFICATIONS.error(this.props.language.failEdit)
            })
    }

    onClose = () => {
        this.setState({ open: false }, () => this.props.onClose())
    }

    render() {
        if (this.state.renderPage) {
            return (
                <>
                    <SimpleModal
                        open={this.state.open}
                        onClose={() => this.onClose()}
                        onCancel={() => this.onClose()}
                        onAccept={() => this.onSave()}
                        acceptButtonText={this.props.language.save}
                        cancelButtonText={this.props.language.cancel}
                        title={this.props.language.addDetails}
                        maxWidth={"sm"}
                        canSubmitForm={true}
                    >
                        {this.renderFields()}
                    </SimpleModal>
                </>
            )
        } else return null
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails,
    confirmationDialogLanguage: state.languageReducer.i18n.confirmationDialog,
    offerLanguage: state.languageReducer.i18n.offer
})

const mapDispatchToProps = dispatch => ({
    edit: (id, newInWork) => dispatch(IN_WORKS.edit(id, newInWork)),
    getById: id => dispatch(IN_WORKS.getById(id))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InWorkDetails))