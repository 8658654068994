import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { List, ListItem, ListItemText, ListItemIcon, Collapse, withStyles, Button } from '@material-ui/core'
import { FolderOutlined as FolderOutlinedIcon, ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon, Menu } from '@material-ui/icons'

import * as LOGIN from '../../redux/actions/login'
import { roleInArray } from '../../utils/helpers'

const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing.unit * 2
    },
    truckIcon: {
        fontSize: 21,
        paddingLeft: 4,
        paddingTop: 2,
        color: 'white'
    },
    sidebarHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sidebarHeaderContent: {
        width: '90%',
        height: 80,
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #eee'
    },
    listItemText: {
        fontSize: 16,
        fontWeight: 500,
        color: '#ffffff',
        fontStyle: 'normal',
        fontStretch: 'normal'
    },
    sidebarIcon: {
        color: '#ffffff'
    },
    logoutButton: {
        color: 'white',
        backgroundColor: theme.palette.mainColor,
        position: ' absolute',
        bottom: '10px'
    },
    userAccount: {
        display: 'flex',
        borderBottom: '1px solid white'
    },
    username: {
        fontSize: '18px',
        lineHeight: '47px',
        textTransform: 'capitalize'
    },
    displayNone: {
        display: 'none'
    },
    languageSelector: {
        marginLeft: 'auto'
    },
    listWrapper: {
        maxHeight: '80%',
        overflow: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 8
    },
    listElements: {
        width: '100%',
        fontFamily: 'Roboto',
    },
    headerText: {
        padding: 34,
        fontWeight: 500,
        color: 'white'
    },
    logo: {
        '& img.image': {
            height: '60px'
        },
        '& img.text': {
            height: '30px',
            marginBottom: '15px'
        },
        marginLeft: '15px'
    },
    closeSidebarButton: {
        color: 'white',
        cursor: 'pointer'
    },
    noSidebarContainer: {
        backgroundColor: '#FBFBFA',
        width: 40
    },
    noSidebar: {
        height: 46,
        paddingTop: 24,
        paddingLeft: 14,
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        backgroundColor: 'white'
    },
    openSidebarButton: {
        cursor: 'pointer'
    },
    italic: {
        fontStyle: 'italic'
    },
    selectedText: {
        fontStyle: 'italic',
        color: '#D0D0D0',
        fontWeight: 'bold',
    }
})

class Sidebar extends Component {
    state = {
        selected: {},
        expanded: {},
        displayNone: false
    }

    componentDidMount() {
        this.props.getRoles()
        this.handlePathExpansion()
    }

    handlePathExpansion = () => {
        const currentPathname = this.props.location.pathname

        if (currentPathname.length === 1) {
            this.handleSelect('dashboard')
        }
        else {
            const labels = currentPathname.split('/')
            const currentLabel = labels.pop()
            this.handleSelect(currentLabel)
            labels.forEach(label => {
                this.handleExpand(label)
            })
        }
    }

    handleSelect = value => {
        let selectedCopy = { ...this.state.selected }
        Object.keys(selectedCopy).forEach(element => {
            selectedCopy[element] = false
        })
        selectedCopy[value] = !selectedCopy[value]
        this.setState({ selected: selectedCopy })
    }


    handleExpand = value => {
        let expandCopy = { ...this.state.expanded }
        expandCopy[value] = !expandCopy[value]
        this.setState({ expanded: expandCopy })
    }

    render() {
        const { classes } = this.props
        const ListItemComponent = props => {
            return <ListItem style={{ borderBottom: props.withBorder ? '1px solid rgba(255, 255, 255, 0.3)' : '' }} component={props.to ? Link : 'div'} to={props.to} divider={true} button selected={this.state.selected[props.label]} onClick={() => this.handleSelect(props.label)}>
                <ListItemIcon>
                    {props.icon ? <span className={this.state.selected[props.label] ? this.props.classes.selectedText : ''}>{props.icon}</span> : <FolderOutlinedIcon />}
                </ListItemIcon>
                <ListItemText classes={{ primary: `${classes.listItemText} ${props.extended ? classes.italic : ''} ${this.state.selected[props.label] ? classes.selectedText : ''}` }} inset primary={props.text} />
                {props.nested ? props.open ? <ExpandLessIcon className={classes.sidebarIcon} onClick={() => this.handleExpand(props.label)} /> : <ExpandMoreIcon className={classes.sidebarIcon} onClick={() => this.handleExpand(props.label)} /> : null}
            </ListItem>
        }

        return (
            <>
                <div className={`${this.state.displayNone ? classes.displayNone : ""} sidebar`}>
                    <div className={classes.sidebarHeaderContent}>
                        <Menu className={classes.closeSidebarButton} onClick={() => {
                            this.props.displaySidebarHandler(false)
                            this.setState({ displayNone: true })
                        }} />
                        <div className={classes.logo}>
                            <img src='/logo.svg' className='image' alt="logo" />
                            <img src='/ipmg.svg' className='text' alt='logo' />
                        </div>
                    </div>
                    <List component='nav' className={classes.listWrapper}>
                        <>
                            <div className={classes.listElements}>
                                {this.props.listItems.map(item => {
                                    if (!item.nested) {
                                        return (<div key={item.label}><ListItemComponent
                                            {...item}
                                            text={this.props.language[item.label]}
                                            open={this.state.expanded[item.expandedText]} /></div>)
                                    }
                                    if (item.nested) {
                                        return (<div key={item.label}>
                                            <ListItemComponent {...item} text={this.props.language[item.label][item.label]} nested open={this.state.expanded[item.expandedText]} />
                                            <Collapse
                                                in={this.state.expanded[item.expandedText]}
                                                timeout='auto'
                                                unmountOnExit>
                                                <List
                                                    className={classes.nested}
                                                    component='div'
                                                    disablePadding>
                                                    {item.nestedComponents.map(component => roleInArray(this.props.roles, component.role) ? <ListItemComponent extended={true} key={component.label} {...component} text={this.props.language[item.label][component.label]} /> : null)}
                                                </List>
                                            </Collapse>
                                        </div>)
                                    }
                                    return null
                                })}
                            </div>
                        </>
                    </List>
                    <Button className={classes.logoutButton} onClick={this.props.onClickButton}>{this.props.buttonText}</Button>
                </div>
                {this.state.displayNone &&
                    <div className={classes.noSidebarContainer}>
                        <div className={classes.noSidebar}>
                            <Menu className={classes.openSidebarButton} onClick={() => {
                                this.props.displaySidebarHandler(true)
                                this.setState({ displayNone: false })
                            }} />
                        </div>
                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.sidebar,
    navigationReducer: reducers.navigationReducer,
    isLoggedIn: reducers.loginReducer.isLogged,
    roles: reducers.loginReducer.roles,
    allRoles: reducers.loginReducer.allRoles,
    username: reducers.loginReducer.username
})

const mapDispatchToProps = dispatch => ({
    getRoles: () => dispatch(LOGIN.getAllRoles())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Sidebar)))