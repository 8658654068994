import queryString from 'query-string'
import axios from '../../utils/axios'
import { errorHandler } from '../../utils/apiFunctions'

const baseRoute = '/costs'

export const get = queryParams => dispatch =>
    axios.get(baseRoute + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getById = leasingCostId => dispatch =>
    axios.get(`${baseRoute}/${leasingCostId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const create = cost => dispatch => {
    return axios.post(baseRoute, { cost })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)
}

export const edit = (leasingCostId, leasingCost) => dispatch =>
    axios.put(`${baseRoute}/${leasingCostId}`, { leasingCost })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const del = leasingCostId => dispatch =>
    axios.delete(`${baseRoute}/${leasingCostId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const addFuel = (fuel, costId) => dispatch =>
    axios.post(`${baseRoute}/${costId}/fuel`, { fuel })
        .catch(errorHandler)

export const deleteFuel = (fuelId, costId) => dispatch =>
    axios.delete(`${baseRoute}/${costId}/fuel/${fuelId}`)
        .catch(errorHandler)

export const addRca = (rca, costId) => dispatch =>
    axios.post(`${baseRoute}/${costId}/rca`, { rca })
        .catch(errorHandler)

export const deleteRca = (rcaId, costId) => dispatch =>
    axios.delete(`${baseRoute}/${costId}/rca/${rcaId}`)
        .catch(errorHandler)

export const addTransport = (transport, costId) => dispatch =>
    axios.post(`${baseRoute}/${costId}/transport`, { transport })
        .catch(errorHandler)

export const deleteTransport = (transportId, costId) => dispatch =>
    axios.delete(`${baseRoute}/${costId}/transport/${transportId}`)
        .catch(errorHandler)

export const addTaxation = (taxation, costId) => dispatch =>
    axios.post(`${baseRoute}/${costId}/taxation`, { taxation })
        .catch(errorHandler)

export const deleteTaxation = (taxationId, costId) => dispatch =>
    axios.delete(`${baseRoute}/${costId}/taxation/${taxationId}`)
        .catch(errorHandler)


export const addSubsequentAccessory = (subsequentAccessory, costId) => dispatch =>
    axios.post(`${baseRoute}/${costId}/subsequentAccessory`, { subsequentAccessory })
        .catch(errorHandler)

export const deleteSubsequentAccessory = (subsequentAccessoryId, costId) => dispatch =>
    axios.delete(`${baseRoute}/${costId}/subsequentAccessory/${subsequentAccessoryId}`)
        .catch(errorHandler)


export const addCommission = (commission, costId) => dispatch =>
    axios.post(`${baseRoute}/${costId}/commission`, { commission })
        .catch(errorHandler)

export const deleteCommission = (commissionId, costId) => dispatch =>
    axios.delete(`${baseRoute}/${costId}/commission/${commissionId}`)
        .catch(errorHandler)

export const addCasco = (casco, costId) => dispatch =>
    axios.post(`${baseRoute}/${costId}/casco`, { casco })
        .catch(errorHandler)

export const deleteCasco = (cascoId, costId) => dispatch =>
    axios.delete(`${baseRoute}/${costId}/casco/${cascoId}`)
        .catch(errorHandler)

export const addThirdParty = (thirdParty, costId) => dispatch =>
    axios.post(`${baseRoute}/${costId}/thirdParty`, { thirdParty })
        .catch(errorHandler)

export const deleteThirdParty = (thirdPartyId, costId) => dispatch =>
    axios.delete(`${baseRoute}/${costId}/thirdParty/${thirdPartyId}`)
        .catch(errorHandler)

export const addVigniette = (vignette, costId) => dispatch =>
    axios.post(`${baseRoute}/${costId}/vignette`, { vignette })
        .catch(errorHandler)

export const deleteVignette = (vignetteId, costId) => dispatch =>
    axios.delete(`${baseRoute}/${costId}/vignette/${vignetteId}`)
        .catch(errorHandler)

export const addPdi = (pdi, costId) => dispatch =>
    axios.post(`${baseRoute}/${costId}/pdi`, { pdi })
        .catch(errorHandler)

export const deletePdi = (pdiId, costId) => dispatch =>
    axios.delete(`${baseRoute}/${costId}/pdi/${pdiId}`)
        .catch(errorHandler)

export const addPrt = (prt, costId) => dispatch =>
    axios.post(`${baseRoute}/${costId}/prt`, { prt })
        .catch(errorHandler)

export const deletePrt = (prtId, costId) => dispatch =>
    axios.delete(`${baseRoute}/${costId}/prt/${prtId}`)
        .catch(errorHandler)

export const addRegistration = (registration, costId) => dispatch =>
    axios.post(`${baseRoute}/${costId}/registration`, { registration })
        .catch(errorHandler)

export const deleteRegistration = (registrationId, costId) => dispatch =>
    axios.delete(`${baseRoute}/${costId}/registration/${registrationId}`)
        .catch(errorHandler)