import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, withStyles } from '@material-ui/core'
import { AddCircleOutlineOutlined as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'

import { validations } from '../../../utils/validationUtils'
import SimpleList from '../../common/SimpleList'
import FormModal from '../../common/SimpleFormGenerator'
import ConfirmationDialog from '../../common/ConfirmationDialog'
import * as CONSTANTS from '../../../utils/constants'
import * as CAR from '../../../redux/actions/cars'
import * as NOTIFICATION from '../../../utils/notification'

const styles = theme => ({
    addButton: {
        padding: '8px 20px',
        fontSize: '15px',
        width: '100px',
        marginTop: '15px',
        display: 'flex'
    },
    brandBackground: {
        backgroundColor: 'transparent'
    },
    subtitle: {
        paddingTop: '30px',
        fontSize: '25px',
        paddingBottom: '5px'
    },
    iconStyle: {
        paddingRight: 5
    }
})

class CarModel extends Component {
    initialFields = [
        {
            value: '',
            type: 'text',
            label: this.props.language.form.fields.name,
            name: 'name',
            validation: {
                checks: [validations.notEmpty]
            }, breakline: true
        },
        {

            value: CONSTANTS.CAR_MODEL_OPTIONS.GEARBOX_TYPES.MANUAL,
            type: 'dropdownSelector',
            utils: this.props.language.form.fields.gearbox,
            name: 'gearbox',
            validation: {
                checks: [validations.notEmpty]
            },
            breakline: true,
            options: Object.values(CONSTANTS.CAR_MODEL_OPTIONS.GEARBOX_TYPES).map(option => ({ label: this.props.language.form.options.gearbox[option], name: option }))
        },
        {

            value: CONSTANTS.CAR_MODEL_OPTIONS.TRANSMISSION_TYPES.FRONT,
            type: 'dropdownSelector',
            utils: this.props.language.form.fields.transmission,
            name: 'transmission',
            validation: {
                checks: [validations.notEmpty]
            },
            options: Object.values(CONSTANTS.CAR_MODEL_OPTIONS.TRANSMISSION_TYPES).map(option => ({ label: this.props.language.form.options.transmission[option], name: option }))
        },
        {

            value: CONSTANTS.CAR_MODEL_OPTIONS.FUEL_TYPES.DIESEL,
            type: 'dropdownSelector',
            utils: this.props.language.form.fields.fuel,
            name: 'fuel', validation: {
                checks: [validations.notEmpty]
            },
            breakline: true,
            options: Object.values(CONSTANTS.CAR_MODEL_OPTIONS.FUEL_TYPES).map(option => ({ label: this.props.language.form.options.fuel[option], name: option }))
        },
        {

            value: '',
            type: 'number',
            label: this.props.language.form.fields.emissions,
            name: 'emissions',
            validation: {
                checks: [validations.notEmpty]
            }
        },
        {

            value: CONSTANTS.CAR_MODEL_OPTIONS.POLLUTION_NORMS.EURO6,
            type: 'dropdownSelector',
            label: this.props.language.form.fields.pollutionNorm,
            name: 'pollutionNorm',
            validation: {
                checks: [validations.notZero]
            },
            utils: this.props.language.form.fields.pollutionNorm,
            selected: true,
            options: Object.values(CONSTANTS.CAR_MODEL_OPTIONS.POLLUTION_NORMS).map(option => ({ label: this.props.language.form.options.pollution[option], name: option }))
        },
        {

            value: '',
            type: 'number',
            label: this.props.language.form.fields.cp,
            name: 'cp',
            validation: {
                checks: [validations.notZero]
            },
            breakline: true
        },
        {

            value: '',
            type: 'number',
            label: this.props.language.form.fields.cylinderCapacity,
            name: 'cylinderCapacity',
            validation: {
                checks: [validations.notZero]
            }
        },
        {

            value: '',
            type: 'number',
            label: this.props.language.form.fields.acceleration,
            name: 'acceleration',
            validation: {
                checks: [validations.notZero]
            }
        },
        {

            value: '',
            type: 'number',
            label: this.props.language.form.fields.urbanConsumption,
            name: 'urbanConsumption',
            validation: {
                checks: [validations.notZero]
            },
            breakline: true
        },
        {

            value: '',
            type: 'number',
            label: this.props.language.form.fields.extraUrbanConsumption,
            name: 'extraUrbanConsumption',
            validation: {
                checks: [validations.notEmpty]
            }
        },
        {

            value: '',
            type: 'number',
            label: this.props.language.form.fields.mixtConsumption,
            name: 'mixtConsumption',
            validation: {
                checks: [validations.notZero]
            }
        },
        {

            value: '',
            type: 'number',
            label: this.props.language.form.fields.maximumMass,
            name: 'maximumMass',
            validation: {
                checks: [validations.notZero]
            },
            breakline: true
        }
    ]

    modelToDelete = {}
    brandToEdit = {}

    _deepClone = arr => [...arr].map(el => ({ ...el }))

    state = {
        models: [],
        count: 0,
        from: 0,
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        openModal: false,
        openConfirmationModal: false,
        modalType: CONSTANTS.CREATE,
        modalFields: this._deepClone(this.initialFields)
    }

    componentDidUpdate(prevProps) {
        if (this.props.brandId !== prevProps.brandId && this.props.brandId) {
            this.getModels(this.props.brandId)
        }
    }

    getModels = () =>
        this.props.get(this.props.brandId).then(data => {
            this.setState({
                models: data.carModels.map((brand, index) => ({ index: index + 1, name: brand.name, _id: brand.id })),
                count: data.count,
                openModal: false,
                openConfirmationModal: false
            })
            return Promise.resolve()
        })

    _clearForm = () => {
        this.setState({ modalFields: this._deepClone(this.initialFields) })
    }

    onCreateHandler = fields => {
        let model = {}

        fields.forEach(field => {
            if (field.value && field.value.length) model[field.name] = field.value
        })

        this.props.create(this.props.brandId, model).then(() => {
            this.getModels(this.props.brandId)
            this._clearForm()
            NOTIFICATION.success(this.props.language.create.success)
        }).catch(() => {
            NOTIFICATION.error(this.props.language.create.failure)
        })
    }

    onEditHandler = fields => {
        let model = {}

        fields.forEach(field => {
            if (field.value && field.value.length) model[field.name] = field.value
        })

        this.props.edit(this.modelToEdit._id, model).then(() => {
            this.getModels(this.props.brandId)
            this._clearForm()
            NOTIFICATION.success(this.props.language.edit.success)
        }).catch(() => {
            NOTIFICATION.error(this.props.language.edit.failure)
        })
    }

    onDeleteHandler = () => {
        this.props.delete(this.modelToDelete._id).then(() => {
            this.getModels(this.props.brandId)
            NOTIFICATION.success(this.props.language.delete.success)
        }).catch(() => {
            NOTIFICATION.error(this.props.language.delete.failure)
        })
    }

    toggleEditModal = model => {
        this.modelToEdit = model
        this.props.getById(model._id).then(modelResponse => {
            this.setState({
                modalFields: [...this.state.modalFields].map(field => ({ ...field, value: modelResponse[field.name] })),
                openModal: true,
                modalType: CONSTANTS.EDIT
            })
        }).catch(() => NOTIFICATION.error(this.props.language.get.notFound))
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, () => this.getModels(this.state.brandId))
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getModels(this.state.brandId))
    }

    onChangeHandler = event => {
        let fieldsCopy = this._deepClone(this.state.modalFields)

        let fieldIndex = fieldsCopy.findIndex(field => field.name === event.target.name)

        if (fieldIndex < 0) return

        fieldsCopy[fieldIndex].value = event.target.value

        this.setState({ modalFields: fieldsCopy })
    }

    render() {
        const { classes } = this.props

        return this.props.brandId && <>
            <ConfirmationDialog
                open={this.state.openConfirmationModal}
                cancelButtonText={this.props.language.confirmationDialog.cancelButtonText}
                acceptButtonText={this.props.language.confirmationDialog.acceptButtonText}
                text={`${this.props.language.confirmationDialog.text} ${this.modelToDelete.name}?`}
                onCancel={() => this.setState({ openConfirmationModal: false })}
                onClose={() => this.setState({ openConfirmationModal: false })}
                onAccept={this.onDeleteHandler}
            />
            <FormModal
                canSubmit
                fields={this.state.modalFields}
                key={this.state.openModal}
                open={this.state.openModal}
                onCancel={() => {
                    this._clearForm()
                    this.setState({ openModal: false })
                }}
                onSave={() => this.state.modalType === CONSTANTS.CREATE ? this.onCreateHandler(this.state.modalFields) : this.onEditHandler(this.state.modalFields)}
                dialogTitle={this.state.modalType === CONSTANTS.CREATE ? this.props.language.formModal.add : this.props.language.formModal.title}
                cancelButtonText={this.props.language.confirmationDialog.cancelButtonText}
                submitButtonText={this.state.modalType === CONSTANTS.CREATE ? this.props.language.formModal.add : this.props.language.formModal.edit}
                type={this.state.modalType}
                validate={true}
                maxWidth='sm'
                onChange={this.onChangeHandler}
            />
            <SimpleList
                headerFields={this.props.language.modelHeaderFields}
                selectedOption={CONSTANTS.CAR_OPTIONS.MODEL}
                items={this.state.models}
                tableClass={classes.brandBackground}
                actionsTitle={this.props.language.simpleTable.actionsTitle}
                selectedRow={this.state.selectedRow}
                disableBorders
                actions={[
                    {
                        tooltip: this.props.language.simpleTable.actions.delete,
                        onClick: (_, row) => {
                            this.modelToDelete = row
                            this.setState({ openConfirmationModal: true })
                        },
                        icon: <DeleteIcon />
                    },
                    {
                        tooltip: this.props.language.simpleTable.actions.edit,
                        onClick: (_, row) => this.toggleEditModal(row),
                        icon: <EditIcon />
                    }
                ]}
                onSelect={this.toggleEditModal}
                headerButton={{
                    tooltip: this.props.language.simpleTable.headerButton.text,
                    icon: <Button onClick={() => this.setState({ openModal: true, modalFields: this.initialFields })} className={classes.addButton} variant='contained' color='primary'>
                        <AddIcon className={classes.iconStyle} />
                        {this.props.language.simpleTable.headerButton.buttonText}
                    </Button>,
                    onClick: () => this.setState({ openModal: true, modalType: CONSTANTS.CREATE })
                }}
                rowsPerPage={this.state.rowsPerPage}
                count={this.state.count}
                countTitle={`${this.props.language.models} ${this.props.brandName}`}
                changeRowsPerPage={this.changeRowsPerPageHandler}
                changePageHandler={this.changePageHandler}
            />
        </>
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.car.models
})

const mapDispatchToProps = dispatch => ({
    get: (from, limit, brandId) => dispatch(CAR.model.get(from, limit, brandId)),
    getById: brandId => dispatch(CAR.model.getById(brandId)),
    create: (brandId, model) => dispatch(CAR.model.create(brandId, model)),
    delete: modelId => dispatch(CAR.model.del(modelId)),
    edit: (modelId, model) => dispatch(CAR.model.edit(modelId, model))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CarModel))