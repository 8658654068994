import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Paper, Divider, ListItem, ListItemText, Typography, ClickAwayListener, withStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

import * as ALERT from '../../redux/actions/alerts'
import { DEFAULT_DATE_FORMAT } from '../../utils/constants'
import AlertsButton from './subComponents/AlertsButton'

const styles = theme => ({
    notificationsContainer: {
        borderBottom: '1px solid #eaedf3',
        position: 'absolute',
        zIndex: '200',
        backgroundColor: '#ffffff',
        maxHeight: '400px',
        height: 'auto',
        overflow: 'auto',
        padding: '10px',
        width: '350px',
        marginLeft: '-250px'
    },
    notificationItem: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#efefef'
        }
    },
    divider: {
        width: '100%',
        '&:nth-of-type(1)': {
            display: 'none'
        }
    },
})

class Alerts extends Component {

    state = {
        renderAlerts: false,
        active: false,
        count: 0
    }

    componentDidMount() {
        this.props.count().then(countResponse => {
            if (!countResponse.count)
                return this.setState({
                    active: false,
                    renderAlerts: true
                })
            this.setState({
                renderAlerts: true,
                active: true,
                count: countResponse.count
            })
        })
    }

    AlertItem = props => {
        const { classes, language } = this.props
        return this.state.renderAlerts ? <React.Fragment key={props.stockId}>
            <Divider className={classes.divider} component="div" />
            <ListItem className={classes.notificationItem} component={Link} to={`/stock/${props.stockId}`} alignItems="flex-start">
                <ListItemText
                    primary={props.title}
                    secondary={
                        <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                        >
                            <b>{props.brandName} - {props.modelName}</b> {`- ${language.alerts.expiresIn} ${moment(props.expirationDate).format(DEFAULT_DATE_FORMAT)}`}
                        </Typography>
                    }
                />
            </ListItem>
        </React.Fragment> : null
    }

    closeAlerts = () => {
        if (this.props.alerts.isOpen)
            this.props.closeAlerts()
    }

    renderAlerts = () => {
        const { language, alerts } = this.props

        return alerts.alerts.alerts.map(alert => {
            if (alert.type === 'RCA') return this.AlertItem({ ...alert, title: language.alerts.rcaTitle })
            if (alert.type === 'CASCO') return this.AlertItem({ ...alert, title: language.alerts.cascoTitle })
            return this.AlertItem({ ...alert, title: language.alerts.vignetteTitle })
        })
    }

    render() {
        let { isOpen } = this.props.alerts
        let { classes } = this.props
        return (
            <>
                <ClickAwayListener onClickAway={() => this.closeAlerts()}>
                    <div className={classes.container}>
                        <AlertsButton active={this.state.active} count={this.state.count} />
                        {isOpen && this.state.count ? <Paper className={classes.notificationsContainer}>
                            {this.renderAlerts()}
                        </Paper> : null}
                    </div>
                </ClickAwayListener>
            </>
        )
    }
}

const mapStateToProps = ({ alerts, languageReducer }) => ({
    alerts,
    language: languageReducer.i18n
})

const mapDispatchToProps = dispatch => {
    return {
        count: () => dispatch(ALERT.count()),
        toggleAlerts: () => dispatch(ALERT.toggle()),
        closeAlerts: () => dispatch(ALERT.close())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Alerts))