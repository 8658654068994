import axios from '../../utils/axios'
import queryString from 'query-string'
import { errorHandler } from '../../utils/apiFunctions'
import * as TYPES from '../actionTypes'

const baseRoute = '/users'

export const get = queryParams => dispatch =>
    axios.get(baseRoute + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getById = userId => dispatch =>
    axios.get(`${baseRoute}/${userId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const create = user => dispatch =>
    axios.post(baseRoute, { user })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const edit = (userId, user) => dispatch =>
    axios.put(`${baseRoute}/${userId}`, { user })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getMe = () => dispatch =>
    axios.get(`${baseRoute}/me`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const updateMe = user => dispatch =>
    axios.put(`${baseRoute}/me`, { user })
        .then(response => {
            if (user.username)
                dispatch({
                    type: TYPES.CHANGE_USERNAME,
                    payload: user.username
                })
            return Promise.resolve(response.data)
        })
        .catch(errorHandler)

export const del = userId => dispatch =>
    axios.delete(`${baseRoute}/${userId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)