import axios from '../../utils/axios'
import * as TYPES from '../actionTypes'

export const login = (username, password) => dispatch => axios.post('/login', {
    username,
    password
}).then(response => {
    dispatch({
        type: TYPES.IS_LOGGED,
        payload: {
            roles: response.data.roles,
            username: response.data.username,
            providerId: response.data.providerId,
            userId: response.data.userId,
            isIPMG: response.data.isIPMG
        }
    })
    return Promise.resolve(response);
})

export const isLogged = () => dispatch => axios.get('/logged')
    .then(response => {
        dispatch({
            type: TYPES.IS_LOGGED,
            payload: {
                roles: response.data.roles,
                username: response.data.username,
                providerId: response.data.providerId,
                userId: response.data.userId,
                isIPMG: response.data.isIPMG
            }
        })
        return Promise.resolve()
    }).catch(err => {
        dispatch({
            type: TYPES.IS_NOT_LOGGED
        })
    })

export const getAllRoles = () => dispatch => axios.get('/roles')
    .then(response => {
        dispatch({
            type: TYPES.GET_ROLES,
            payload: {
                allRoles: response.data.roles
            }
        })
        return Promise.resolve(response.data.roles)
    })

export const logout = () => dispatch => axios.post('/logout')
    .then(response => {
        dispatch({
            type: TYPES.IS_NOT_LOGGED
        })
        return Promise.resolve(response)
    })