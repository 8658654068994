import React from 'react'
import { Button } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

const noOptions = props => (
	<Button onClick={() => this.openFormModalHandler()} className={props.classes} variant='contained' color='primary'>
		<AddIcon style={{ marginRight: 'auto' }} />
		{props.text}
	</Button>
)

export default noOptions