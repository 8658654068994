import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogActions, DialogTitle, Button, CircularProgress, Tooltip, withStyles } from '@material-ui/core'
import { Close, Info } from '@material-ui/icons'

const style = theme => ({
    dialogTitle: {
        borderBottom: '1px solid #eaedf3',
        padding: '4px 0px 8px 22px'
    },
    dialogActions: {
        borderTop: '1px solid #eaedf3',
        marginTop: 10,
        paddingTop: 25
    },
    acceptButton: {
    },
    cancelButton: {
        marginRight: 'auto',
        marginLeft: 4
    },
    closeStyle: {
        marginRight: 'auto'
    },
    topRightCancelButton: {
        float: 'right',
        margin: '5px 15px 0px 0px',
        cursor: 'pointer',
        fontSize: 18
    },
    whiteColor: {
        color: 'white'
    },
    spinnerStyle: {
        color: 'white',
        height: '25px !important',
        width: '25px !important'
    },
    actionOnTitleStyle: {
        cursor: 'pointer'
    },
    infoIcon: {
        fontSize: 25
    },
    infoIconContainer: {
        padding: 9,
        position: 'relative',
        top: 4,
        cursor: 'pointer'
    },
    noTooltipStyle: {
        display: 'none'
    },
    tooltipStyle: {
        fontSize: 15
    }
})

class SimpleModal extends Component {

    render() {
        return (
            <Dialog
                open={this.props.open}
                fullWidth={true}
                fullScreen={this.props.fullScreen || false}
                maxWidth={this.props.maxWidth || 'md'}
                onClose={this.props.onClose}
            >
                <DialogTitle
                    className={this.props.styles && this.props.styles.title ? this.props.styles.title : this.props.classes.dialogTitle}>
                    {<span className={this.props.actionOnTitle ? this.props.classes.actionOnTitleStyle : ''} onClick={this.props.actionOnTitle ? () => this.props.actionOnTitle() : () => { }}>{this.props.title}</span>}
                    {this.props.actionOnTitle &&
                        <Tooltip title={'Detalii stock'} placement={'right-start'} classes={{
                            tooltip: !this.props.actionOnTitle ? this.props.classes.noTooltipStyle : this.props.classes.tooltipStyle
                        }}>
                            <span onClick={() => this.props.actionOnTitle()} className={this.props.classes.infoIconContainer}><Info className={this.props.classes.infoIcon} /></span>
                        </Tooltip>
                    }
                    {!this.props.withCancelButton ? <Close onClick={this.props.onCancel} className={this.props.classes.topRightCancelButton} /> : null}
                </DialogTitle>
                <DialogContent className={this.props.styles ? this.props.styles.contentStyle : null}>
                    {this.props.children}
                </DialogContent>
                <DialogActions className={this.props.classes.dialogActions}>
                    {this.props.editButtonText && <Button onClick={() => this.props.onEdit()} color="secondary">
                        {this.props.editButtonText}
                    </Button>}
                    {this.props.cancelButtonText && <Button className={this.props.styles && this.props.styles.closeStyle ? this.props.styles.closeStyle : !this.props.deleteButtonText ? this.props.classes.closeStyle : ''} onClick={() => this.props.onCancel()} color="secondary">
                        {this.props.cancelButtonText}
                    </Button>}
                    {this.props.utilsButton ? this.props.utilsButton() : null}
                    {this.props.deleteButtonText && <Button className={this.props.styles ? this.props.classes.cancelStyle : this.props.classes.cancelButton} onClick={() => this.props.onDelete()} color="secondary">
                        {this.props.deleteButtonText}
                    </Button>}
                    {this.props.acceptButtonText && <Button id='acceptButton' disabled={this.props.spinner} className={this.props.styles && this.props.styles.acceptStyle ? this.props.styles.acceptStyle : this.props.classes.acceptButton} onClick={() => this.props.onAccept()} color="primary">
                        {this.props.spinner ? <><span className={this.props.classes.whiteColor}>{this.props.spinnerText ? this.props.spinnerText : ''}</span>&nbsp;<CircularProgress className={this.props.classes.spinnerStyle} /></> : this.props.acceptButtonText}
                    </Button>}
                </DialogActions>
            </Dialog >
        )
    }
}

SimpleModal.propTypes = {
    open: PropTypes.bool.isRequired,
    cancelButtonText: PropTypes.string,
    acceptButtonText: PropTypes.string,
    onAccept: PropTypes.func,
    onClose: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    size: PropTypes.string
}

export default withStyles(style)(SimpleModal)