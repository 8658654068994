import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'

import SearchableHeader from '../../common/SearchableHeader'
import SimpleList from '../../common/SimpleList'

import * as CONSTANTS from '../../../utils/constants'
import * as TRACE from '../../../redux/actions/trace'

const styles = theme => ({
    listContainer: {
        padding: 27,
        overflow: 'auto'
    },
    withoutSidebar: {
        padding: '24px 36px 24px 0px'
    }
})

class Trace extends Component {

    state = {
        count: 0,
        from: 0,
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        trace: [],
        search: ""
    }

    componentDidMount() {
        this.getTrace()
    }

    onSearch = search => {
        this.setState({ search }, () => this.getTrace())
    }

    getTrace = () => {
        this.props.getTrace({
            from: this.state.from,
            limit: this.state.rowsPerPage,
            search: this.state.search
        }).then(result => {
            this.setState({
                trace: result.traces,
                count: result.count
            })
        })
    }

    openStockDetails = stockId => {
        this.props.history.push(`/stock/${stockId}`)
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, () => this.getTrace())
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getTrace())
    }

    render() {

        return (
            <>
                <SearchableHeader
                    placeholder={this.props.languageReducer.searchPlaceholder}
                    title={this.props.languageReducer.simpleTable.title}
                    onSearch={this.onSearch}
                />
                 <div className={`${!this.props.sidebarReducer.displaySidebar ? this.props.classes.withoutSidebar : ""} ${this.props.classes.listContainer}`}>
                    <SimpleList
                        onSelect={stockId => this.openStockDetails(stockId)}
                        noHeader
                        selectedOption={CONSTANTS.TRACE_OPTION}
                        headerFields={this.props.languageReducer.simpleListHeaders}
                        items={this.state.trace}
                        disableBorders
                        rowsPerPage={this.state.rowsPerPage}
                        count={this.state.count}
                        changeRowsPerPage={this.changeRowsPerPageHandler}
                        changePageHandler={this.changePageHandler}
                    />
                </div>
            </>
        )
    }
}

const mapStateToProps = reducers => ({
    languageReducer: reducers.languageReducer.i18n.trace,
    sidebarReducer: reducers.sidebarReducer
})

const mapDispatchToProps = dispatch => ({
    getTrace: queryParams => dispatch(TRACE.get(queryParams)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Trace))