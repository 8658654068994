import queryString from 'query-string'
import axios from '../../utils/axios'
import { errorHandler } from '../../utils/apiFunctions'

const baseRoute = '/trace'

export const get = queryParams => dispatch =>
    axios.get(baseRoute + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)
