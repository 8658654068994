import React from 'react'
import { withStyles } from '@material-ui/core'
import { ArrowLeftOutlined as ArrowLeftOutlinedIcon, ArrowRightOutlined as ArrowRightOutlinedIcon, UnfoldMore as UnfoldMoreIcon } from '@material-ui/icons'

const styles = theme => ({
    container: {
        width: 140,
        height: 40,
        borderRadius: 4,
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.08)',
        border: 'solid 1px #d8dce6',
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        backgroundColor: 'white'
    },
    arrowContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: '100%',
        color: '#9ea0a5'
    },
    pageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 60,
        height: '100%',
        borderRight: 'solid 1px #d8dce6',
        borderLeft: 'solid 1px #d8dce6',
        flexDirection: 'row'
    },
    unfoldMore: {
        color: '#9ea0a5'
    }
})

let switchPage = props => (
    <div className={props.classes.container}>
        <div className={props.classes.arrowContainer} onClick={props.onLeftArrowClicked}>
            <ArrowLeftOutlinedIcon />
        </div>
        <div className={props.classes.pageContainer}>
            {props.pageNumber}
            {props.withMore ? <UnfoldMoreIcon className={props.classes.unfoldMore} /> : null}
        </div>
        <div className={props.classes.arrowContainer} onClick={props.onRightArrowClicked}>
            <ArrowRightOutlinedIcon />
        </div>
    </div>
)

export default withStyles(styles)(switchPage)