import axios from '../../utils/axios'
import { errorHandler } from '../../utils/apiFunctions'
import queryString from 'query-string'

const baseRoute = '/offers'
const inWorkRoute = '/inWorks'


export const getStocks = queryParams => dispatch => {
    return axios.get(baseRoute + '/stocks' + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)
}

export const getByStockId = (stockId, queryParams) => dispatch => {
    return axios.get(baseRoute + `/stocks/${stockId}` + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)
}

export const get = queryParams => dispatch =>
    axios.get(baseRoute + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getById = offerId => dispatch =>
    axios.get(`${baseRoute}/${offerId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const create = offer => dispatch =>
    axios.post(baseRoute, { offer })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const sendAnalysis = (offerId, leasingId) => dispatch =>
    axios.post(`${baseRoute}/${offerId}/analyzeLeasing`, { leasingId })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const edit = (offerId, offer) => dispatch =>
    axios.put(`${baseRoute}/${offerId}`, { offer })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const reserve = (offerId, offer) => dispatch =>
    axios.put(`${baseRoute}/${offerId}/reserve`, { offer })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const del = offerId => dispatch =>
    axios.delete(`${baseRoute}/${offerId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)


// In work
export const checkVerification = (inWorkId, verifications) => dispatch =>
    axios.patch(`${inWorkRoute}/${inWorkId}/verifications`, { verifications })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const sendInWork = offerId => dispatch =>
    axios.post(`${inWorkRoute}`, { inWork: { offerId } })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getInWork = queryParams => dispatch =>
    axios.get(inWorkRoute + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const sendPVPP = (inWorkId) => dispatch =>
    axios.post(`${inWorkRoute}/${inWorkId}/pvpp/sendEmail`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getInWorkById = inWorkId => dispatch =>
    axios.get(`${inWorkRoute}/${inWorkId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const generateSaleContract = (inWorkId, inWork) => dispatch =>
    axios.put(`${inWorkRoute}/${inWorkId}/saleContract`, { inWork })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const deleteInWork = (inWorkId, inWork) => dispatch =>
    axios.delete(`${inWorkRoute}/${inWorkId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)


export const sendInvoice = offerId => dispatch =>
    axios.get(`${baseRoute}/${offerId}/sendInvoice`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)


// Files
export const getFiles = offerId => dispatch =>
    axios.get(`${baseRoute}/${offerId}/files`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const uploadFile = (offerId, formData) => dispatch =>
    axios.post(`${baseRoute}/${offerId}/files`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const deleteFile = (offerId, fileId) => dispatch =>
    axios.delete(`${baseRoute}/${offerId}/files/${fileId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)
