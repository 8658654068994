import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as TEST_DRIVE from '../../../redux/actions/stocks'
import * as CONSTANTS from '../../../utils/constants'
import SimpleList from '../../common/SimpleList'


class TestDriveList extends Component {
    state = {
        testDrives: [],
        count: 0,
        rentsRowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        from: 0
    }

    componentDidMount() {
        this.getTestDrives()
    }

    getTestDrives = () => {
        this.props.get(this.props.stockId, { from: this.state.from, limit: this.state.rowsPerPage })
            .then(result => {
                this.setState({ testDrives: result.rows, count: result.count })
            })
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getTestDrives())
    }

    render() {
        return <SimpleList
            noHeader
            headerFields={this.props.language.simpleTable.headerTestDrive}
            items={this.state.testDrives}
            disableBorders
            onSelect={() => { }}
            selectedOption={CONSTANTS.TEST_DRIVE_OPTION}
            rowsPerPage={this.state.rentsRowsPerPage}
            count={this.state.count}
            changePageHandler={this.changePageHandler} />
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.stock,
    headerFields: state.languageReducer.i18n.itemDetails.offer.offerTabHeaderFields
})

const mapDispatchToProps = dispatch => ({
    get: (stockId, options) => dispatch(TEST_DRIVE.getTestDriveByStockId(stockId, options))
})

export default connect(mapStateToProps, mapDispatchToProps)(TestDriveList)