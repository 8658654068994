import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

export default function SimpleMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event, value) => {
        setAnchorEl(event.currentTarget)
        if (value)
            props.onClick(value)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <Button disabled={props.disabled} aria-controls="simple-menu" aria-haspopup="true" color='secondary' style={props.style ? props.style : {}} onClick={handleClick}>
                {props.text}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {props.options.map((option, index) => <MenuItem key={index} onClick={event => handleClick(event, option.value)}>{option.label}</MenuItem>)}
            </Menu>
        </div>
    )
}