import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Tooltip, Button, withStyles, Typography } from '@material-ui/core'
import { TurnedIn as TurnedIcon } from '@material-ui/icons'
import { validations, isValidField } from '../../utils/validationUtils'
import { setStatusHandler, roleInArray } from '../../utils/helpers'
import SimpleModal from '../common/SimpleModal'
import MiniGallery from '../common/MiniGallery'

import * as STOCKS from '../../redux/actions/stocks'
import * as CLIENT from '../../redux/actions/clients'
//import * as OFFER from '../../redux/actions/offers'
import * as CARS from '../../redux/actions/cars'
import * as PROVIDERS from '../../redux/actions/providers'
import * as NOTIFICATION from '../../utils/notification'
import * as CONSTANTS from '../../utils/constants'
import InputGenerator from '../common/subComponents/InputGenerator'
import Optionals from './Optionals/Optionals'

const styles = theme => ({
    modalContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        color: 'black',
        paddingTop: 18
    },
    bottomContainer: {
        flex: 4,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    clientContainer: {
        flex: 4,
        padding: '2px 0px 2px 18px',
    },
    carOptionsContainer: {
        flex: 1,
        padding: '2px 18px'
    },
    stockDetailsContainer: {
        flex: 1,
        padding: '20px 9px 20px 9px',
        paddingTop: 2
    },
    stockContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 12,
    },
    equalFlex: {
        flex: 1
    },
    inputPadding: {
        paddingRight: 10
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    codeInput: {
        flex: '1 !important'
    },
    valueInput: {
        flex: '3 !important'
    },
    customText: {
        color: 'rgba(0,0,0,0.54)',
        fontWeight: 500,
        fontSize: 14
    },
    noMargins: {
        margin: 0,
        padding: 0
    },
    noInline: {
        display: "contents"
    },
    statusSelector: {
        width: 100
    },
    uploadLabel: {
        textAlign: 'center'
    },
    displayNone: {
        display: 'none'
    },
    addPhotoButton: {
        marginTop: 8
    },
    noPhotosText: {
        fontSize: 12,
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.54)',
        textTransform: 'uppercase'
    },
    uploadButtonFull: {
        width: '100%'
    },
    imgWrapper: {
        width: '100%'
    },
    photosText: {
        fontSize: '12px',
        marginLeft: '-8px',
        color: 'rgba(0, 0, 0, 0.54)',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        textTransform: 'uppercase'
    }
})

class StockModal extends Component {

    todayValue = moment().format(CONSTANTS.INPUT_TYPE_DATE_FORMAT)
    createdStock = {}
    stockToEdit = {}

    tagsWithLabels = CONSTANTS.STOCK_TAGS.map(tag => ({ ...tag, label: this.props.language.itemDetails.stock.tagsLabel[tag.name] }))
    statusWithLabels = CONSTANTS.STOCK_STATUS.map(status => ({ ...status, disabled: false, label: this.props.language.itemDetails.stock.statusLabel[status.name] }))
    typeWithLabels = CONSTANTS.STOCK_TYPE.map(type => ({ ...type, label: this.props.language.itemDetails.stock.typeLabel[type.name] }))
    producedMonthWithLabels = CONSTANTS.STOCK_MONTHS.map(month => ({ ...month, label: this.props.language.itemDetails.stock.monthLabel[month.name] }))
    subsequentAccessoriesTypeWithLabel = CONSTANTS.SUBSEQ_ACC_TYPE.map(sub => ({ ...sub, label: this.props.language.itemDetails.stock.subsequentAccessoriesTypeLabel[sub.name] }))

    initialFields = [
        {
            value: '', type: 'dropdownSelector', utils: this.props.language.itemDetails.stock.provider, name: 'providerId',
            options: [],
            validation: { checks: [validations.notEmpty] }
        },
        {
            value: '', type: 'dropdownSelector', utils: this.props.language.itemDetails.stock.brand, name: 'carBrandId',
            options: [],
            validation: { checks: [validations.notEmpty] }
        },
        {
            value: '', type: 'dropdownSelector', utils: this.props.language.itemDetails.stock.model, name: 'carModelId',
            options: [],
            validation: { checks: [validations.notEmpty] },
        },
        { type: 'multiselector', name: 'stockStatus', utils: this.props.language.itemDetails.stock.status, value: [...this.statusWithLabels].map(field => ({ ...field })).filter(field => field.name === 'ipmgStock' || field.name === 'orderManufacturing') },
        { value: '', type: 'text', label: this.props.language.itemDetails.stock.vinNumber, name: 'vinNumber' },
        { value: '', type: 'number', label: this.props.language.itemDetails.stock.manufactureYear, name: 'manufactureYear', validation: {} },
        { value: '', type: 'text', label: this.props.language.itemDetails.stock.orderNumber, name: 'orderNumber', validation: {} },
        { value: '', type: 'text', label: this.props.language.itemDetails.stock.interiorColor, name: 'interiorColor', validation: {} },
        { value: '', type: 'text', label: this.props.language.itemDetails.stock.exteriorColor, name: 'exteriorColor', validation: {} },
        { value: '', type: 'text', label: this.props.language.itemDetails.stock.registrationNumber, name: 'registrationNumber', validation: {} },
        { value: '', type: 'text', label: this.props.language.itemDetails.stock.contractNumber, name: 'contractNumber', validation: {} },
        { type: 'dropdownSelector', name: 'type', utils: this.props.language.itemDetails.stock.type, value: CONSTANTS.STOCK_TYPE[0].name, options: this.typeWithLabels },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.stock.price, name: 'listPrice', validation: {} },
        { value: '', type: 'numberFormat', decimalSize: CONSTANTS.EXCHANGE_DECIMAL_SIZE, label: this.props.language.itemDetails.stock.discountPercent, name: 'discountPercent', validation: {} },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.stock.accessories, name: 'accessoriesPrice', validation: {} },
        { value: '', type: 'numberFormat', decimalSize: CONSTANTS.EXCHANGE_DECIMAL_SIZE, label: this.props.language.itemDetails.stock.purchaseExchangeRate, name: 'purchaseExchangeRate', validation: {} },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.stock.km, name: 'mileage', validation: {} },
        { value: '', type: 'numberFormat', label: this.props.language.itemDetails.stock.PDI, name: 'PDI', validation: {} },
        { value: '', type: 'numberFormat', decimalSize: CONSTANTS.EXCHANGE_DECIMAL_SIZE, label: this.props.language.itemDetails.stock.addition, name: 'addition', validation: {} },
        { value: '', type: 'numberFormat', disabled: true, label: this.props.language.itemDetails.stock.discountEUR, name: 'discountEUR', endAdornment: <Tooltip title='Formula'><TurnedIcon /></Tooltip> },
        { value: '', type: 'numberFormat', disabled: true, label: this.props.language.itemDetails.stock.purchasePrice, name: 'purchasePrice', endAdornment: <Tooltip title='Formula'><TurnedIcon /></Tooltip> },
        { value: null, type: 'date', label: this.props.language.itemDetails.stock.prtExpirationDate, name: 'prtExpirationDate', validation: {} },
        { value: null, type: 'date', label: this.props.language.itemDetails.stock.deliveryDate, name: 'IPMGDeliveryDate', validation: {} },
        { type: 'dropdownSelector', name: 'productionMonth', utils: this.props.language.itemDetails.stock.productionMonth, value: CONSTANTS.STOCK_MONTHS[0].name, options: this.producedMonthWithLabels },
        { value: null, type: 'date', label: this.props.language.itemDetails.stock.registrationProductionDate, name: 'registrationProductionDate', validation: {} },
        { value: null, type: 'date', label: this.props.language.itemDetails.stock.contractDate, name: 'contractDate', validation: {} },
        { value: null, type: 'date', label: this.props.language.itemDetails.stock.retention, name: 'retentionDate', validation: {} },
        { type: 'radioSelector', name: 'hasFinancing', utils: this.props.language.itemDetails.stock.financing, value: CONSTANTS.NO, options: CONSTANTS.STOCK_NEED_FINANCING },
        { value: '', type: 'text', label: this.props.language.itemDetails.stock.comment, name: 'comment' },
        { value: '', type: 'text', label: this.props.language.itemDetails.stock.externalURL, name: 'externalURL' },
    ]


    initialTagsFields = [
        { type: 'multiselector', name: 'tags', utils: this.props.language.itemDetails.stock.tags, value: this.tagsWithLabels },
    ]

    state = {
        openModal: false,
        isOrderManufacturing: false,
        isIPMG: this.props.login.isIPMG,
        sa3ToUpload: null,
        stock: null,
        stockPhotos: [],
        renderPage: false,
        filesToUpload: null,
        tagFields: this.initialTagsFields,
        modalFields: [...this.initialFields.map(field => ({ ...field, error: false }))],
        optionalsList: ''
    }

    componentDidMount() {
        this.setState({
            renderPage: true,
            openModal: this.props.open
        })
    }

    validate = () => {
        let newFields = [...this.state.modalFields]
        let isValid = true

        let tagValuesMap = {}

        let tagsIndex = this.state.tagFields.findIndex(element => element.name === 'tags')
        if (tagsIndex > -1) {
            this.state.tagFields[tagsIndex].value.forEach(value => {
                tagValuesMap[value.name] = value.value
            })
        }

        let newFieldsMap = {}

        newFields.forEach((field, index) => {
            if (!isValidField(field)) {
                isValid = false
                field.error = true
            } else {
                field.error = false
            }
            newFieldsMap[field.name] = field
        })

        // Check if order manufacturing status is selected
        const stockStatusValues = newFieldsMap['stockStatus'].value
        let isOrderManufacturing = false
        const orderManufacturingIndex = stockStatusValues.findIndex(index => index.name === 'orderManufacturing')
        if (orderManufacturingIndex > -1) {
            if (stockStatusValues[orderManufacturingIndex].value) isOrderManufacturing = true
        }


        //check for contract nr and contract id to be completed when contracted tag is selected
        if (Object.keys(tagValuesMap).length) {
            if (tagValuesMap['contracted']) {
                let contractDateValue = newFieldsMap['contractDate'].value
                let contractNumberValue = newFieldsMap['contractNumber'].value
                if (!contractDateValue || contractDateValue === CONSTANTS.INVALID_DATE_INPUT_ERROR) {
                    newFieldsMap['contractDate'].error = true
                    isValid = false
                }

                if (!contractNumberValue || !contractNumberValue.length) {
                    newFieldsMap['contractNumber'].error = true
                    isValid = false
                }
            }
        }

        //check for PRF ( ITP ) when registration date exists 
        let stockType = newFieldsMap['type'].value
        let registrationProductionDateValue = newFieldsMap['registrationProductionDate'].value
        let prtExpirationDateValue = newFieldsMap['prtExpirationDate'].value
        if (stockType !== 'external' && registrationProductionDateValue && (!prtExpirationDateValue || prtExpirationDateValue === CONSTANTS.INVALID_DATE_INPUT_ERROR)) {
            newFieldsMap['prtExpirationDate'].error = true
            isValid = false
        }

        //check for registration date and prt date when you have registrationNumber
        if (newFieldsMap['registrationNumber'].value) {
            if (!isOrderManufacturing) {
                if (!registrationProductionDateValue || registrationProductionDateValue === CONSTANTS.INVALID_DATE_INPUT_ERROR) {
                    newFieldsMap['registrationProductionDate'].error = true
                    isValid = false
                }
            }
            if (!prtExpirationDateValue || prtExpirationDateValue === CONSTANTS.INVALID_DATE_INPUT_ERROR) {
                newFieldsMap['prtExpirationDate'].error = true
                isValid = false
            }
        }

        let retentionDate = newFieldsMap['retentionDate'].value
        let registrationProductionDate = newFieldsMap['registrationProductionDate'].value
        if (stockType === 'fleet' && !(!registrationProductionDate || registrationProductionDate === CONSTANTS.INVALID_DATE_INPUT_ERROR)) {
            if (!retentionDate || retentionDate === CONSTANTS.INVALID_DATE_INPUT_ERROR) {
                newFieldsMap['retentionDate'].error = true
                isValid = false
            }
        }

        let contractDate = newFieldsMap['contractDate'].value
        //check for contract date if contract number
        if (newFieldsMap['contractNumber'].value) {
            if (!contractDate || contractDate === CONSTANTS.INVALID_DATE_INPUT_ERROR) {
                newFieldsMap['contractDate'].error = true
                isValid = false
            }
        }

        // Check for order number if external type. It can be created without it.
        if (newFieldsMap['orderNumber'].value === "" && stockType !== 'external') {
            newFieldsMap['orderNumber'].error = true
            isValid = false
        }

        if (newFieldsMap['vinNumber'].value.length && newFieldsMap['vinNumber'].value.length !== CONSTANTS.VIN_NUMBER_SIZE && newFieldsMap['vinNumber'].value !== CONSTANTS.COMMAND) {
            newFieldsMap['vinNumber'].error = true
            isValid = false
        }


        this.setState({ modalFields: [...Object.values(newFieldsMap)] })
        return isValid
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.modalType === CONSTANTS.EDIT && this.props.modalType === CONSTANTS.CREATE) {
            this.toggleEditModal(nextProps.stockIdToEdit)
        } else
            if (nextProps.modalType === CONSTANTS.CREATE && this.props.modalType === CONSTANTS.EDIT) {
                this.setState({
                    openModal: false
                })
            } else
                if (!nextProps.open && nextProps.modalType === CONSTANTS.CREATE) {
                    this.setState({
                        openModal: false
                    })
                }
                else
                    if (nextProps.open && !this.state.openModal && nextProps.modalType === CONSTANTS.CREATE) {
                        this.openFormModalHandler()
                    }
    }

    toggleEditModal = stockToEdit => {
        this.stockToEdit = stockToEdit
        this.props.getById(stockToEdit.id, true).then(response => {
            // if (response.blockedBy && response.blockedBy.id !== this.props.login.userId) {
            //     this.onCloseHandler()
            //     return NOTIFICATION.error(`${this.props.language.stock.blocked} ${response.blockedBy.username}`)
            // }

            this.selectedCarBrandId = response.carBrand.id

            let tagsCopy = [...this.state.tagFields].map(field => ({ ...field }))
            let modalFieldsCopy = [...this.state.modalFields].map(field => {
                if (field.type === 'number') return ({
                    ...field,
                    value: response[field.name]
                })
                if (field.type === 'numberFormat') return ({
                    ...field,
                    value: response[field.name] || ''
                })
                if (field.type === 'date') {
                    if (response['type'] !== 'fleet' && field.name === 'retentionDate') {
                        return ({
                            ...field,
                            disabled: true,
                            value: response[field.name] ? moment(response[field.name]).format(CONSTANTS.INPUT_TYPE_DATE_FORMAT) : null
                        })
                    }

                    return ({
                        ...field,
                        value: response[field.name] ? moment(response[field.name]).format(CONSTANTS.INPUT_TYPE_DATE_FORMAT) : null
                    })
                }
                if (field.name === 'stockStatus') return ({ //because stock is overwriten below
                    ...field
                })
                if (field.type === 'radioSelector') {
                    if (field.name === CONSTANTS.HAS_FINANCING) {
                        return ({
                            ...field,
                            value: response[field.name] ? CONSTANTS.YES : CONSTANTS.NO,
                            touched: true
                        })
                    }
                    return ({
                        ...field,
                        value: response[field.name],
                        touched: true
                    })
                }
                return ({
                    ...field,
                    value: response[field.name]
                })
            })

            let updatedTags = [...this.tagsWithLabels]
            let tagIndex = tagsCopy.findIndex(field => field.name === CONSTANTS.TAGS)

            //fix type on edit
            let purchasePriceIndex = modalFieldsCopy.findIndex(field => field.name === 'purchasePrice')
            if ((response['type'] === 'sh' || response['type'] === 'external') && purchasePriceIndex > -1)
                modalFieldsCopy[purchasePriceIndex].disabled = false


            if (tagIndex > -1) {
                let stockTagsArray = response.tags
                updatedTags = updatedTags.map(tag => stockTagsArray.includes(tag.name) ?
                    ({
                        name: tag.name,
                        label: tag.label,
                        value: true
                    }) :
                    ({
                        name: tag.name,
                        label: tag.label,
                        value: false
                    })
                )
                tagsCopy[tagIndex].value = updatedTags
            }

            let statusIndex = modalFieldsCopy.findIndex(field => field.name === 'stockStatus')

            if (statusIndex > -1) {
                let stockStatusArray = response.stockStatus
                let statusForType = setStatusHandler(response.type, this.statusWithLabels).map(status => status.name)

                let updateStatus = statusForType.map(status => stockStatusArray.includes(status) ?
                    ({
                        name: status,
                        label: this.props.language.itemDetails.stock.statusLabel[status],
                        value: true
                    }) :
                    ({
                        name: status,
                        label: this.props.language.itemDetails.stock.statusLabel[status],
                        value: false
                    })
                )

                const ipmgStockIndex = updateStatus.findIndex(index => index.name === 'ipmgStock')
                const providerStockIndex = updateStatus.findIndex(index => index.name === 'providerStock')

                // Check which one is selected so we disable option to select another one. Only one between ipmg and provider can be selected
                if (ipmgStockIndex > -1 && providerStockIndex > -1) {
                    // If ipmgStock is selected, disable provider stock option
                    if (updateStatus[ipmgStockIndex].value) {
                        updateStatus[providerStockIndex].disabled = true
                        updateStatus[ipmgStockIndex].disabled = false
                    }
                    else {
                        updateStatus[providerStockIndex].disabled = false
                    }

                    // If provider stock is selected, disable ipmg stock option
                    if (updateStatus[providerStockIndex].value) {
                        updateStatus[ipmgStockIndex].disabled = true
                        updateStatus[providerStockIndex].disabled = false
                    }
                    else {
                        updateStatus[ipmgStockIndex].disabled = false
                    }
                }

                modalFieldsCopy[statusIndex].value = updateStatus
            }

            let providersGet = roleInArray(this.props.login.roles, [CONSTANTS.ROLES.EXTERNAL_SELLER]) ? this.props.getProviderById(this.props.login.providerId) : this.props.getProviders()

            //populate dropdown selectors
            Promise.all([
                this.props.getCarBrands(),
                this.props.getCarModels(response.carBrandId),
                providersGet]
            ).then(dropDownPopulate => {
                this.populateModalFieldsOptions(modalFieldsCopy, 'carBrandId', dropDownPopulate[0].carBrands)
                this.populateModalFieldsOptions(modalFieldsCopy, 'carModelId', dropDownPopulate[1].carModels)
                this.populateModalFieldsOptions(modalFieldsCopy, 'providerId', this.props.login.isIPMG ? dropDownPopulate[2].providers : Array(dropDownPopulate[2]))
                this.setState({
                    modalFields: modalFieldsCopy,
                    stockPhotos: response.images,
                    tagFields: tagsCopy,
                    openModal: true,
                    retentionNumber: response.carBrand.name.toLowerCase() === CONSTANTS.BMW.toLowerCase() ? CONSTANTS.THREE_MONTHS : CONSTANTS.SIX_MONTHS,
                    optionalsList: response.optionalsList
                })
            })
        }).catch(() => {
            NOTIFICATION.error("Not found")
        })
    }

    indexOfInitialField = (initialFields, searchedNameInFields) => initialFields.findIndex(f => f.name === searchedNameInFields)

    mapForOptions = rows => [{ label: '', name: '' }, ...rows.map(row => ({
        label: row.name,
        name: row.id
    }))]

    populateModalFieldsOptions = (modalFieldsRef, field, values) => {
        let index = this.indexOfInitialField(modalFieldsRef, field)

        if (index === -1) {
            modalFieldsRef[index].options = []
        } else {
            modalFieldsRef[index].options = this.mapForOptions(values)
        }
    }

    InputWrapper = ({ input, keyProps, shrink, tooltipText }) => <div className={`${`${this.props.classes.equalFlex} ${this.props.classes.inputPadding}`}`}>
        <Tooltip placement="top" title={tooltipText || ''}><InputGenerator
            key={keyProps}
            fullWidth={true}
            InputLabelProps={shrink ? { shrink: true } : {}}
            margin="dense"
            {...input}
            onChange={event => this.formChangeHandlerSwitch(event)}
        /></Tooltip>
    </div>

    renderCarOptions = () => {
        const InputWrapper = this.InputWrapper
        let jsonMap = {}
        this.state.modalFields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <div className={this.props.classes.stockContainer}>
                {this.props.login.isIPMG && <InputWrapper tooltipText={this.props.language.itemDetails.stock.provider} input={jsonMap['providerId']} key={'providerId'} />}
                <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.carBrand} input={jsonMap['carBrandId']} key={'carBrandId'} />
                <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.carModel} input={jsonMap['carModelId']} key={'carModelId'} />
                <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.type} input={jsonMap['type']} key={'type'} />
            </div>
        )
    }

    renderFields = () => {

        let jsonMap = {}

        this.state.modalFields.forEach(field => {
            jsonMap[field.name] = field
        })

        let isOrderManufacturingStatus = false
        let providerStatus = false

        let orderManufacturingIndex = jsonMap['stockStatus'].value.findIndex(item => item.name === 'orderManufacturing')
        if (orderManufacturingIndex > -1 && jsonMap['stockStatus'].value[orderManufacturingIndex].value)
            isOrderManufacturingStatus = true

        let providerStatusIndex = jsonMap['stockStatus'].value.findIndex(item => item.name === 'providerStock')
        if (providerStatusIndex > -1 && jsonMap['stockStatus'].value[providerStatusIndex].value)
            providerStatus = true

        const InputWrapper = this.InputWrapper

        return (
            <>
                <div className={this.props.classes.inputContainer}>
                    <div className={this.props.classes.stockContainer}>
                        <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.vinNumber} input={jsonMap['vinNumber']} key={'vinNumber'} />
                        <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.manufactureYear} input={jsonMap['manufactureYear']} key={'manufactureYear'} />
                        {jsonMap["type"].value !== "external" && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.orderNumber} input={jsonMap['orderNumber']} key={'orderNumber'} />}
                        {!providerStatus && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.contractNumber} input={jsonMap['contractNumber']} key={'contractNumber'} />}
                        {jsonMap["type"].value !== "external" && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.registrationNumber} input={jsonMap['registrationNumber']} key={'registrationNumber'} />}
                    </div>
                    <div className={this.props.classes.stockContainer}>
                        {(jsonMap["type"].value !== "sh" && jsonMap["type"].value !== "external") && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.listPrice} input={jsonMap['listPrice']} key={'listPrice'} />}
                        {(jsonMap["type"].value !== "sh" && jsonMap["type"].value !== "external") && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.discountPercent} input={jsonMap['discountPercent']} key={'discountPercent'} />}
                        {jsonMap["type"].value !== "external" && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.accessoriesPrice} input={jsonMap['accessoriesPrice']} key={'accessoriesPrice'} />}
                        {!providerStatus && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.purchaseExchangeRate} input={jsonMap['purchaseExchangeRate']} key={'purchaseExchangeRate'} />}
                        {!isOrderManufacturingStatus && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.mileage} input={jsonMap['mileage']} key={'mileage'} />}
                    </div>
                    <div className={this.props.classes.stockContainer}>
                        {(jsonMap["type"].value !== "sh" && jsonMap["type"].value !== "external") && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.pdi} input={jsonMap['PDI']} key={'PDI'} />}
                        {(jsonMap["type"].value !== "sh" && jsonMap["type"].value !== "external") && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.discountEUR} input={jsonMap['discountEUR']} key={'discountEUR'} />}
                        <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.purchasePrice} input={jsonMap['purchasePrice']} key={'purchasePrice'} />
                        {(jsonMap["type"].value === "sh" || jsonMap["type"].value === "external") && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.addition} input={jsonMap['addition']} key={'addition'} />}
                    </div>
                    <div className={this.props.classes.stockContainer}>
                        {!isOrderManufacturingStatus && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.registrationProductionDate} shrink={true} input={jsonMap['registrationProductionDate']} key={'registrationProductionDate'} />}
                        {isOrderManufacturingStatus && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.productionMonth} shrink={true} input={jsonMap['productionMonth']} key={'productionMonth'} />}
                        {jsonMap["type"].value !== "external" && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.prtExpirationDate} shrink={true} input={jsonMap['prtExpirationDate']} key={'prtExpirationDate'} />}
                        {!providerStatus && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.IPMGDeliveryDate} shrink={true} input={jsonMap['IPMGDeliveryDate']} key={'IPMGDeliveryDate'} />}
                        {!providerStatus && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.contractDate} shrink={true} input={jsonMap['contractDate']} key={'contractDate'} />}
                        {(jsonMap["type"].value !== "sh" && jsonMap["type"].value !== "external") && <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.retentionDate} shrink={true} input={jsonMap['retentionDate']} key={'retentionDate'} />}
                    </div>
                    <div className={this.props.classes.stockContainer}>
                        {this.props.login.isIPMG && jsonMap["type"].value !== "external" && <InputWrapper input={jsonMap['hasFinancing']} key={'hasFinancing'} />}
                    </div>
                    <div className={this.props.classes.stockContainer}>
                        <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.comment} input={jsonMap['comment']} key={'comment'} />
                        <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.interiorColor} input={jsonMap['interiorColor']} key={'interiorColor'} />
                        <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.exteriorColor} input={jsonMap['exteriorColor']} key={'exteriorColor'} />
                    </div>
                    <div className={this.props.classes.stockContainer}>
                        <Optionals
                            onChangeOptionalText={text => this.setState({ optionalsList: text })}
                            optionalsText={this.state.optionalsList}
                            carBrandId={jsonMap['carBrandId'] && jsonMap['carBrandId'].value}
                        />
                    </div>
                </div>
            </>
        )
    }

    // uploadSa3 = (stockId, type) => {
    //     if (this.state.sa3ToUpload) {
    //         let files = Array.from(this.state.sa3ToUpload)
    //         if (!files.length) return;

    //         const formData = new FormData()
    //         formData.append('file', files[0])

    //         if (type === CONSTANTS.EDIT) {
    //             this.props.deleteSa3(stockId).then(() => {
    //                 this.props.uploadSa3(stockId, formData).catch(() => {
    //                     NOTIFICATION.error(this.props.language.itemDetails.stock.sa3.failure)
    //                 })
    //             })
    //         }
    //         else {
    //             this.props.uploadSa3(stockId, formData).catch(() => {
    //                 NOTIFICATION.error(this.props.language.itemDetails.stock.sa3.failure)
    //             })
    //         }
    //     }
    // }

    uploadFile = stockId => {
        if (this.state.filesToUpload) {
            let files = Array.from(this.state.filesToUpload)
            if (!files.length) return;

            const formData = new FormData()
            files.forEach((file, i) => {
                formData.append('file', file)
            })

            return this.props.uploadFileToStock(stockId, formData)
        }
        return Promise.resolve()
    }

    orderImages = images => {
        if (images) {
            return images.sort((a, b) => a.order - b.order)
        }
    }

    reloadCurrentStock = () => {
        this.toggleEditModal(this.stockToEdit)
    }


    renderTagFields = () => {
        let jsonMap = {}
        this.state.tagFields.forEach(field => {
            jsonMap[field.name] = field
        })

        const InputWrapper = this.InputWrapper

        return (
            <div className={this.props.classes.inputContainer}>
                <div className={this.props.classes.stockContainer}>
                    <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.tags} input={jsonMap['tags']} key={'tags'} />
                </div>
            </div>
        )
    }

    renderStatusFields = () => {
        let jsonMap = {}
        this.state.modalFields.forEach(field => {
            jsonMap[field.name] = field
        })

        const InputWrapper = this.InputWrapper

        return (
            <div className={this.props.classes.inputContainer}>
                <div className={this.props.classes.stockContainer}>
                    <InputWrapper tooltipText={this.props.language.itemDetails.stock.tooltipText.status} input={jsonMap['stockStatus']} key={'stockStatus'} />
                </div>
            </div>

        )
    }

    getStock = stockId => {
        this.props.getStockById(stockId)
            .then(stock => {
                this.setState({ stock, renderPage: true, openModal: true })
            })
    }


    createRawObject = () => {
        let createJson = {}
        let createJsonTags = {}
        this.state.modalFields.forEach(field => {
            if (field.type === 'numberFormat' || field.type === 'number')
                createJson[field.name] = field.value || 0
            else
                createJson[field.name] = field.value
        })
        this.state.tagFields.forEach(field => createJsonTags[field.name] = field.value)
        let { vinNumber, hasFinancing, ...restOfObject } = createJson
        let { tags, producedMonth, ...restOfObjectTags } = createJsonTags
        let resultFinancing = hasFinancing === CONSTANTS.YES ? true : false
        let mappedTags = tags.filter(tag => tag.value).map(tag => tag.name)
        let newVINNumber = vinNumber ? vinNumber : CONSTANTS.COMMAND
        restOfObject.producedMonth = this.props.language.itemDetails.stock.monthLabel[producedMonth]
        restOfObject.vinNumber = newVINNumber
        restOfObject.tags = mappedTags
        restOfObject.stockStatus = restOfObject.stockStatus.filter(status => status.value).map(status => status.name)
        restOfObject.hasFinancing = resultFinancing

        //optionals
        restOfObject.optionalsList = this.state.optionalsList

        if (!restOfObject.retentionDate || !restOfObject.retentionDate.length || restOfObject.retentionDate.toLowerCase() === CONSTANTS.INVALID_DATE.toLowerCase()) restOfObject.retentionDate = null
        if (!restOfObject.registrationProductionDate || !restOfObject.registrationProductionDate.length || restOfObject.registrationProductionDate.toLowerCase() === CONSTANTS.INVALID_DATE.toLowerCase()) restOfObject.registrationProductionDate = null
        if (!restOfObject.IPMGDeliveryDate || !restOfObject.IPMGDeliveryDate.length || restOfObject.IPMGDeliveryDate.toLowerCase() === CONSTANTS.INVALID_DATE.toLowerCase()) restOfObject.IPMGDeliveryDate = null
        if (!restOfObject.contractDate || !restOfObject.contractDate.length || restOfObject.contractDate.toLowerCase() === CONSTANTS.INVALID_DATE.toLowerCase()) restOfObject.contractDate = null
        let { retentionDate, listPrice, discountPercent, discountEUR, ...objectForSH } = restOfObject

        if (restOfObject.type === 'sh') {
            objectForSH.discountPercent = 0
            objectForSH.discountEUR = 0
            objectForSH.listPrice = 0
            return { ...objectForSH }
        }
        if (restOfObject.type === 'external') {
            objectForSH.discountPercent = 0
            objectForSH.discountEUR = 0
            objectForSH.listPrice = 0
            objectForSH.orderNumber = ''
            objectForSH.registrationNumber = ''
            objectForSH.prtExpirationDate = null

            return { ...objectForSH }
        }
        return { ...restOfObject }
    }

    onCreate = () => {
        if (!this.validate()) return NOTIFICATION.error(this.props.language.validation.error)

        let createObject = this.createRawObject()
        this.props.create(createObject).then(createdStock => {
            this.createdStock = createdStock
            this.uploadFile(this.createdStock.id)
                .catch(() => NOTIFICATION.error(this.props.language.itemDetails.stock.image.failure))
            // this.uploadSa3(this.createdStock.id, CONSTANTS.CREATE)
            this.props.getStocks()
            this.stockToEdit = null
            //this.props.afterCreate()
            NOTIFICATION.success(this.props.language.stock.create.success)
            this.setState({ modalFields: this.initialFields, tagFields: this.initialTagsFields, sa3ToUpload: null, filesToUpload: null, stockPhotos: [] })
            if (createObject.hasFinancing) this.props.openFinancingCostModal(this.createdStock)
        }).catch(error => {
            NOTIFICATION.error(this.props.language.stock.create.failure)
        })

    }

    onEdit = () => {
        if (!this.validate()) return NOTIFICATION.error(this.props.language.validation.error)
        // this.uploadFile(this.props.stockIdToEdit.id)
        // this.uploadSa3(this.props.stockIdToEdit.id, CONSTANTS.EDIT)
        this.props.edit(this.props.stockIdToEdit.id, this.createRawObject()).then(() => {
            this.props.getStocks()
            this.stockToEdit = null
            NOTIFICATION.success(this.props.language.stock.edit.success)
            this.setState({ modalFields: this.initialFields, tagFields: this.initialTagsFields, openModal: false, sa3ToUpload: null, filesToUpload: null, stockPhotos: [] })
        }).catch(() => {
            this.props.getStocks()
            NOTIFICATION.error(this.props.language.stock.edit.failure)
        })
        this.setState({ modalFields: this.initialFields, tagFields: this.initialTagsFields, openModal: false, filesToUpload: null, stockPhotos: [] })
    }

    checkForPDIValue = modalFieldsCopy => {
        let carBrandIndex = this.indexOfInitialField(modalFieldsCopy, 'carBrandId')
        let providerIndex = this.indexOfInitialField(modalFieldsCopy, 'providerId')
        if (carBrandIndex > -1) {
            let PDIIndex = modalFieldsCopy.findIndex(field => field.name === 'PDI')
            if (PDIIndex > -1 && providerIndex > -1) {
                let providerTag = modalFieldsCopy[providerIndex].options.filter(option => option.value === modalFieldsCopy[providerIndex].value).map(option => option.tag)
                let brandTag = modalFieldsCopy[carBrandIndex].options.filter(option => option.value === modalFieldsCopy[carBrandIndex].value).map(option => option.tag)
                if (brandTag[0] && providerTag[0] && brandTag[0].toLowerCase() === CONSTANTS.BMW.toLowerCase() && providerTag[0].toLowerCase() === CONSTANTS.XCARS.toLowerCase()) {
                    return true
                }
                else return false
            }
        }
    }

    formCarBrandChangeHandler = event => {
        let modalFieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))

        let carBrandIndex = modalFieldsCopy.findIndex(field => field.name === 'carBrandId')

        if (carBrandIndex > -1) {
            modalFieldsCopy[carBrandIndex].value = event.target.value
            modalFieldsCopy[carBrandIndex].touched = true
            let PDIIndex = modalFieldsCopy.findIndex(field => field.name === 'PDI')
            let carModelIndex = modalFieldsCopy.findIndex(field => field.name === 'carModelId')
            modalFieldsCopy[carModelIndex].value = ''
            if (this.checkForPDIValue(modalFieldsCopy)) modalFieldsCopy[PDIIndex].value = CONSTANTS.PDI_VALUE
            else modalFieldsCopy[PDIIndex].value = 0

            this.selectedCarBrandId = event.target.value
            let selectedLabelIndex = modalFieldsCopy[carBrandIndex].options.findIndex(option => Number(option.name) === Number(event.target.value))

            if (selectedLabelIndex > -1) {
                let selectedLabel = modalFieldsCopy[carBrandIndex].options[selectedLabelIndex].label
                this.props.getCarModels(event.target.value).then(response => {
                    let carModelIndex = modalFieldsCopy.findIndex(field => field.name === 'carModelId')
                    if (carModelIndex > -1) {

                        modalFieldsCopy[carModelIndex].options = this.mapForOptions(response.carModels)
                        modalFieldsCopy[carModelIndex].optionsTag = event.target.value

                        this.setState({ modalFields: modalFieldsCopy, retentionNumber: selectedLabel.toLowerCase() === CONSTANTS.BMW.toLowerCase() ? CONSTANTS.THREE_MONTHS : CONSTANTS.SIX_MONTHS })
                    }
                })
            }
        }
    }

    formTagsChangeHandler = event => {
        let currentIndex = this.state.tagFields.findIndex(field => field.name === event.target.name)
        if (currentIndex > -1) {
            let modalFieldsCopy = [...this.state.tagFields].map(field => ({ ...field }))

            modalFieldsCopy[currentIndex].value = event.target.value
            modalFieldsCopy[currentIndex].touched = true

            this.setState({ tagFields: modalFieldsCopy })
        }
    }

    formTypeChangeHandler = event => {
        // If sh value selected enable purchase Price
        let currentIndex = this.state.modalFields.findIndex(field => field.name === event.target.name)

        if (currentIndex > -1) {
            let modalFieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))
            let accessoriesIndex = modalFieldsCopy.findIndex(field => field.name === 'accessoriesPrice')
            let purchasePriceIndex = modalFieldsCopy.findIndex(field => field.name === 'purchasePrice')
            let PDIIndex = modalFieldsCopy.findIndex(field => field.name === 'PDI')
            let priceIndex = modalFieldsCopy.findIndex(field => field.name === 'listPrice')
            let discountPercentIndex = modalFieldsCopy.findIndex(field => field.name === 'discountPercent')
            let retentionIndex = modalFieldsCopy.findIndex(field => field.name === 'retentionDate')
            let statusIndex = modalFieldsCopy.findIndex(field => field.name === 'stockStatus')

            // Set status options based on current type
            const validStatusForType = setStatusHandler(event.target.value, this.statusWithLabels)
            modalFieldsCopy[statusIndex].value = validStatusForType

            if (event.target.value === 'sh' || event.target.value === 'external') {
                modalFieldsCopy[purchasePriceIndex].disabled = false
                modalFieldsCopy[purchasePriceIndex].endAdornment = null
                modalFieldsCopy[purchasePriceIndex].value = ''
            }
            else {
                modalFieldsCopy[purchasePriceIndex].disabled = true
                modalFieldsCopy[purchasePriceIndex].endAdornment = <Tooltip title='Formula'><TurnedIcon /></Tooltip>
                modalFieldsCopy[purchasePriceIndex].value = (Number(modalFieldsCopy[priceIndex].value) * (1 - Number(modalFieldsCopy[discountPercentIndex].value) / 100)) + Number(modalFieldsCopy[PDIIndex].value) + Number(modalFieldsCopy[accessoriesIndex].value)
            }

            if (event.target.value === 'fleet') {
                modalFieldsCopy[retentionIndex].disabled = false
                //this.setState({ modalFields: modalFieldsCopy })
            }
            else {
                modalFieldsCopy[retentionIndex].disabled = true
                modalFieldsCopy[retentionIndex].value = ''
                //this.setState({ modalFields: modalFieldsCopy })
            }

            modalFieldsCopy[currentIndex].value = event.target.value
            modalFieldsCopy[currentIndex].touched = true

            this.setState({ modalFields: modalFieldsCopy })
        }
    }

    formStatusChangeHandler = event => {
        let currentIndex = this.state.modalFields.findIndex(field => field.name === event.target.name)
        if (currentIndex > -1) {
            let modalFieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))

            modalFieldsCopy[currentIndex].value = event.target.value
            modalFieldsCopy[currentIndex].touched = true

            let valueCopy = event.target.value.map(val => ({ ...val }))
            let orderManufacturingIndex = valueCopy.findIndex(index => index.name === 'orderManufacturing')
            let ipmgStockIndex = valueCopy.findIndex(index => index.name === 'ipmgStock')
            let providerStockIndex = valueCopy.findIndex(index => index.name === 'providerStock')

            // Only ipmg stock or provider stock, not both of them
            if (ipmgStockIndex > -1 && providerStockIndex > -1) {
                if (valueCopy[ipmgStockIndex].value) {
                    valueCopy[providerStockIndex].disabled = true
                    valueCopy[ipmgStockIndex].disabled = false
                }
                else {
                    valueCopy[providerStockIndex].disabled = false
                }

                if (valueCopy[providerStockIndex].value) {
                    valueCopy[ipmgStockIndex].disabled = true
                    valueCopy[providerStockIndex].disabled = false
                }
                else {
                    valueCopy[ipmgStockIndex].disabled = false
                }

                modalFieldsCopy[currentIndex].value = valueCopy
            }

            if (orderManufacturingIndex > -1 && valueCopy[orderManufacturingIndex].value) {
                let registrationProductionDateIndex = modalFieldsCopy.findIndex(item => item.name === 'registrationProductionDate')
                if (registrationProductionDateIndex > -1) {
                    modalFieldsCopy[registrationProductionDateIndex].value = null
                }
            }
            this.setState({ modalFields: modalFieldsCopy })
        }
    }

    formChangeHandlerSwitch = event => {
        switch (event.target.name) {
            case 'carBrandId':
                return this.formCarBrandChangeHandler(event)
            case 'tags':
                return this.formTagsChangeHandler(event)
            case 'type':
                return this.formTypeChangeHandler(event)
            case 'stockStatus':
                return this.formStatusChangeHandler(event)
            default:
                return this.onChange(event)
        }
    }

    onChange = event => {

        let currentIndex = this.state.modalFields.findIndex(field => field.name === event.target.name)
        if (currentIndex > -1) {
            let modalFieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))
            let PDIIndex = modalFieldsCopy.findIndex(field => field.name === 'PDI')
            let typeIndex = modalFieldsCopy.findIndex(field => field.name === 'type')

            if (event.target.name === 'PDI') {
                if (this.checkForPDIValue(modalFieldsCopy)) modalFieldsCopy[PDIIndex].value = CONSTANTS.PDI_VALUE
                else modalFieldsCopy[PDIIndex].value = ''
            }

            modalFieldsCopy[currentIndex].value = event.target.value
            modalFieldsCopy[currentIndex].touched = true

            //formulas
            let discountEURIndex = modalFieldsCopy.findIndex(field => field.name === 'discountEUR')
            let purchasePriceIndex = modalFieldsCopy.findIndex(field => field.name === 'purchasePrice')
            let registrationDateIndex = modalFieldsCopy.findIndex(field => field.name === 'registrationProductionDate')
            if (discountEURIndex > -1 && purchasePriceIndex > -1 && registrationDateIndex > -1) {
                let priceIndex = modalFieldsCopy.findIndex(field => field.name === 'listPrice')
                let discountPercentIndex = modalFieldsCopy.findIndex(field => field.name === 'discountPercent')
                let retentionIndex = modalFieldsCopy.findIndex(field => field.name === 'retentionDate')
                let accessoriesIndex = modalFieldsCopy.findIndex(field => field.name === 'accessoriesPrice')

                // Check for retention if registration date is null

                if (!modalFieldsCopy[registrationDateIndex].value) {

                    if (modalFieldsCopy[typeIndex].value === 'fleet') {

                        modalFieldsCopy[retentionIndex].disabled = false
                        modalFieldsCopy[registrationDateIndex].value = ''
                    }
                    else {
                        modalFieldsCopy[retentionIndex].value = ''
                        modalFieldsCopy[retentionIndex].disabled = true
                    }
                    //return this.setState({ modalFields: modalFieldsCopy })
                }
                else {

                    if (modalFieldsCopy[typeIndex].value !== 'fleet') {
                        modalFieldsCopy[retentionIndex].value = null
                        //this.setState({ modalFields: modalFieldsCopy })
                    }

                }
                if (modalFieldsCopy[typeIndex].value !== 'sh' && modalFieldsCopy[typeIndex].value !== 'external')
                    modalFieldsCopy[purchasePriceIndex].value = (Number(modalFieldsCopy[priceIndex].value) * (1 - Number(modalFieldsCopy[discountPercentIndex].value) / 100)) + Number(modalFieldsCopy[PDIIndex].value) + Number(modalFieldsCopy[accessoriesIndex].value)

                modalFieldsCopy[discountEURIndex].value = Number(modalFieldsCopy[priceIndex].value) - Number(modalFieldsCopy[priceIndex].value) * (1 - (Number(modalFieldsCopy[discountPercentIndex].value) / 100)) - Number(modalFieldsCopy[PDIIndex].value)
            }


            //if contract number and contract date is present, check "contracted" tag
            let tagsCopy = [...this.state.tagFields].map(field => ({ ...field, value: field.value.map(value => ({ ...value })) }))
            if (event.target.name === 'contractNumber' || event.target.name === 'contractDate') {
                let contractNumberIndex = modalFieldsCopy.findIndex(field => field.name === 'contractNumber')
                let contractDateIndex = modalFieldsCopy.findIndex(field => field.name === 'contractDate')
                if (contractNumberIndex > -1 && contractDateIndex > -1) {
                    if (modalFieldsCopy[contractNumberIndex].value && modalFieldsCopy[contractDateIndex].value && modalFieldsCopy[contractDateIndex].value !== CONSTANTS.INVALID_DATE_INPUT_ERROR) {
                        let tagsIndex = tagsCopy.findIndex(tags => tags.name === 'tags')
                        let contractedIndex = tagsCopy[tagsIndex].value.findIndex(tag => tag.name === 'contracted')
                        tagsCopy[tagsIndex].value[contractedIndex].value = true
                    }
                }
            }

            this.setState({ modalFields: modalFieldsCopy, tagFields: tagsCopy })
        }
    }

    onCloseHandler = () => {
        this.props.onClose()
        this.setState({ modalFields: this.initialFields, tagFields: this.initialTagsFields, stockPhotos: [], sa3ToUpload: null })
    }

    clearForm = () => {
        //first clear options from 
        this.setState({ modalFields: this.initialFields, tagFields: this.initialTagsFields })
    }

    openFormModalHandler = () => {
        this.clearForm()
        let providersGet = roleInArray(this.props.login.roles, [CONSTANTS.ROLES.EXTERNAL_SELLER]) ? this.props.getProviderById(this.props.login.providerId) : this.props.getProviders()
        let carBrandsGet = this.props.getCarBrands()

        Promise.all([providersGet, carBrandsGet]).then(values => {
            let modalFieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))

            let providerIdIndex = modalFieldsCopy.findIndex(field => field.name === 'providerId')
            modalFieldsCopy[providerIdIndex].value = this.props.login.providerId
            if (!this.props.login.isIPMG) modalFieldsCopy[providerIdIndex].disabled = true

            this.populateModalFieldsOptions(modalFieldsCopy, 'providerId', this.props.login.isIPMG ? values[0].providers : Array(values[0]))
            this.populateModalFieldsOptions(modalFieldsCopy, 'carBrandId', values[1].carBrands)
            this.setState({
                openModal: true,
                modalFields: modalFieldsCopy,
                optionalsList: ''
            })
        })
    }

    cancelCostModal = () => this.setState({ financingCostModal: false }, this.props.getStocks)
    onCloseOptionalModal = () => this.setState({ openOptionalsModal: false })

    render() {
        const { language } = this.props
        return <>
            <SimpleModal
                open={this.state.openModal}
                onCancel={() => this.onCloseHandler()}
                onDelete={this.props.onDelete}
                onAccept={() => this.props.modalType === CONSTANTS.CREATE ? this.onCreate() : this.onEdit()}
                acceptButtonText={this.props.modalType === CONSTANTS.CREATE ? language.stock.formModal.add : language.stock.formModal.edit}
                cancelButtonText={this.props.language.stock.confirmationDialog.cancelButtonText}
                deleteButtonText={"Sterge"}
                canSubmitForm={true}
                title={this.props.modalType === CONSTANTS.CREATE ? language.stock.formModal.add : language.stock.formModal.title}
                maxWidth={'lg'}
            >
                <div className={this.props.classes.modalContainer}>
                    <div className={this.props.classes.carOptionsContainer}>
                        {this.renderCarOptions()}
                    </div>
                    <div className={this.props.classes.bottomContainer}>
                        <div className={this.props.classes.clientContainer}>
                            {this.renderFields()}
                        </div>
                        <div className={this.props.classes.stockDetailsContainer}>
                            {this.renderStatusFields()}
                            {this.renderTagFields()}
                            <div className={this.props.classes.imgWrapper}>
                                <Typography className={this.props.classes.photosText}>{this.props.language.stock.photos}</Typography>
                                {this.state.filesToUpload ? <Typography className={this.props.classes.photosText}>{this.props.language.stock.chosedPhotos.replace('@replace', this.state.filesToUpload.length)}</Typography> : null}
                                {this.stockToEdit && this.state.stockPhotos && <MiniGallery
                                    displayMainPhoto={false}
                                    images={this.orderImages(this.state.stockPhotos)}
                                    stockId={this.stockToEdit.id}
                                    reloadImages={() => this.reloadCurrentStock()}
                                />}
                                <div className={this.props.classes.addPhotoButton}>
                                    <input
                                        className={this.props.classes.displayNone}
                                        onChange={event => {
                                            this.setState({ filesToUpload: event.target.files }, () => {
                                                if (this.props.modalType === CONSTANTS.EDIT) {
                                                    this.uploadFile(this.props.stockIdToEdit.id)
                                                        .catch(() => NOTIFICATION.error(this.props.language.itemDetails.stock.image.failure))
                                                        .then(() => NOTIFICATION.success(this.props.language.itemDetails.stock.image.success))
                                                }
                                            })
                                        }}
                                        id="upload-file"
                                        multiple
                                        type="file"
                                    />
                                    <label htmlFor="upload-file" className={this.props.classes.uploadLabel}>
                                        <Button variant="contained" component="span" className={this.props.classes.uploadButtonFull}>
                                            {this.props.language.itemDetails.addPhoto}
                                        </Button>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </SimpleModal>
        </>
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    login: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    getStockById: stockId => dispatch(STOCKS.getById(stockId)),
    getFromAnaf: queryParams => dispatch(CLIENT.getFromAnaf({ ...queryParams, limit: CONSTANTS.ALL_ROWS })),
    getProviders: queryParams => dispatch(PROVIDERS.get({ ...queryParams, limit: CONSTANTS.ALL_ROWS })),
    getProviderById: providerId => dispatch(PROVIDERS.getById(providerId)),
    getCarModels: (brandId, queryParams) => dispatch(CARS.model.get(brandId, { ...queryParams, limit: CONSTANTS.ALL_ROWS })),
    getCarBrands: queryParams => dispatch(CARS.brand.get({ ...queryParams, limit: CONSTANTS.ALL_ROWS })),
    getById: (stockId, blocked) => dispatch(STOCKS.getById(stockId, blocked)),
    unblockStock: stockId => dispatch(STOCKS.unblockStock(stockId)),
    getByIdPopulate: stockId => dispatch(STOCKS.getByIdPopulate(stockId)),
    create: stock => dispatch(STOCKS.create(stock)),
    delete: stockId => dispatch(STOCKS.del(stockId)),
    edit: (stockId, stock) => dispatch(STOCKS.edit(stockId, stock)),
    uploadFileToStock: (stockId, files) => dispatch(STOCKS.uploadFileToStock(stockId, files)),
    uploadSa3: (stockId, files) => dispatch(STOCKS.uploadSa3(stockId, files)),
    deleteSa3: stockId => dispatch(STOCKS.deleteSa3(stockId))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(StockModal))