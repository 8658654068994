import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { withStyles, TableRow, TableCell, Tooltip } from '@material-ui/core'

import * as CONSTANTS from '../../utils/constants'
import config from '../../config/local.json'

import ListAvatarAndCarBrand from '../common/subComponents/ListAvatarAndCarBrand'
import InputGenerator from '../common/subComponents/InputGenerator'
import { formatNumber, ClickWrapper } from '../../utils/helpers'

const styles = theme => ({
    listWrapper: {
        '& span:not(:first-child)': {
            display: 'inline-block',
            marginLeft: '5px'
        }
    }
})

class MonitoringList extends Component {

    onChangeRefound = () => {
        this.props.onChangeRefound(
            this.props.item.financingCost.id,
            !this.props.item.financingCost.refoundInterest,
            this.props.item.carBrand.name,
            this.props.item.carModel.name
        )
    }

    onChangeLogistics = () => {
        this.props.onChangeLogistics(
            this.props.item.id,
            !this.props.item.stopLogistics,
            this.props.item.carBrand.name,
            this.props.item.carModel.name
        )
    }

    render() {
        const { retentionDate, cost, financingCost } = this.props.item
        const logoPath = `${config.baseUrl}/cars/brands/${this.props.item.carBrandId}/image`
        const isClosedWithRestitution = (financingCost.isClosed && financingCost.closedType === 'WITH_RESTITUTION')
        
        return (
            <Tooltip placement="top" title={this.props.item.comment}>
                <TableRow>
                    <ClickWrapper onClick={() => this.props.onSelect(this.props.item)}>
                        <TableCell>
                            <ListAvatarAndCarBrand
                                carBrandName={this.props.item.carBrand.name}
                                carModelName={this.props.item.carModel.name}
                                logoPath={logoPath}
                            />
                        </TableCell>
                        <TableCell >
                            <Tooltip placement="top" title={this.props.language.vinNumber}><span className={this.props.classes.fieldText}>{`${this.props.item.vinNumber.substr(-1 * CONSTANTS.VIN_SUBSTR_LENGTH)}`}</span></Tooltip>
                        </TableCell>
                        <TableCell size='small' >
                            {cost.rcas[0] ? moment(cost.rcas[0].expirationDate).format(CONSTANTS.DEFAULT_DATE_FORMAT) : '-'}
                        </TableCell>
                        <TableCell size='small' >
                            {cost.cascos[0] ? moment(cost.cascos[0].expirationDate).format(CONSTANTS.DEFAULT_DATE_FORMAT) : '-'}
                        </TableCell>
                        <TableCell size='small' >
                            {cost.vignettes[0] ? moment(cost.vignettes[0].expirationDate).format(CONSTANTS.DEFAULT_DATE_FORMAT) : '-'}
                        </TableCell>
                        <TableCell size='small' >
                            {cost.prts[0] ? moment(cost.prts[0].expirationDate).format(CONSTANTS.DEFAULT_DATE_FORMAT) : '-'}
                        </TableCell>
                        <TableCell size='small' >
                            {retentionDate ? moment(retentionDate).format(CONSTANTS.DEFAULT_DATE_FORMAT) : '-'}
                        </TableCell>
                        <TableCell size='small' >
                            {financingCost.interestRestitution && isClosedWithRestitution ? `${formatNumber(financingCost.interestRestitution)} RON` : '-'}
                        </TableCell>

                        <TableCell size='small'>
                            {isClosedWithRestitution ? <InputGenerator
                                name='interestRestitution'
                                type='checkbox'
                                label={this.props.monitoringLanguage.refounded}
                                value={this.props.item.financingCost.refoundInterest}
                                checked={this.props.item.financingCost.refoundInterest}
                                onChange={this.onChangeRefound}
                            /> : '-'}
                        </TableCell>

                        <TableCell size='small'>
                            <InputGenerator
                                name='logistics'
                                type='checkbox'
                                label={this.props.monitoringLanguage.stop}
                                value={this.props.item.stopLogistics}
                                checked={this.props.item.stopLogistics}
                                onChange={this.onChangeLogistics}
                            />
                        </TableCell>
                    </ClickWrapper>
                </TableRow>
            </Tooltip>)
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.itemDetails.stock,
    monitoringLanguage: state.languageReducer.i18n.monitoring
})

const mapDispatchToProps = dispatch => ({

})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MonitoringList))