import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import SimpleTabs from '../common/SimpleTabs'
import StockTab from './stockTab/StockTab'
import OfferTab from './offerTab/OfferTab'
import Header from '../common/SearchableHeader'
import { roleInArray } from '../../utils/helpers'

import * as CONSTANTS from '../../utils/constants'

const styles = theme => ({
    container: {
        padding: 27,
        overflow: 'auto'
    },
    withoutSidebar: {
        padding: '24px 36px 24px 0px'
    }
})

class Offer extends Component {

    state = {
        activeTab: 0,
        search: '',
        isFinancing: false,
        renderPage: false
    }

    componentDidMount() {
        if (!roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.IPMG_SELLER, CONSTANTS.ROLES.EXTERNAL_SELLER])) {
            this.setState({
                activeTab: 1,
                isFinancing: true,
                renderPage: true
            })
        }
        else this.setState({ renderPage: true })
    }

    onSearch = search => this.setState({ search })

    render() {
        let { classes } = this.props
        let tabs = []

        if (roleInArray(this.props.loginReducer.roles, [CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.IPMG_SELLER, CONSTANTS.ROLES.EXTERNAL_SELLER])) {
            tabs = [{
                label: this.props.stockTitle,
                index: 0,
            }, {
                label: this.props.offerTitle,
                index: 1
            }]
        }
        else {
            tabs = [{
                label: this.props.offerTitle,
                index: 1
            }]
        }
        if (this.state.renderPage) {
            return (
                <>
                    <div className={classes.header}>
                        <Header
                            title={this.props.offerTitle}
                            placeholder={this.props.searchPlaceholder}
                            onSearch={this.onSearch}
                        />
                    </div>
                    <div className={`${!this.props.sidebarReducer.displaySidebar ? this.props.classes.withoutSidebar : ""} ${this.props.classes.container}`}>
                        <SimpleTabs
                            tabs={tabs}
                            value={this.state.activeTab}
                            onChangeHandler={index => {
                                this.setState({
                                    activeTab: index,
                                    search: ''
                                })
                            }}
                        >
                            {!this.state.isFinancing ? <SimpleTabs.Tab index={0} >
                                <StockTab
                                    searchText={this.state.search}
                                />
                            </SimpleTabs.Tab> : <></>}
                            <SimpleTabs.Tab index={1}>
                                <OfferTab
                                    searchText={this.state.search}
                                />
                            </SimpleTabs.Tab>
                        </SimpleTabs>
                    </div>

                </>
            )
        } else return null
    }
}
const mapStateToProps = reducers => ({
    searchPlaceholder: reducers.languageReducer.i18n.car.searchPlaceholder,
    offerTitle: reducers.languageReducer.i18n.offer.title,
    sidebarReducer: reducers.sidebarReducer,
    stockTitle: reducers.languageReducer.i18n.stock.title,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Offer))