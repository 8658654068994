import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography, withStyles } from '@material-ui/core'
import moment from 'moment'

import * as SALE from '../../redux/actions/sales'
import * as CONSTANTS from '../../utils/constants'
import * as NOTIFICATION from '../../utils/notification'

import SearchableHeader from '../common/SearchableHeader'
import SimpleList from '../common/SimpleList'
import SaleModal from './SaleModal'
import FormModal from '../common/FormModal'

const styles = theme => ({
    listContainer: {
        padding: '24px',
        overflow: 'auto'
    },
    subtitle: {
        fontSize: '36px',
        fontWeight: '500',
        paddingTop: '20px',
        color: theme.palette.primaryColor,
        display: 'block',
        width: '350px'
    },
    table: {
        marginTop: '20px'
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    withoutSidebar: {
        padding: '24px 36px 24px 0px'
    },
    sameYear: {
        backgroundColor: theme.palette.firstColor
    },
    differentYear:{
        backgroundColor: theme.palette.secondColor
    }
})

class Sale extends Component {
    initialFields = [
        { value: '', type: 'numberFormat', label: this.props.saleLanguage.leasingExchangeRate, name: 'leasingExchangeRate', decimalSize: CONSTANTS.EXCHANGE_DECIMAL_SIZE },
        { value: '', type: 'date', label: this.props.saleLanguage.soldAt, name: 'createdAt' }
    ]

    state = {
        renderPage: false,
        sales: [],
        from: 0,
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        search: '',
        headerFields: this.props.language.simpleTable.headerSale,
        item: null,
        openAddDetailsModal: false,
        detailsRow: {},
        saleIdToEdit: null,
        detailsModalFields: [...this.initialFields].map(o => ({ ...o })),
    }

    componentDidMount() {
        this.getSales()
    }

    getSales = () => {
        let params = {
            search: this.state.search
        }
        return this.props.get({
            ...params
        }).then(saleResponse => {
            if(saleResponse.count){
                let groupedSales = []
                saleResponse.sales.years.forEach((year) => {
                    year.months.forEach((month) => {
                        let show =  true
                        month.data.forEach((row,index) => {
                            if(show) {
                                show = !show
                                groupedSales.push({
                                    ...CONSTANTS.YEAR_ROW,
                                    "year": year,
                                    "month": month,
                                })
                            }
                            groupedSales.push({...row, isYear: false})
                        })
                    })
                })
                this.setState({
                    sales: groupedSales,
                    count: saleResponse.count,
                    renderPage: true
                })
                return Promise.resolve()
            }
            this.setState({
                sales: [],
                count: saleResponse.count,
                renderPage: true
            })
            return Promise.resolve()
        })
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, () => this.getSales())
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, () => this.getSales())
    }

    onSelectHandler = stock => {
        this.setState({ openModal: true, item: stock })
    }

    onSearch = search => this.setState({ search }, () => this.getSales())

    openDetailsModal = row => {
        let detailsModalFieldsCopy = [...this.state.detailsModalFields].map(o => ({ ...o }))
        detailsModalFieldsCopy.forEach(field => {
            if(field.type === 'date') field.value = moment(row[field.name]).format('YYYY-MM-DD')
            else
                field.value = row[field.name]
        })
        this.setState({ openAddDetailsModal: true, detailsRow: row, detailsModalFields: detailsModalFieldsCopy, saleIdToEdit: row.id })
    }

    onEditHandler = details => {
        this.props.edit(this.state.saleIdToEdit, details)
            .then(() => {
                NOTIFICATION.success(this.props.saleLanguage.confirmationDialog.successEdit)
                this.setState({ openAddDetailsModal: false, detailsRow: {}, saleIdToEdit: null })
                this.getSales()
            })
            .catch(() => {
                NOTIFICATION.error(this.props.saleLanguage.confirmationDialog.errorEdit)
                this.setState({ openAddDetailsModal: false, detailsRow: {}, saleIdToEdit: null })
            })
    }

    render() {
        const { classes } = this.props
        if(this.state.renderPage) {
            return (<>
                <div className={classes.titleContainer}>
                    <SearchableHeader
                        placeholder={this.props.placeholderLanguage}
                        title={this.props.language.saleTitle}
                        onSearch={this.onSearch}
                    />
                </div>
                <SaleModal
                    open={this.state.openModal}
                    onClose={() => this.setState({ openModal: false })}
                    item={this.state.item}
                />

                <FormModal
                    fields={this.state.detailsModalFields}
                    key={this.state.openAddDetailsModal}
                    open={this.state.openAddDetailsModal}
                    onCancel={() => this.setState({ openAddDetailsModal: false })}
                    onSave={data => this.onEditHandler(data.fields)}
                    dialogTitle={this.props.saleLanguage.details.details}
                    cancelButtonText={this.props.saleLanguage.confirmationDialog.cancelButtonText}
                    submitButtonText={this.props.saleLanguage.confirmationDialog.saveButtonText}
                    type={CONSTANTS.CREATE}
                    validate={true}
                    maxWidth='xs'
                />

                <div className={`${!this.props.sidebarReducer.displaySidebar ? this.props.classes.withoutSidebar : ""} ${this.props.classes.listContainer}`}>
                    <SimpleList
                        headerFields={this.state.headerFields}
                        addDetails={row => this.openDetailsModal(row)}
                        getSale={this.getSales}
                        items={this.state.sales}
                        countTitle={this.props.language.carsInStock}
                        title={<Typography className={this.props.classes.subtitle} component='span'>{this.props.language.saleTitle}</Typography>}
                        actionsTitle={this.props.language.simpleTable.actionsTitle}
                        disableBorders
                        selectedOption={CONSTANTS.SALE_OPTIONS}
                        onSelect={sale => this.onSelectHandler(sale.offer.stock)}
                        noHeader
                        actions={[]}
                        search={{onSearch: this.onSearch}}
                        rowsPerPage={this.state.rowsPerPage}
                        count={this.state.count}
                        changeRowsPerPage={this.changeRowsPerPageHandler}
                        changePageHandler={this.changePageHandler}
                    />
                </div>
            </>)
        }
        else return null
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n.stock,
    sidebarReducer: state.sidebarReducer,
    saleLanguage: state.languageReducer.i18n.sale,
    placeholderLanguage: state.languageReducer.i18n.car.searchPlaceholder
})

const mapDispatchToProps = dispatch => ({
    get: options => dispatch(SALE.get(options)),
    edit: (saleId, options) => dispatch(SALE.edit(saleId, options))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Sale))