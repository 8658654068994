import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { withStyles } from '@material-ui/core'

import SimpleModal from '../../../common/SimpleModal'

import * as STOCK from '../../../../redux/actions/stocks'
import * as CLIENT from '../../../../redux/actions/clients'
import * as CONSTANTS from '../../../../utils/constants'
import * as NOTIFICATIONS from '../../../../utils/notification'

import InputGenerator from '../../../common/subComponents/InputGenerator'

const styles = theme => ({
    modalContainer: {
        paddingTop: 18,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        color: 'black'
    },
    testDriveModalContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    clientContainer: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid rgba(0,0,0,0.1)'
    },
    detailsContainer: {
        flex: 1,
        padding: 20
    },
    equalFlex: {
        flex: 1
    },
    inputPadding: {
        paddingRight: 10,
        paddingTop: 2
    },
})

class TestDriveModal extends Component {

    currentDate = moment().format(CONSTANTS.INPUT_TYPE_DATE_FORMAT)

    initialFields = [
        { value: '', type: 'number', label: this.props.language.itemDetails.offer.testDrive.contractNumber, name: 'contractNumber' },
        { value: '', type: 'date', label: this.props.language.itemDetails.offer.testDrive.contractDate, name: 'contractDate' },
        { value: '', type: 'number', label: this.props.language.itemDetails.offer.testDrive.kmLimit, name: 'kmLimit' },
        { value: '', type: 'number', label: this.props.language.itemDetails.offer.testDrive.period, name: 'periodDays' },
    ]

    clientFields = [
        { value: '', disabled: true, type: 'text', label: this.props.language.itemDetails.offer.cui, name: 'cui' },
        { value: '', disabled: true, type: 'text', label: this.props.language.itemDetails.offer.email, name: 'contactEmail' },
        { value: '', disabled: true, type: 'text', label: this.props.language.itemDetails.offer.phoneNumber, name: 'contactPhoneNumber' },
        { value: '', disabled: true, type: 'text', label: this.props.language.itemDetails.offer.companyName, name: 'name' },
    ]

    state = {
        openModal: false,
        renderPage: false,
        modalFields: this.initialFields,
        clients: [],
        dropdownMappedClients: [],
        clientFields: this.clientFields,
        selectedClient: null
    }


    InputWrapper = ({ input, keyProps, shrink, onChange }) => <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding}`}>
        <InputGenerator
            key={keyProps}
            fullWidth={true}
            InputLabelProps={shrink ? { shrink: true } : {}}
            margin="dense"
            {...input}
            onChange={event => onChange(event)}
        />
    </div>

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open && this.props.stockId) {
            this.getStock(this.props.stockId)
        }
        if (prevProps.open && !this.props.open) {
            this.setState({
                openModal: false
            })
        }
    }

    getStock = stockId => {
        this.props.getStockById(stockId)
            .then(stock => {
                this.setState({ stock }, this.getClients)
            })
    }

    getClients = () => {
        this.props.getClients().then(result => {
            this.setState({
                dropdownMappedClients: [{ name: '', label: this.props.language.itemDetails.offer.chooseClient, value: '' }].concat(result.clients.map(client => ({
                    name: client.id,
                    label: client.name || client.contactName,
                    value: false
                }))),
                clients: result.clients,
                renderPage: true,
                openModal: true
            })
        })
    }


    onCloseHandler = () => {
        this.props.onClose()
        this.setState({ modalFields: this.initialFields, clientFields: this.clientFields, openModal: false, selectedClient: null })
    }

    renderClientFields = () => {

        const InputWrapper = this.InputWrapper

        let jsonMap = {}
        this.state.clientFields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <>
                <InputWrapper input={jsonMap['cui']} key={'cui'} onChange={() => { }} />
                <InputWrapper input={jsonMap['contactEmail']} key={'contactEmail'} onChange={() => { }} />
                <InputWrapper input={jsonMap['contactPhoneNumber']} key={'contactPhoneNumber'} onChange={() => { }} />
                <InputWrapper input={jsonMap['name']} key={'name'} onChange={() => { }} />
            </>
        )

    }

    populateClientFields = event => {
        const clientsCopy = [...this.state.clients].map(field => ({ ...field }))
        const selectedClientIndex = clientsCopy.findIndex(index => index.id === Number(event.target.value))

        if (selectedClientIndex > -1) {
            const selectedClient = clientsCopy[selectedClientIndex]
            const clientFieldsCopy = [...this.state.clientFields].map(field => ({ ...field }))

            clientFieldsCopy.forEach((field, index) => {
                clientFieldsCopy[index].value = selectedClient[field.name]
                if (clientFieldsCopy[index].name === 'name' && !selectedClient.isLegalEntity) {
                    clientFieldsCopy[index].label = this.props.language.itemDetails.offer.name
                    clientFieldsCopy[index].value = selectedClient['name'].length ? selectedClient['name'] : selectedClient['contactName']
                }
            });

            this.setState({ clientFields: clientFieldsCopy, selectedClient: selectedClient })
        }
    }

    renderTestDriveFields = () => {


        const InputWrapper = this.InputWrapper

        let jsonMap = {}
        this.state.modalFields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <>
                <InputWrapper input={jsonMap['contractNumber']} key={'testDriveContractNumber'} onChange={event => this.onChangeHandler(event)} />
                <InputWrapper input={jsonMap['contractDate']} key={'testDriveContractDate'} onChange={event => this.onChangeHandler(event)} shrink={true} />
                <InputWrapper input={jsonMap['periodDays']} key={'testDrivePeriodDays'} onChange={event => this.onChangeHandler(event)} />
                <InputWrapper input={jsonMap['kmLimit']} key={'testDriveKMLimit'} onChange={event => this.onChangeHandler(event)} />
            </>
        )

    }

    onChangeHandler = event => {
        const fieldsCopy = [...this.state.modalFields].map(field => ({ ...field }))
        const currentIndex = fieldsCopy.findIndex(index => index.name === event.target.name)

        if (currentIndex > -1) {
            fieldsCopy[currentIndex].value = event.target.value
            this.setState({ modalFields: fieldsCopy })
        }
    }

    renderFields = () => {
        return (
            <div className={this.props.classes.testDriveModalContainer}>
                <div className={this.props.classes.clientContainer}>
                    {this.state.selectedClient ? this.renderClientFields() :
                        <div className={`${this.props.classes.equalFlex} ${this.props.classes.inputPadding}`}>
                            <InputGenerator
                                key={"client"}
                                fullWidth={true}
                                margin="dense"
                                value=''
                                label="Client"
                                options={this.state.dropdownMappedClients}
                                type='dropdownSelector'
                                onChange={event => this.populateClientFields(event)}
                            />
                        </div>}
                </div>
                <div className={this.props.classes.detailsContainer}>
                    {this.renderTestDriveFields()}
                </div>
            </div>

        )
    }

    onCreate = () => {
        if (!this.state.selectedClient) return NOTIFICATIONS.error(this.props.language.itemDetails.stock.noClient)

        let testDriveJson = {}
        this.state.modalFields.forEach(field => {
            testDriveJson[field.name] = field.value
        })

        testDriveJson.endDate = moment(testDriveJson["contractDate"]).add(testDriveJson["periodDays"], 'days').format(CONSTANTS.INPUT_TYPE_DATE_FORMAT)
        testDriveJson.clientId = this.state.selectedClient ? this.state.selectedClient.id : null

        this.props.createTestDrive(this.props.stockId, testDriveJson).then(() => {
            this.onCloseHandler()
            NOTIFICATIONS.success(this.props.language.itemDetails.stock.createTestDrive)
        })
            .catch(() => {
                NOTIFICATIONS.error(this.props.language.itemDetails.stock.failTestDrive)
            })
    }

    render() {
        const { language } = this.props

        return this.state.renderPage ? <SimpleModal
            styles={this.props.classes}
            open={this.state.openModal}
            onClose={() => this.onCloseHandler()}
            onCancel={() => this.onCloseHandler()}
            onAccept={() => this.onCreate()}
            acceptButtonText={language.offer.createModal.testDrive}
            cancelButtonText={language.offer.createModal.cancel}
            canSubmitForm={true}
            title={language.offer.createModal.testDriveTitle}
            maxWidth={'md'}
        >
            {this.renderFields()}
        </SimpleModal> : null
    }
}
const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getStockById: stockId => dispatch(STOCK.getById(stockId)),
    getClients: () => dispatch(CLIENT.get({ limit: CONSTANTS.ALL_ROWS })),
    createTestDrive: (stockId, testDriveJson) => dispatch(STOCK.createTestDrive(stockId, testDriveJson))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TestDriveModal))