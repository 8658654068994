import axios from '../../utils/axios'
import * as TYPES from '../actionTypes'
import { errorHandler } from '../../utils/apiFunctions'

const alertRoute = '/stocks/alerts'
const alertCountRoute = '/stocks/alerts/count'

const get = () => () =>
    axios.get(alertRoute)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const count = () => () =>
    axios.get(alertCountRoute)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const close = () => dispatch => {
    dispatch({
        type: TYPES.TOGGLE_ALERTS,
        payload: {
            isOpen: false,
            alerts: []
        }
    })
}

export const toggle = () => (dispatch, getState) => {
    let { alerts } = getState()
    if (!alerts.isOpen) {
        dispatch(get()).then(alerts => {
            dispatch({
                type: TYPES.TOGGLE_ALERTS,
                payload: {
                    isOpen: !alerts.isOpen,
                    alerts: alerts
                }
            })
        })
    } else {
        dispatch({
            type: TYPES.TOGGLE_ALERTS,
            payload: {
                isOpen: !alerts.isOpen,
                alerts: []
            }
        })
    }
}
