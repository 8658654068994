import React from 'react'
import { Tabs, Tab, withStyles } from '@material-ui/core'

const styles = theme => ({
    tabsContainer: {
        borderRadius: 4,
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.08)',
        border: '1px solid rgba(0,0,0,0.1)',
        backgroundColor: 'white'
    },
    tab: {
        borderRight: '1px solid rgba(0,0,0,0.1)',
        color: theme.palette.greyColor,
        flex: 1
    },
    selectedTab: {
        color: theme.palette.primaryColor
    },
    indicator: {
        backgroundColor: theme.palette.greenColor,
        height: 3
    }

})

export const TabPanelWrapper = props => {
    return props.index === props.value ? <div>
        {props.children}
    </div> : null
}

class SimpleTabs extends React.Component {
    static Tab = TabPanelWrapper

    render() {
        let { classes } = this.props

        return (
            <>
                <div className={classes.tabsContainer}>
                    <Tabs
                        classes={{ indicator: classes.indicator }}
                        value={this.props.value}
                        onChange={(event, index) => this.props.onChangeHandler(index)}>
                        {this.props.tabs.map((props, index) => <Tab
                            classes={{ selected: classes.selectedTab }}
                            className={classes.tab}
                            key={index}
                            value={props.index}
                            {...props} />)}
                    </Tabs>
                </div>
                {React.Children.map(this.props.children, child => React.cloneElement(child, {
                    value: this.props.value
                }))}
            </>
        )
    }
}

export default withStyles(styles)(SimpleTabs)