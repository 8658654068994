import React from 'react'
import { IconButton, MenuItem, Menu, ClickAwayListener } from '@material-ui/core'

class TooltipMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            options: this.props.options,
            anchorEl: null,
            open: false
        }
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget, open: true })
    }

    handleClose = clicked => {
        this.setState({ anchorEl: null, open: false })
        this.props.onSelect(clicked)
    }

    render() {
        const { anchorEl } = this.state

        return (
            <ClickAwayListener onClickAway={() => this.setState({ open: false })}>
                <>
                    <IconButton
                        aria-label="More"
                        aria-owns={this.state.open ? 'long-menu' : null}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        color='inherit'
                    >
                        {this.props.children}
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={this.state.open}
                        style={this.props.tooltipStyle}
                    >
                        {this.state.options.map((option, index) => (
                            <MenuItem key={index} onClick={() => this.handleClose(option.key)}>
                                {option.value}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            </ClickAwayListener>
        )
    }
}

export default TooltipMenu