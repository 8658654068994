import queryString from 'query-string'
import axios from '../../utils/axios'
import { errorHandler } from '../../utils/apiFunctions'

const baseRoute = '/clients'

export const get = queryParams => dispatch =>
    axios.get(baseRoute + `?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const getFromAnaf = queryParams => dispatch =>
    axios.get(baseRoute + `/anaf?${queryString.stringify(queryParams)}`)
        .then(response => Promise.resolve(response))
        .catch(errorHandler)

export const getById = clientId => dispatch => {
    return axios.get(`${baseRoute}/${clientId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)
}

export const create = client => dispatch =>
    axios.post(baseRoute, { client })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const edit = (clientId, client) => dispatch =>
    axios.put(`${baseRoute}/${clientId}`, { client })
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)

export const del = clientId => dispatch =>
    axios.delete(`${baseRoute}/${clientId}`)
        .then(response => Promise.resolve(response.data))
        .catch(errorHandler)