import React from 'react'

export const ClickWrapper = ({ children, onClick }) => (
    <React.Fragment>
        {React.Children.map(children, child => (
            React.cloneElement(child, {
                onClick,
            })
        ))}
    </React.Fragment>
)

export const formatNumber = (number, fractionDigits = 2) => new Intl.NumberFormat('ro-RO', { maximumFractionDigits: fractionDigits }).format(number)

export const setStatusHandler = (stockType, availableStatuses = []) => {
    let statusList = [...availableStatuses].map(status => ({ ...status }))

    switch (stockType) {
        case 'fleet':
            statusList = statusList.filter(status => status.name.toLowerCase() === 'ipmgstock' || status.name.toLowerCase() === 'ordermanufacturing')
            break
        case 'bailment':
            statusList = statusList.filter(status => status.name.toLowerCase() === 'providerstock')
            break
        case 'other':
            statusList = statusList.filter(status => status.name.toLowerCase() === 'ipmgstock' || status.name.toLowerCase() === 'ordermanufacturing')
            break
        case 'sh':
            statusList = statusList.filter(status => status.name.toLowerCase() === 'ipmgstock' || status.name.toLowerCase() === 'providerstock')
            break
        case 'external':
            statusList = statusList.filter(status => status.name.toLowerCase() === 'ipmgstock')
            break

    }

    return statusList
}

export const roleInArray = (roles, role) => {
    let exists = false
    role.forEach(r => {
        if (roles.includes(r)) {
            exists = true
            return
        }
    })

    return exists
}

export const generateOptionalsHtml = optionalsText => {
    let html = ''
    try {
        optionalsText.split('#').forEach(line => {
            if (line.length) {
                let lineSplit = line.split('@')
                if (lineSplit.length === 2) {
                    html += `<tr><td>${lineSplit[0].trim()}</td><td>${lineSplit[1].trim()}</td></tr>`
                }
            }
        })

        return html
    } catch (e) {
        console.log(e)
        return ''
    }
}